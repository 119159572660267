import React from 'react';
import { Alert, Slide, Snackbar } from '@mui/material';

import { devConsoleLog } from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { hideSnackbar, selectSnackbar } from '../../features/snackbar/snackbarSlice';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const FreedomSnackBar = () => {
  devConsoleLog('freedom snak bar');
  const dispatch = useDispatch();
  const { open, message, severity } = useSelector(selectSnackbar);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideSnackbar());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      TransitionComponent={SlideTransition}
      style={{ paddingTop: '50px', marginRight: '60px' }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert
        sx={{
          width: '100%'
        }}
        onClose={handleClose}
        severity={severity || 'success'}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default FreedomSnackBar;
