import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getFollowUpCustomerApiUrl } from '../../api/apiUrls/customerApiUrl';
import { GraphPeriod } from '../../FreedomConstants/freedomConstants';
import moment from 'moment';
import { devConsoleLog } from '../../utils/utilityFunctions';
const initialState = {
  graphTabValue: GraphPeriod.Week,
  investorsTopCount: 0,
  pendingInvestorsTopCount: 0,
  investorsSubCount: 0,
  pendingInvestorsSubCount: 0,
  graphData: [],
  customerType: 1,
  graphPeriod: 1,
  firstlineGraphCount: 0,
  affiliateGraphCount: 0,
  FollowUpDistributorData: [],
  PreviousInvitesData: [],
  PendingTabData: [],
  hideBottombar: false,
  graphDataForDropDown: [],
  selectedYearInvestment: moment().format('YYYY'),
  startDateOfGraphInvestment: moment().subtract(6, 'd').format('YYYY-MM-DD'),
  endDateOfGraphInvestment: moment().format('YYYY-MM-DD'),

  loading: false
  // startDateOfGraphInvestment: moment().clone().startOf('isoWeek').format('YYYY-MM-DD'),
  // endDateOfGraphInvestment: moment().clone().endOf('isoWeek').format('YYYY-MM-DD'),
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    updateGraphTabValue: (state, action) => {
      state.graphTabValue = action.payload;
    },
    updateInvestorsTopCount: (state, action) => {
      state.investorsTopCount = action.payload;
    },
    updatePendingInvestorsTopCount: (state, action) => {
      state.pendingInvestorsTopCount = action.payload;
    },
    updateInvestorsSubCount: (state, action) => {
      state.pendingInvestorsTopCount = action.payload;
    },
    updatePendingInvestorsSubCount: (state, action) => {
      state.pendingInvestorsSubCount = action.payload;
    },
    updateGraphDataForInvestment: (state, action) => {
      state.graphData = action.payload;
    },
    updateCustomerType: (state, action) => {
      state.customerType = action.payload;
    },
    updateGraphPeriodInvestment: (state, action) => {
      state.graphPeriod = action.payload;
    },
    updateFirstLineSubCount: (state, action) => {
      state.firstLineSubCount = action.payload;
    },
    updateAffiliatesSubCount: (state, action) => {
      state.affiliatesSubCount = action.payload;
    },
    updatePendingAffiliatesSubCount: (state, action) => {
      state.pendingAffiliatesSubCount = action.payload;
    },
    updatePendingTabData: (state, action) => {
      state.PendingTabData = action.payload;
    },
    updatehideBottombar: (state, action) => {
      state.hideBottombar = action.payload;
    },
    updateSelectedYearInvestment: (state, action) => {
      state.selectedYearInvestment = action.payload;
    },
    updateStartDateOfGraphInvestment: (state, action) => {
      state.startDateOfGraphInvestment = action.payload;
    },
    updateEndDateOfGraphInvestment: (state, action) => {
      state.endDateOfGraphInvestment = action.payload;
    },
    updateInvestorDashboardData: (state, action) => {
      const { pendingInvestorsTopCount, pendingInvestorsSubCount, investorsSubCount, investorsTopCount, graphDataForDropDown, loading } = action.payload;

      state.pendingInvestorsSubCount = pendingInvestorsSubCount;
      state.pendingInvestorsTopCount = pendingInvestorsTopCount;
      state.investorsSubCount = investorsSubCount;
      state.investorsTopCount = investorsTopCount;
      state.graphDataForDropDown = graphDataForDropDown;
      state.loading = loading;
    },

    updateLatestData: (state, action) => {
      const { graphPeriod, customerType } = action.payload;

      state.graphPeriod = graphPeriod;
      state.customerType = customerType;
    },

    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateStardAndEndDataOfInvestorGraph: (state, action) => {
      const { startDateOfGraphInvestment, endDateOfGraphInvestment, graphPeriod } = action.payload;
      state.startDateOfGraphInvestment = startDateOfGraphInvestment;
      state.endDateOfGraphInvestment = endDateOfGraphInvestment;

      if (graphPeriod) {
        state.graphPeriod = graphPeriod;
      }
    }
  }
  /**
     * affiliatesSubCount: 0,
    pendingAffiliatesSubCount: 0,
     */
});

export const getTabData = (accessToken) => async (dispatch) => {
  const today = new Date();

  const todayDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
  try {
    await axios
      .get(getFollowUpCustomerApiUrl(todayDate), {
        headers: { Authorization: 'Bearer ' + accessToken }
      })
      .then((res) => {
        devConsoleLog('response', res);
        dispatch(updatePendingTabData(res.data));
      });
  } catch (err) {
    throw new Error(err);
  }
};

// Action creators are generated for each case reducer function

export const {
  updateGraphTabValue,
  updateInvestorsTopCount,
  updatePendingInvestorsTopCount,
  updateInvestorsSubCount,
  updatePendingInvestorsSubCount,
  updatePendingAffiliateCount,
  updateManageAffiliateCount,
  updateFirstlineCount,
  updateDownlineCount,
  updateDownlineWeekArray,
  updateGraphDataForInvestment,
  updateCustomerType,
  updateGraphPeriodInvestment,
  updateFirstlineGraphCount,
  updateAffiliateGraphCount,
  updatePendingGraphCount,
  updateFirstLineSubCount,
  updateAffiliatesSubCount,
  updateFollowUpDistributorData,
  updatePendingAffiliatesSubCount,
  updatePreviousInvitesData,
  updatePendingTabData,
  updatehideBottombar,
  updateSelectedYearInvestment,
  updateStartDateOfGraphInvestment,
  updateEndDateOfGraphInvestment,
  updateInvestorDashboardData,
  updateLoading,
  updateLatestData,
  updateStardAndEndDataOfInvestorGraph
} = customerSlice.actions;

export const tabData = (state) => state.customer.PendingTabData;
export default customerSlice.reducer;
