import { styled } from '@mui/styles';
import React, { useContext } from 'react';
import { ColorModeContext } from '../../../store';
import { images } from '../../../url/SvgLinks';
import { paymentAccountType, withDrawOption } from '../../../FreedomConstants/freedomConstants';
const successLight = images.successLight;
const successDark = images.successDark;
const Image = styled('img')(({ theme }) => ({
  width: 272,
  height: 170,
  [theme.breakpoints.down('sm')]: {
    width: 272,
    height: 102
  }
}));
const FreedomSuccessImage = ({ paymentmethod }) => {
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  return (
    <>
      <Image src={mode === 'light' ? successLight : successDark} />

      {paymentmethod === (paymentAccountType.bankAccount || withDrawOption.bank) && <p style={{ marginTop: '5px' }}>Thanks!</p>}
      {paymentmethod === (paymentAccountType.bankAccount || withDrawOption.bank) && <p style={{ marginTop: '5px' }}>We are currently waiting for your Bank payment to be received.</p>}
    </>
  );
};

export default FreedomSuccessImage;
