import React from 'react';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { Alert, Box, Dialog, DialogContent, DialogContentText, Grid, useTheme } from '@mui/material';

import useStyle from '../Style';
import { images } from '../../url/SvgLinks';
import useFetch from '../../CustomHooks/useFetch';
import useLogout from '../../CustomHooks/useLogout';
import { appInsights } from '../../auth/appInsight';
import { capitalizeFullName, devConsoleLog } from '../../utils/utilityFunctions';
import downloadicondark from '../../Images/downloadicondark.svg';
import { getBaseUrl, getCompanyOnBoardForm } from '../../utils/userConfig';
import FreedomLinearLoader from '../FreedomLinearLoader/FreedomLinearLoader';
import FreedomWrapper from '../SharedComponent/FreedomWrapper/FreedomWrapper';
import FreedomButton1 from '../SharedComponent/FreedomButton1/FreedomButton1';
import FreedomButton2 from '../SharedComponent/FreedomButton2/FreedomButton2';
import { Para } from '../SharedComponent/WelcomeScreenElement/WelcomeScreenElement';

const logoimageaffiliate = images.logoimageaffiliate;

const Title = styled('div')(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '32px',
  lineHeight: '48px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    lineHeight: '36px'
  },
  color: theme.palette.text.primary,
  textAlign: 'center'
}));

const Investmentimg = styled('img')(({ theme }) => ({
  maxWidth: '643.77px',
  [theme.breakpoints.down('sm')]: {
    width: '320.23px !important'
  }
}));

const ContactWithAffiliate = () => {
  const theme = useTheme();
  const userName = useSelector((state) => state.user.user);
  const { fullName, userType } = userName;
  const { loading, error, data } = useFetch(
    `${getBaseUrl()}userinvitations?$filter=InvitedUserId%20eq%20@UserId&$top=1&$orderby=Id%20desc&$select=id,givenname,surname,fullname,status,usertype,invitationCode,invitedUserId&$expand=ParentUser($select=Username)&$expand=InvitedByUser($select=Username)`
  );
  const { handleLogOutRedirect } = useLogout();

  try {
    return (
      <FreedomWrapper>
        <Box mt={'200px'}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item md={12}>
              <Title>Hello {capitalizeFullName(data[0]?.Fullname)}</Title>
            </Grid>
            <Grid item xs={12} md={9}>
              <Para>
                {userType === 2 ? `Looks like your affiliate did not add you to the tree as yet. Please email a 'speed-up' request at:` : userType === 1 && `Looks like your affiliate did not set leg as yet. Please email a 'speed-up' request at:`}{' '}
                <br />
                <span style={{ color: theme.palette.primary.main }}>
                  {' '}
                  {loading && <FreedomLinearLoader />}
                  {error && <p>something went wrong</p>}
                  {data.length > 0 ? data[0]?.ParentUser?.Username || data[0]?.InvitedByUser?.Username : ''}
                </span>
              </Para>
            </Grid>
          </Grid>
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            <Grid item mt={2} xs={8} sm={7} md={4} lg={3}>
              <FreedomButton1 borderradius="10px" btnbackground={theme.palette.button.buttonbgcolor} lineheight="24px" onClick={handleLogOutRedirect}>
                Sign Out!
              </FreedomButton1>
            </Grid>
            {/* <Grid item container justifyContent="center" md={12}>
              <Investmentimg src={logoimageaffiliate} alt="" />
            </Grid> */}
          </Grid>
        </Box>
      </FreedomWrapper>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default ContactWithAffiliate;

/****
 * Document Verification Pending
 */

export const DocumentVerificationPending = () => {
  const theme = useTheme();
  const { givenname } = useSelector((state) => state.user.user);
  const { handleLogOutRedirect } = useLogout();
  const handleDownloadForm = () => {
    // Create an anchor element to trigger the download
    const anchor = document.createElement('a');
    anchor.href = getCompanyOnBoardForm();
    anchor.target = '_blank'; // Open in a new tab
    anchor.download = 'companyonboardingform.pdf'; // Set the desired file name

    // Trigger a click event on the anchor element to start the download
    anchor.click();

    // Remove the anchor element from the DOM
    anchor.remove();
  };

  try {
    return (
      <FreedomWrapper>
        <Box mt={'200px'}>
          <Grid container justifyContent="center" alignItems="center" spacing={1}>
            <Grid item md={12}>
              <Title>Hello {givenname}</Title>
            </Grid>
            <Grid item xs={12} md={4}>
              <Para fontsize={'14px'} textalign={'left'}>
                Thank you for submitting your company profile to our platform. Now, its approval is pending for company’s documents verification. It may take a while to get your company onboard.
                <br />
                <br />
                If you have any query, please contact at: <span style={{ color: theme.palette.primary.main }}>support@myfreedom.ae</span>
              </Para>
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item mt={2} xs={4} sm={4} md={2} lg={1.5}>
              <FreedomButton1 borderradius="10px" height={'40px'} btnbackground={theme.palette.button.buttonbgcolor} lineheight="24px" onClick={handleLogOutRedirect}>
                Sign Out!
              </FreedomButton1>
            </Grid>
            <Grid item mt={2} xs={4} sm={4} md={2} lg={1.5}>
              <FreedomButton2
                onClick={() => {
                  handleDownloadForm();
                }}
                height={'40px'}
                sx={{ ml: '4px' }}
                fontsize={'12px'}
                fontweight={400}
                btncolor={theme.palette.border.border}
                startIcon={<img src={downloadicondark} style={{ width: 14, height: 18 }} alt="download-icon" />}
              >
                {' '}
                Download Form
              </FreedomButton2>
            </Grid>
          </Grid>
        </Box>
      </FreedomWrapper>
    );
  } catch (error) {
    devConsoleLog(error);
    appInsights.trackException({ error: new Error(error) });
  }
};

/**
 * Inactive User
 */
export const InActiveUser = () => {
  const classes = useStyle();
  const { handleLogOutRedirect } = useLogout();
  const theme = useTheme();
  return (
    <Dialog
      open={true}
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody
      }}
      aria-describedby="alert-dialog-descroniption"
    >
      <DialogContent style={{ opacity: '1' }}>
        <DialogContentText id="alert-dialog-description" style={{ color: 'black' }}>
          <div>
            <Alert severity="warning">
              <b>Your Account is Inactive due to certain reason. Please Contact Support!</b>
            </Alert>
          </div>
          <Grid container xs={6} mt={2}>
            <FreedomButton1 btnbackground={theme.palette.button.buttonbgcolor} variant="contained" onClick={handleLogOutRedirect}>
              <b>Sign Out</b>
            </FreedomButton1>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
