// reducers/filterReducer.js
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { devConsoleLog } from '../../utils/utilityFunctions';
import { tradeHistory } from '../../freedomDB/db';

const today = moment().format('YYYY-MM-DD');
const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');
const startDateOfWeek = moment().startOf('week').format('YYYY-MM-DD');
const endDateOfWeek = moment().endOf('week').format('YYYY-MM-DD');
const startDateOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD');
const endDateOfCurrentMonth = moment().endOf('month').format('YYYY-MM-DD');

const initialState = {
  searchText: '',
  tradeDirection: 'All Direction',
  tradeHistoryDuration: tradeHistory[1].label,
  startDate: today,
  endDate: today
};

const tradeHistorySlice = createSlice({
  name: 'tradeHistory',
  initialState,
  reducers: {
    setFilterOption: (state, action) => {
      devConsoleLog('state', action.payload);
      const payload = action.payload;
      Object.keys(payload).forEach((key) => {
        if (state.hasOwnProperty(key)) {
          state[key] = payload[key];
        }
      });
    }
  }
});

export const { setFilterOption } = tradeHistorySlice.actions;
export default tradeHistorySlice.reducer;
