import { getBaseUrl, getBasicUrl } from '../../utils/userConfig';

const baseUrl = getBaseUrl();
const basicUrl = getBasicUrl();

/**
 *
 * api url for New Credit Card
 * end
 */
//=====================================================//

/**
 *
 * api url for Edit Credit Card
 * start
 */

export const getEditCreditCardsApiUrl = (databaseId) => {
  return `${baseUrl}usercreditcards?$filter=UserId eq @UserId and Active eq true&$select=Name,Id,Cvv,Cvvmasked,BillingAddressId,CardNumber,Active,CardNumberMasked,ExpMonth,ExpYear,IsSystemCard&$orderby=CreatedOn desc`;
};
/**
 *
 * api url for Edit Credit Card
 * end
 */

/**
 * API call for getting primary payment option
 */

export const getPrimaryPaymentOptionUrl = () => {
  return `${baseUrl}users?$filter=Id eq @UserId&$top=1&$select=Givenname,Surname,Username,PrimaryPaymentMethodType,PrimaryPaymentMethodId`;
};

export const getPrimaryCardUrl = (Id) => {
  return `${baseUrl}usercreditcards?$filter=UserId eq @UserId and Id eq ${Id} and Active eq true&$select=Name,Id,Cvv,Cvvmasked,BillingAddressId,CardNumber,Active,CardNumberMasked,ExpMonth,ExpYear,IsSystemCard&$orderby=CreatedOn desc`;
};
//=====================================================//

/**
 *
 * api url for Edit Bank Information
 * start
 */

export const getEditBankInformationApiUrl = (databaseId) => {
  return `${baseUrl}UserBankInformations?$filter=UserId eq @UserId&$select=Id,AccountName,AccountNumber,Iban,SwiftCode,BankName&$orderby=CreatedOn desc`;
  // return `${baseUrl}UserBankInformations?$filter=UserId eq @UserId&$top=1&$select=Id,AccountName,AccountNumber,Iban,SwiftCode,BankName&$orderby=CreatedOn desc`
};
/**
 *
 * api url for Edit Bank Information
 * end
 */
//=====================================================//
/**
 *
 * api url for Credit card information and userAddresses
 * start
 */

export const getCreditCardAndAddressInformationApiUrl = (addressId) => {
  return `${baseUrl}UserCreditCards?$select=Id,Name,CardNumberMasked,ExpMonth,ExpYear,Cvvmasked&$orderby=CreatedOn desc`;
  // return `${baseUrl}UserCreditCards?$filter=BillingAddressId eq ${addressId}&$top=1&$select=Id,Name,CardNumberMasked,ExpMonth,ExpYear,Cvvmasked&$orderby=CreatedOn desc`;
};

/**
 *
 * api url for Credit card information and userAddresses
 * end
 */
//=====================================================//
//=====================================================//
/**
 *
 * api url for getUserInternalAmountComponentWithThirtyDaysData
 * start
 */

export const getUserInternalAmountComponentWithThirtyDaysData = (startDate, endDate) => {
  return `${baseUrl}GetUserInternalAmountComponentWithThirtyDaysData(${startDate},${endDate})`;
};
/**
 *
 * api url for getUserInternalAmountComponentWithThirtyDaysData
 * end
 */
//=====================================================//
//=====================================================//
/**
 *
 * api url for GetUserTradingAmountComponentWithThirtyDaysData
 * start
 */

export const GetUserTradingAmountComponentWithThirtyDaysData = (startDate, endDate, userId = 0) => {
  return `${baseUrl}GetUserBalanceComponentData(${1},${startDate},${endDate}, ${userId})`;
  // return `${baseUrl}GetUserTradingAmountComponentWithThirtyDaysData(${startDate},${endDate})`;
};
/**
 *
 * api url for getUserInternalAmountComponentWithThirtyDaysData
 * end
 */
//=====================================================//
//=====================================================//
/**
 *
 * api url for GetUserIncomeComponentWithThirtyDaysData
 * start
 */

export const GetUserIncomeComponentWithThirtyDaysData = (startDate, endDate) => {
  return `${baseUrl}GetUserIncomeComponentWithThirtyDaysData(${startDate},${endDate})`;
};
/**
 *
 * api url for getUserInternalAmountComponentWithThirtyDaysData
 * end
 */
//=====================================================//

//=====================================================//
//=====================================================//
//=====================================================//
/**
 *
 * api url for GetUserPendingPayments for dashboard
 * start
 */

// export const GetUserPendingPayments = () => {
//   // devConsoleLog(`${baseUrl}GetUserIncomeComponentWithThirtyDaysData(${startDate},${endDate})`)
//   return `${baseUrl}userinvoices?$filter=status eq 1&$top=10`;
// };
/**
 *
 * api url for getUserInternalAmountComponentWithThirtyDaysData
 * end
 */
//=====================================================//

//=====================================================//
/**
/**
 *
 * api url for GetUserPendingPayments for dashboard
 * start
 */

// export const GetUserPendingPaymentsforPendings = () => {
//   // devConsoleLog(`${baseUrl}GetUserIncomeComponentWithThirtyDaysData(${startDate},${endDate})`)
//   return `${baseUrl}usershoppingcarts?$filter=active eq true and isPaid eq false`;
// };
/**
 *
 * api url for getUserInternalAmountComponentWithThirtyDaysData
 * end
 */
//=====================================================//

//=====================================================//
/**
 *
 * api url for OTP Stored Procedures
 * start
 */

export const getOTPApiURL = (OTP, OTPType, systemID, OTPStatus) => {
  return `${baseUrl}updateotp('${OTP}',${OTPType},${systemID},${OTPStatus})`;
};

/**
 *
 * api url for OTP Stored Procedures
 * end
 */
//=====================================================//
//=====================================================//
/**
 *
 * api url for OTP Stored Procedures
 * start
 */

export const GetUserRecentActivities = () => {
  return `${baseUrl}getmypaymentactivities(1,100,'ID','desc')`;
};

/**
 *
 * api url for OTP Stored Procedures
 * end
 */
//=====================================================//
//=====================================================//
/**
 *
 * api url for OTP Stored Procedures
 * start
 */

export const GetUserInternalAmountComponentData = (graphPeriod, startDate, endDate) => {
  return `${baseUrl}GetUserInternalAmountComponentData(${graphPeriod},${startDate},${endDate},)`;
};

/**
 *
 * api url for OTP Stored Procedures
 * end
 */
//=====================================================//
//=====================================================//
/**
 *
 * api url for OTP Stored Procedures
 * start
 */

export const GetUserIncomeComponentData = (graphPeriod, startDate, endDate) => {
  return `${baseUrl}GetUserIncomeComponentData(${graphPeriod},${startDate},${endDate},)`;
};

/**
 *
 * api url for OTP Stored Procedures
 * end
 */
//=====================================================//
//=====================================================//
/**
 *
 * api url for OTP Stored Procedures
 * start
 */

export const GetUserTradingAmountComponentData = (graphPeriod, startDate, endDate) => {
  return `${baseUrl}GetUserTradingAmountComponentData(${graphPeriod},${startDate},${endDate},)`;
};

/**
 *
 * api url for OTP Stored Procedures
 * end
 */
//=====================================================//
//=====================================================//
/**
 *
 * api url for trasaction history table
 * start
 */

export const getTrasactionHistoryUrl = (
  page,
  countSkip,
  property,
  order,
  // status,
  searchedText
) => {
  if (searchedText === '') {
    return `${baseUrl}getmypaymentactivities(${page},${countSkip},'${property}','${order}')`;
  } else {
    return `${baseUrl}searchmypaymentactivities(${page},${countSkip},'${property}','${order}','${searchedText}')`;
  }
};

/**
 *
 * api url for rasaction history table
 * end
 */
//=====================================================//

// export const getPendingHistorySearchUrl = (
//   page,
//   countSkip,
//   property,
//   order,
//   // status,
//   searchedText
// ) => {
//   if (searchedText === "") {
//     return `${baseUrl}searchuserpendinginvoices(${page},${countSkip},'${property}','${order}')`;
//   } else {
//     return `${baseUrl}searchuserpendinginvoices(${page},${countSkip},'${property}','${order}','${searchedText}')`;
//   }
// };

export const getPendingHistoryUrl = (
  page,
  countSkip,
  property,
  order,
  // status,
  searchedText
) => {
  if (searchedText === '') {
    return `${baseUrl}getuserpendinginvoices(${page},${countSkip},'${property}','${order}')`;
  } else {
    return `${baseUrl}searchuserpendinginvoices(${page},${countSkip},'${property}','${order}','${searchedText}')`;
  }
};

/**
 *
 * api url for rasaction history table
 * end
 */
//=====================================================//

/**
 * cash deposit api credit card
 */

export const getStripPaymentUrl = () => {
  return `${basicUrl}payments/stripepayment`;
};

export const getWalletAppsUrl = () => {
  return `${basicUrl}Payments/GetWalletApps`;
};
export const getCryptoRatesUrl = (camount, currency, Walletid) => {
  return `${basicUrl}Payments/GetRate(${camount},${currency},${Walletid})`;
};
export const getCurrencyListUrl = () => {
  return `${basicUrl}Payments/GetCurrencyList(USD)`;
};
export const getCryptoStartPaymentUrl = () => {
  return `${basicUrl}Payments/StartPayment`;
};
/**
 * cash deposit api credit card
 */

export const getStripConfirmPaymentUrl = () => {
  return `${basicUrl}Payments/confirmpayment`;
};

export const getStripConfirmSubscriptionsUrl = () => {
  return `${basicUrl}data/usersubscriptions`;
};

export const getPublisableKeyStripeUrl = () => {
  return `${basicUrl}Payments/getpublishablekey`;
};

export const pendingInvoiceUrl = (id) => {
  return `${baseUrl}generatependingpaymentinvoiceotp(${id})`;
};
export const popupReminderUrl = (id) => {
  return `${basicUrl}Reminders/GetPopupData?UserId=${id}`;
};
