import React from 'react';
import { styled, useTheme } from '@mui/system';
import PropTypes from 'prop-types'; // Import PropTypes for type checking
import { appInsights } from '../../../auth/appInsight';

const Text = styled('p')(({ theme, padding, ...rest }) => ({
  fontFamily: rest.fontfamily || 'Poppins',
  fontStyle: 'normal',
  fontWeight: rest.fontweight || 400,
  fontSize: rest.fontsize || '14px !important',
  lineHeight: rest.lineheight || '21px',
  paddingRight: rest.paddingright || '10px',
  padding: padding || '0px',
  textAlign: rest.textalign || 'left',
  letterSpacing: '0.46px',
  whiteSpace: rest.whitespace || 'nowrap',
  width: rest.width || '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  margin: rest.margin || '0px',
  textTransform: rest.texttransform || '',
  [theme.breakpoints.down('sm')]: {
    margin: rest.smmargin || '0px 0px 0px 0px',
    fontSize: rest.smfontsize || '12px !important'
    // lineHeight: "20px",
  },
  [theme.breakpoints.up('xll')]: {
    fontSize: rest.xllfontsize || '12px'
    // lineHeight: "20px",
  },
  color: rest.textcolor || theme.palette.text.primary
  // ...rest
}));

export const FreedomDialogTypography = (props) => {
  const { children, ...rest } = props;
  try {
    return <Text {...rest}>{children}</Text>;
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
    return null; // Ensure a component is always returned
  }
};
