import axios from 'axios';
import { getInvestmentModule, getTradeCountAndPerformancedUrl } from '../apiUrls/investmentApiUrls';
import { devConsoleLog } from '../../utils/utilityFunctions';

export const GetUserProducts = async (accessToken, uniqueId) => {
  return await axios
    .get(getInvestmentModule(uniqueId), {
      headers: { Authorization: 'Bearer ' + accessToken }
    })
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      // handle error
      devConsoleLog('Error in Products', error);
    });
};

export const GetUserProductsNewApi = async (accessToken, id) => {
  return await axios
    .get(getInvestmentModule(id), {
      headers: { Authorization: 'Bearer ' + accessToken }
    })
    .then((res) => {
      devConsoleLog('data coming from getInvestmentModule', res.data);
      return res;
    })
    .catch(function (error) {
      // handle error
      devConsoleLog('Error in Products', error);
    });
};

export const GetTradeCountAndPerformanced = async (accessToken) => {
  return await axios
    .get(getTradeCountAndPerformancedUrl(), {
      headers: { Authorization: 'Bearer ' + accessToken }
    })
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      // handle error
      devConsoleLog('Error in Products', error);
    });
};
