import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invitationCodeId: 0,
  inviteCode: 0,
  isEmptyInviteCode: false,
  isValidInvitationCode: undefined
};

const invitationCodeSlice = createSlice({
  name: 'invitationCode',
  initialState,
  reducers: {
    updateInvitationCodeId: (state, action) => {
      state.invitationCodeId = action.payload;
    },
    updateInviteCode: (state, action) => {
      state.inviteCode = action.payload;
    },
    updateIsEmptyInviteCode: (state, action) => {
      state.isEmptyInviteCode = action.payload;
    },
    updateIsValidInvitationCode: (state, action) => {
      state.isValidInvitationCode = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateInvitationCodeId, updateInviteCode, updateIsEmptyInviteCode, updateIsValidInvitationCode } = invitationCodeSlice.actions;

export default invitationCodeSlice.reducer;
