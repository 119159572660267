export const getLiveTradeEnv = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return process.env.REACT_APP_TRADING_MIDA_APP_PRE_PROD_URL;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return process.env.REACT_APP_TRADING_MIDA_APP_PRE_PROD_URL;
  }
  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return process.env.REACT_APP_TRADING_MIDA_APP_PROD_URL;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return process.env.REACT_APP_TRADING_MIDA_APP_PROD_URL;
  }

  if (window.location.href.includes('https://test.myfreedom.ae') || window.location.href.includes('https://freedomv2.azurewebsites.net') || window.location.href.includes('http://test.myfreedom.ae/')) {
    return process.env.REACT_APP_TRADING_MIDA_APP_TEST_URL;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae/')) {
    return process.env.REACT_APP_TRADING_MIDA_APP_TEST_URL;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return process.env.REACT_APP_TRADING_MIDA_APP_TEST_URL;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return process.env.REACT_APP_TRADING_MIDA_DEV_URL;
  }
};
export const CTraderAccountReconciliation = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return process.env.REACT_APP_TRADING_BASE_API_PRE_PROD_URL;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return process.env.REACT_APP_TRADING_BASE_API_PRE_PROD_URL;
  }
  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return process.env.REACT_APP_TRADING_BASE_API_PROD_URL;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return process.env.REACT_APP_TRADING_BASE_API_PROD_URL;
  }

  if (window.location.href.includes('https://test.myfreedom.ae') || window.location.href.includes('https://freedomv2.azurewebsites.net') || window.location.href.includes('http://test.myfreedom.ae/')) {
    return process.env.REACT_APP_TRADING_BASE_API_TEST_URL;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae/')) {
    return process.env.REACT_APP_TRADING_BASE_API_TEST_URL;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return process.env.REACT_APP_TRADING_BASE_API_TEST_URL;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return process.env.REACT_APP_TRADING_BASE_API_DEV_URL;
  }
};
export const getBrokerLink = () => {
  // /**
  //  * for pre production
  //  */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return process.env.REACT_APP_TRADING_TOKEN_APP_PRE_PROD_URL;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return process.env.REACT_APP_TRADING_TOKEN_APP_PRE_PROD_URL;
  }
  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return process.env.REACT_APP_TRADING_TOKEN_APP_PROD_URL;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return process.env.REACT_APP_TRADING_TOKEN_APP_PROD_URL;
  }

  if (window.location.href.includes('https://test.myfreedom.ae') || window.location.href.includes('https://freedomv2.azurewebsites.net') || window.location.href.includes('http://test.myfreedom.ae')) {
    return process.env.REACT_APP_TRADING_TOKEN_APP_TEST_URL;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    return process.env.REACT_APP_TRADING_TOKEN_APP_TEST_URL;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return process.env.REACT_APP_TRADING_TOKEN_APP_TEST_URL;
  }

  if (window.location.href.includes('https://dev.myfreedom.ae') || window.location.href.includes('http://dev.myfreedom.ae')) {
    return process.env.REACT_APP_TRADING_TOKEN_APP_DEV_URL;
  }
};
export const getRealTimeLiveTradeEnv = () => {
  // /**
  //  * for pre production
  //  */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_TRADING_LIVEDATA_TEST_URL}/signalr`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_TRADING_LIVEDATA_TEST_URL}/signalr`;
  }
  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_TRADING_LIVEDATA_PROD_URL}/signalr`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_TRADING_LIVEDATA_PROD_URL}/signalr`;
  }

  if (window.location.href.includes('https://test.myfreedom.ae') || window.location.href.includes('https://freedomv2.azurewebsites.net') || window.location.href.includes('http://test.myfreedom.ae')) {
    return `${process.env.REACT_APP_TRADING_LIVEDATA_TEST_URL}/signalr`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    return `${process.env.REACT_APP_TRADING_LIVEDATA_TEST_URL}/signalr`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_TRADING_LIVEDATA_TEST_URL}/signalr`;
  }

  if (window.location.href.includes('https://dev.myfreedom.ae') || window.location.href.includes('http://dev.myfreedom.ae')) {
    return `${process.env.REACT_APP_TRADING_LIVEDATA_DEV_URL}/signalr`;
  }
};
