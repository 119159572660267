import axios from 'axios';
import { useSelector } from 'react-redux';
import React, { lazy, useEffect, useState, Suspense, useContext } from 'react';

import { devConsoleLog } from '../utils/utilityFunctions';
import { getBaseUrl, getRowNumber } from '../utils/userConfig';
import BusinessOnboardFee from '../OnbaordFeeFlow/BusinessFee/BusinessOnboardFee';
import { DocumentVerificationPending } from '../Components/Pages/ContactWithAffiliate';
import FreedomGifLoader from '../Components/SharedComponent/FreedomGifLoader/FreedomGifLoader';
import useTransactionFee from '../CustomHooks/useTransactionFee';
import { ColorModeContext } from '../store';

const NotAccessible = lazy(() => import('../Components/Pages/NotAccessible'));
const Main = lazy(() => import('../Main'));
const OnBoardMainScreen = lazy(() => import('../Lightonboard/OnBoardMainScreen'));
const OnBoardFeeLoading = lazy(() => import('../OnbaordFeeFlow/OnBoardFeeLoading'));
const ContactWithAffiliate = lazy(() => import('../Components/Pages/ContactWithAffiliate'));
const InActiveUser = lazy(() => import('../Components/Pages/ContactWithAffiliate'));
const OnBoardSelection = lazy(() => import('../Lightonboard/OnBoardSelection'));

const baseUrl = getBaseUrl();

const FlowControl = () => {
  const {
    colorMode: { toggleMode, mode }
  } = useContext(ColorModeContext);
  const user = useSelector((state) => state.user.user);
  const { accessToken, activeUser, parentUserLeg, parentUserId, rowNumber, userType, onboardingCompleted, isCodeValid: isValidInvitationCode } = user;
  const { themeOther } = useSelector((state) => state.user.user);
  const userother = useSelector((state) => state.user.user);
  useTransactionFee();

  const [localStates, setLocalStates] = useState({
    userStatus: 0,
    ledgerTransaction: false,
    loading: true
  });

  /**
   * This function should only call in case of company profile and the response should be modified e.g true or false
   */
  const getStatusAndLedgerFee = async () => {
    await axios
      .get(`${baseUrl}userinvitations?$filter=invitedUserId eq @UserId&$select=Id,Status`, {
        headers: { Authorization: 'Bearer ' + accessToken }
      })
      .then((res) => {
        if (res.data.length > 0) {
          setLocalStates((prevstates) => ({
            ...prevstates,
            userStatus: res.data[0].Status
          }));
        }
      });

    await axios
      .get(`${baseUrl}ledgertransactions?$filter=createdByUserId eq @UserId`, {
        headers: { Authorization: 'Bearer ' + accessToken }
      })
      .then((res) => {
        if (res.data.length > 0) {
          setLocalStates((prevstates) => ({
            ...prevstates,
            ledgerTransaction: true
          }));
        }
      });

    setLocalStates((prevstates) => ({
      ...prevstates,
      loading: false
    }));
  };
  useEffect(() => {
    devConsoleLog('flow control');
    getStatusAndLedgerFee();
  }, []);

  useEffect(() => {
    devConsoleLog('mode,theme,userother', mode, themeOther, userother);
    if (mode !== themeOther) toggleMode();
  }, []);

  const { userStatus, ledgerTransaction, loading } = localStates;

  if (loading) return <FreedomGifLoader />;

  if ((userStatus === 8 && ledgerTransaction === false && activeUser === true) || (rowNumber === getRowNumber() && onboardingCompleted === false)) {
    return <BusinessOnboardFee />;
  } else if ((userStatus === 8 && ledgerTransaction === true && activeUser === true) || (rowNumber === getRowNumber() && onboardingCompleted === false)) {
    return <DocumentVerificationPending />;
  }

  if ((userType === 2 && parentUserLeg !== null && parentUserId !== null && (onboardingCompleted === false || onboardingCompleted === 0) && activeUser === true) || (rowNumber === getRowNumber() && onboardingCompleted === false)) {
    return (
      <Suspense fallback={<FreedomGifLoader />}>
        {/* <OnBoardMainScreen /> */}
        <OnBoardSelection />
      </Suspense>
    );
  }

  if (activeUser === false && onboardingCompleted === false) {
    return (
      <Suspense fallback={<FreedomGifLoader />}>
        <InActiveUser />
      </Suspense>
    );
  }

  if ((userType === 2 || userType === 1) && parentUserLeg === null && activeUser === true && onboardingCompleted === true && rowNumber !== getRowNumber()) {
    return (
      <Suspense fallback={<FreedomGifLoader />}>
        <ContactWithAffiliate />
      </Suspense>
    );
  }

  if (onboardingCompleted === true)
    return (
      <Suspense fallback={<FreedomGifLoader />}>
        {/* <OnBoardFeeLoading /> */}
        <Main />
      </Suspense>
    );

  if (isValidInvitationCode === undefined) return <FreedomGifLoader />;

  if (isValidInvitationCode === true)
    return (
      <Suspense fallback={<FreedomGifLoader />}>
        <OnBoardSelection />
      </Suspense>
    );

  return (
    <Suspense fallback={<FreedomGifLoader />}>
      {/* <NotAccessible /> */}
      <OnBoardSelection />
    </Suspense>
  );
};

export default FlowControl;
