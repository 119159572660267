import { Box, styled } from '@mui/material';

export const FreedomScroll = styled(Box)(({ theme, height = '60', width = '100' }) => ({
  minHeight: `${height}vh`,
  maxHeight: `${height}vh`,
  height: '100%',
  width: `${width}%`,
  padding: '1px',
  overflowY: 'scroll',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
  // [theme.breakpoints.down('sm')]: {
  // 	minHeight: `300px`,
  // 	maxHeight: `300px`
  // }
}));
