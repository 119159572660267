import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useStyle from '../../Style';
import { FreedomTypography } from '../FreedomTypography/FreedomTypography';
import { appInsights } from '../../../auth/appInsight';
import { devConsoleLog } from '../../../utils/utilityFunctions';

const FreedomTimer = ({ startTime, disableButton }) => {
  const classes = useStyle();

  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(startTime);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  useEffect(() => {
    let timerId;
    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      devConsoleLog('expired');
      setRunTimer(false);
      setCountDown(0);
      disableButton(false);
    }
  }, [countDown, runTimer]);

  try {
    return (
      <Box>
        <FreedomTypography className={classes.text}>
          Time Remaining: {minutes}:{seconds}
        </FreedomTypography>
      </Box>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomTimer;
