import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, useTheme } from '@mui/material';
import { ColorModeContext } from '../../../store';
import { useMediaQuery } from '@mui/material';
import { appInsights } from '../../../auth/appInsight';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& .MuiFilledInput-root': {
      // height: "63px",
      // paddingLeft: 10,
      overflow: 'hidden',
      width: '100%',
      borderRadius: '8px',
      backgroundColor: theme.palette.background.default,
      // backgroundColor: theme.palette.mode === 'light' ?  "#ffffff" : "#171717",
      // backgroundColor: "#c7c7c7",
      color: `${theme.palette.text.primary} !important`,
      border: (props) => (props.errorBorder ? `2px solid ${theme.palette.primary.error}` : `2px solid ${theme.palette.border.textFieldBorder}`),
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        background: theme.palette.hoverColor.textFieldHover
        // color: '#000 !important',
        // background: 'yellow'
      },
      '& .MuiFilledInput-root.Mui-focused': {
        // borderColor: 'white',
        background: 'yellow'
      },
      '& .MuiFilledInput-input': {
        textTransform: (props) => (props?.texttransform ? props?.texttransform : 'capitalize')
      },
      // "&:focus": {
      //   background: "green",
      //   color: 'red !important',
      //   boxShadow: `1px 1px 10px ${theme.palette.primary.main}`,
      //   // borderColor: 'red'
      // },
      '& label.Mui-focused': {
        color: '#r'
      },
      // "& input.form-control":{
      //   color: "#000",
      // },
      '& :-webkit-autofill': {
        // '-webkit-text-fill-color': 'white',
        '-webkit-box-shadow': '0 0 0px 1000px #00000000 inset'
      },
      input: {
        '&::placeholder': {
          textOverflow: 'ellipsis !important',
          color: 'red'
        }
      }
    },
    '& .MuiFilledInput-root.Mui-focused': {
      outline: `0px 0px 2px 1px ${theme.palette.border.textFieldBorder}`
      // boxShadow: `0px 0px 2px 1px ${theme.palette.border.borderColor}`,
      // filter: 'drop-shadow(0px 4px 8px #E6B64A)',
    }
  },

  InputTextField: {
    '& .MuiFilledInput-root': {
      fontFamily: 'Poppins',
      // height: "55px",
      height: '63px',
      paddingLeft: 10,
      overflow: 'hidden',
      borderRadius: '8px',
      // borderRadius: "30px !important",
      backgroundColor: theme.palette.primary.main,
      color: `${theme.palette.primary.lightwhite} !important`,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: `${theme.palette.primary.dialogtextfield} !important`,
        color: `${theme.palette.primary.lightwhite} !important`
      },
      '&:focus': {
        backgroundColor: `${theme.palette.primary.dialogtextfield} !important`,
        color: `${theme.palette.primary.lightwhite} !important`,
        borderColor: theme.palette.primary.main
      },
      '& label.Mui-focused': {
        color: theme.palette.primary.lightwhite
      },
      '& input.form-control': {
        color: `${theme.palette.primary.lightwhite} !important`
      },
      '& :-webkit-autofill': {
        // '-webkit-text-fill-color': 'white',
        '-webkit-box-shadow': '0 0 0px 1000px #00000000 inset'
      }
    }
  },
  InputTextFieldDark: {
    '& .MuiFilledInput-root': {
      height: '63px',
      // paddingLeft: 10,
      overflow: 'hidden',
      borderRadius: '8px',
      backgroundColor: theme.palette.primary.white,
      // backgroundColor: "#c7c7c7",
      color: `${theme.palette.primary.black} !important`,
      border: '1px solid orange',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        // backgroundColor: "#c7c7c7",
        color: `${theme.palette.primary.lightwhite} !important`
        // background: 'yellow'
      },
      '& .MuiFilledInput-root.Mui-focused': {
        // borderColor: 'white',
        background: 'yellow'
      },

      '&:focus': {
        background: 'green',
        color: 'red !important',
        outline: `1px 1px 10px ${theme.palette.primary.main}`
        // boxShadow: `1px 1px 10px ${theme.palette.primary.main}`,
        // borderColor: 'red'
      },
      '& label.Mui-focused': {
        color: theme.palette.primary.lightblack
      },
      '& input.form-control': {
        color: theme.palette.primary.lightblack
      },
      '& :-webkit-autofill': {
        // '-webkit-text-fill-color': 'white',
        '-webkit-box-shadow': '0 0 0px 1000px #00000000 inset'
      }
    }
  }
}));

export const FreedomTextField = (props) => {
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();
  // devConsoleLog(theme)
  const classes = useStyles(props);
  try {
    return (
      <TextField
        // inputProps={{className: classes.input}}
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: isSmallScreen ? 12 : 16
          }
        }}
        InputLabelProps={{
          style: {
            color: theme.palette.primary.placeholder,
            fontSize: isSmallScreen ? 12 : 16
          }
        }}
        sx={{
          '& .MuiFormHelperText-root': {
            color: `${theme.palette.primary.error} !important`
          }
        }}
        // sx={{
        //   border: errorBorder ? "1px solid red" : `1px solid ${theme.palette.border.borderColor}`,
        //   borderRadius: "8.0859px !important"
        // }}
        // className={mode === "light" ? (classes.InputTextFieldDark) : (classes.InputTextField)}
        className={`${classes.root}`}
        {...props}
      />
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};
