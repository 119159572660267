import React from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NotificationListItem from './NotificationListItem';
import { notificationData } from './notificationFakeData';
import { appInsights } from '../../../auth/appInsight';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    height: 500
    // padding: '10px 20px'
  },
  header: {
    // backgroundColor: 'yellow',
    borderBottom: '1px solid #87878724',
    // position: 'sticky',
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between'
    // background: theme.palette.background.paper,
    // top: '0px',
    // zIndex: 8
  },
  title: {
    letterSpacing: '1px',
    fontWeight: 600
  },
  notificationBody: {
    overflowY: 'auto',
    padding: '10px 20px'
  }
}));

const NotificationSideBar = ({ handleNotificationbarClose }) => {
  const theme = useTheme();
  const classes = useStyles();

  // devConsoleLog(theme)
  try {
    return (
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Typography variant="h6" component="strong" className={classes.title}>
            Notifications
          </Typography>
          <Link href="#" color="inherit">
            clear All
          </Link>
        </Box>
        <Box className={classes.notificationBody}>
          <NotificationListItem data={notificationData} menuClose={handleNotificationbarClose} />
        </Box>
      </Box>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default NotificationSideBar;
