import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColorModeContext } from '../../store';
import { appInsights } from '../../auth/appInsight';

const CardsWrapper = styled(Box)(({ padding, theme, mode }) => ({
  borderRadius: '10px',
  padding: padding || '24px 42.5px',
  // background: mode === 'light' ? '#FFFFFF' : 'none',
  width: '100%',
  // marginBottom: '16px',

  [theme.breakpoints.down('sm')]: {
    padding: '5px '
  }
}));

const FreedomCardsWrapper = (props) => {
  const { children, padding } = props;
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  try {
    return (
      <CardsWrapper padding={padding} mode={mode}>
        {children}
      </CardsWrapper>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomCardsWrapper;
