import { Box, Grid, useTheme } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserProducts } from '../../../api/DataProvider/investmentDataProvider';
import FreedomButton1 from '../../../Components/SharedComponent/FreedomButton1/FreedomButton1';
import FreedomCheckbox1 from '../../../Components/SharedComponent/FreedomCheckbox1/FreedomCheckbox1';
import { setUserProduct, setUserProducts } from '../../../features/investment/productsSlice';
import useDataJson from '../../../freedomHooks/useDataJson';
import InvestmentModel from '../InvestmentModel.js/InvestmentModel';
import { appInsights } from '../../../auth/appInsight';
import CloseIcon from '../../../Components/SharedComponent/CloseIcon/CloseIcon';
import { tradingStatus } from '../../../FreedomConstants/freedomConstants';
import { images, svgIcon } from '../../../url/SvgLinks';
import { devConsoleLog } from '../../../utils/utilityFunctions';
import { CTraderAccountReconciliation } from '../../../utils/tradingConfig';

// const DisclaimerIcon = svgIcon.DisclaimerIcon
const lightProductSelect = images.lightProductSelect;

const darkProductSelect = images.darkProductSelect;

const ProductSubscriptionDialogBox = ({ openDialog, setOpenDialog, card, setOpen, showMessage, color }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { accessToken, uniqueId } = useSelector((state) => state.user.user);
  // );
  const userTradingBalance = useSelector((state) => state.investmentProducts.userBalance);
  const myPlanResponse = useSelector((state) => state.investmentProducts.myPlanResponse);

  const [checked, setChecked] = useState(false);
  // const uniqueId = localStorage.getItem("uniqueId")
  const { apiData } = card;

  const fetchUserProducts = async () => {
    const response = await GetUserProducts(accessToken, uniqueId);
    dispatch(setUserProduct(response?.data?.data?.userProduct));
  };
  const subscriptionHandler = async () => {
    if (userTradingBalance >= apiData.minimumDeposit) {
      // let payload = [
      //   {
      //     __metadata: { type: "UserProducts" },
      //     ...jsonPrefix,
      //     Id: 0,
      //     UserId: "@UserId",
      //     ProductID: apiData?.id,
      //     TradeStatus:tradingStatus.Idle,
      //     AccountBalance: userTradingBalance,
      //     LowMinRiskPercentage: apiData?.lowMinRiskMinPercentage,
      //     LowMinRiskBalance: apiData?.minimumDeposit,
      //     LowMaxRiskPercentage: apiData?.lowMaxRiskMaxPercentage,
      //     LowMaxRiskBalance: apiData?.LowMaxRiskBalance,
      //     MediumMinRiskPercentage: apiData?.MediumMinRiskPercentage,
      //     MediumMinRiskBalance: apiData?.MediumMinRiskPercentage,
      //     MediumMaxRiskPercentage: apiData?.MediumMinRiskPercentage,
      //     MediumMaxRiskBalance: apiData?.MediumMinRiskPercentage,
      //     HighMinRiskPercentage: apiData?.MediumMinRiskPercentage,
      //     HighMinRiskBalance: apiData?.MediumMinRiskPercentage,
      //     HighMaxRiskPercentage: apiData?.MediumMinRiskPercentage,
      //     HighMaxRiskBalance: apiData?.MediumMinRiskPercentage,
      //     Status: true,
      //     CreatedByUserID: "@UserId",
      //     ModifiedByUserID: "@UserId",
      //   },
      // ];

      let newPayload = { userId: uniqueId, productId: apiData?.id };

      const myJSON = JSON.stringify(newPayload);
      const secondJSON = JSON.stringify(myJSON);
      // devConsoleLog("product is subscribed",secondJSON)
      axios({
        url: `${CTraderAccountReconciliation()}/api/FreedomMyPlan/UpdateUserProduct`,
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + accessToken
        },
        method: 'post',
        data: myJSON
      })
        .then((res) => {
          devConsoleLog('res', res);
          showMessage('Information successfully saved.', 4000, 'info');
          fetchUserProducts();
          setOpen(true);
          setOpenDialog(false);
        })
        .catch((error) => {
          devConsoleLog(error);
          appInsights.trackException({ error: new Error(error) });
          showMessage('Error While Subscribe Product', 4000, 'error');
          setOpen(true);
          setOpenDialog(false);
        });
    } else {
      setOpenDialog(false);
    }
  };
  try {
    return (
      <>
        <Box>
          {openDialog === true && (
            <InvestmentModel setOpenDialog={setOpenDialog} openDialog={openDialog}>
              <CloseIcon
                padding={'0px'}
                handleonclick={() => {
                  setOpenDialog(false);
                  setChecked(false);
                }}
              />
              <Grid
                container
                display="flex"
                flexDirection="column"
                // spacing={2}
                sx={{
                  paddingBottom: '20px'
                }}
                textalign="center"
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    fontWeight: 700,
                    fontSize: '24px',
                    lineHeight: '36px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  textalign="center"
                  mb={3}
                >
                  <img
                    src={theme.palette.mode === 'light' ? lightProductSelect : darkProductSelect}
                    alt="alert-icon"
                    style={{
                      width: '22px',
                      height: '22px',
                      marginRight: '15px'
                    }}
                  />{' '}
                  Disclaimer
                </Grid>
                <Grid item xs={12} mb={3} px={2}>
                  Yes, I agree I want to subscribe <span style={{ color: color }}>{card?.cardName}</span>
                </Grid>
                <Grid item xs={12} textalign="center" display="flex" justifyContent="center">
                  {/* <FreedomButton1
                    // btnbackground={theme.palette.icon.iconColor}
                    btncolor={theme.palette.primary.onBoardTextColor}
                    btnborder={theme.palette.icon.iconColor}

                    variant="contained"
                    borderradius="10px"
                    // width="55%"
                    width='200px'
                    height={
                      "56px"
                    }
                    style={{marginRight:'10px'}}
                    onClick={() => {setOpenDialog(false); setChecked(false)}}
                  >
                    Cancel
                  </FreedomButton1> */}
                  <FreedomButton1
                    btnbackground={theme.palette.button.buttonbgcolor}
                    colorbtn={theme.palette.primary.white}
                    variant="contained"
                    borderradius="10px"
                    // width="55%"
                    width="200px"
                    height={'56px'}
                    // disabled={!checked}
                    sx={{ border: 'none' }}
                    onClick={() => {
                      subscriptionHandler();
                    }}
                  >
                    Ok
                  </FreedomButton1>
                </Grid>
                {/* </Box> */}
              </Grid>
            </InvestmentModel>
          )}
        </Box>
      </>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default ProductSubscriptionDialogBox;
