import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { getBrowserInfo, getOS, getOSVersion } from '../helper/helperFunctions';
import { useDispatch } from 'react-redux';
import { updateBrowserInfo } from '../features/location/locationSlice';

const useOS = () => {
  const [osDetails, setOsDetails] = useState('');
  const dispatch = useDispatch();

  const osInformation = async () => {
    let details = {};

    const os = getOS();
    const osVersion = getOSVersion();
    const browserInformation = getBrowserInfo();

    details = {
      os,
      osVersion,
      browserName: browserInformation.browserName,
      fullVersion: browserInformation.fullVersion
    };
    dispatch(updateBrowserInfo(details));
    setOsDetails(details);
  };

  useEffect(() => {
    osInformation();
  }, []);

  return { osDetails };
};

export default useOS;
