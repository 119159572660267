import { Box, Grid } from '@mui/material';
import FreedomPanel from '../../../Components/SharedComponent/FreedomPanel/FreedomPanel';
import SummaryComp from '../../shared/SummaryComp';

const UpgradeProfileSecondStep = ({ paymentPayMethod, handleChangeRadioButtonForPaymentMethod, balance, selectedVoucher, form, desc = 'Account Upgrade' }) => {
  return (
    <Grid item sm={12} lg={12}>
      <FreedomPanel padding="10px" style={{ minHeight: '60vh' }}>
        <Box sx={{ maxWidth: '1440px', margin: 'auto' }}>
          <SummaryComp paymentPayMethod={paymentPayMethod} balance={balance} handleChangeRadioButtonForPaymentMethod={handleChangeRadioButtonForPaymentMethod} selectedVoucher={selectedVoucher} form={form} desc={desc} />
        </Box>
      </FreedomPanel>
    </Grid>
  );
};

export default UpgradeProfileSecondStep;
