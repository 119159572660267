import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserAddressesUrl } from '../api/apiUrls/apiUrl';
import { appInsights } from '../auth/appInsight';
import { devConsoleLog } from '../utils/utilityFunctions';

const useAddress = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  const { accessToken } = useSelector((state) => state.user.user);

  const fetchData = async () => {
    await axios
      .get(getUserAddressesUrl(), { headers: { Authorization: 'Bearer ' + accessToken } })
      .then((res) => {
        setData(res.data);
        // setLoading(false)
        devConsoleLog('data from address hook', res.data);
      })
      .catch((error) => {
        setError(true);
        appInsights.trackException({ error: new Error('user Address 1' + error) });
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchData();
  }, []);
  try {
    return { loading, error, data, fetchData };
  } catch (error) {
    appInsights.trackException({ error: new Error('user addresses 2' + error) });
  }
};

export default useAddress;
