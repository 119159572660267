import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { appInsights } from '../auth/appInsight';
import { getTransactionFee } from '../api/apiUrls/apiUrl';
import { updateTransactionFee } from '../features/user/userSlice';

const useTransactionFee = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const {
    user: { accessToken }
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const fetchData = async () => {
    await axios
      .get(getTransactionFee(), { headers: { Authorization: 'Bearer ' + accessToken } })
      .then((res) => {
        dispatch(updateTransactionFee(res?.data?.Table[0]?.TransactionFee));
      })
      .catch((error) => {
        appInsights.trackException({ error: new Error('url' + error) });

        setError(true);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchData();
  }, []);
  try {
    return { loading, error, setError, fetchData };
  } catch (error) {
    appInsights.trackException({ error: new Error('fetch url' + error) });
  }
};

export default useTransactionFee;
