/**
 * investment svgs
 */

// export const standardBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/standardBg.svg';
// export const standardBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/standardBg.svg';
// export const standardLCardBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/standardLCardBg.svg';
// export const silverLCardBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/silverLCardBg.svg';
// export const silverBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/silverBg.svg';
// export const goldCardBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/goldCardBg.svg';
// export const platinumCardBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/platinumCardBg.svg';
// export const platinumBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/platinumBg.svg';
// export const goldFooterBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/goldFooterBg.svg';
// export const silverDLiveBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/silverDLiveBg.svg';
// export const standardDLiveBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/standardDLiveBg.svg';
// export const platinumFooterCardBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/platinumFooterCardBg.svg';
// export const standardFooterCardBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/standardFooterCardBg.svg';
// export const standardLFooterCardBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/standardLFooterCardBg.svg';
// export const platinumLFooterCardBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/platinumLFooterCardBg.svg';
// export const goldLFooterCardBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/goldLFooterCardBg.svg';
// export const silverLCardLiveBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/silverLCardLiveBg.svg';
// export const silverLFooterBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/silverLFooterBg.svg';
// export const standardLCardInactiveFooterBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/standardLCardInactiveFooterBg.svg';
// export const goldLliveBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/goldLliveBg.svg';
// export const silverLLive = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/silverLLive.svg';
// export const platinumLBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/platinumLBg.svg';
// export const goldLCardBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/goldLCardBg.svg';
// export const DashboardBasicBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/basicBg.svg';
// export const DashboardSilverLightBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/silveradvanceLightBg.svg';
// export const DashboardBasicLightBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/basicLightBg.svg';
// export const DashboardAdvanceLightBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/silveradvanceLightBg.svg';
// export const DashboardAdvanceBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/advanceBg.svg';
// export const DashboardSilverAddBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/silverAdd.svg';
// export const DashboardSilverLightAddBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/silverLightAdd.svg';
// export const DashboardGoldLightAddBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/goldLightAdd.svg';
// export const DashboardEliteLightAddBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/eliteLightBg.svg';
// export const DashboardElightLighCardBgg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/elightLighCardBg.svg';
// export const platinumLightLiveBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/platinumLightLiveBg.svg';
// export const goldLightLiveBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/goldLightLiveBg.svg';
// export const platinumLliveBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/platinumLliveBg.svg';
// export const platinumLCardBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/platinumLCardBg.svg';
// export const standardLliveBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/standardLliveBg.svg';
// export const standardFooterBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/standardFooterBg.svg';
// export const GoldFooterLight = 'https://myfreedomprod.blob.core.windows.net/frontendsvgs/GoldFooterLight.svg';
// export const GoldFooterDark = 'https://myfreedomprod.blob.core.windows.net/frontendsvgs/GoldFooterDark.svg';
// export const PlatinumFooterLight = 'https://myfreedomprod.blob.core.windows.net/frontendsvgs/PlatinumFooterLight.svg';
// export const PlatinumFooterDark = 'https://myfreedomprod.blob.core.windows.net/frontendsvgs/PlatinumFooterDark.svg';
// export const standardLFooterBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/standardLFooterBg.svg';
// export const silverBasicIcon = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/silverBasicIcon.svg';
// export const silverAdvanceIcon = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/silverAdvanceIcon.svg';
// export const silverProIcon = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/newImages/silverProIcon.svg';
// export const multipleAddBgLight = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/Images/dashboard/multipleAdd.svg';
// export const multipleAddBgDark = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/Images/dashboard/multipleAddDark.svg';
// export const addButtonBg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/Images/dashboard/addButton.svg';
export const basicProductbg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/basicProductbg.svg';
export const basicProductPlusbg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/basicProductPlusbg.svg';
export const supremeProductbg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/supremeProductbg.svg';
export const eliteProductbg = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/eliteProductbg.svg';
export const basicNameIcon = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/basicNameIcon.svg';
export const basicProductbgLight = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/basicProductbgLight.svg';
export const basicProductPlusbgLight = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/basicProductPlusbgLight.svg';
export const supremeProductbgLight = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/supremeProductbgLight.svg';
export const eliteProductbgLight = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/eliteProductbgLight.svg';
export const livePanel = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/livePanel.svg';
export const productDashboard = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/productDashboard.svg';
export const productSetting = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/Settings.svg';
// export const productRunning = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/productRunning.svg';
// export const productPaused = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/productPaused.svg';
// export const productStopped = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/productStopped.svg';
export const productChangeArrow = 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/productChangeArrow.svg';

export const investmentDashboardIcons = {
  brokerOverviewLinkIcon: 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/investmentdashboard/brokerOverviewLinkIcon.svg',
  productOverviewLinkIcon: 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/investmentdashboard/productOverviewLinkIcon.svg',
  purchaseProductLinkIcon: 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/investmentdashboard/purchaseProductLinkIcon.svg',
  tradingNumberBg: 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/investmentdashboard/tradingNumberBg.svg',
  tradingNumberBgLight: 'https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/investmentdashboard/tradingNumberBgLight.svg'
};

// export const svgLinksDark = {
//     standardBasicDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/standardBasicFinal.svg`,
//     standardAdvanceDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/standardAdvanceFinalDark.svg`,
//     standardProDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/standardProFinalDark.svg`,
//     silverBasicDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/silverBasicFinalDark.svg`,
//     silverAdvanceDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/silverAdvanceFinalDark.svg`,
//     silverProDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/silverProFinalDark.svg`,
//     goldDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/goldDarkInactive.svg`,
//     eliteDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/eliteFooterBgImg.svg`,
//     footerBasicActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/BasicActiveDark.svg`,
//     footerBasicInactiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/BasicInactiveDark.svg`,
//     footerStandardActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/StandardActiveDark.svg`,
//     footerStandardInactiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/StandardInactiveDark.svg`,
//     footerProActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/ProActiveDark.svg`,
//     footerProInactiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/ProInactiveDark.svg`,
//     footerbgdark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/footerbgdark.svg`,
//     footerSupremeFooterBgImg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/supremeFooterBgImg.svg`,
//     Silverbg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/silverbg.svg`,
//     productselectioninfo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/productselectioninfo.svg`,
//     eliteinv: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/eliteinv.svg`,
//     eliteinvsmall: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/eliteinvsmall.svg`,
//     standardbasicInv: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/stadandardBasicInv.svg`,
//     standardAdvanceInv: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/silverStadandardInv.svg`,
//     standardProInv: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/silverProInv.svg`,
//     silverBasicInv: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/silverBasicInv.svg`,
//     silverStandardInv: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/silverStandardInv.svg`,
//     silverProInv: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/silverProInv1.svg`,
//     supremeinv: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/supremeinv.svg`,
//     goldDarkprod: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/goldDark.svg`,
//     PlatinumBGDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/PlatinumBGDark.svg`,
//     BasicSiDarkInactive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/basicSiDarkInactive.svg`,

// }

export const svgLinksDark = {
  invDashMulBg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dashMulBg.svg`,
  // addButtonBg: addButtonBg,
  // multipleAddBgDark: multipleAddBgDark,
  // standardBasicDark: standardBg,
  // standardAdvanceDark: standardBg,
  // standardProDark: standardBg,
  // silverBasicDark: silverBg,
  // silverAdvanceDark: silverBg,
  // silverProDark: silverBg,
  // goldDark: goldCardBg,
  // eliteDark: platinumCardBg,
  // footerBasicActiveDark: standardFooterCardBg,
  // footerBasicInactiveDark: standardFooterCardBg,
  // footerStandardActiveDark: standardFooterCardBg,
  // footerStandardInactiveDark: standardFooterCardBg,
  // footerProActiveDark: standardFooterCardBg,
  // footerProInactiveDark: standardFooterCardBg,
  // footerbgdark: standardFooterBg,
  footerbgdark2: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/footerbgdark.svg`,
  // GoldFooterDark: GoldFooterDark,
  // PlatinumFooterDark: PlatinumFooterDark,
  // footerSupremeFooterBgImg: goldFooterBg,
  // Silverbg: silverBg,
  productselectioninfo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/productselectioninfo.svg`,
  // eliteinv: platinumFooterCardBg,
  // eliteinvsmall: platinumFooterCardBg,
  // standardbasicInv: DashboardBasicBg,
  // standardAdvanceInv: DashboardSilverLightBg,
  // standardProInv: DashboardBasicBg,
  // silverBasicInv: DashboardAdvanceBg,
  // silverStandardInv: DashboardAdvanceBg,
  // silverProInv: DashboardAdvanceBg,
  // supremeinv: goldFooterBg,
  // goldDarkprod: goldCardBg,
  // PlatinumBGDark: platinumBg,
  BasicSiDarkInactive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/basicSiDarkInactive.svg`,
  BrokerLoader: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/broker/brokerloader.svg`,
  DownArrow: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/broker/downArrow.svg`,
  UpArrow: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/broker/upArrow.svg`,
  PepperStone: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/broker/paperstone.svg`,
  IcMarket: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/broker/icmarkets.svg`,
  signupProcessIc: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/ICMarketSignUpDark.svg`,
  signupProcessPep: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/signupPepDark.svg`,
  icmarketImg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/marketsimg.svg`,
  pepmarketImgDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/pepmarketsimg.svg`,
  notificationTextureDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/NotificationTextureDark.svg`
};

export const svgLinksLight = {
  invDashMulBg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dashMulBg.svg`,
  // addButtonBg: addButtonBg,
  // multipleAddBgLight: multipleAddBgLight,
  // standardBasicLight: standardLCardBg,
  // standardAdvanceLight: standardLCardBg,
  // standardProLight: standardLCardBg,
  // silverBasicLight: silverLCardBg,
  // silverAdvanceLight: silverLCardBg,
  // silverProLight: silverLCardBg,
  // goldLight: goldLCardBg,
  // goldLightHover: goldCardBg,
  // standardbasicInv: DashboardBasicLightBg,
  // standardAdvanceInv: DashboardAdvanceLightBg,
  // standardProInv: DashboardBasicLightBg,
  // silverBasicInv: DashboardAdvanceLightBg,
  // silverStandardInv: DashboardAdvanceLightBg,
  // silverProInv: DashboardAdvanceLightBg,
  // supremeinv: goldFooterBg,
  // eliteinv: DashboardElightLighCardBgg,
  // footerBasicActiveLight: standardLFooterCardBg,
  // footerStandardActiveLight: standardLFooterCardBg,
  // footerBasicInactiveLight: standardLCardInactiveFooterBg,
  // footerStandardInactiveLight: standardLCardInactiveFooterBg,
  // footerProActiveLight: standardLFooterCardBg,
  // footerProInactiveLight: standardLCardInactiveFooterBg,
  // footerSupremeLight: goldLFooterCardBg,
  // footerEliteLight: platinumLFooterCardBg,
  // footerbglight: standardLFooterBg,
  // footerbglight2: silverLFooterBg,
  // GoldFooterLight: GoldFooterLight,
  // PlatinumFooterLight: PlatinumFooterLight,
  // StandardBasicInactiveBgImg: standardDLiveBg,
  // StandardAdvanceInactiveBgImg: standardDLiveBg,
  // StandardProInactiveBgImg1: standardDLiveBg,
  // SilverBasicInactiveBgImg: silverDLiveBg,
  // SilverAdvanceInactiveBgImg: silverDLiveBg,
  // SilverProInactiveBgImg1: silverDLiveBg,
  // SilverProLiveBgLight: silverLCardLiveBg,
  // SilverAdvanceBg: silverLLive,
  // SilverBasicBg: silverLLive,
  // BasicSiLightInactive: silverLCardLiveBg,
  // StandardBasicBg: standardLliveBg,
  // standardAdvanceFinalLight: standardLliveBg,
  // standardProFinalLight: standardLliveBg,
  // GoldSelectedLiveBgImg: goldLliveBg,
  // PlatinumCardbg: platinumLCardBg,
  // PlatinumLiveBg: platinumLliveBg,
  // silverproductdashboard: DashboardSilverAddBg,
  // silverproductdashboardLight: DashboardSilverLightAddBg,
  // goldproductdashboardLight: DashboardGoldLightAddBg,
  // eliteProductdashboardLight: DashboardEliteLightAddBg,
  investmentinfoicon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/investmentinfoicon.svg`,
  investmentcrossicon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/investmentcrossicon.svg`,
  warningicon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/warningicon.svg`,
  // goldLightpro: goldLFooterCardBg,
  // PlatinumBGLight: platinumLBg,
  // goldLiveBg: goldLightLiveBg,
  // platinumLiveBg: platinumLightLiveBg,
  BasicSiHover: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/basicSiHover.svg`,
  AdvanceSiHover: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/advanceSiHover.svg`,
  ProSiHover: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/proSiHover.svg`,
  BrokerLoader: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/broker/brokerloadeLight.svg`,
  PepperStone: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/broker/paperstoneLight.svg`,
  IcMarket: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/broker/icmarketsLight.svg`,
  signupProcessIc: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/ICMarketSignUpLight.svg`,
  signupProcessPep: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/signupPepLight.svg`,
  icmarketImg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/ICmarketLight.svg`,
  pepmarketImgDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/pepmarketsimg.svg`,
  notificationTextureLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/NotificationTextureLight.svg`
};

// export const svgLinksLight = {
//     standardBasicLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/standardBasicLightFinal.svg`,
//     standardAdvanceLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/standardAdvanceFinalLight.svg`,
//     standardProLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/standardProFinalLight.svg`,
//     silverBasicLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/silverBasicFinalLight.svg`,
//     silverAdvanceLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/silverAdvanceFinalLight.svg`,
//     silverProLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/silverProFinalLight.svg`,
//     goldLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/goldLightInactive.svg`,
//     goldLightHover: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/goldLightHover.svg`,
//     footerBasicActiveLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/BasicActiveLight.svg`,
//     footerStandardActiveLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/StandardActiveLight.svg`,
//     footerBasicInactiveLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/BasicActiveLight.svg`,
//     footerStandardInactiveLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/StandardInactiveLight.svg`,
//     footerProActiveLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/ProActiveLight.svg`,
//     footerProInactiveLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/ProInactiveLight.svg`,
//     footerSupremeLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/SupremeLight.svg`,
//     footerEliteLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/EliteLight.svg`,
//     footerbglight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/footer/footerbglight.svg`,
//     StandardBasicInactiveBgImg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/basicInactiveBgImg.svg`,
//     StandardAdvanceInactiveBgImg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/advanceInactiveBgImg.svg`,
//     StandardProInactiveBgImg1: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/proInactiveBgImg2.svg`,
//     SilverProLiveBgLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/silverProLiveBg.svg`,
//     SilverAdvanceBg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/silverAdvanceBg.svg`,
//     SilverBasicBg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/silverBasicBg.svg`,
//     BasicSiLightInactive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/basicSiLightInactive.svg`,
//     StandardBasicBg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/standardBasicBg.svg`,
//     standardAdvanceFinalLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/standardAdvanceFinalLight.svg`,
//     GoldSelectedLiveBgImg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/goldSelectedLive.svg`,
//     PlatinumCardbg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/platinumCardbg.svg`,
//     PlatinumLiveBg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/platinumLiveBg.svg`,
//     silverproductdashboard: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/silverproductdashboard.svg`,
//     investmentinfoicon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/investmentinfoicon.svg`,
//     investmentcrossicon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/investmentcrossicon.svg`,
//     warningicon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/warningicon.svg`,
//     goldLightpro: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/dark/goldLight.svg`,
//     PlatinumBGLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/PlatinumBGLight.svg`,
//     BasicSiHover: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/basicSiHover.svg`,
//     AdvanceSiHover: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/advanceSiHover.svg`,
//     ProSiHover: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/proSiHover.svg`,
// }

export const svgIcon = {
  standardbasicicon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/proIcon1.svg`,
  standardadvanceicon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/proIcon1.svg`,
  standardproicon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/proIcon1.svg`,
  // silverbasicicon: silverBasicIcon,
  // silveradvanceicon: silverAdvanceIcon,
  // silverproicon: silverProIcon,
  supremeIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/supremeIcon.svg`,
  eliteIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/eliteIcon.svg`,
  // silverPro: silverProIcon,
  // silverAdvance: silverAdvanceIcon,
  // silverBasic: silverBasicIcon,
  PlatinumIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/platinumIcon.svg`,
  AlertIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/alert.svg`,
  ResetIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/resetIcon.svg`,
  // SilverBasicIconpro: silverBasicIcon,
  SilverAdvanceInactiveIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/advanceInActiveIcon.svg`,
  SilverProInactiveIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/proInActiveIcon.svg`,
  DisclaimerIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/disclaimer.svg`
};

// export const svgIcon = {
//     standardbasicicon : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/basicicon.svg`,
//     standardadvanceicon : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/advanceicon.svg`,
//     standardproicon : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/proIcon1.svg`,
//     silverbasicicon : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/silverbasicicon.svg`,
//     silveradvanceicon : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/silveradvanceicon.svg`,
//     silverproicon : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/light/silverproicon.svg`,
//     supremeIcon : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/supremeIcon.svg`,
//     eliteIcon : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/eliteIcon.svg`,
//     silverPro : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/silverPro.svg`,
//     silverAdvance : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/silverAdvance.svg`,
//     silverBasic : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/silverBasic.svg`,
//     PlatinumIcon : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/platinumIcon.svg`,
//     AlertIcon : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/alert.svg`,
//     ResetIcon : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/resetIcon.svg`,
//     SilverBasicIconpro : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/silverBasicIcon.svg`,
//     SilverAdvanceInactiveIcon : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/advanceInActiveIcon.svg`,
//     SilverProInactiveIcon : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/proInActiveIcon.svg`,
//     DisclaimerIcon : `https://myfreedomdev.blob.core.windows.net/freedomsvgs/investment/disclaimer.svg`,

// }

/**
 * voucher images
 */

export const voucherSvg = {
  darkgold: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/darkgold.svg`,
  lightsilver: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/lightsilver.svg`,
  darksilver: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/darksilver.svg`,
  lightgold: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/lightgold.svg`,
  lightplatinum: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/lightplatinum.svg`,
  darkplatinum: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/darkplatinum.svg`,
  lightsilvertogold: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/lightsilvertogold.svg`,
  darksilvertogold: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/darksilvertogold.svg`,
  lightsilvertoplatinum: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/lightsilvertoplatinum.svg`,
  darksilvertoplatinum: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/darksilvertoplatinum.svg`,
  lightgoldtoplatinum: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/lightgoldtoplatinum.svg`,
  darkgoldtoplatinum: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/darkgoldtoplatinum.svg`,
  exclusivevoucherdark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/exclusivevoucherdark.svg`,
  exclusivevoucherlight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/exclusivevoucherlight.svg`,
  vipvoucherdark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/vipvoucherdark.svg`,
  vipvoucherlight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/vipvoucherlight.svg`,
  exclusivetovipvoucherdark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/ecclusivetovipvoucherdark.svg`,
  exclusivetovipvoucherlight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/ecclusivetovipvoucherlight.svg`,
  importantNotes: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/importantNotes.svg`,

  /**
   * upgrade profile
   */
  lightunselectedsilver: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/lightunselectedsilver.svg`,
  lightselectedsilver: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/lightselectedsilver.svg`,
  lightunselectedgold: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/lightunselectedgold.svg`,
  lightselectedgold: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/lightselectedgold.svg`,
  lightunselectedplatinum: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/lightunselectedplatinum.svg`,
  lightselectedplatinum: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/lightselectedplatinum.svg`,
  silverproductpopup: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/silverproductpopup.svg`,
  goldproductpopup: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/goldproductpopup.svg`,
  platinumproductpopup: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Voucher/purpleproductpopup.svg`
};

/**
 * payment svgs
 */

export const paymentsvg = {
  silver: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/sliver.svg`,
  gold: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/gold.svg`,
  platinum: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/platinum.svg`,
  sliverTogold: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/sliverTogold.svg`,
  goldToplatinum: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/goldToplatinum.svg`,
  slivertoplatinum: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/slivertoplatinum.svg`,
  silverDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/sliverDark.svg`,
  goldDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/goldDark.svg`,
  platinumDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/platinumDark.svg`,
  sliverTogoldDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/sliverTogoldDark.svg`,
  goldToplatinumDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/goldToplatinumDark.svg`,
  slivertoplatinumDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/goldToplatinumDark.svg`,
  internalCard: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/internalCard.svg`,
  internalCardLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/internalCardLight.svg`,
  creditCard: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/creditCard.svg`,
  creditCardLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/creditCardLight.svg`,
  bankCard: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/bankCard.svg`,
  visa: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/visa.svg`,
  master: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/payment/master.svg`
};

export const images = {
  notfound: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/404.gif`,
  logo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/logo.png`,
  EmptyTable: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/EmptyHistoryTable.svg`,
  delete: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/delete.svg`,
  avatarimage: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/img-07.png`,
  avatarimagewhite: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/FreedomLogoWhite.png`,
  logoimageaffiliate: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/welcomeImage.svg`,
  darkWelcomeImage: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/darkWelcomeImage.svg`,
  EmptyCardIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/EmptyCardIcon.svg`,
  uploadfileicon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/uploadicon.svg`,
  FreedomSplash: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/FreedomSplash.gif`,
  successLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/successLight.svg`,
  successDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/successDark.svg`,
  silver: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/silvericon.svg`,
  gold: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/goldicon.svg`,
  platinum: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/platinumicon.svg`,
  standard: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/standardlogo.svg`,
  successlogo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/Successlogo.png`,
  welcomeonboardimg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/welcomeonboardimg.svg`,
  emptydata: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/EmptyGraph1.svg`,
  darkemptydata: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/darkemptygraph.svg`,
  resendEmail: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/resendEmail.svg`,
  logoSvg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/Freedomlogo.svg`,
  RedDownArrow: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/RedDownArrow.svg`,
  pepperstonelogo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/pepperstonelogo.svg`,
  icmarketlogo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/icmarketlogo.svg`,
  productselectioninfo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/productselectioninfo.svg`,
  GreenUpgraph: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/GreenUpgraph.svg`,
  logoimage: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/logoimage.svg`,
  cardTwo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/card-02.png`,
  Success: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/Success.svg`,
  circleTermAndCondition: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/circleTermAndCondition.svg`,
  youtubeIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/youtubeIcon.svg`,
  instagramIcon: `https://myfreedomdev.blob.core.windows.net/profilepics/instagram.png`,
  pepperstonelogolight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/lightpepperstonelogo.svg`,
  darkpepperstonelogo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/darkpepperstonelogo.svg`,
  darkticmarketlogo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/darkticmarketlogo.svg`,
  lighticmarketlogo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/lighticmarketlogo.svg`,
  CError: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/CTraderError.svg`,
  discord: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/Discord.svg`,
  uploadFile: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/uploadFile.svg`,
  preView: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/preView.svg`,
  emptyImage: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/emptyImage.svg`,
  hideTextIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/hideeyeIcon.svg`,
  showTextIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/showTextIcon.svg`,
  BlackCheckIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/blackCheckIcon.svg`,
  CircleBlackfilled: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/circleBlackfilled.svg`,
  NoVoucher: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/NoVoucher.svg`,
  EmptyPayment: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/EmptyPayment.svg`,
  DarkEmptyPayment: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/DarkEmptyPayment.svg`,
  EmptyInvestor: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/EmptyInvestor.svg`,
  DarkEmptyInvestor: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/DarkEmptyInvestor.svg`,
  EmptyPending: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/EmptyPending.svg`,
  DarkEmptyPending: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/DarkEmptyPending.svg`,
  EmptyTrade: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/EmptyTrade.svg`,
  DarkEmptyTrade: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/DarkEmptyTrade.svg`,
  ListIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/list.svg`,
  Bank: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/bankCard.svg`,
  rejected: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/rejected.svg`,
  bglight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/walletlightcard.svg`,
  bgdark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/bgdarkcard.svg`,
  Elipse104: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/Ellipse104.svg`,
  lightelipse: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/lightelipse.svg`,
  ShotOne: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/walletdel.png`,
  creditCardIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/creditCardIcon.png`,
  walletcardmanagementmainscreen: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/walletcardmanagementmainscreen.svg`,
  lightProductSelect: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/lightproductselect.svg`,
  darkProductSelect: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/darkproductselect.svg`,
  copyIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/images/CopyIcon.svg`
};

export const wallet = {
  incomingamounticon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/incomingamounticon.svg`,
  outgoingamounticon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/outgoingamounticon.svg`,
  PrimaryCard: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/PrimaryCard.svg`,
  CreditCardActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/CreditCardActive.svg`,
  CreditCardInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/CreditCardInActive.svg`,
  BankActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/BankActive.svg`,
  BankInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/BankInActive.svg`,
  AddBankCard: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/AddBankCard.svg`,
  AddCreditCard: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/AddCreditCard.svg`,
  BankCardLogo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/BankCardLogo.svg`,
  CreditCardLogo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/CreditCardLogo.svg`,
  lightEmptyImage: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/lightEmptyInvestment.png`,
  darkEmptyImage: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/darkEmptyInvestment.png`,
  ForwardArrow: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/ForwardArrow.svg`,
  BackwardArrow: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/BackwardArrow.svg`,
  darkEmptyImageWallet: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/darkEmptyWallet.png`,
  lightEmptyImageWallet: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/lightEmptyWallet.png`,
  safetyDarkCard: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/safetyDarkCard.svg`,
  safetyIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/safetyIcon.svg`,
  safetyLightCard: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/safetyLightCard.svg`,
  forumpayqr: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/forumpayqr.svg`,
  copylogo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/wallet/copylogo.svg`
};

export const upgradeAccount = {
  SilverDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/UpgradeAccount/SilverDark.svg`,
  SilverLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/UpgradeAccount/SilverLight.svg`,
  GoldDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/UpgradeAccount/GoldDark.svg`,
  GoldLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/UpgradeAccount/GoldLight.svg`,
  PlatinumDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/UpgradeAccount/PlatinumDark.svg`,
  PlatinumLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/UpgradeAccount/PlatinumLight.svg`,
  ExclusiveAccountTypeIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/UpgradeAccount/ExclusiveAccountTypeIcon.svg`,
  VipAccountTypeIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/UpgradeAccount/VipAccountTypeIcon.svg`,
  ExclusiveDarkBg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/UpgradeAccount/ExclusiveDarkBg.svg`,
  ExclusiveLightBg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/UpgradeAccount/ExclusiveLightBg.svg`,
  VipDarkBg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/UpgradeAccount/VipDarkBg.svg`,
  VipLightBg: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/UpgradeAccount/VipLightBg.svg`
};

export const topNavbar = {
  freedomIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/topNavBar/freedomIcon.svg`,
  freedomIcon1: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/FreedomIcon.svg`,
  Sun: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/topNavBar/Sun.svg`,
  darkMode: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/topNavBar/lightMode.svg`
};

export const dialogIcon = {
  email: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/dialogIcon/email.svg`,
  whatsapp: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/dialogIcon/whatsApp.svg`,
  whatsappActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/dialogIcon/whatsAppActive.svg`,
  emailActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/dialogIcon/emailActive.svg`,
  closeIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/dialogIcon/close.svg`
};

export const bottomNavbar = {
  AffiliateActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/AffiliateActive.svg`,
  AffiliateActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/AffiliateActiveDark.svg`,
  AffiliateInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/AffiliateInActive.svg`,
  AffiliateInActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/AffiliateInActiveDark.svg`,
  EarningActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/EarningActive.svg`,
  EarningActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/EarningActiveDark.svg`,
  EarningInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/EarningInActive.svg`,
  EarningInActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/EarningInActiveDark.svg`,
  InvestmentActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/InvestmentActive.svg`,
  InvestmentActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/InvestmentActiveDark.svg`,
  InvestmentInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/InvestmentInActive.svg`,
  InvestmentInActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/InvestmentInActiveDark.svg`,
  InvestorActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/InvesterActive.svg`,
  InvestorActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/InvesterActiveDark.svg`,
  InvestorInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/InvesterInActive.svg`,
  InvestorInActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/InvesterInActiveDark.svg`,
  FriendsActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/FriendsActive.svg`,
  FriendsActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/FriendsActiveDark.svg`,
  FriendsInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/FriendsInActive.svg`,
  FriendsInActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/FriendsInActiveDark.svg`,
  WalletActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/WalletActive.svg`,
  WalletActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/WalletActiveDark.svg`,
  WalletInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/WalletInActive.svg`,
  WalletInActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/WalletInActiveDark.svg`,
  MyPlanActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/MyPlanActive.svg`,
  MyPlanInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/MyPlanInActive.svg`,
  AllPlanActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/AllPlanActive.svg`,
  AllPlanInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/AllPlanInActive.svg`,
  NewPlanActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/newPlan.svg`,
  NewPlanInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/newPlanInActive.svg`,
  LiveTradesActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/LiveTradesActive.svg`,
  LiveTradesInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/LiveTradesInActive.svg`,
  HistoryActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/HistoryActive.svg`,
  HistoryInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/HistoryInActive.svg`,
  MyPlanActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/MyPlanActiveDark.svg`,
  MyPlanInActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/MyPlanInActiveDark.svg`,
  AllPlanActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/AllPlanActiveDark.svg`,
  AllPlanInActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/AllPlanInActiveDark.svg`,
  LiveTradesActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/LiveTradesActiveDark.svg`,
  LiveTradesInActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/LiveTradesInActiveDark.svg`,
  HistoryActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/HistoryActiveDark.svg`,
  HistoryInActiveDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/bottomNavBar/HistoryInActiveDark.svg`,
  ViewAllProductActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/ViewAllProductActive.svg`,
  ViewAllProductInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/ViewAllProductInActive.svg`
};

export const affiliate = {
  Arrow: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/Arrow.svg`,
  AllInvestor: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/AllInvestor.svg`,
  Allmembers: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/Allmembers.svg`,
  Downline: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/Downline.svg`,
  Firstline: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/Firstline.svg`,
  Pending: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/Pending.svg`,
  DownlineUp: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/DownlineUp.svg`,
  DownlineDown: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/DownlineDown.svg`,
  DownlineBackword: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/DownlineBackword.svg`,
  DownlineRoot: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/DownlineRoot.svg`,
  TreeToTable: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/TreeToTable.svg`,
  TableToTree: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/TableToTree.svg`,
  DownlineWhite: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/DownlineWhite.svg`,
  DownlineManyRight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/DownlineManyRight.svg`,
  DownlineManyLeft: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/DownlineManyLeft.svg`,
  DownlineLeft: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/DownlineLeft.svg`,
  DownlineRight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/DownlineRight.svg`,
  darkemptydata: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/Affliates/darkemptygraph.svg`
};

export const tableIcon = {
  edit: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/edit.svg`,
  infoDelIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/infoDel.svg`,
  forward: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/forward.svg`,
  backward: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/backward.svg`,
  infoIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/infoIcon.svg`,
  infoIconD: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/infoIconD.svg`,
  leftLegIconDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/legLDark.svg`,
  rightLegIconDark: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/legRDark.svg`,
  leftLegIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/legL.svg`,
  rightLegIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/legR.svg`,
  upTreeView: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/upTreeView.svg`,
  downloadIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/downloadIcon.svg`,
  actionIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/actionsIcon.svg`,
  dollarIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/dollaricon.svg`,
  shareIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/shareicon.svg`,
  dangeralert: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/tableIcons/dangeralert.svg`
};

export const sideBarIcon = {
  mainDashboardIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/mainDashboard.svg`,
  affiliateIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/affiliateIcon.svg`,
  investorIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/investorIcon.svg`,
  walletIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/walletIcon.svg`,
  investmentIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/investmentIcon.svg`,
  earningIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/earningIcon.svg`,
  favoriteIcon: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/favoriteIcon.svg`,
  empatyImage: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/empatyForbottom.svg`,
  empatyImageLight: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/empatyForbottomLight.svg`,
  inviteAffiliateActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/inviteAffiliateActive.svg`,
  inviteAffiliateInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/inviteAffiliateInActive.svg`,
  pendingAffiliateActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/pendingAffiliateActive.svg`,
  pendingAffiliateInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/pendingAffiliateInActive.svg`,
  downlineActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/downlineActive.svg`,
  downlineInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/downlineInActive.svg`,
  firstlineActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/firstlineActive.svg`,
  firstlineInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/firstlineInActive.svg`,
  directActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/directActive.svg`,
  directInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/directInActive.svg`,
  depositActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/depositActive.svg`,
  depositInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/depositInActive.svg`,
  withdrawActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/withdrawActive.svg`,
  withdrawInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/withdrawInActive.svg`,
  pendingInvestorActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/pendingInvestorActive.svg`,
  pendingInvestorInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/pendingInvestorInActive.svg`,
  pendingPaymentActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/pendingPaymentActive.svg`,
  pendingPaymentInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/pendingPaymentInActive.svg`,
  recentActivitiesActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/recentActivitiesActive.svg`,
  recentActivitiesInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/recentActivitiesInActive.svg`,
  inviteInvestorActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/inviteInvestorActive.svg`,
  inviteInvestorInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/inviteInvestorInActive.svg`,
  starIconOne: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/star1.svg`,
  starIconTwo: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/star2.svg`,
  starIconThree: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/star3.svg`,
  starIconFourth: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/star4.svg`,
  friendDashboardActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/friendDashboardActive.svg`,
  friendDashboardInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/friendDashboardInActive.svg`,
  inviteFriendsActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/inviteFriendsActive.svg`,
  inviteFriendsInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/inviteFriendsInActive.svg`,
  pendingFriendsActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/pendingFriendsActive.svg`,
  pendingFriendsInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/pendingFriendsInActive.svg`,
  investorDashboardActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/investorDashboardActive.svg`,
  investorDashboardInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/investorDashboardInActive.svg`,
  brokerSetingActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/brokerSetingActive.svg`,
  brokerSetingInActive: `https://myfreedomdev.blob.core.windows.net/freedomsvgs/sidebar/brokerSetingInActive.svg`
};
