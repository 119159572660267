import React from 'react';
import { styled } from '@mui/system';
import { Button } from '@mui/material';

import { appInsights } from '../../../auth/appInsight';

const FreedomButton = styled(Button)(
  ({
    theme,
    btncolor,
    borderColor,
    width,
    padding,
    lineHeight,
    borderradius,
    btnbackground,
    gap,
    fontSize,
    height,
    fontWeight,
    margin,
    btnboxshadow,
    hoveredBackground,
    btnboxshadow1,
    btnhovercolor,
    disableLabelColor,
    fiexedColor,
    opacityOnDisable,
    disablecolor
  }) => ({
    background: btnbackground || 'transparent',
    backgroundColor: btnbackground,
    boxShadow: `0px 4px 14px ${btnboxshadow}`,
    border: `2px solid  ${borderColor ? borderColor : btncolor}`,
    borderRadius: borderradius,
    gap: gap,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: fontWeight || '500',
    fontSize: fontSize || '16px',
    lineHeight: lineHeight || '15px',
    color: fiexedColor ? fiexedColor : btncolor || theme.palette.text.primary,
    textTransform: 'capitalize',
    width: width || '100%',
    height: height || '56px',
    padding: padding || '2px 6px',
    margin: margin,
    '&:disabled': {
      color: fiexedColor ? fiexedColor : disablecolor || theme.palette.text.primary,
      opacity: opacityOnDisable ? opacityOnDisable : 0.7
    },

    '&:hover': {
      background: hoveredBackground
    },

    '&:selected': {
      background: 'black'
    },

    '&.Mui-selected': {
      background: 'red'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4px',
      fontSize: '12px'
    }
  })
);
const NewInvestmentButton = (props) => {
  const { children } = props;
  try {
    return (
      <FreedomButton disabled={props.disabled} disableElevation={true} disableRipple={true} disableFocusRipple={true} {...props}>
        {children}
      </FreedomButton>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default NewInvestmentButton;
