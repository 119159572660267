import { Box, Grid } from '@mui/material';
import React from 'react';
import { Title, Wrapper } from './upgradeprofile.styles';
import FreedomTypographyText from '../../../../Components/SharedComponent/FreedomTypographyText/FreedomTypographyText';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { FixedAfterTwoDigit1, capitalizeFullName, currentDate } from '../../../../utils/utilityFunctions';
import FreedomColorDivider from '../../../../Components/SharedComponent/FreedomColorDivider/FreedomColorDivider';
import PaymentCard from '../../PaymentCard';
import { paymentAccountType } from '../../../../FreedomConstants/freedomConstants';
import { useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import FreedomCreditCard from '../../../../Components/SharedComponent/FreedomCreditCard/FreedomCreditCard';
import { formatExpirationDate } from '../../../../Wallet/ManagePaymentOptions/utils/utilFunctions';

const CreditCardSummary = ({ paymentPayMethod, form, cards }) => {
  const theme = useTheme();
  const { transactionFee, fullname: username, userName: email } = useSelector((state) => state.user.user);

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center">
      <Grid container spacing={1} item xs={12} sm={12} md={10} lg={8} xl={8}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Wrapper height={'100%'} bordercolor={'none'} padding={'0px'}>
            <Wrapper flexdirection={'column'} padding={'10px'} alignItems={'center'}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <FreedomTypographyText fontsize={'16px'} lineheight={'24px'} fontweight={400} textalign={'left'}>
                  {' '}
                  Account Upgrade{' '}
                </FreedomTypographyText>
                <ArrowRightAltIcon />
                <FreedomTypographyText fontFamily="Poppins" fontsize={'16px'} lineheight={'24px'} fontweight={400} textalign={'left'}>
                  Card Payment
                </FreedomTypographyText>
              </Box>
            </Wrapper>
            <Wrapper>
              <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} lineheight={'18px'} fontweight={400}>
                  {' '}
                  Deposit{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.placeholder} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  $ {FixedAfterTwoDigit1(+form.voucherPrice)}{' '}
                </FreedomTypographyText>
              </Wrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
                <FreedomTypographyText lineheight={'18px'} fontsize={'12px'} fontweight={400}>
                  {' '}
                  Fee{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.placeholder} fontsize={'12px'} fontweight={600}>
                  {' '}
                  ${FixedAfterTwoDigit1(+transactionFee)}
                </FreedomTypographyText>
              </Wrapper>
            </Wrapper>
            <Wrapper height={'200px'} bordercolor={'none'} padding={'0px'}>
              {paymentPayMethod === paymentAccountType.creditCardAccount ? (
                <FreedomCreditCard number={`**** **** **** ${cards?.last4}`} name={' '} expiry={formatExpirationDate(`${cards?.expiryMonth}/${cards?.expiryYear}`)} issuer={cards?.brand} cvc={form.cvc} />
              ) : (
                <PaymentCard paymentPayMethod={paymentPayMethod} username={username} />
              )}
            </Wrapper>
          </Wrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Wrapper height={'100%'} padding={'8px'} justifycontent={'space-between'}>
            <Wrapper height={'100%'} bordercolor={'none'} padding={'0px'}>
              <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Email{' '}
                </FreedomTypographyText>
                <Title color={theme.palette.primary.paymentsummarycolor} fontsize={'12px'} fontweight={600} textalign={'right'}>
                  {email}
                </Title>
              </Wrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Transaction Date{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  {currentDate()}
                </FreedomTypographyText>
              </Wrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Card Number{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  **** **** **** {cards?.last4}{' '}
                </FreedomTypographyText>
              </Wrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Payment Method{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  {paymentPayMethod === paymentAccountType.internalAccount
                    ? 'Internal Account'
                    : paymentPayMethod === paymentAccountType.creditCardAccount
                      ? capitalizeFullName(`${cards?.funding} Card`)
                      : paymentPayMethod === paymentAccountType.bankAccount
                        ? 'Bank'
                        : ''}{' '}
                </FreedomTypographyText>
              </Wrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Expiry Month{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  {cards?.expMonth}{' '}
                </FreedomTypographyText>
              </Wrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Expiry Year{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  {cards?.expYear}{' '}
                </FreedomTypographyText>
              </Wrapper>
            </Wrapper>
            <Wrapper flexdirection={'row'} justifycontent={'space-between'} padding={'10px'} alignItems={'center'}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} lineheight={'18px'} fontweight={400} textalign={'left'}>
                  {' '}
                  Total Amount Charged{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'10px'} lineheight={'14px'} fontweight={400} textalign={'left'}>
                  {' '}
                  Total Amount Charged =(Deposit + Fee)
                </FreedomTypographyText>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'14px'} lineheight={'18px'} fontweight={700} color={theme.palette.primary}>
                  {' '}
                  $ {FixedAfterTwoDigit1(+(form.voucherPrice + +transactionFee))}
                </FreedomTypographyText>
              </Box>
            </Wrapper>
          </Wrapper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreditCardSummary;
