import React from 'react';
import { paymentAccountType, paymentOTPType } from '../../../FreedomConstants/freedomConstants';
import FreedomAlert from '../../../Components/SharedComponent/FreedomAlert/FreedomAlert';
import { Grid } from '@mui/material';
import FreedomWrapperRoot from '../../../Components/SharedComponent/FreedomWrapperRoot/FreedomWrapperRoot';
import FreedomOTP from '../../../Components/FreedomOTP';
import BankDetail from './components/BankDetail';
import { PaymentElement } from '@stripe/react-stripe-js';
import FreedomPanel from '../../../Components/SharedComponent/FreedomPanel/FreedomPanel';

const UpgradeProfileThirdStep = ({ paymentPayMethod, openAlertMessage, opentAlert, setOpenAlert, showSnackbar, setActiveStep, systemID, confirmOTP, openDialog, setOpenDialog, setLoading }) => {
  if (paymentPayMethod == paymentAccountType.creditCardAccount) {
    return (
      <Grid container item sm={12} lg={12}>
        <FreedomPanel padding="10px" style={{ minHeight: '60vh' }}>
          <Grid container item justifyContent={'center'}>
            <Grid item xs={12} md={8} xlll={6} m={1}>
              <FreedomAlert severity={'error'} message={openAlertMessage} opentAlert={opentAlert} setOpenAlert={setOpenAlert} />
              <PaymentElement />
            </Grid>
          </Grid>
        </FreedomPanel>
      </Grid>
    );
  } else if (paymentPayMethod == paymentAccountType.internalAccount) {
    return (
      <Grid item sm={12} lg={12}>
        <FreedomWrapperRoot marginTop="0px" height="57.6">
          <Grid container justifyContent="center">
            <FreedomOTP showMessage={showSnackbar} setActiveStep={setActiveStep} systemID={systemID} paymentOTPType={paymentOTPType.account} ref={confirmOTP} setLoading={setLoading} />
          </Grid>
        </FreedomWrapperRoot>
      </Grid>
    );
  } else if (paymentPayMethod == paymentAccountType.bankAccount) {
    return (
      <Grid item sm={12} lg={12}>
        <FreedomWrapperRoot height="61" marginTop="0px">
          <BankDetail openDialog={openDialog} setOpenDialog={setOpenDialog} systemID={systemID} />
        </FreedomWrapperRoot>
      </Grid>
    );
  }
};

export default UpgradeProfileThirdStep;
