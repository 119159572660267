import React, { useContext } from 'react';
import { ColorModeContext } from '../../../store';
// import useStyle from '../../Style';
import { Box, Divider, Paper } from '@mui/material';
import { FreedomTypography } from '../FreedomTypography/FreedomTypography';
import { makeStyles, styled } from '@mui/styles';
import { appInsights } from '../../../auth/appInsight';

const Wrapper = styled(Box)(({ theme, padding, border, bgcolor }) => ({
  background: bgcolor || theme.palette.background.default,
  border: border || `2px solid ${theme.palette.border.containerborder}`,
  width: '100%',
  // height: '100%',
  padding: padding || '22.99px 43.24px 36.33px 31.19px',
  borderRadius: '10px',
  [theme.breakpoints.down('sm')]: {
    padding: '8px'
  }
  // [theme.breakpoints.up("xlll")]: {
  //   height: "calc(100vh - 250px)",
  // },
}));

// const useStyle = makeStyles((theme) => ({
//   boxWrapLight: {
//     background: theme.palette.background.default,
//     border: `2px solid ${theme.palette.border.containerborder}`,
//     width: "100%",
//     padding: (props) => props.padding || "22.99px 43.24px 36.33px 31.19px",
//     borderRadius: "10px",
//     [theme.breakpoints.down("sm")]: {
//       padding: "8px",
//     },
//   },
// }));

const FreedomPanel = (props) => {
  const { children, ...rest } = props;
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  // const classes = useStyle(props);

  try {
    return (
      // <Box  className={mode === "light" ? (classes.boxWrapLight) : (classes.boxWrapDark)}>
      <Wrapper {...rest}>{children}</Wrapper>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomPanel;
