import { getUserLocation } from './actions';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  location: {},
  loading: false,
  error: ''
};

const geolocationSlice = createSlice({
  name: 'geoLocation',
  initialState,
  // reducers: {
  //   getUserLocations(state, action){
  //     console.log("action from reducer")
  //   }
  // },
  extraReducers: (builder) => {
    builder.addCase(getUserLocation.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUserLocation.fulfilled, (state, action) => {
      state.loading = false;
      state.location = action.payload;
    });
    builder.addCase(getUserLocation.rejected, (state, action) => {
      state.loading = false;
      state.location = '';
      state.error = action.error.message;
    });
  }
});

export const { getUserLocations } = geolocationSlice.actions;

const geoLocationReducer = geolocationSlice.reducer;

export default geoLocationReducer;
