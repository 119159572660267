import { Box, Card, Dialog, Grid, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FreedomButton1 from '../../../Components/SharedComponent/FreedomButton1/FreedomButton1';
import FreedomTypographyText from '../../../Components/SharedComponent/FreedomTypographyText/FreedomTypographyText';
import { Para } from '../../../Components/SharedComponent/WelcomeScreenElement/WelcomeScreenElement';
import useStyle from '../../../Components/Style';
import { appInsights } from '../../../auth/appInsight';
const useDialogStyle = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '15px',
      maxWidth: '1100px'
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  dialogBox: {
    border: `2px solid ${theme.palette.border.popupBorder}`,
    padding: '15px 60px 0px 60px',
    background: theme.palette.primary.defualt,
    borderRadius: '15px !important',
    width: '100%',
    overflowY: 'auto!important',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px'
    }
  },
  term: {
    border: `1px solid ${theme.palette.border.borderColor}`,
    borderRadius: '5px',
    padding: '16px',
    marginBottom: '8px'
  }
}));
const InvestmentModel = ({ setOpenDialog, openDialog, dialogamountcolor, productName, amount, width, children }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const mobile360 = useMediaQuery(theme.breakpoints.down('sm'));
  const dialogStyle = useDialogStyle();
  const confirmClose = () => {
    setOpenDialog(false);
  };
  try {
    return (
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={dialogStyle.root}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { width } // Set your width here
            }
          }
        }}
      >
        <Card className={dialogStyle.dialogBox}>{children}</Card>
      </Dialog>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default InvestmentModel;
