import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

////////////////////English////////////////////////
// Translation Common English
import translationCommonen from '../Translation/en/commonen.json';
import translationSideBaren from '../Translation/en/translationSideBar.json';

// Translation My Setting English
import traslationMyInformationen from '../Translation/en/Settings/translationMyInformationen.json';
import translationNotificationen from '../Translation/en/Settings/translationNotificationen.json';
import translationKYCRegistrationen from '../Translation/en/Settings/translationKYCRegistrationen.json';

// Translation Customer English
import translationAddCustomeren from '../Translation/en/Customer/translationAddCustomeren.json';

// Translation Distributor English
import translationAddNewDistributoren from '../Translation/en/Distributor/translationAddNewDistributoren.json';
import translationPendingDistributoren from '../Translation/en/Distributor/translationPendingDistributoren.json';

// Translation Wallet English
import translationDashboarden from '../Translation/en/Wallet/translationDashboarden.json';
import translationAccountOverviewen from '../Translation/en/Wallet/translationAccountOverviewen.json';
import translationCashDepositen from '../Translation/en/Wallet/translationCashDepositen.json';
import translationBankAccounten from '../Translation/en/Wallet/translationBankAccounten.json';
import translationAddNewCreditCarden from '../Translation/en/Wallet/translationAddNewCreditCarden.json';
import translationWithdrawRequesten from '../Translation/en/Wallet/translationWithdrawRequesten.json';
import translationInternalTransferen from '../Translation/en/Wallet/translationInternalTransferen.json';
import translationOverviewInternalTransferen from '../Translation/en/Wallet/translationOverviewInternalTransferen.json';
import translationManagePaymentOptionen from '../Translation/en/Wallet/translationManagePaymentOptionen.json';
import translationCurrentCreditCarden from '../Translation/en/Wallet/translationCurrentCreditCarden.json';
import translationCurrentBankAccounten from '../Translation/en/Wallet/translationCurrentBankAccounten.json';

// // Translation Earning And Expens English
import translationEarningAndExpenseDashboarden from '../Translation/en/EarningAndExpense/translationDashboarden.json';
import translationEarningSummaryen from '../Translation/en/EarningAndExpense/translationEarningSummaryen.json';
import translationExpensesSummaryen from '../Translation/en/EarningAndExpense/translationExpensesSummaryen.json';
import translationDirectCustomerCommissionen from '../Translation/en/EarningAndExpense/translationDirectCustomerCommissionen.json';
import translationBinaryCommissionen from '../Translation/en/EarningAndExpense/translationBinaryCommissionen.json';
import translationMatchingBonusen from '../Translation/en/EarningAndExpense/translationMatchingBonusen.json';
import translationFastStartBonusen from '../Translation/en/EarningAndExpense/translationFastStartBonusen.json';

////////////////////Danish/////////////////////////////
// Translation Common Danish
import translationCommondk from '../Translation/dk/common.json';
import translationSideBardk from '../Translation/dk/translationSideBar.json';

// Translation My Setting Danish
import traslationMyInformationdk from '../Translation/dk/Settings/translationMyInformation.json';
import translationNotificationdk from '../Translation/dk/Settings/translationNotification.json';
import translationKYCRegistrationdk from '../Translation/dk/Settings/translationKYCRegistration.json';

// Translation Customer Danish
import translationAddCustomerdk from '../Translation/dk/Customer/translationAddCustomer.json';

// Translation Distributor Danish
import translationAddNewDistributordk from '../Translation/dk/Distributor/translationAddNewDistributor.json';
import translationPendingDistributordk from '../Translation/dk/Distributor/translationPendingDistributordk.json';

// Translation Wallet Danish
import translationDashboarddk from '../Translation/dk/Wallet/translationDashboarddk.json';
import translationAccountOverviewdk from '../Translation/dk/Wallet/translationAccountOverviewdk.json';
import translationCashDepositdk from '../Translation/dk/Wallet/translationCashDepositdk.json';
import translationBankAccountdk from '../Translation/dk/Wallet/translationBankAccountdk.json';
import translationAddNewCreditCarddk from '../Translation/dk/Wallet/translationAddNewCreditCarddk.json';
import translationWithdrawRequestdk from '../Translation/dk/Wallet/translationWithdrawRequestdk.json';
import translationInternalTransferdk from '../Translation/dk/Wallet/translationInternalTransferdk.json';
import translationOverviewInternalTransferdk from '../Translation/dk/Wallet/translationOverviewInternalTransferdk.json';
import translationManagePaymentOptiondk from '../Translation/dk/Wallet/translationManagePaymentOptiondk.json';
import translationCurrentCreditCarddk from '../Translation/dk/Wallet/translationCurrentCreditCarddk.json';
import translationCurrentBankAccountdk from '../Translation/dk/Wallet/translationCurrentBankAccountdk.json';

// Translation Earning And Expens Danish
import translationEarningAndExpenseDashboarddk from '../Translation/dk/EarningAndExpense/translationDashboarddk.json';
import translationEarningSummarydk from '../Translation/dk/EarningAndExpense/translationEarningSummarydk.json';
import translationExpensesSummarydk from '../Translation/dk/EarningAndExpense/translationExpensesSummarydk.json';
import translationDirectCustomerCommissiondk from '../Translation/dk/EarningAndExpense/translationDirectCustomerCommissiondk.json';
import translationBinaryCommissiondk from '../Translation/dk/EarningAndExpense/translationBinaryCommissiondk.json';
import translationMatchingBonusdk from '../Translation/dk/EarningAndExpense/translationMatchingBonusdk.json';
import translationFastStartBonusdk from '../Translation/dk/EarningAndExpense/translationFastStartBonusdk.json';

///////////////////////////English Translation///////////////////////////////////////
const translationCommonEng = Object.assign(translationCommonen, translationSideBaren);
const translationMySettingEng = Object.assign(traslationMyInformationen, translationNotificationen, translationKYCRegistrationen);
const translationCustomerEng = Object.assign(translationAddCustomeren);
const translationDistributorEng = Object.assign(translationAddNewDistributoren, translationPendingDistributoren);
const translationWalletEng = Object.assign(
  translationDashboarden,
  translationAccountOverviewen,
  translationCashDepositen,
  translationBankAccounten,
  translationAddNewCreditCarden,
  translationWithdrawRequesten,
  translationInternalTransferen,
  translationOverviewInternalTransferen,
  translationManagePaymentOptionen,
  translationCurrentCreditCarden,
  translationCurrentBankAccounten
);
const translationEarningAndExpenseEng = Object.assign(
  translationEarningAndExpenseDashboarden,
  translationEarningSummaryen,
  translationExpensesSummaryen,
  translationDirectCustomerCommissionen,
  translationBinaryCommissionen,
  translationMatchingBonusen,
  translationFastStartBonusen
);

const translationDataen = Object.assign(translationCommonEng, translationMySettingEng, translationCustomerEng, translationDistributorEng, translationWalletEng, translationEarningAndExpenseEng);

///////////////////////////Danish Data Translation///////////////////////////////////
const translationCommonDk = Object.assign(translationCommondk, translationSideBardk);
const translationMySettingDk = Object.assign(traslationMyInformationdk, translationNotificationdk, translationKYCRegistrationdk);
const translationCustomerDK = Object.assign(translationAddCustomerdk);
const translationDistributorDK = Object.assign(translationAddNewDistributordk, translationPendingDistributordk);
const translationWalletDk = Object.assign(
  translationDashboarddk,
  translationAccountOverviewdk,
  translationCashDepositdk,
  translationBankAccountdk,
  translationAddNewCreditCarddk,
  translationWithdrawRequestdk,
  translationInternalTransferdk,
  translationOverviewInternalTransferdk,
  translationManagePaymentOptiondk,
  translationCurrentCreditCarddk,
  translationCurrentBankAccountdk
);
const translationEarningAndExpenseDk = Object.assign(
  translationEarningAndExpenseDashboarddk,
  translationEarningSummarydk,
  translationExpensesSummarydk,
  translationDirectCustomerCommissiondk,
  translationBinaryCommissiondk,
  translationMatchingBonusdk,
  translationFastStartBonusdk
);

const translationDatadk = Object.assign(translationCommonDk, translationMySettingDk, translationCustomerDK, translationDistributorDK, translationWalletDk, translationEarningAndExpenseDk);

const resources = {
  en: {
    translation: translationDataen
  },
  dk: {
    translation: translationDatadk
  }
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources
  });

export default i18n;
