import { Box, Card, Dialog, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FreedomButton1 from '../FreedomButton1/FreedomButton1';
import FreedomTypographyText from '../FreedomTypographyText/FreedomTypographyText';
import { appInsights } from '../../../auth/appInsight';
import { devConsoleLog } from '../../../utils/utilityFunctions';

const useDialogStyle = makeStyles((theme) => ({
  root: {
    //flexGrow: 1,
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '10px',
      border: `2px solid ${theme.palette.border.popupBorder}`
      // boxShadow: "0px 16px 40px rgba(230, 182, 74, 0.4)",
      // background: theme.palette.background.black
      // overflowY: 'auto',
      // height: '100%',
      // width: "100%",
      // maxWidth: "1100px"
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  dialogBox: {
    // border: `2px solid ${theme.palette.border.popupBorder}`,
    // boxShadow: "0px 16px 40px rgba(230, 182, 74, 0.4)",
    // overflowY: "auto"
    padding: '15px 60px 10px 60px',
    background: theme.palette.primary.onBoardTextColor,
    borderRadius: '15px',
    // minWidth: "479px",
    maxWidth: '846px',
    width: '100%',
    overflowY: 'auto!important',
    // maxHeight: '300px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px'
    }
  },
  term: {
    border: '1px solid #EFD183',
    borderRadius: '5px',
    padding: '16px',
    marginBottom: '8px'
  }
}));

const ErrorDialogBox = ({ errormessage, iserror, setIsError }) => {
  //iserror prop should be true or false
  //   const classes = useStyle();

  devConsoleLog(
    'errormessage, iserror, setIsError',
    errormessage
    // iserror,
    // setIsError
  );
  const { t } = useTranslation();
  const dialogStyle = useDialogStyle();
  const theme = useTheme();
  const mobile360 = useMediaQuery(theme.breakpoints.down('sm'));

  const [error, setError] = useState(iserror);

  const [displayMode, setDisplayMode] = useState('partial');
  const MAX_LENGTH = 300;

  const confirmClose = () => {
    setError(false);
    setIsError(false);
  };

  const toggleDisplayMode = () => {
    if (displayMode === 'partial') {
      setDisplayMode('full');
    } else {
      setDisplayMode('partial');
    }
  };

  const displayText = displayMode === 'partial' ? errormessage?.slice(0, MAX_LENGTH) : errormessage;

  const errorPopup = (
    <Dialog
      open={error}
      // onClose={confirmClose}

      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={dialogStyle.root}
      BackdropProps={{
        classes: {
          root: dialogStyle.backDrop
        }
      }}
    >
      <FreedomTypographyText fontweight={600} fontsize={mobile360 ? '16px' : '24px'} lineheight={mobile360 ? '15px' : '36px'} padding="10px">
        {t(`Report Exception`)}
      </FreedomTypographyText>
      <Card
        // sx={{ width: "100%", maxWidth: "1100px" }}
        className={dialogStyle.dialogBox}
      >
        <Box
        //   style={{
        //     width: '100%',
        //     maxWidth: '1100px',
        //     padding: mobile360 ? '0px' : '10px',
        //   }}
        // gap={20}
        >
          <FreedomTypographyText fontweight={200} fontsize={mobile360 ? '10px' : '12px'} lineheight={mobile360 ? '15px' : '20px'}>
            {t(`${displayText}`)}
          </FreedomTypographyText>
          <>
            {displayMode === 'partial' && errormessage?.length > MAX_LENGTH && (
              <button
                style={{
                  background: 'none',
                  textDecoration: 'underline',
                  color: theme.palette.text.primary,
                  border: 'none',
                  outline: 'none'
                }}
                onClick={toggleDisplayMode}
              >
                Show All
              </button>
            )}
          </>
        </Box>
      </Card>
      <FreedomButton1
        // sx={{
        //   marginTop:"20px"
        // }}
        btnbackground={theme.palette.button.buttonbgcolor}
        // btncolor={theme.palette.background.default}
        borderradius="10px"
        variant="contained"
        padding="10px"
        onClick={() => confirmClose()}
      >
        {t('Close')}
      </FreedomButton1>
    </Dialog>
  );
  try {
    return <Box>{error && errorPopup}</Box>;
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default ErrorDialogBox;
