import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { CssBaseline, useTheme } from '@mui/material';
import { useEffect, Suspense } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import './App.css';
import useOS from './freedomHooks/useOS';
import appInsights from './auth/appInsight';
import withAppInsights from './auth/appInsight';
import FlowControl from './flowControl/FlowControl';
import { devConsoleLog } from './utils/utilityFunctions';
import { getUserLocation } from './features/location/actions';
import { updateBrowserInfo } from './features/location/locationSlice';
import useUserAuthentication from './CustomHooks/useUserAuthentication';
import FreedomSnackBar from './Components/SharedComponent/FreedomSnackBar';
import FreedomGifLoader from './Components/SharedComponent/FreedomGifLoader/FreedomGifLoader';
import { selectSnackbar, showSnackbar } from './features/snackbar/snackbarSlice';

const query = new URLSearchParams(window.location.search);
const inviteCode = query.get('inv');

if (inviteCode) {
  localStorage.setItem('inv', inviteCode);
}

const App = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open } = useSelector(selectSnackbar);
  const stripePromise = useSelector((state) => state.user.stripePromise);

  const {
    osDetails: { os, osVersion, browserName, fullVersion }
  } = useOS();
  const { redirectToLogin, isLoading, error } = useUserAuthentication();

  const options = {
    mode: 'payment',
    amount: 9956,
    currency: 'usd',
    appearance: {
      labels: 'floating',
      theme: 'none',
      variables: {
        colorPrimary: 'yellow',
        colorBackground: theme.palette.mode === 'light' ? '#F0F0F0' : '#1a1a1a',
        colorText: theme.palette.primary.onBoardTextColor,
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '4px',
        borderRadius: '8px'
      }
    },
    rules: {
      '.Tab': {
        border: '1px solid #E0E6EB',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)'
      },
      '.Tab:hover': {
        color: 'var(--colorText)'
      },
      '.Tab--selected': {
        borderColor: '#E0E6EB',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)'
      },
      '.Input': {
        padding: '50px',
        border: '2px solid red'
      },
      '.Input--invalid': {
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)'
      },
      '.Input:focus': {
        background: 'green'
      }
      // See all supported class names and selector syntax below
    }
  };

  useEffect(() => {
    if (!os || !fullVersion || !browserName || !osVersion) return;

    dispatch(updateBrowserInfo({ os, fullVersion, browserName, osVersion }));
  }, [os]);

  useEffect(() => {
    dispatch(getUserLocation());
  }, []);

  useEffect(() => {
    window.addEventListener('online', () => {
      devConsoleLog('You are back online');
      dispatch(showSnackbar({ message: `${t('You are back online')}`, severity: 'success' }));
      // showMessage(`${t("You are back online")}`, 4000, "success");
    });

    window.addEventListener('offline', () => {
      dispatch(showSnackbar({ message: `${t('Please check your internet connection')}`, severity: 'error' }));
    });

    return () => {
      window.removeEventListener('online', () => {
        devConsoleLog('removed online listener');
      });
      window.removeEventListener('offline', () => {
        devConsoleLog('removed offline listener');
      });
    };
  }, [t]);

  if (isLoading)
    return (
      <>
        <CssBaseline />
        <FreedomGifLoader />
      </>
    );

  if (error) return <p>An Error has encounterd during user creation process</p>;

  try {
    return (
      <>
        <Elements stripe={stripePromise} options={options}>
          <CssBaseline />
          {open && <FreedomSnackBar />}
          <Suspense fallback={<FreedomGifLoader />}>
            <AuthenticatedTemplate>
              <FlowControl />
            </AuthenticatedTemplate>
          </Suspense>
          <UnauthenticatedTemplate>{redirectToLogin}</UnauthenticatedTemplate>
        </Elements>
      </>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error('APP' + error) });
  }
};

export default withAppInsights(App);
