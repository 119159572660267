import axios from 'axios';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { appInsights } from '../auth/appInsight';

const useFetch = (url) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState('');
  const [data, setData] = useState([]);
  const {
    user: { accessToken }
  } = useSelector((state) => state.user);

  const fetchData = async (signal) => {
    try {
      await axios
        .get(url, {
          headers: { Authorization: 'Bearer ' + accessToken },
          signal: signal // Pass the signal to the request
        })
        .then((res) => {
          setData(res?.data);
        })
        .catch((error) => {
          appInsights.trackException({ error: new Error('url' + url + error) });
          setError(true);
          setErrorDescription(error);
        })
        .finally(() => setLoading(false));
    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('Fetch aborted');
        // Handle abort if needed
      } else {
        console.error('Fetch error:', err);
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchData(signal);

    return () => {
      controller.abort(); // Abort the request when the component is unmounted
    };
  }, [url]);

  try {
    return { loading, error, setError, data, fetchData, errorDescription };
  } catch (error) {
    appInsights.trackException({ error: new Error('fetch url' + url + error) });
  }
};

export default useFetch;
