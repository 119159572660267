import { devConsoleLog } from '../utils/utilityFunctions';

export const getOS = () => {
  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
};

export const getOSVersion = () => {
  let fullVersion = window.navigator.appVersion;
  let fullVersionFirefox = window.navigator.userAgent;

  const version = fullVersion.split(' ');
  const firefoxVersion = fullVersionFirefox.split(' ');
  // devConsoleLog('version of browser', version, firefoxVersion);
  let finalVersion = '';

  if (version.indexOf('MSIE') > -1 || version.indexOf('Trident/7.0;') > -1) {
    // if(browseris.ie)
    finalVersion = version[3];
  } else if (firefoxVersion.indexOf('Mozilla/5.0') > -1) {
    let version = firefoxVersion[firefoxVersion.length - 1].split('/')[1];
    if (version) {
      finalVersion = version;
    }
    devConsoleLog('Firefox version', firefoxVersion[firefoxVersion.length - 1].split('/')[1]);
  } else finalVersion = version[3];

  const newVersion = finalVersion.split(';')[0];
  //
  return newVersion;
};

export const getBrowserInfo = () => {
  // Navigation Work ---
  // var nVer = navigator.appVersion;

  var nAgt = navigator.userAgent;
  var browserName = navigator.appName;
  var fullVersion = '' + parseFloat(navigator.appVersion);
  var majorVersion = parseInt(navigator.appVersion, 10);
  var nameOffset, verOffset, ix;

  // In Opera 15+, the true version is after "OPR/"
  if ((verOffset = nAgt.indexOf('OPR/')) !== -1) {
    browserName = 'Opera';
    fullVersion = nAgt.substring(verOffset + 4);
  } else if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    // In older Opera, the true version is after "Opera" or after "Version"
    browserName = 'Opera';
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) fullVersion = nAgt.substring(verOffset + 8);
  } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    // In MSIE, the true version is after "MSIE" in userAgent
    browserName = 'Microsoft Internet Explorer';
    fullVersion = nAgt.substring(verOffset + 5);
  } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    // In Chrome, the true version is after "Chrome"
    browserName = 'Chrome';
    fullVersion = nAgt.substring(verOffset + 7);
  } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    // In Safari, the true version is after "Safari" or after "Version"
    browserName = 'Safari';
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) fullVersion = nAgt.substring(verOffset + 8);
  } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    // In Firefox, the true version is after "Firefox"
    browserName = 'Firefox';
    fullVersion = nAgt.substring(verOffset + 8);
  } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
    // In most other browsers, "name/version" is at the end of userAgent
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(';')) !== -1) fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(' ')) !== -1) fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt('' + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = '' + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  // dispatch(updateBrowserName(browserName));
  // dispatch(updateBrowserFullVersion(fullVersion));

  // devConsoleLog("browser information", browserName, fullVersion)
  return { browserName, fullVersion };
};
