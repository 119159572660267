import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedRiskMode: null,
  selectedTradeStyle: null,
  selectedBroker: {},
  searchText: null,
  selectedProduct: {}
};

const productSelectionSlice = createSlice({
  name: 'productSelection',
  initialState,
  reducers: {
    setRiskMode: (state, action) => {
      state.selectedRiskMode = action.payload;
    },
    setTradeStyle: (state, action) => {
      state.selectedTradeStyle = action.payload;
    },
    setBroker: (state, action) => {
      state.selectedBroker = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setProductCard: (state, action) => {
      state.selectedProduct = action.payload;
    },
    resetAllFilteration: (state, action) => {
      state.selectedRiskMode = null;
      state.selectedTradeStyle = null;
      state.searchText = null;
    },
    resetAllFilterationAndBroker: (state, action) => {
      state.selectedRiskMode = null;
      state.selectedTradeStyle = null;
      state.searchText = null;
      state.selectedBroker = {};
    }
  }
});

export const { setRiskMode, setTradeStyle, setBroker, setSearchText, resetAllFilteration, setProductCard, resetAllFilterationAndBroker } = productSelectionSlice.actions;

export default productSelectionSlice.reducer;
