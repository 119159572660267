import React, { useState, useEffect } from 'react';
import { Dialog, Slide, IconButton, Grid, Checkbox, Box, useMediaQuery, Card } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import useForm from '../../../freedomHooks/useForm';
import { FreedomTextField } from '../../../Components/SharedComponent/FreedomTextField/FreedomTextField';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { countries } from '../../../Distributors/freedomDB/db';
import FreedomGrid from '../../../Components/SharedComponent/FreedomGrid/FreedomGrid';
import { FreedomTypography } from '../../../Components/SharedComponent/FreedomTypography/FreedomTypography';
// import CloseIcon from '@mui/icons-material/Close';
import FormInput from './FormInput';
import axios from 'axios';
import { loginRequest } from './../../../auth/authConfig';
import { useMsal, useAccount } from '@azure/msal-react';
import { freedomConstants } from './../../../FreedomConstants/freedomConstants';
import CircleChecked from '@mui/icons-material/CheckCircleOutline';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { updatePlaces } from '../../../features/googleAddressSearch/googleAdressSearchSlice';
import useDataJson from '../../../freedomHooks/useDataJson';
import FreedomSearchField from '../../../Components/SharedComponent/FreedomSearchField/FreedomSearchField';
import { SearchOutlined } from '@mui/icons-material';
import FreedomCheckbox1 from '../../../Components/SharedComponent/FreedomCheckbox1/FreedomCheckbox1';
import FreedomButton1 from '../../../Components/SharedComponent/FreedomButton1/FreedomButton1';
import FreedomButton2 from '../../../Components/SharedComponent/FreedomButton2/FreedomButton2';
import CloseIcon from '../../../Components/SharedComponent/CloseIcon/CloseIcon';
import { devConsoleLog } from '../../../utils/utilityFunctions';
import { getBaseUrl } from '../../../utils/userConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '80px 0',
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '20px',
      '&::-webkit-scrollbar': {
        width: '5px',
        height: '250px',
        color: 'orange',
        backgroundColor: '#eee',
        borderRadius: '20px'
      },
      '&::-webkit-scrollbar-thumb': {
        width: '0.5px',
        backgroundColor: 'orange',
        borderRadius: '20px',
        cursor: 'pointer'
      }
    }
  },
  root1: {
    padding: '20px',
    Paddingleft: '10px',
    paddingRight: '10px'
  },
  root2: {
    padding: '30px 0'
  },
  widthStyle: {
    width: '100%',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px'
    }
  },
  addressSearchStyle: {
    background: 'rgba(0, 0, 0, 0.09)',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  addressSearchDarkStyle: {
    background: 'rgba(0, 0, 0, 0.09)',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  dialogHeader: {
    // "flex items-center justify-between"
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px 0 20px'
  }
}));

const useDialogStyle = makeStyles((theme) => ({
  root: {
    //flexGrow: 1,
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '10px'
      // boxShadow: '0px 16px 40px rgba(230, 182, 74, 0.4)',
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  dialogBox: {
    border: `2px solid ${theme.palette.border.popupBorder}`,
    // boxShadow: '0px 16px 40px rgba(230, 182, 74, 0.4)',
    overflowY: 'auto',
    background: `linear-gradient(180deg, ${theme.palette.background.wrapperborderTop} 0%, ${theme.palette.background.wrappereBottom} 100%)`,
    borderRadius: '8px',
    maxWidth: '488px',
    width: '100%'
  }
}));

const isValidFormInitialize = {
  email: false,
  mobile: false,
  secondName: false,
  city: false,
  zipCode: false,
  middleName: false,
  street: false,
  street_number: false,
  extra: false,
  firstName: false,
  country: false,
  state: false,
  bankName: false,
  bank: false,
  branchName: false,
  branchCode: false,
  cprNumber: false,
  day: false,
  month: false,
  year: false
};

const resetFormData = {
  Id: '',
  street: '',
  careof: '',
  streetNumber: '',
  address2: '',
  zipCode: '',
  city: '',
  country: '',
  state: '',
  search: false,
  fullAddress: '',
  countryID: '',
  selectPrimary: false
};

const siteUrl = getBaseUrl();

const AddressDialog = ({ openDialog, onClose, getAddresses, showMessage, addressEditDialog = {} }) => {
  // devConsoleLog('addressEditDialog', addressEditDialog);
  const classes = useStyles();
  const dialogStyle = useDialogStyle();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { jsonPrefix } = useDataJson();
  const { form, handleChange, resetForm, setForm } = useForm(resetFormData);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [isValidForm, setFormValue] = useState(isValidFormInitialize);
  const [values, setValues] = useState({});
  const [postDataProgress, setPostDataProgress] = useState(false);
  // const [ open, setOpen ] = useState(false);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { location } = useSelector((state) => state.geoLocation);
  // const os = useSelector((state) => state.location.os);
  const osVersion = useSelector((state) => state.location.osVersion);
  const browserName = useSelector((state) => state.location.browserName);
  const fullBrowserVersion = useSelector((state) => state.location.fullBrowserVersion);
  const platformId = useSelector((state) => state.user.platformId);
  // const invitationCodeId = useSelector((state) => state.user.invitationCodeId);
  const databaseId = useSelector((state) => state.user.Id);
  const { uniqueId } = useSelector((state) => state.user);
  // const memberId = useSelector((state) => state.user.memberId);

  const placeObj = useSelector((state) => state.googleAddressSearch.placeObj);
  const completeAddress = useSelector((state) => state.googleAddressSearch.completeAddress);
  // let countryName = countries.filter((country) => country.ISO2 === placeObj.country);

  let taxableCountry = countries.filter((country) => country.ISO2 === _.upperCase(placeObj.country));
  // let countryID = countries.filter((country) => country.Name === _.capitalize(addressEditDialog.country))
  const inputs = [];

  // devConsoleLog("countryID", completeAddress)

  useEffect(() => {
    if (placeObj.search === true) {
      setForm({
        ...form,
        street: placeObj.street !== undefined && placeObj.street !== null ? placeObj.street : '',
        streetNumber: placeObj.street_number !== undefined && placeObj.street_number !== null ? placeObj.street_number : '',
        city: placeObj.city !== undefined && placeObj.city !== null ? placeObj.city : '',
        state: placeObj.province !== undefined && placeObj.province !== null ? placeObj.province : '',
        country: taxableCountry.length > 0 ? taxableCountry[0].Name : '',
        zipCode: placeObj.postal_code !== undefined && placeObj.postal_code !== null ? placeObj.postal_code : '',
        fullAddress: completeAddress.description !== null && completeAddress.description !== undefined && completeAddress.description,
        countryID: taxableCountry.length > 0 ? taxableCountry[0].ID : '',
        search: true
      });
      dispatch(updatePlaces({ ...placeObj, search: false }));
    } else {
      setForm({
        ...form,
        Id: addressEditDialog.Id,
        street: addressEditDialog.street ? addressEditDialog.street : '',
        careof: addressEditDialog.careof ? addressEditDialog.careof : '',
        streetNumber: addressEditDialog.streetNumber ? addressEditDialog.streetNumber : '',
        address2: addressEditDialog.address2 ? addressEditDialog.address2 : '',
        zipCode: addressEditDialog.zipCode ? addressEditDialog.zipCode : '',
        city: addressEditDialog.city ? addressEditDialog.city : '',
        country: addressEditDialog.country ? addressEditDialog.country : '',
        state: addressEditDialog.state ? addressEditDialog.state : '',
        IsPrimaryAddress: addressEditDialog.IsPrimaryAddress,
        countryID: addressEditDialog.countryID,
        fullAddress: addressEditDialog.fullAddress,
        search: addressEditDialog.search
      });
    }
  }, [placeObj.street, placeObj.street_number, placeObj.city, placeObj.province]); // eslint-disable-line react-hooks/exhaustive-deps

  const ValidateForm = () => {
    if (form !== undefined) {
      if (taxableCountry.length > 0 && taxableCountry[0].ISO2 === _.upperCase('AE')) {
        if (
          (form.street.length > 0 || placeObj.street.length > 0) &&
          (form.city.length > 0 || placeObj.city.length > 0) &&
          isValidForm.street === false &&
          isValidForm.country === false &&
          isValidForm.zipCode === false &&
          isValidForm.city === false &&
          (placeObj.search === false || placeObj.search === undefined)
        ) {
          return true;
        } else {
          return false;
        }
      } else if (taxableCountry.length > 0 && taxableCountry[0].ISO2 === _.upperCase('PK')) {
        if (
          (form.street.length > 0 || placeObj.street.length > 0) &&
          (form.city.length > 0 || placeObj.city.length > 0) &&
          isValidForm.street === false &&
          isValidForm.country === false &&
          isValidForm.zipCode === false &&
          isValidForm.city === false &&
          (placeObj.search === false || placeObj.search === undefined)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (Object.keys(addressEditDialog).length > 0) {
          if (
            (placeObj.street_number.length > 0 || form.streetNumber.length > 0) &&
            (form.street.length > 0 || placeObj.street.length > 0) &&
            // (form.zipCode.length > 0 || placeObj.postal_code.length > 0) &&
            (form.city.length > 0 || placeObj.city.length > 0) &&
            (form.country.length > 0 || placeObj.country.length > 0) &&
            isValidForm.street === false &&
            isValidForm.country === false &&
            isValidForm.zipCode === false &&
            isValidForm.city === false &&
            (form.search !== false || form.search === undefined)
          ) {
            // if(form.selectPrimary === true){
            return true;
            // }else {
            // 	devConsoleLog("control coming here")

            // 	return false
            // }
          } else if (
            (placeObj.street_number.length > 0 || form.streetNumber.length > 0) &&
            (form.street.length > 0 || placeObj.street.length > 0) &&
            // (form.zipCode.length > 0 || placeObj.postal_code.length > 0) &&
            (form.city.length > 0 || placeObj.city.length > 0) &&
            (form.country.length > 0 || placeObj.country.length > 0) &&
            isValidForm.street === false &&
            isValidForm.country === false &&
            isValidForm.zipCode === false &&
            isValidForm.city === false
          ) {
            if (form.selectPrimary === true) {
              return true;
            }
            // devConsoleLog("control coming here")
            return false;
          }
        } else {
          if (
            placeObj.street_number.length > 0 &&
            (form.street.length > 0 || placeObj.street.length > 0) &&
            // (form.zipCode.length > 0 || placeObj.postal_code.length > 0) &&
            (form.city.length > 0 || placeObj.city.length > 0) &&
            (form.country.length > 0 || placeObj.country.length > 0) &&
            isValidForm.street === false &&
            isValidForm.country === false &&
            isValidForm.zipCode === false &&
            isValidForm.city === false &&
            (form.search !== false || form.search === undefined)
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
    } else {
      return false;
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleAddressUpdate = (e) => {
    e.preventDefault();
    setPostDataProgress(true);
    // devConsoleLog('Address updated successfully!');

    async function RequestAccessToken() {
      const request = {
        ...loginRequest,
        account: account
      };

      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      return instance
        .acquireTokenSilent(request)
        .then((response) => {
          if (response) {
            return response;
          }
        })
        .catch((e) => {
          return instance.acquireTokenPopup(request).then((response) => {
            if (response) {
              return response;
            }
          });
        });
    }
    RequestAccessToken().then(async (_response) => {
      const LangaugeId = 1;
      let payload = [];
      if (Object.keys(addressEditDialog).length > 0) {
        payload = [
          {
            __metadata: { type: 'UserAddresses' },
            ...jsonPrefix,
            // ModifiedByAppId: freedomConstants.APP_ID,
            // ModifiedByAppName: browserName,
            // ModifiedByAppVersionNumber: fullBrowserVersion,
            // ModifiedByBrowserName: browserName,
            // ModifiedByBrowserVersion: fullBrowserVersion,
            // ModifiedByPlatformId: platformId,
            // ModifiedByPlatformVersion: osVersion,
            // ModifiedByLatitude: location.CreatedByLatitude,
            // ModifiedByLongitude: location.CreatedByLongitude,
            // ModifiedByIp: location.CreatedByIp,
            // ModifiedByCity: location.CreatedByCity,
            // ModifiedByRegion: location.CreatedByRegion,
            // ModifiedByCountryId: location.CreatedByCountryId,
            // ModifiedByLanguageId: LangaugeId,
            id: form.Id,
            /*"id": '@UserId'*/
            userId: '@UserId',
            ModifiedByUserID: '@UserId',
            address: form.street ? form.street : '',
            Address2: form.address2 ? form.address2 : '',
            StreetNumber: form.streetNumber ? form.streetNumber : '',
            ///////////
            // 	Id: addressEditDialog.Id,
            // street: addressEditDialog.street ? addressEditDialog.street : '',
            // careof: addressEditDialog.careof ? addressEditDialog.careof : '',
            // streetNumber: addressEditDialog.streetNumber ? addressEditDialog.streetNumber : '',
            // address2: addressEditDialog.address2 ? addressEditDialog.address2 : '',
            // zipCode: addressEditDialog.zipCode ? addressEditDialog.zipCode : '',
            // city: addressEditDialog.city ? addressEditDialog.city : '',
            // country: addressEditDialog.country ? addressEditDialog.country : '',
            // state: addressEditDialog.state ? addressEditDialog.state : '',
            // IsPrimaryAddress: addressEditDialog.IsPrimaryAddress
            // address1: form.extra ? form.extra : '',
            PostalCode: form.zipCode,
            city: form.city,
            state: form.state,
            countryId: form.countryID,
            // countryId: taxableCountry[0].ID,
            CareOf: form.careof,
            IsPrimaryAddress: form.IsPrimaryAddress,
            FullAddress: form.fullAddress
          },
          {
            __metadata: { type: 'B2CUser' },
            id: databaseId,
            UserId: '@UserId',
            // givenname: form.firName,
            // surname: form.lastName,
            AadObjectId: uniqueId,

            Address: placeObj.address ? placeObj.address : null, //address
            PostalCode: placeObj.postal_code, //address
            City: placeObj.city, //address
            Country: taxableCountry.length > 0 && taxableCountry[0].Name //address
          }
        ];
      } else {
        payload = [
          {
            __metadata: { type: 'UserAddresses' },
            ...jsonPrefix,
            // CreatedByAppId: freedomConstants.APP_ID,
            // CreatedByAppName: browserName,
            // CreatedByAppVersionNumber: fullBrowserVersion,
            // CreatedByBrowserName: browserName,
            // CreatedByBrowserVersion: fullBrowserVersion,
            // CreatedByPlatformId: platformId,
            // CreatedByPlatformVersion: osVersion,
            // CreatedByLatitude: location.CreatedByLatitude,
            // CreatedByLongitude: location.CreatedByLongitude,
            // CreatedByIp: location.CreatedByIp,
            // CreatedByCity: location.CreatedByCity,
            // CreatedByRegion: location.CreatedByRegion,
            // CreatedByCountryId: location.CreatedByCountryId,
            // CreatedByLanguageId: LangaugeId,
            id: 0,
            /*"id": '@UserId'*/
            userId: '@UserId',
            CreatedByUserID: '@UserId',
            address: placeObj.street ? placeObj.street : '',
            Address2: form.address2 ? form.address2 : '',
            StreetNumber: placeObj.street_number ? placeObj.street_number : '',
            // address1: form.extra ? form.extra : '',
            PostalCode: placeObj.postal_code,
            city: placeObj.city,
            state: placeObj.province,
            countryId: taxableCountry[0].ID,
            CareOf: form.careof,
            IsPrimaryAddress: form.IsPrimaryAddress,
            FullAddress: form.fullAddress
          }
          // {
          // 	__metadata: { type: 'B2CUser' },
          // 	id: databaseId,
          // 	UserId: '@UserId',
          // 	// givenname: form.firName,
          // 	// surname: form.lastName,
          // 	AadObjectId: uniqueId,

          // 	Address: placeObj.address ? placeObj.address : null, //address
          // 	PostalCode: placeObj.postal_code, //address
          // 	City: placeObj.city, //address
          // 	Country: taxableCountry.length > 0 && taxableCountry[0].Name //address
          // }
        ];
      }

      const myJSON = JSON.stringify(payload);
      const secondJSON = JSON.stringify(myJSON);

      devConsoleLog('2nd Stringify data In Update Address INFORMATION', secondJSON);
      // devConsoleLog('siteurl', siteUrl);
      await axios({
        url: siteUrl,
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + _response.accessToken
        },
        method: 'post',
        data: secondJSON
      })
        .then(function (response) {
          setPostDataProgress(false);
          getAddresses();
          resetForm();
          onClose();
          showMessage('Address Update Successfully', 4000, 'success');
        })
        .catch(function (error) {
          showMessage('Could Not update the Address', 4000, 'error');
          resetForm();
          onClose();
          setPostDataProgress(false);
          devConsoleLog('', error);
        });
    });
  };

  devConsoleLog('form of address', form.search);

  return (
    <React.Fragment>
      {/* <Box>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
					style={{ paddingTop: '50px', marginRight: '60px' }}
					open={open}
					autoHideDuration={_duration}
					onClose={handleClose}
				>
					<Alert onClose={handleClose} severity={_variant || 'success'}>
						{_message}
					</Alert>
				</Snackbar>
			</Box> */}
      <Dialog
        // onClose={onClose}
        // aria-labelledby="customized-dialog-title"
        // open={openDialog}
        // className={classes.root}
        // TransitionComponent={Transition}
        // disableEscapeKeyDown={true}
        // scroll="paper"
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        className={dialogStyle.root}
        BackdropProps={{
          classes: {
            root: dialogStyle.backDrop
          }
        }}
      >
        <Card
          style={{
            border: `2px solid ${theme.palette.border.borderColor}`,
            boxShadow: 'none',
            overflowY: 'auto',
            background: `linear-gradient(180deg, ${theme.palette.background.wrapperborderTop} 0%, ${theme.palette.background.wrappereBottom} 100%)`,
            borderRadius: '10px',
            maxWidth: '488px',
            width: '100%',
            maxHeight: '786px',
            height: '90%'
          }}
        >
          <Box>
            <CloseIcon
              padding={'8px 16px 0px 0px'}
              handleonclick={() => {
                onClose();
              }}
            />
            <Box onClose={onClose} className={classes.dialogHeader}>
              <FreedomTypography variant="h6" sx={{ fontSize: isSmallScreen ? '16px' : '24px' }}>
                {' '}
                {Object.keys(addressEditDialog).length > 0 ? 'Edit Address' : 'Add New Address'}
              </FreedomTypography>
              {/* <Box className="text-lg ml-2">
								<FreedomTypography variant="h6">
									{Object.keys(addressEditDialog).length > 0 ? 'Edit Address' : 'Add New Address'}
								</FreedomTypography>
							</Box>
							<Box className="text-right mr-2  mt-2">
								<IconButton onClick={onClose}>
									<CloseIcon />
								</IconButton>
							</Box> */}
            </Box>

            <Grid container>
              <form className={classes.widthStyle}>
                <FreedomGrid spacing={1}>
                  {/* <Grid item sm={12} lg={12} xs={12}>
										<AddressSearch />
									</Grid> */}
                  {/* <Grid container justifyContent="center" item>
										<Grid item xs={10} sm={10} md={6} lg={6} xl={6}>
											<FreedomSearchField
												value={debouncedTerm}
												onChange={(e) => {
													setDebouncedTerm(e.target.value)
													// setSearchText(e.target.value);
												}}
												onKeyPress={(event) => {
													if (event.key === 'Enter') {
														Search();
													}
												}}
												InputProps={{
													endAdornment: (
														<IconButton onClick={() => Search()}>
															<SearchOutlined sx={{color: theme.palette.icon.iconColor}}/>
														</IconButton>
													),
												}}
											/>
										</Grid>
									</Grid> */}
                  <Grid item sm={12} lg={12} xs={12}>
                    <FreedomTextField
                      label="C/O"
                      type="text"
                      name="careof"
                      value={form.careof ? form.careof : ''}
                      onChange={(careof) => {
                        handleChange(careof);
                      }}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} lg={12} xs={12}>
                    <FreedomTextField
                      label="Address 2 / House no / Flat no"
                      type="text"
                      name="address2"
                      value={form.address2 ? form.address2 : ''}
                      onChange={(address2) => {
                        handleChange(address2);
                      }}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} lg={6} xs={12}>
                    <FreedomTextField
                      label="Street Address"
                      type="text"
                      name="street"
                      value={form.street ? form.street : ''}
                      helperText={isValidForm.street ? 'Street Address is too long' : ''}
                      error={isValidForm.street}
                      onChange={(street) => {
                        const re = /^.{1,35}$/;
                        setFormValue({
                          ...isValidForm,
                          street: !re.test(String(street.target.value))
                        });
                        handleChange(street);
                      }}
                      variant="filled"
                      required
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12} lg={6} xs={12}>
                    <FreedomTextField
                      label="Street Number"
                      type="text"
                      name="street"
                      value={form.streetNumber ? form.streetNumber : ''}
                      helperText={isValidForm.street_number ? 'Street Address is too long' : ''}
                      inputProps={{ maxLength: 36 }}
                      error={isValidForm.street_number}
                      onChange={(street_number) => {
                        const re = /^.{1,35}$/;
                        setFormValue({
                          ...isValidForm,
                          street_number: !re.test(String(street_number.target.value))
                        });
                        handleChange(street_number);
                      }}
                      variant="filled"
                      required
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12} lg={6} xs={12}>
                    <FreedomTextField
                      label="Zip-Code"
                      name="zipCode"
                      autoComplete="off"
                      value={form.zipCode ? form.zipCode : ''}
                      helperText={isValidForm.zipCode && 'ZipCode too long'}
                      error={isValidForm.zipCode}
                      onChange={(zipCode) => {
                        const re = /^.{1,25}$/;

                        setFormValue({
                          ...isValidForm,
                          zipCode: !re.test(String(zipCode.target.value))
                        });
                        handleChange(zipCode);
                      }}
                      variant="filled"
                      // required
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12} lg={6} xs={12}>
                    <FreedomTextField
                      label="City"
                      type="text"
                      name="city"
                      value={form.city ? form.city : ''}
                      autoComplete="off"
                      helperText={isValidForm.city && 'City Name too long'}
                      error={isValidForm.city}
                      onChange={(city) => {
                        const re = /^.{1,25}$/;
                        setFormValue({
                          ...isValidForm,
                          city: !re.test(String(city.target.value))
                        });
                        handleChange(city);
                      }}
                      variant="filled"
                      required
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} lg={6} xs={12}>
                    <FreedomTextField
                      label="State"
                      type="text"
                      name="state"
                      value={form.state ? form.state : ''}
                      autoComplete="off"
                      helperText={isValidForm.state && 'State Name too long'}
                      error={isValidForm.state}
                      onChange={(state) => {
                        const re = /^.{1,25}$/;
                        setFormValue({
                          ...isValidForm,
                          state: !re.test(String(state.target.value))
                        });
                        handleChange(state);
                      }}
                      disabled
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} lg={6} xs={12}>
                    <FreedomTextField
                      label="Country"
                      type="text"
                      name="state"
                      value={form.country ? form.country : ''}
                      autoComplete="off"
                      helperText={isValidForm.country && 'State Name too long'}
                      error={isValidForm.country}
                      onChange={(country) => {
                        const re = /^.{1,25}$/;
                        setFormValue({
                          ...isValidForm,
                          country: !re.test(String(country.target.value))
                        });
                        handleChange(country);
                      }}
                      disabled
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} lg={12} xs={12}>
                    <FreedomCheckbox1
                      checkboxcolor={theme.palette.primary.main}
                      disabled={addressEditDialog.IsPrimaryAddress}
                      name="primaryOption"
                      label="Mark it as my Primary address"
                      handlechange={(e) => {
                        setForm({
                          ...form,
                          IsPrimaryAddress: !form.IsPrimaryAddress,
                          selectPrimary: true
                        });
                      }}
                      checked={form.IsPrimaryAddress ? form.IsPrimaryAddress : false}
                    />
                    {/* <Checkbox
											disabled={addressEditDialog.IsPrimaryAddress}
											icon={<CircleUnchecked />}
											checkedIcon={<CircleChecked />}
											name="primaryOption"
											onChange={(e) => {
												setForm({
													...form,
													IsPrimaryAddress: !form.IsPrimaryAddress,
													selectPrimary: true
												});
											}}
											checked={form.IsPrimaryAddress ? form.IsPrimaryAddress : false}
										/>
										<FreedomTypography variant="body2" component="span">
											Mark it as my Primary address
										</FreedomTypography> */}
                  </Grid>
                </FreedomGrid>
                {inputs.map((input) => (
                  <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange} />
                ))}
                <Grid container spacing={2} justifyContent="center">
                  {/* <Box className="my-3 mx-auto"> */}
                  {/* <Grid item xs={6} md={6} lg={6}>
											<FreedomButton2
												btnbackground="none"
												btncolor={theme.palette.icon.iconColor}
												btnborder={theme.palette.primary.main}
												borderradius="10px"
												onClick={onClose}	>
												Cancel
											</FreedomButton2>
										</Grid> */}
                  <Grid item xs={6} md={6} lg={6}>
                    <FreedomButton1 disabled={!ValidateForm()} btnbackground={theme.palette.primary.main} btncolor={theme.palette.background.default} borderradius="10px" loading={+postDataProgress} onClick={handleAddressUpdate}>
                      {Object.keys(addressEditDialog).length > 0 ? 'Update Address' : 'Save Address'}
                    </FreedomButton1>
                  </Grid>
                  {/* </Box> */}
                </Grid>
                {/* <Grid container justifyContent="right">
									<LoadingButton
										style={{
											marginTop: '15px'
										}}
										disabled={!ValidateForm()}
										loading={postDataProgress}
										variant="contained"
										onClick={handleAddressUpdate}
									>
										{Object.keys(addressEditDialog).length > 0 ? 'Update Address' : 'Save Address'}
									</LoadingButton>
								</Grid> */}
              </form>
            </Grid>
          </Box>
        </Card>
      </Dialog>
    </React.Fragment>
  );
};

export default AddressDialog;
