import { useTheme } from '@mui/material';
import { PieChart, Pie, Cell, Label } from 'recharts';

let profile = [
  { ID: 0, Name: 'Low', value: 20 },
  { ID: 1, Name: 'Med', value: 40 },
  { ID: 2, Name: 'High', value: 60 }
];
let style = [
  { ID: 0, Name: 'Short', value: 20 },
  { ID: 1, Name: 'Med', value: 40 },
  { ID: 2, Name: 'Long', value: 60 }
];

function calculateAngle(value, max, min) {
  let minAngle = -40;
  let maxAngle = 220;
  let valuee = value;

  if (min !== 0 && valuee === min) {
    valuee = 0;
  }
  let singleAngle = (maxAngle - minAngle) / max;
  let finalAngle = singleAngle * valuee;
  return maxAngle - finalAngle;
}

const RenderLabel = ({ viewBox, value, position, fontSize, lineheigt, fontWeight, spacePercentage, guageType }) => {
  const theme = useTheme();
  const { cx, cy } = viewBox;
  let xPercentage = position === 1 ? 0.15 : 0.85; // Adjust as needed
  let yPercentage = 0.7; // Adjust as needed

  if (position === 2) {
    xPercentage = 1 - xPercentage;
  }

  // Apply space between min and max values
  xPercentage += position === 1 ? -spacePercentage : spacePercentage;

  const xValue = cx + cx * (xPercentage - 0.5); // Adjusted for centering
  const yValue = cy + cy * yPercentage;

  const setMinMaxText = () => {
    if (guageType == 'Profile') {
      switch (position) {
        case 1:
          return profile[0]?.Name;
        case 2:
          return profile[2]?.Name;
      }
    } else if (guageType == 'Style') {
      switch (position) {
        case 1:
          return style[0]?.Name;
        case 2:
          return style[2]?.Name;
      }
    } else {
      return value + '%';
    }
  };

  return (
    <text x={xValue} y={yValue} fill={theme.palette.primary.onBoardTextColor} textAnchor="middle" fontSize={fontSize} lineHeight={lineheigt} fontWeight={fontWeight} fontFamily="Poppins" dominantBaseline="central">
      {setMinMaxText()}
    </text>
  );
};

const calculateGradientColor = (value, max, guageType) => {
  if (guageType === 'Style') return 'url(#linear-gradient3)';
  if (guageType === 'ROI') return 'url(#linear-gradient1)';

  if (value < max / 2) return 'url(#linear-gradient1)';
  if (value < (max / 4) * 3) return 'url(#linear-gradient2)';
  return 'url(#linear-gradient)';
};

const GuageMeter = ({
  min,
  max,
  value,
  width = 400,
  height = 200,
  innervaluefontsize = '10px',
  innervalueHeight = '16px',
  innervaluefontweight = 400,
  minmaxvaluefontsize = '8px',
  minmaxvalueHeight = '12px',
  minmaxvaluefontweight = 400,
  minspacePercentage = 0,
  maxspacePercentage = 0,
  guageType = 'ROI',
  name = ''
}) => {
  ///// minspacePercentage and maxspacePercentage value should be 0 to 1/////

  const theme = useTheme();

  const setCenterValue = () => {
    if (name) {
      return name;
    } else {
      if (guageType == 'Profile') {
        let Name = profile?.filter((item) => item?.value > value)[0]?.Name;
        return Name;
      } else if (guageType == 'Style') {
        let Name = style?.filter((item) => item?.value > value)[0]?.Name;
        return Name;
      } else {
        return value + '%';
      }
    }
  };
  const gaugeColor = calculateGradientColor(value, max, guageType);

  const data = [
    { value: value === min ? 0 : value, fill: gaugeColor },
    { value: max - value, fill: theme.palette.background.speedometerbg } // Color for the rest of the pie
  ];

  const highlightValues = [data[0]?.value];

  return (
    <PieChart width={width} height={height}>
      <defs>
        <linearGradient id="linear-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#00cc39' }} />
          <stop offset="60%" style={{ stopColor: '#e0c253' }} />
          <stop offset="100%" style={{ stopColor: '#fd4228' }} />
        </linearGradient>
      </defs>
      <defs>
        <linearGradient id="linear-gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#00cc39' }} />
        </linearGradient>
      </defs>
      <defs>
        <linearGradient id="linear-gradient2" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#00cc39' }} />
          <stop offset="60%" style={{ stopColor: '#e0c253' }} />
        </linearGradient>
      </defs>
      <defs>
        <linearGradient id="linear-gradient3" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="60%" style={{ stopColor: '#e0c253' }} />
        </linearGradient>
      </defs>
      {highlightValues.map((value, index) => (
        <Pie
          key={`highlight-pie-${index}`}
          data={[{ value }]}
          cx="50%"
          cy="50%"
          innerRadius="110%"
          outerRadius="110%" // Adjust the outerRadius to control the size of the circle
          fill="transparent"
          stroke={theme.palette.background.speedometerbg}
          startAngle={218}
          endAngle={-40}
          paddingAngle={0}
          strokeWidth={2}
          dataKey="value"
          strokeDasharray="3 3"
          cornerRadius={10}
        />
      ))}
      <Pie
        data={data}
        dataKey="value"
        cx="50%"
        cy="50%"
        startAngle={220}
        endAngle={-40}
        stroke={0}
        innerRadius="85%" // Adjust the innerRadius to control the thickness of the pie
        outerRadius="100%"
        cornerRadius={10}
        paddingAngle={0}
        labelLine={false}
        label={(payload) => {
          const { cx, cy, midAngle, innerRadius, outerRadius, index } = payload;

          const RADIAN = Math.PI / 180;

          let total = 0;
          data.forEach((v) => {
            total += v?.value;
          });

          // Example usage:
          const ang = calculateAngle(data[0]?.value, max, min);

          const length = (innerRadius + 1.9 * outerRadius) / 3;
          const sin = Math.sin(-RADIAN * ang);
          const cos = Math.cos(-RADIAN * ang);
          const x0 = cx;
          const y0 = cy;
          const xp = x0 + length * cos;
          const yp = y0 + length * sin;

          if (index === 0) {
            return (
              <g key={`circle-group-${index}`}>
                <circle cx={xp} cy={yp} r={5} fill="#ffffff" />
              </g>
            );
          }
        }}
        // strokeDasharray="4 4"
      >
        <Label value={setCenterValue()} position="center" fill={theme.palette.primary.onBoardTextColor} fontSize={innervaluefontsize} fontWeight={innervaluefontweight} lineHeight={innervalueHeight} />
        <Label content={<RenderLabel value={min} position={1} fontSize={minmaxvaluefontsize} lineheigt={minmaxvalueHeight} fontWeight={minmaxvaluefontweight} spacePercentage={minspacePercentage} guageType={guageType} />} />
        <Label content={<RenderLabel value={max} position={2} fontSize={minmaxvaluefontsize} lineheigt={minmaxvalueHeight} fontWeight={minmaxvaluefontweight} spacePercentage={maxspacePercentage} guageType={guageType} />} />
        {data?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.fill} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default GuageMeter;
