import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  documentUploadedId: null
};

export const onBoardSlice = createSlice({
  name: 'onBoardSlice',
  initialState,
  reducers: {
    updateDocumentUploadedId: (state, action) => {
      state.documentUploadedId = action.payload;
    }
  }
});

export const { updateDocumentUploadedId } = onBoardSlice.actions;

export default onBoardSlice.reducer;
