import React, { useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { appInsights } from '../../../auth/appInsight';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: '10px 0',
    backgroundColor: theme.palette.text.secondray
  },
  date: {
    color: theme.palette.text.secondary,
    fontSize: '12px!important'
  },
  message: {
    cursor: 'pointer'
  }
}));

const NotificationListItem = ({ data, menuClose }) => {
  // devConsoleLog(data);
  const [dataArray, setDataArray] = useState(data);
  const classes = useStyles();

  const handleDelete = (id) => {
    const tempArr = dataArray.filter((data) => data.id !== id);
    setDataArray(tempArr);
  };
  try {
    return (
      <React.Fragment>
        {dataArray.map((notificationData) => {
          return (
            <Grid key={notificationData.id} container wrap="nowrap" className={classes.listItem}>
              <Grid
                item
                lg={10}
                className={classes.message}
                onClick={() => {
                  menuClose();
                }}
              >
                <Typography variant="body2">{notificationData.message}</Typography>
                <Typography variant="body2" className={classes.date}>
                  {notificationData.date}
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Box>
                  <IconButton onClick={() => handleDelete(notificationData.id)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </React.Fragment>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default React.memo(NotificationListItem);
