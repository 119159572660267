import React from 'react';
import { Box } from '@mui/material';

import { images } from '../../../url/SvgLinks';
import { appInsights } from '../../../auth/appInsight';

const FreedomSplash = images.FreedomSplash;

const FreedomGifLoader = () => {
  try {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)'
        }}
      >
        <img src={FreedomSplash} alt="Loading..." height={200} width={200} />
      </Box>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomGifLoader;
