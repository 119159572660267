import { Alert, Box, Button, Grid, Snackbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
// import useFetch from '../../../CustomHooks/useFetch';
// import { grtSubsctibedProducts } from '../../../api/apiUrls/investmentApiUrls';
import { GetUserProducts } from '../../../api/DataProvider/investmentDataProvider';
import { setUserProducts } from '../../../features/investment/productsSlice';
import LowInvestmentDialogBox from '../LowInvestmentDialogBox/LowInvestmentDialogBox';
import { useContext } from 'react';
import { ColorModeContext } from '../../../store';
import useDataJson from '../../../freedomHooks/useDataJson';
import { appInsights } from '../../../auth/appInsight';
import { useNavigate } from 'react-router-dom';
import { VoucherUrls } from '../../../url/Links';
import { FixedAfterTwoDigit1, devConsoleLog } from '../../../utils/utilityFunctions';
import ProductSubscriptionDialogBox from '../ProductSubscriptionDialogBox/ProductSubscriptionDialogBox';
import useBroker from '../../../CustomHooks/useBroker';
import { updateBroker } from '../../../features/broker/brokerSlice';
import FreedomSnackBar from '../../../Components/SharedComponent/FreedomSnackBar';

const CardWrapper = styled(Box)((props) => ({
  // height: "432px",
  border: props.subscribed ? `2px solid ${props.color}` : '',
  borderRadius: ' 15px',
  // padding: "13px",
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  width: '100%',
  // backgroundImage:
  //   props.hovered || props.subscribed
  //     ? `url(${props.hoveredBgImg})`
  //     : `url(${props.inactiveBgImg})`,
  backgroundImage: props.subscribed ? `url(${props.activeBgImg})` : props.hovered ? `url(${props.hoveredBgImg})` : `url(${props.inactiveBgImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
  // opacity: props.subscribed && '.9'
}));

const CardHeaderWrapper = styled(Box)((props) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '11px',
  marginBottom: '16px',
  justifyContent: props.live && 'space-between'
}));

const Icon = styled('img')((props) => ({
  width: '40px',
  height: '40px',
  // backgroundColor: props.hovered ? 'white' : props.cardColor,
  // padding: "5px",
  // borderRadius: "9px",
  marginRight: '10px'
}));

const Heading = styled(Typography)((props) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '18px',
  // color:  props.subscribed ? "white" : props.cardColor,
  color: props.hovered ? (props.mode === 'light' ? 'black' : 'white') : props.subscribed ? (props.mode === 'light' ? 'black' : 'white') : props.textColor,
  opacity: props.status === 'disable' ? '0.5' : ''
}));

const SubHeading = styled(Typography)((props) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '16px',
  // lineHeight: "24px",
  // color: props.subscribed ? "white" : props.cardColor,
  color: props.hovered ? (props.mode === 'light' ? 'black' : 'white') : props.subscribed ? (props.mode === 'light' ? 'black' : 'white') : props.cardColor,
  textTransform: 'capitalize',
  padding: '16px',
  // paddingRight:'17%',
  marginBottom: '34px',
  opacity: props.status === 'disable' ? '0.5' : '',
  width: '100%',
  height: '63px',

  // background: 'rgba(0, 0, 0, 0.4)',

  background: props.subHeadingBgColor ? props.subHeadingBgColor : 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(12px)',
  display: 'flex',
  // justifyContent: "space-between",
  alignItems: 'center'
}));

const CardInfo = styled(Typography)((props) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  [props.theme.breakpoints.down('md')]: {
    fontSize: '10px'
  },
  marginLeft: '5.5px',
  // color:  props.subscribed ? "white" : props.cardColor,

  color: props.hovered ? (props.mode === 'light' ? 'black' : 'white') : props.subscribed ? (props.mode === 'light' ? 'black' : 'white') : props.cardColor,
  whiteSpace: 'nowrap',
  opacity: props.status === 'disable' ? '0.5' : ''
}));

const StyledCheckIcon = styled(CheckCircleOutlineIcon)((props) => ({
  // color:  props.subscribed ? "white" : props.cardColor,
  color: props.hovered ? (props.mode === 'light' ? 'black' : 'white') : props.subscribed ? (props.mode === 'light' ? 'black' : 'white') : props.cardColor,
  opacity: props.status === 'disable' ? '0.5' : '',
  fontSize: '16px'
}));

let _message = '';
let _duration = 5000;
let _variant = '';
const InvestmentProductCard = ({ card, cardProps, live, subscribed, balance, id, isUpgradePlatinum, goldLive, marginTop, cardHeight, infoCard }) => {
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false);
  // const { data: brokerData, loading  } = useBroker("investmentProductCard");
  const dispatch = useDispatch();
  // const userTradingBalance = useSelector(
  //   (state) => state.investmentProducts.userTradingBalance
  // );
  const userTradingBalance = useSelector((state) => state.investmentProducts.userBalance);

  // const { jsonPrefix } = useDataJson();

  const showMessage = (msg, duration, variant) => {
    _message = msg;
    _duration = duration;
    _variant = variant;
    setOpen(true);
  };
  const subscribeButtonLabel = subscribed === true ? 'Subscribed' : ' subscribe now';
  const { apiData } = card;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // useEffect(() => {
  //   if (!loading && brokerData.length > 0) {
  //     dispatch(updateBroker(brokerData[0]));
  //   }
  // }, [loading]);

  // const fetchUserProducts = async () => {
  //   const response = await GetUserProducts(accessToken);
  //   dispatch(setUserProducts(response.data));
  // };

  // const subscriptionHandler = async () => {
  //   if (userTradingBalance >= apiData.minimumDeposit) {
  //     let payload = [
  //       {
  //         __metadata: { type: "UserProducts" },
  //         ...jsonPrefix,
  //         Id: 0,
  //         UserId: "@UserId",
  //         ProductID: apiData?.id,
  //         TradeStatus: 3,
  //         AccountBalance: userTradingBalance,
  //         LowMinRiskPercentage: apiData?.lowMinRiskMinPercentage,
  //         LowMinRiskBalance: apiData?.minimumDeposit,
  //         LowMaxRiskPercentage: apiData?.lowMaxRiskMaxPercentage,
  //         LowMaxRiskBalance: apiData?.LowMaxRiskBalance,
  //         MediumMinRiskPercentage: apiData?.MediumMinRiskPercentage,
  //         MediumMinRiskBalance: apiData?.MediumMinRiskPercentage,
  //         MediumMaxRiskPercentage: apiData?.MediumMinRiskPercentage,
  //         MediumMaxRiskBalance: apiData?.MediumMinRiskPercentage,
  //         HighMinRiskPercentage: apiData?.MediumMinRiskPercentage,
  //         HighMinRiskBalance: apiData?.MediumMinRiskPercentage,
  //         HighMaxRiskPercentage: apiData?.MediumMinRiskPercentage,
  //         HighMaxRiskBalance: apiData?.MediumMinRiskPercentage,
  //         Status: true,
  //         CreatedByUserID: "@UserId",
  //         ModifiedByUserID: "@UserId",
  //       },
  //     ];
  //     const myJSON = JSON.stringify(payload);
  //     const secondJSON = JSON.stringify(myJSON);

  //     axios({
  //       url: siteUrl,
  //       headers: {
  //         "content-type": "application/json; charset=utf-8",
  //         Authorization: "Bearer " + accessToken,
  //       },
  //       method: "post",
  //       data: secondJSON,
  //     })
  //       .then((res) => {
  //         devConsoleLog("res", res);
  //         showMessage("Information Saved Successfully", 4000, "info");
  //         fetchUserProducts();
  //       })
  //       .catch((error) => {
  //         devConsoleLog(error);
  //         appInsights.trackException({ error: new Error(error) });

  //         showMessage("Error While Subscribe Product", 4000, "error");
  //       });
  //   } else {
  //     setOpenDialog(true);
  //   }
  // };

  devConsoleLog('userTradingBalance', userTradingBalance);
  try {
    return (
      <>
        <Box>{openDialog === true && <LowInvestmentDialogBox setOpenDialog={setOpenDialog} openDialog={openDialog} dialogamountcolor={cardProps?.color} productName={card?.apiData?.productCode} amount={card?.apiData?.minimumDeposit} />}</Box>
        <Box>
          {openSubscriptionDialog === true && (
            <ProductSubscriptionDialogBox setOpenDialog={setOpenSubscriptionDialog} openDialog={openSubscriptionDialog} card={card} tradeStatus={'testing'} setOpen={setOpen} showMessage={showMessage} color={cardProps.color} cardProps={cardProps} />
          )}
        </Box>
        <Box>
          <FreedomSnackBar open={open} duration={_duration} variant={_variant} message={_message} handleClose={handleClose} />
          {/* <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{ paddingTop: "50px", marginRight: "60px" }}
          open={open}
          autoHideDuration={_duration}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={_variant || "success"}>
            {_message}
          </Alert>
        </Snackbar> */}
        </Box>
        <Grid item onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)} display="flex" justifyContent="right" xs={12} height={cardHeight}>
          <CardWrapper {...cardProps} hovered={hovered} subscribed={subscribed} style={{ marginTop: 0 }}>
            <CardHeaderWrapper live={live}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                // sx={{ padding: '0px 13px' }}
                p={' 0 5px 0 4px'}
                // mt={marginTop || 1}
              >
                <Box display="flex" alignItems="center">
                  <Icon {...cardProps} hovered={hovered} src={cardProps.icon} alt="img here" />
                  <Heading {...cardProps} hovered={hovered} subscribed={subscribed} status={card.status} mode={mode}>
                    {card.heading}
                  </Heading>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "'Poppins'",
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: '11.1628px',
                      lineHeight: '16px',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      textTransform: 'capitalize',
                      // color: subscribed ? "white" : cardProps.color,
                      color: hovered ? (mode === 'light' ? 'black' : 'white') : subscribed ? (mode === 'light' ? 'black' : 'white') : cardProps.textColor
                    }}
                  >
                    Investment from
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "'Poppins'",
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: '18.6046px',
                      lineHeight: '16px',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      textTransform: 'capitalize',
                      // color:  subscribed ? "white" : cardProps.color,
                      color: hovered ? (mode === 'light' ? 'black' : 'white') : subscribed ? (mode === 'light' ? 'black' : 'white') : cardProps.textColor,
                      float: 'right'
                    }}
                  >
                    ${FixedAfterTwoDigit1(card?.apiData?.minimumDeposit)}
                  </Typography>
                </Box>
              </Box>
            </CardHeaderWrapper>
            {!live && (
              <>
                <SubHeading cardColor={cardProps.textColor} status={card.status} subHeadingBgColor={cardProps.subHeadingBgColor} hovered={hovered} subscribed={subscribed} mode={mode}>
                  <Grid item xs={6.7} sm={6.5} md={6.5} lg={6.7}>
                    <Typography>Expected Annual</Typography>
                  </Grid>
                  <Grid item xs={5.3} sm={5.5} md={5.5} lg={5.3}>
                    <Typography
                      sx={{
                        fontFamily: "'Poppins'",
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: '26px',
                        lineHeight: '45px',
                        textTransform: 'capitalize'
                        // color: hovered ? 'white' : cardProps.color,
                      }}
                    >
                      {card.subheading}
                    </Typography>
                  </Grid>
                </SubHeading>
                <Grid container>
                  {card.infoPoints.map((des, idx) => (
                    <Grid item key={idx} xs={idx % 2 === 0 ? 6.2 : 5.8} display="flex" alignItems="center" px={'16px'} mb={goldLive === false ? '32px' : '15px'}>
                      <StyledCheckIcon cardColor={cardProps.textColor} hovered={hovered} status={card.status} subscribed={subscribed} mode={mode} />
                      <CardInfo cardColor={cardProps.textColor} status={card.status} hovered={hovered} subscribed={subscribed} mode={mode}>
                        {des}
                      </CardInfo>
                    </Grid>
                  ))}
                </Grid>

                {isUpgradePlatinum ? (
                  <Button
                    // fullWidth
                    sx={{
                      border: `1px solid  ${
                        subscribed ? 'none' : hovered ? (mode === 'light' ? cardProps.selectedButtonbg : 'white') : cardProps.selectedButtonbg
                        // hovered || subscribed ? "white" : cardProps.color
                      }`,

                      margin: '20px',
                      // marginBottom: '20px',
                      borderRadius: '10px',
                      width: '90%',
                      opacity: card.status === 'disable' ? '0.5' : '',
                      color: hovered ? (mode === 'light' ? 'black' : 'white') : subscribed ? (mode === 'light' ? 'white' : 'white') : cardProps.textColor,
                      // background: subscribeButtonLabel=== 'Subscribed' ?cardProps.color : '',
                      // background:cardProps.color,
                      textTransform: 'capitalize',
                      background: (subscribeButtonLabel === 'Subscribed' && cardProps.selectedButtonbg) || (hovered && cardProps.hoverButtonbg),
                      '&.Mui-disabled': {
                        color: '#ffffff'
                      },
                      '&:hover': {
                        backgroundColor: cardProps.selectedButtonbg
                      }
                    }}
                    mt={6}
                    // mx={2}
                    onClick={() => navigate(VoucherUrls.upgradeProfile)}
                    // disabled={
                    //   subscribeButtonLabel === "Subscribed" ? true : false
                    // }
                  >
                    Upgrade to Platinum
                  </Button>
                ) : infoCard ? null : (
                  <Button
                    // fullWidth
                    sx={{
                      border: `1px solid  ${
                        subscribed ? 'none' : hovered ? (mode === 'light' ? cardProps.selectedButtonbg : 'white') : cardProps.selectedButtonbg
                        // hovered || subscribed ? "white" : cardProps.color
                      }`,

                      margin: '30px 20px 20px 20px',
                      // marginBottom: '20px',
                      borderRadius: '10px',
                      width: '90%',
                      opacity: card.status === 'disable' ? '0.5' : '',
                      // color:
                      //   hovered || subscribeButtonLabel === "Subscribed"
                      //     ? "white"
                      //     : cardProps.color,
                      color: hovered ? (mode === 'light' ? 'black' : 'white') : subscribed ? (mode === 'light' ? 'white' : 'white') : cardProps.textColor,
                      // background: subscribeButtonLabel=== 'Subscribed' ?cardProps.color : '',
                      // background:cardProps.color,
                      textTransform: 'capitalize',
                      background: (subscribeButtonLabel === 'Subscribed' && cardProps.selectedButtonbg) || (hovered && cardProps.hoverButtonbg),
                      '&.Mui-disabled': {
                        color: '#ffffff'
                      },
                      '&:hover': {
                        backgroundColor: cardProps.selectedButtonbg
                      }
                    }}
                    mt={6}
                    // mx={2}
                    // onClick={() => subscriptionHandler()}
                    onClick={() => (userTradingBalance >= apiData.minimumDeposit ? setOpenSubscriptionDialog(true) : setOpenDialog(true))}
                    disabled={subscribeButtonLabel === 'Subscribed' ? true : false}
                  >
                    {subscribeButtonLabel === 'Subscribed' && (
                      <CheckCircleOutlineIcon
                        fontsize="small"
                        //  mr={2}
                        sx={{ marginRight: '10px' }}
                      />
                    )}
                    {subscribeButtonLabel}
                  </Button>
                )}
              </>
            )}
          </CardWrapper>
        </Grid>
      </>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default InvestmentProductCard;
