import { Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import FreedomWrapperRoot from '../../Components/SharedComponent/FreedomWrapperRoot/FreedomWrapperRoot';
import InvestmentProductCard from '../components/Card/InvestmentProductCard';

import TradingMarketsContainer from '../components/TradingMarketsContainer/TradingMarketsContainer';
import { useContext, useEffect } from 'react';
import { ColorModeContext } from '../../store';
import ProductDesctiptionContainer from '../components/ProductDesctiptionContainer/ProductDesctiptionContainer';
import InvestmentFooterCard from '../components/Card/InvestmentFooterCard';
import { useDispatch, useSelector } from 'react-redux';
import { setProducts } from '../../features/investment/productsSlice';
import { useState } from 'react';
import useFetch from '../../CustomHooks/useFetch';
import { getInvestmentModule, getProductsUrl, grtSubsctibedProducts } from '../../api/apiUrls/investmentApiUrls';
import FreedomLoader, { Loader } from '../../Components/SharedComponent/FreedomLoader/FreedomLoader';
import InvestmentPlatinumProducts from './InvestmentPlatinumProducts';
import { appInsights } from '../../auth/appInsight';
import { svgIcon, svgLinksDark, svgLinksLight } from '../../url/SvgLinks';
// import { GetProducts } from "../../api/DataProvider/investmentDataProvider";
// import InvestmentFooter from "../components/Footer/InvestmentFooter";
// import { cardsData } from "../data";
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
const PlatinumIcon = svgIcon.PlatinumIcon;
const SupremeIcon = svgIcon.supremeIcon;
const goldDarkprod = svgLinksDark.goldDarkprod;
const goldLightpro = svgLinksLight.goldLightpro;
const EliteFooterBgImg = svgLinksDark.eliteDark;
const PlatinumBGLight = svgLinksLight.PlatinumBGLight;

const CardsWrapper = styled(Box)(({ theme, mode, padding }) => ({
  borderRadius: '10px',
  padding: padding || '24px 42.5px',
  // background: "#FFFFFF",
  background: mode === 'light' ? '#FFFFFF' : 'none',
  width: '100%',
  // marginBottom: "16px",

  [theme.breakpoints.down('sm')]: {
    padding: '5px '
  }
}));

const BgImgWrapper = styled(Box)(({ mode }) => ({
  backgroundImage: mode === 'light' ? `url(${PlatinumBGLight})` : `url(${EliteFooterBgImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100%',
  // border: '2px solid rebeccapurple',
  borderRadius: '15px'
}));

// const CardInfoPoint = styled(Typography)(() => ({
//   fontFamily: "Poppins",
//   fontStyle: "normal",
//   fontWeight: 400,
//   fontSize: "12px",
//   lineHeight: "34px",
//   marginLeft: "5.5px",
// }));

const PageHeading = styled(Typography)(() => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '24px',
  lineHeight: '36px'
}));

const cardPropsSelector = (type, mode) => {
  return {
    color: '#ECC44F',
    // color: mode === "light" ? "black" : "#ECC44F",
    icon: mode === 'light' ? SupremeIcon : SupremeIcon,
    // ? SilverBasicIcon
    // : SilverBasicIcon,
    // bgImg: SilverBasicInactiveIcon,
    // inactiveBgImg:
    //   mode === "light" ? GoldLightInactiveBgImg : GoldDarkInactiveBgImg,
    // hoveredBgImg: GoldLightHoverBgImg,
    activeBgImg: mode === 'light' ? goldLightpro : goldDarkprod,
    inactiveBgImg: mode === 'light' ? goldLightpro : goldDarkprod,
    hoveredBgImg: mode === 'light' ? goldLightpro : goldDarkprod,
    hoverButtonbg: '#ECC44F',
    subHeadingBgColor: mode === 'light' ? 'rgba(188, 139, 0, 0.30)' : 'rgba(194, 165, 82, 0.3)',
    selectedButtonbg: '#ECC44F',
    textColor: mode === 'light' ? '#000000' : '#ffffff'

    // hoveredBgImg: BasicSiHover
  };
};

const godldCardIcons = [
  {
    icon: PlatinumIcon,
    altText: 'PlatinumIcon'
  }
];

const InvestmentGoldProducts = () => {
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  const theme = useTheme();
  const [load, isLoading] = useState(true);
  const { uniqueId } = useSelector((state) => state.user.user);

  const { loading: productLoading, data: productData, fetchData } = useFetch(getInvestmentModule(uniqueId));
  // const { data, loading, error } = useFetch(getProductsUrl());
  // const subscribedProduct = useFetch(grtSubsctibedProducts());
  const mobile360 = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const products = useSelector((state) => state.investmentProducts.products);
  const userProduct = useSelector((state) => state.investmentProducts.userProduct);

  useEffect(() => {
    dispatch(setProducts(productData?.data?.products));
    !productLoading && isLoading(productLoading);
  }, [productData?.data?.products, dispatch, productLoading]);

  const supremeCard = products[6];
  const eliteCard = products[7];
  const id = userProduct?.id;

  const supremeCardsData = [
    {
      heading: supremeCard?.name,
      subheading: `ROI ${supremeCard?.annualExpectedRoi}%​`,
      infoPoints: [
        // `Expected Annual ROI ${supremeCard?.annualExpectedRoi}%​`,
        `VPS Class ${supremeCard?.vpsclass}`,
        'Period 12 Month​',
        `Risk Profile ${supremeCard?.riskProfile}%​`,
        'Money Management​',
        'Risk Management​',
        // `Investment from $${supremeCard?.minimumbalance}​`,
        'Upgradable​'
      ],
      status: 'inactive',
      type: supremeCard?.name,
      apiData: supremeCard,
      buttonLabel: 'Gold',
      cardName: supremeCard?.name
    }
  ];

  const eliteCardsData = [
    {
      heading: eliteCard?.name,
      subheading: `Expected Annual ROI ${eliteCard?.annualExpectedRoi}%​`,
      infoPoints: [
        `Expected Annual ROI ${eliteCard?.annualExpectedRoi}%​`,
        `VPS Class ${eliteCard?.vpsclass}`,
        'Period 12 Month​',
        `Risk Profile ${eliteCard?.riskProfile}%​`,
        'Money Management​',
        'Risk Management​',
        `Investment from $${eliteCard?.minimumBalance}​`,
        'Upgradable​'
      ],
      status: 'inactive',
      type: eliteCard?.name,
      apiData: eliteCard,
      buttonLabel: 'Platinum'
    }
  ];

  try {
    return (
      <Grid container>
        {/* <Grid item xs={12} textalign="center">
        <PageHeading mb="24px">Product Upgrades</PageHeading>
      </Grid> */}
        {load ? (
          <Box
            sx={{
              position: 'absolute',
              top: '45%',
              left: '47.5%'
            }}
          >
            <Loader />{' '}
          </Box>
        ) : (
          <>
            <Grid item xs={12}>
              {/* <FreedomWrapperRoot table="true" marginTop="0"> */}
              <Box width="100%">
                <CardsWrapper padding={'15px 15px'} mode={mode}>
                  <Grid container spacing={mobile360 ? 1 : 1}>
                    {products.length
                      ? supremeCardsData.map((card) => {
                          let cardProps = cardPropsSelector(card.type, mode);
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={4}
                              // mr={{ lg: 2 }}
                            >
                              <InvestmentProductCard cardProps={cardProps} card={card} cardStatus subscribed={userProduct?.id === card?.apiData?.id ? true : false} id={id} cardHeight="100%" />
                            </Grid>
                          );
                        })
                      : null}
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                      {/* <BgImgWrapper py={1} mode={mode}>
                      <ProductDesctiptionContainer
                        standardCardIcons={godldCardIcons}
                        gold={true}
                        header="PLATINUM
                      PRODUCT"
                        description="Upgrade to Elite product with an adjustable risk ratio. Platinum has a risk profile of up to 50%, and the expected annual ROI is +50 %. Elite offers money management and risk management with extended sub-products. "
                      />
                      {products.length
                        ? eliteCardsData.map((card, idx) => {
                            const eliteCardData = {
                              activeBgImg:mode === 'light' ? PlatinumBGLight : EliteFooterBgImg,
                              inactiveBgImg: mode === 'light' ? PlatinumBGLight : EliteFooterBgImg,
                              inActiveIcon:
                                mode === "light" ? PlatinumIcon : PlatinumIcon,
                                color:mode === "light" ? 'black': 'white'
                            };
                            return (
                              <InvestmentFooterCard
                                inactiveBgImg={eliteCardData.inactiveBgImg}
                                activeBgImg={eliteCardData.activeBgImg}
                                cardColor={eliteCardData.color}
                                card={card}
                                activeIcon={eliteCardData.activeIcon}
                                inActiveIcon={eliteCardData.inActiveIcon}
                                supreme={true}
                                index={idx}
                                suscribed={
                                  subscribedProduct.data[
                                    subscribedProduct.data?.length - 1
                                  ]?.productId === card?.apiData?.id
                                    ? true
                                    : false
                                }
                              />
                            );
                          })
                        : null}
                    </BgImgWrapper> */}
                      <InvestmentPlatinumProducts fullwidth={true} isUpgradePlatinum={true} _padding={1} />
                    </Grid>
                  </Grid>
                </CardsWrapper>
              </Box>
              {/* </FreedomWrapperRoot> */}
            </Grid>
          </>
        )}
      </Grid>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default InvestmentGoldProducts;
