import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { ColorModeContext } from '../../../store';

const UpgradeAccountCircle = ({ background, bordercolor, pathtitle }) => {
  console.log('bordercolor', bordercolor);
  const theme = useTheme();
  const mobile360 = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  return (
    <>
      {pathtitle === 'Silver' && mode === 'dark' ? (
        <Box>
          <svg width={mobile360 ? '230' : '300'} height={mobile360 ? '110' : '200'} viewBox="0 0 241 116" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M98.5782 18.2704C88.2551 7.61971 73.7972 1 57.7933 1C26.4271 1 1 26.427 1 57.793C1 89.159 26.4271 114.586 57.7933 114.586C73.7977 114.586 88.256 107.966 98.5791 97.3148C104.615 92.569 112.227 89.7383 120.501 89.7383C130.898 89.7383 140.252 94.209 146.743 101.333C156.609 109.604 169.327 114.585 183.208 114.585C214.573 114.585 240 89.158 240 57.7929C240 26.4277 214.573 1.0016 183.208 1.0016C169.327 1.0016 156.609 5.98186 146.743 14.2531C140.252 21.3769 130.898 25.8475 120.501 25.8475C112.227 25.8475 104.614 23.0166 98.5782 18.2704Z"
              fill="url(#paint0_linear_1335_49256)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M98.5782 18.2704C88.2551 7.61971 73.7972 1 57.7933 1C26.4271 1 1 26.427 1 57.793C1 89.159 26.4271 114.586 57.7933 114.586C73.7977 114.586 88.256 107.966 98.5791 97.3148C104.615 92.569 112.227 89.7383 120.501 89.7383C130.898 89.7383 140.252 94.209 146.743 101.333C156.609 109.604 169.327 114.585 183.208 114.585C214.573 114.585 240 89.158 240 57.7929C240 26.4277 214.573 1.0016 183.208 1.0016C169.327 1.0016 156.609 5.98186 146.743 14.2531C140.252 21.3769 130.898 25.8475 120.501 25.8475C112.227 25.8475 104.614 23.0166 98.5782 18.2704Z"
              fill="url(#paint1_linear_1335_49256)"
            />
            <path
              d="M98.5782 18.2704L98.2192 18.6184L98.2427 18.6426L98.2692 18.6635L98.5782 18.2704ZM98.5791 97.3148L98.2701 96.9218L98.2436 96.9426L98.2201 96.9668L98.5791 97.3148ZM146.743 101.333L146.374 101.67L146.396 101.695L146.422 101.716L146.743 101.333ZM146.743 14.2531L146.422 13.8699L146.396 13.8915L146.374 13.9163L146.743 14.2531ZM57.7933 1.5C73.6564 1.5 87.9864 8.06085 98.2192 18.6184L98.9373 17.9224C88.5239 7.17858 73.938 0.5 57.7933 0.5V1.5ZM1.5 57.793C1.5 26.7031 26.7032 1.5 57.7933 1.5V0.5C26.151 0.5 0.5 26.1508 0.5 57.793H1.5ZM57.7933 114.086C26.7033 114.086 1.5 88.8828 1.5 57.793H0.5C0.5 89.4351 26.151 115.086 57.7933 115.086V114.086ZM98.2201 96.9668C87.9872 107.525 73.6568 114.086 57.7933 114.086V115.086C73.9385 115.086 88.5247 108.407 98.9382 97.6628L98.2201 96.9668ZM120.501 89.2383C112.111 89.2383 104.391 92.1091 98.2701 96.9218L98.8882 97.7079C104.839 93.0289 112.343 90.2383 120.501 90.2383V89.2383ZM147.113 100.996C140.53 93.7726 131.045 89.2383 120.501 89.2383V90.2383C130.752 90.2383 139.973 94.6453 146.374 101.67L147.113 100.996ZM183.208 114.085C169.449 114.085 156.843 109.148 147.065 100.95L146.422 101.716C156.374 110.06 169.205 115.085 183.208 115.085V114.085ZM239.5 57.7929C239.5 88.8819 214.297 114.085 183.208 114.085V115.085C214.85 115.085 240.5 89.4342 240.5 57.7929H239.5ZM183.208 1.5016C214.297 1.5016 239.5 26.7039 239.5 57.7929H240.5C240.5 26.1516 214.85 0.501601 183.208 0.501601V1.5016ZM147.065 14.6363C156.843 6.43768 169.449 1.5016 183.208 1.5016V0.501601C169.205 0.501601 156.374 5.52604 146.422 13.8699L147.065 14.6363ZM120.501 26.3475C131.045 26.3475 140.53 21.8133 147.113 14.5899L146.374 13.9163C139.973 20.9406 130.752 25.3475 120.501 25.3475V26.3475ZM98.2692 18.6635C104.39 23.4764 112.111 26.3475 120.501 26.3475V25.3475C112.343 25.3475 104.838 22.5567 98.8873 17.8774L98.2692 18.6635Z"
              fill="url(#paint2_linear_1335_49256)"
            />
            <defs>
              <linearGradient id="paint0_linear_1335_49256" x1="120.5" y1="1" x2="120.5" y2="114.586" gradientUnits="userSpaceOnUse">
                <stop stopColor={'#282828'} />
                <stop offset="1" stopOpacity="0.12" />
              </linearGradient>
              <linearGradient id="paint1_linear_1335_49256" x1="240" y1="114.586" x2="240" y2="1" gradientUnits="userSpaceOnUse">
                <stop stopOpacity="0.01" />
                <stop offset="1" stopOpacity="0.1" />
              </linearGradient>
              <linearGradient id="paint2_linear_1335_49256" x1="120.5" y1="1" x2="120.5" y2="114.586" gradientUnits="userSpaceOnUse">
                <stop stopColor={'#6D6C75'} />
                <stop offset="1" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
      ) : pathtitle === 'Gold' && mode === 'dark' ? (
        <Box>
          <svg width={mobile360 ? '230' : '300'} height={mobile360 ? '110' : '200'} viewBox="0 0 241 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M98.5782 18.1492C88.2551 7.52987 73.7972 0.929688 57.7933 0.929688C26.4271 0.929688 1 26.2817 1 57.5551C1 88.8286 26.4271 114.181 57.7933 114.181C73.7976 114.181 88.2559 107.58 98.5791 96.9604C104.615 92.2286 112.227 89.4062 120.501 89.4062C130.898 89.4062 140.251 93.8636 146.743 100.966C156.609 109.213 169.327 114.179 183.209 114.179C214.574 114.179 240 88.8277 240 57.555C240 26.2824 214.574 0.93128 183.209 0.93128C169.327 0.93128 156.609 5.89694 146.743 14.1439C140.251 21.2466 130.898 25.7039 120.501 25.7039C112.227 25.7039 104.614 22.8813 98.5782 18.1492Z"
              fill="url(#paint0_linear_1452_31693)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M98.5782 18.1492C88.2551 7.52987 73.7972 0.929688 57.7933 0.929688C26.4271 0.929688 1 26.2817 1 57.5551C1 88.8286 26.4271 114.181 57.7933 114.181C73.7976 114.181 88.2559 107.58 98.5791 96.9604C104.615 92.2286 112.227 89.4062 120.501 89.4062C130.898 89.4062 140.251 93.8636 146.743 100.966C156.609 109.213 169.327 114.179 183.209 114.179C214.574 114.179 240 88.8277 240 57.555C240 26.2824 214.574 0.93128 183.209 0.93128C169.327 0.93128 156.609 5.89694 146.743 14.1439C140.251 21.2466 130.898 25.7039 120.501 25.7039C112.227 25.7039 104.614 22.8813 98.5782 18.1492Z"
              fill="url(#paint1_linear_1452_31693)"
            />
            <path
              d="M98.5782 18.1492L98.2197 18.4977L98.2432 18.5218L98.2697 18.5426L98.5782 18.1492ZM98.5791 96.9604L98.2706 96.5669L98.2441 96.5877L98.2206 96.6119L98.5791 96.9604ZM146.743 100.966L146.374 101.304L146.397 101.329L146.422 101.35L146.743 100.966ZM146.743 14.1439L146.422 13.7603L146.397 13.7818L146.374 13.8066L146.743 14.1439ZM57.7933 1.42969C73.6569 1.42969 87.987 7.9714 98.2197 18.4977L98.9367 17.8006C88.5232 7.08834 73.9375 0.429688 57.7933 0.429688V1.42969ZM1.5 57.5551C1.5 26.5592 26.7019 1.42969 57.7933 1.42969V0.429688C26.1524 0.429688 0.5 26.0041 0.5 57.5551H1.5ZM57.7933 113.681C26.7019 113.681 1.5 88.5511 1.5 57.5551H0.5C0.5 89.1061 26.1524 114.681 57.7933 114.681V113.681ZM98.2206 96.6119C87.9878 107.139 73.6573 113.681 57.7933 113.681V114.681C73.938 114.681 88.5241 108.022 98.9376 97.3089L98.2206 96.6119ZM120.501 88.9062C112.112 88.9062 104.391 91.7685 98.2706 96.5669L98.8876 97.3539C104.838 92.6887 112.343 89.9062 120.501 89.9062V88.9062ZM147.112 100.629C140.53 93.4268 131.044 88.9062 120.501 88.9062V89.9062C130.752 89.9062 139.973 94.3004 146.374 101.304L147.112 100.629ZM183.209 113.679C169.448 113.679 156.843 108.757 147.064 100.583L146.422 101.35C156.375 109.67 169.206 114.679 183.209 114.679V113.679ZM239.5 57.555C239.5 88.5501 214.299 113.679 183.209 113.679V114.679C214.848 114.679 240.5 89.1052 240.5 57.555H239.5ZM183.209 1.43128C214.299 1.43128 239.5 26.5599 239.5 57.555H240.5C240.5 26.0049 214.848 0.43128 183.209 0.43128V1.43128ZM147.064 14.5275C156.843 6.35305 169.448 1.43128 183.209 1.43128V0.43128C169.206 0.43128 156.375 5.44084 146.422 13.7603L147.064 14.5275ZM120.501 26.2039C131.044 26.2039 140.529 21.6834 147.112 14.4812L146.374 13.8066C139.973 20.8098 130.752 25.2039 120.501 25.2039V26.2039ZM98.2697 18.5426C104.391 23.3414 112.111 26.2039 120.501 26.2039V25.2039C112.342 25.2039 104.838 22.4212 98.8867 17.7557L98.2697 18.5426Z"
              fill="url(#paint2_linear_1452_31693)"
            />
            <defs>
              <linearGradient id="paint0_linear_1452_31693" x1="120.5" y1="0.929687" x2="120.5" y2="114.181" gradientUnits="userSpaceOnUse">
                <stop stopColor="#282828" />
                <stop offset="1" stopOpacity="0.12" />
              </linearGradient>
              <linearGradient id="paint1_linear_1452_31693" x1="240" y1="114.181" x2="240" y2="0.929688" gradientUnits="userSpaceOnUse">
                <stop stopOpacity="0.01" />
                <stop offset="1" stopOpacity="0.1" />
              </linearGradient>
              <linearGradient id="paint2_linear_1452_31693" x1="120.5" y1="0.929687" x2="120.5" y2="114.181" gradientUnits="userSpaceOnUse">
                <stop stopColor="#ECC44F" />
                <stop offset="1" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
      ) : pathtitle === 'Platinum' && mode === 'dark' ? (
        <Box>
          <svg width={mobile360 ? '230' : '300'} height={mobile360 ? '110' : '200'} viewBox="0 0 241 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M98.5782 18.1492C88.2551 7.52987 73.7972 0.929688 57.7933 0.929688C26.4271 0.929688 1 26.2817 1 57.5551C1 88.8286 26.4271 114.181 57.7933 114.181C72.1972 114.181 85.3488 108.834 95.3606 100.023H145.644C155.655 108.833 168.806 114.179 183.209 114.179C214.574 114.179 240 88.8277 240 57.555C240 26.2824 214.574 0.931282 183.209 0.931282C169.327 0.931282 156.609 5.89695 146.743 14.1439C140.251 21.2466 130.898 25.7039 120.501 25.7039C112.227 25.7039 104.614 22.8813 98.5782 18.1492Z"
              fill="url(#paint0_linear_1452_31748)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M98.5782 18.1492C88.2551 7.52987 73.7972 0.929688 57.7933 0.929688C26.4271 0.929688 1 26.2817 1 57.5551C1 88.8286 26.4271 114.181 57.7933 114.181C72.1972 114.181 85.3488 108.834 95.3606 100.023H145.644C155.655 108.833 168.806 114.179 183.209 114.179C214.574 114.179 240 88.8277 240 57.555C240 26.2824 214.574 0.931282 183.209 0.931282C169.327 0.931282 156.609 5.89695 146.743 14.1439C140.251 21.2466 130.898 25.7039 120.501 25.7039C112.227 25.7039 104.614 22.8813 98.5782 18.1492Z"
              fill="url(#paint1_linear_1452_31748)"
            />
            <path
              d="M98.5782 18.1492L98.2197 18.4977L98.2432 18.5219L98.2697 18.5426L98.5782 18.1492ZM95.3606 100.023V99.5233H95.1719L95.0303 99.6479L95.3606 100.023ZM145.644 100.023L145.974 99.6479L145.832 99.5233H145.644V100.023ZM146.743 14.1439L146.422 13.7603L146.397 13.7818L146.374 13.8066L146.743 14.1439ZM57.7933 1.42969C73.6569 1.42969 87.987 7.9714 98.2197 18.4977L98.9368 17.8006C88.5232 7.08834 73.9375 0.429688 57.7933 0.429688V1.42969ZM1.5 57.5551C1.5 26.5592 26.7019 1.42969 57.7933 1.42969V0.429688C26.1524 0.429688 0.5 26.0041 0.5 57.5551H1.5ZM57.7933 113.681C26.7019 113.681 1.5 88.5511 1.5 57.5551H0.5C0.5 89.1061 26.1524 114.681 57.7933 114.681V113.681ZM95.0303 99.6479C85.1063 108.382 72.0711 113.681 57.7933 113.681V114.681C72.3232 114.681 85.5912 109.287 95.6909 100.399L95.0303 99.6479ZM95.3606 100.523H145.644V99.5233H95.3606V100.523ZM145.313 100.399C155.413 109.286 168.68 114.679 183.209 114.679V113.679C168.932 113.679 155.897 108.381 145.974 99.6479L145.313 100.399ZM183.209 114.679C214.848 114.679 240.5 89.1052 240.5 57.555H239.5C239.5 88.5501 214.299 113.679 183.209 113.679V114.679ZM240.5 57.555C240.5 26.0049 214.848 0.431282 183.209 0.431282V1.43128C214.299 1.43128 239.5 26.5599 239.5 57.555H240.5ZM183.209 0.431282C169.206 0.431282 156.375 5.44084 146.422 13.7603L147.064 14.5275C156.843 6.35305 169.448 1.43128 183.209 1.43128V0.431282ZM146.374 13.8066C139.973 20.8098 130.752 25.2039 120.501 25.2039V26.2039C131.044 26.2039 140.529 21.6834 147.112 14.4812L146.374 13.8066ZM120.501 25.2039C112.342 25.2039 104.838 22.4212 98.8867 17.7557L98.2697 18.5426C104.391 23.3414 112.111 26.2039 120.501 26.2039V25.2039Z"
              fill="url(#paint2_linear_1452_31748)"
            />
            <defs>
              <linearGradient id="paint0_linear_1452_31748" x1="120.5" y1="0.929687" x2="120.5" y2="114.181" gradientUnits="userSpaceOnUse">
                <stop stopColor="#282828" />
                <stop offset="1" stopOpacity="0.12" />
              </linearGradient>
              <linearGradient id="paint1_linear_1452_31748" x1="240" y1="114.181" x2="240" y2="0.929688" gradientUnits="userSpaceOnUse">
                <stop stopOpacity="0.01" />
                <stop offset="1" stopOpacity="0.1" />
              </linearGradient>
              <linearGradient id="paint2_linear_1452_31748" x1="120.5" y1="0.929687" x2="120.5" y2="114.181" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C111FF" />
                <stop offset="1" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
      ) : (
        ''
      )}
      {pathtitle === 'Silver' && mode === 'light' ? (
        <Box>
          <svg width={mobile360 ? '230' : '300'} height={mobile360 ? '110' : '200'} viewBox="0 0 241 116" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M98.5782 18.2704C88.2551 7.61971 73.7972 1 57.7933 1C26.4271 1 1 26.427 1 57.793C1 89.159 26.4271 114.586 57.7933 114.586C73.7977 114.586 88.256 107.966 98.5791 97.3148C104.615 92.569 112.227 89.7383 120.501 89.7383C130.898 89.7383 140.252 94.209 146.743 101.333C156.609 109.604 169.327 114.585 183.208 114.585C214.573 114.585 240 89.158 240 57.7929C240 26.4277 214.573 1.0016 183.208 1.0016C169.327 1.0016 156.609 5.98186 146.743 14.2531C140.252 21.3769 130.898 25.8475 120.501 25.8475C112.227 25.8475 104.614 23.0166 98.5782 18.2704Z"
              fill="url(#paint0_linear_1542_144081)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M98.5782 18.2704C88.2551 7.61971 73.7972 1 57.7933 1C26.4271 1 1 26.427 1 57.793C1 89.159 26.4271 114.586 57.7933 114.586C73.7977 114.586 88.256 107.966 98.5791 97.3148C104.615 92.569 112.227 89.7383 120.501 89.7383C130.898 89.7383 140.252 94.209 146.743 101.333C156.609 109.604 169.327 114.585 183.208 114.585C214.573 114.585 240 89.158 240 57.7929C240 26.4277 214.573 1.0016 183.208 1.0016C169.327 1.0016 156.609 5.98186 146.743 14.2531C140.252 21.3769 130.898 25.8475 120.501 25.8475C112.227 25.8475 104.614 23.0166 98.5782 18.2704Z"
              fill="url(#paint1_linear_1542_144081)"
            />
            <path
              d="M98.5782 18.2704L98.2192 18.6184L98.2427 18.6426L98.2692 18.6635L98.5782 18.2704ZM98.5791 97.3148L98.2701 96.9218L98.2436 96.9426L98.2201 96.9668L98.5791 97.3148ZM146.743 101.333L146.374 101.67L146.396 101.695L146.422 101.716L146.743 101.333ZM146.743 14.2531L146.422 13.8699L146.396 13.8915L146.374 13.9163L146.743 14.2531ZM57.7933 1.5C73.6564 1.5 87.9864 8.06085 98.2192 18.6184L98.9373 17.9224C88.5239 7.17858 73.938 0.5 57.7933 0.5V1.5ZM1.5 57.793C1.5 26.7031 26.7032 1.5 57.7933 1.5V0.5C26.151 0.5 0.5 26.1508 0.5 57.793H1.5ZM57.7933 114.086C26.7033 114.086 1.5 88.8828 1.5 57.793H0.5C0.5 89.4351 26.151 115.086 57.7933 115.086V114.086ZM98.2201 96.9668C87.9872 107.525 73.6568 114.086 57.7933 114.086V115.086C73.9385 115.086 88.5247 108.407 98.9382 97.6628L98.2201 96.9668ZM120.501 89.2383C112.111 89.2383 104.391 92.1091 98.2701 96.9218L98.8882 97.7079C104.839 93.0289 112.343 90.2383 120.501 90.2383V89.2383ZM147.113 100.996C140.53 93.7726 131.045 89.2383 120.501 89.2383V90.2383C130.752 90.2383 139.973 94.6453 146.374 101.67L147.113 100.996ZM183.208 114.085C169.449 114.085 156.843 109.148 147.065 100.95L146.422 101.716C156.374 110.06 169.205 115.085 183.208 115.085V114.085ZM239.5 57.7929C239.5 88.8819 214.297 114.085 183.208 114.085V115.085C214.85 115.085 240.5 89.4342 240.5 57.7929H239.5ZM183.208 1.5016C214.297 1.5016 239.5 26.7039 239.5 57.7929H240.5C240.5 26.1516 214.85 0.501601 183.208 0.501601V1.5016ZM147.065 14.6363C156.843 6.43768 169.449 1.5016 183.208 1.5016V0.501601C169.205 0.501601 156.374 5.52604 146.422 13.8699L147.065 14.6363ZM120.501 26.3475C131.045 26.3475 140.53 21.8133 147.113 14.5899L146.374 13.9163C139.973 20.9406 130.752 25.3475 120.501 25.3475V26.3475ZM98.2692 18.6635C104.39 23.4764 112.111 26.3475 120.501 26.3475V25.3475C112.343 25.3475 104.838 22.5567 98.8873 17.8774L98.2692 18.6635Z"
              fill="url(#paint2_linear_1542_144081)"
            />
            <defs>
              <linearGradient id="paint0_linear_1542_144081" x1="120.5" y1="1" x2="120.5" y2="114.586" gradientUnits="userSpaceOnUse">
                <stop stopColor="#282828" />
                <stop offset="1" stopColor="white" stopOpacity="0.12" />
              </linearGradient>
              <linearGradient id="paint1_linear_1542_144081" x1="240" y1="114.586" x2="240" y2="1" gradientUnits="userSpaceOnUse">
                <stop stopOpacity="0.01" />
                <stop offset="1" stopOpacity="0.1" />
              </linearGradient>
              <linearGradient id="paint2_linear_1542_144081" x1="120.5" y1="1" x2="120.5" y2="114.586" gradientUnits="userSpaceOnUse">
                <stop stopColor="#6D6C75" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
      ) : pathtitle === 'Gold' && mode === 'light' ? (
        <Box>
          <svg width={mobile360 ? '230' : '300'} height={mobile360 ? '110' : '200'} viewBox="0 0 241 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M98.5782 18.1492C88.2551 7.52987 73.7972 0.929688 57.7933 0.929688C26.4271 0.929688 1 26.2817 1 57.5551C1 88.8286 26.4271 114.181 57.7933 114.181C73.7976 114.181 88.2559 107.58 98.5791 96.9604C104.615 92.2286 112.227 89.4062 120.501 89.4062C130.898 89.4062 140.251 93.8636 146.743 100.966C156.609 109.213 169.327 114.179 183.209 114.179C214.574 114.179 240 88.8277 240 57.555C240 26.2824 214.574 0.93128 183.209 0.93128C169.327 0.93128 156.609 5.89694 146.743 14.1439C140.251 21.2466 130.898 25.7039 120.501 25.7039C112.227 25.7039 104.614 22.8813 98.5782 18.1492Z"
              fill="url(#paint0_linear_1542_144134)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M98.5782 18.1492C88.2551 7.52987 73.7972 0.929688 57.7933 0.929688C26.4271 0.929688 1 26.2817 1 57.5551C1 88.8286 26.4271 114.181 57.7933 114.181C73.7976 114.181 88.2559 107.58 98.5791 96.9604C104.615 92.2286 112.227 89.4062 120.501 89.4062C130.898 89.4062 140.251 93.8636 146.743 100.966C156.609 109.213 169.327 114.179 183.209 114.179C214.574 114.179 240 88.8277 240 57.555C240 26.2824 214.574 0.93128 183.209 0.93128C169.327 0.93128 156.609 5.89694 146.743 14.1439C140.251 21.2466 130.898 25.7039 120.501 25.7039C112.227 25.7039 104.614 22.8813 98.5782 18.1492Z"
              fill="url(#paint1_linear_1542_144134)"
            />
            <path
              d="M98.5782 18.1492L98.2197 18.4977L98.2432 18.5218L98.2697 18.5426L98.5782 18.1492ZM98.5791 96.9604L98.2706 96.5669L98.2441 96.5877L98.2206 96.6119L98.5791 96.9604ZM146.743 100.966L146.374 101.304L146.397 101.329L146.422 101.35L146.743 100.966ZM146.743 14.1439L146.422 13.7603L146.397 13.7818L146.374 13.8066L146.743 14.1439ZM57.7933 1.42969C73.6569 1.42969 87.987 7.9714 98.2197 18.4977L98.9367 17.8006C88.5232 7.08834 73.9375 0.429688 57.7933 0.429688V1.42969ZM1.5 57.5551C1.5 26.5592 26.7019 1.42969 57.7933 1.42969V0.429688C26.1524 0.429688 0.5 26.0041 0.5 57.5551H1.5ZM57.7933 113.681C26.7019 113.681 1.5 88.5511 1.5 57.5551H0.5C0.5 89.1061 26.1524 114.681 57.7933 114.681V113.681ZM98.2206 96.6119C87.9878 107.139 73.6573 113.681 57.7933 113.681V114.681C73.938 114.681 88.5241 108.022 98.9376 97.3089L98.2206 96.6119ZM120.501 88.9062C112.112 88.9062 104.391 91.7685 98.2706 96.5669L98.8876 97.3539C104.838 92.6887 112.343 89.9062 120.501 89.9062V88.9062ZM147.112 100.629C140.53 93.4268 131.044 88.9062 120.501 88.9062V89.9062C130.752 89.9062 139.973 94.3004 146.374 101.304L147.112 100.629ZM183.209 113.679C169.448 113.679 156.843 108.757 147.064 100.583L146.422 101.35C156.375 109.67 169.206 114.679 183.209 114.679V113.679ZM239.5 57.555C239.5 88.5501 214.299 113.679 183.209 113.679V114.679C214.848 114.679 240.5 89.1052 240.5 57.555H239.5ZM183.209 1.43128C214.299 1.43128 239.5 26.5599 239.5 57.555H240.5C240.5 26.0049 214.848 0.43128 183.209 0.43128V1.43128ZM147.064 14.5275C156.843 6.35305 169.448 1.43128 183.209 1.43128V0.43128C169.206 0.43128 156.375 5.44084 146.422 13.7603L147.064 14.5275ZM120.501 26.2039C131.044 26.2039 140.529 21.6834 147.112 14.4812L146.374 13.8066C139.973 20.8098 130.752 25.2039 120.501 25.2039V26.2039ZM98.2697 18.5426C104.391 23.3414 112.111 26.2039 120.501 26.2039V25.2039C112.342 25.2039 104.838 22.4212 98.8867 17.7557L98.2697 18.5426Z"
              fill="url(#paint2_linear_1542_144134)"
            />
            <defs>
              <linearGradient id="paint0_linear_1542_144134" x1="120.5" y1="0.929687" x2="120.5" y2="114.181" gradientUnits="userSpaceOnUse">
                <stop stopColor="#ECD580" />
                <stop offset="1" stopColor="white" stopOpacity="0.12" />
              </linearGradient>
              <linearGradient id="paint1_linear_1542_144134" x1="240" y1="114.181" x2="240" y2="0.929688" gradientUnits="userSpaceOnUse">
                <stop stopOpacity="0.01" />
                <stop offset="1" stopOpacity="0.1" />
              </linearGradient>
              <linearGradient id="paint2_linear_1542_144134" x1="120.5" y1="0.929687" x2="120.5" y2="114.181" gradientUnits="userSpaceOnUse">
                <stop stopColor="#ECD580" />
                <stop offset="1" stopColor="#ECD580" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
      ) : pathtitle === 'Platinum' && mode === 'light' ? (
        <Box>
          <svg width={mobile360 ? '230' : '300'} height={mobile360 ? '110' : '200'} viewBox="0 0 241 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M98.5782 18.1492C88.2551 7.52987 73.7972 0.929688 57.7933 0.929688C26.4271 0.929688 1 26.2817 1 57.5551C1 88.8286 26.4271 114.181 57.7933 114.181C73.7976 114.181 88.2559 107.58 98.5791 96.9604C104.615 92.2286 112.227 89.4062 120.501 89.4062C130.898 89.4062 140.251 93.8636 146.743 100.966C156.609 109.213 169.327 114.179 183.209 114.179C214.574 114.179 240 88.8277 240 57.555C240 26.2824 214.574 0.93128 183.209 0.93128C169.327 0.93128 156.609 5.89694 146.743 14.1439C140.251 21.2466 130.898 25.7039 120.501 25.7039C112.227 25.7039 104.614 22.8813 98.5782 18.1492Z"
              fill="url(#paint0_linear_1542_144190)"
            />
            <path
              d="M98.5782 18.1492L98.2197 18.4977L98.2432 18.5218L98.2697 18.5426L98.5782 18.1492ZM98.5791 96.9604L98.2706 96.5669L98.2441 96.5877L98.2206 96.6119L98.5791 96.9604ZM146.743 100.966L146.374 101.304L146.397 101.329L146.422 101.35L146.743 100.966ZM146.743 14.1439L146.422 13.7603L146.397 13.7818L146.374 13.8066L146.743 14.1439ZM57.7933 1.42969C73.6569 1.42969 87.987 7.9714 98.2197 18.4977L98.9367 17.8006C88.5232 7.08834 73.9375 0.429688 57.7933 0.429688V1.42969ZM1.5 57.5551C1.5 26.5592 26.7019 1.42969 57.7933 1.42969V0.429688C26.1524 0.429688 0.5 26.0041 0.5 57.5551H1.5ZM57.7933 113.681C26.7019 113.681 1.5 88.5511 1.5 57.5551H0.5C0.5 89.1061 26.1524 114.681 57.7933 114.681V113.681ZM98.2206 96.6119C87.9878 107.139 73.6573 113.681 57.7933 113.681V114.681C73.938 114.681 88.5241 108.022 98.9376 97.3089L98.2206 96.6119ZM120.501 88.9062C112.112 88.9062 104.391 91.7685 98.2706 96.5669L98.8876 97.3539C104.838 92.6887 112.343 89.9062 120.501 89.9062V88.9062ZM147.112 100.629C140.53 93.4268 131.044 88.9062 120.501 88.9062V89.9062C130.752 89.9062 139.973 94.3004 146.374 101.304L147.112 100.629ZM183.209 113.679C169.448 113.679 156.843 108.757 147.064 100.583L146.422 101.35C156.375 109.67 169.206 114.679 183.209 114.679V113.679ZM239.5 57.555C239.5 88.5501 214.299 113.679 183.209 113.679V114.679C214.848 114.679 240.5 89.1052 240.5 57.555H239.5ZM183.209 1.43128C214.299 1.43128 239.5 26.5599 239.5 57.555H240.5C240.5 26.0049 214.848 0.43128 183.209 0.43128V1.43128ZM147.064 14.5275C156.843 6.35305 169.448 1.43128 183.209 1.43128V0.43128C169.206 0.43128 156.375 5.44084 146.422 13.7603L147.064 14.5275ZM120.501 26.2039C131.044 26.2039 140.529 21.6834 147.112 14.4812L146.374 13.8066C139.973 20.8098 130.752 25.2039 120.501 25.2039V26.2039ZM98.2697 18.5426C104.391 23.3414 112.111 26.2039 120.501 26.2039V25.2039C112.342 25.2039 104.838 22.4212 98.8867 17.7557L98.2697 18.5426Z"
              fill="url(#paint1_linear_1542_144190)"
            />
            <defs>
              <linearGradient id="paint0_linear_1542_144190" x1="120.5" y1="0.929687" x2="120.5" y2="114.181" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C111FF" />
                <stop offset="1" stopColor="white" stopOpacity="0.12" />
              </linearGradient>
              <linearGradient id="paint1_linear_1542_144190" x1="120.5" y1="0.929687" x2="120.5" y2="114.181" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C111FF" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
      ) : (
        ''
      )}
    </>
  );
};

export default UpgradeAccountCircle;
