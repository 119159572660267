import { Grid } from '@mui/material';
import React from 'react';
import FreedomSuccessImage from '../../../../Components/SharedComponent/FreedomSuccessImage/FreedomSuccessImage';

const SuccessTransaction = ({ paymentPayMethod }) => {
  return (
    <Grid container mt={10} mb={10} direction="column" alignItems="center">
      <FreedomSuccessImage paymentmethod={paymentPayMethod} />
    </Grid>
  );
};

export default SuccessTransaction;
