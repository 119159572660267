import { Box, Grid } from '@mui/material';
import React from 'react';
import ImportantNotes from '../../../../Components/SharedComponent/ImportantNotes/ImportantNotes';
import { Wrapper } from './upgradeprofile.styles';
import { useTheme } from '@mui/styles';
import FreedomTypographyText from '../../../../Components/SharedComponent/FreedomTypographyText/FreedomTypographyText';
import { images, voucherSvg } from '../../../../url/SvgLinks';
import FreedomColorDivider from '../../../../Components/SharedComponent/FreedomColorDivider/FreedomColorDivider';
import FreedomFollowUpIcon from '../../../../Components/SharedComponent/FreedomFollowUpIcon/FreedomFollowUpIcon';

const BlackCheckIcon = images.BlackCheckIcon;
const CircleBlackfilled = images.CircleBlackfilled;
const infoIcon = voucherSvg.importantNotes;
const copyIcon = images.copyIcon;

const summarySteps = [
  {
    label: 'Transfer Money',
    description: `Transfer your money to Freedom account.`
  },
  {
    label: 'Process Order ',
    description: 'Wait 1-2 business days for local transfers and 4-5 days for international transfers.'
  },
  {
    label: 'Funds Arrived',
    description: `Receive your deposit amount `
  }
];

const impNotes = [
  {
    description: 'The receiver/Payee/Beneficiary name must be FREEDOM'
  },
  {
    description: 'The name you use must match the name registered to your FREEDOM account      '
  },
  {
    description: `During the transfer mentioned the 'Reference Number' provided by Freedom with its bank details`
  },
  {
    description: 'Please note that if your bank is based outside of Dubai, there might be additional fee involved, please get in touch with your bank      '
  }
];

const BankDetail = ({ openDialog, setOpenDialog, systemID }) => {
  const theme = useTheme();
  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} justifyContent="center">
      {openDialog && (
        <ImportantNotes openDialog={openDialog} setOpenDialog={setOpenDialog} heading="Important Notes">
          <ul
            style={{
              marginLeft: '0'
            }}
          >
            {' '}
            {impNotes.map((note, index) => (
              <li key={index}>
                <FreedomTypographyText fontsize={'12px'} lineheight={'32px'} fontweight={500} textalign="left" margin={'0px'}>
                  {note.description}
                </FreedomTypographyText>
              </li>
            ))}
          </ul>
        </ImportantNotes>
      )}
      <Grid item xs={12} md={4}>
        <Wrapper height={'100%'} padding={'16px'}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px'
            }}
          >
            <FreedomTypographyText color={theme.palette.primary.white} fontsize="16px" fontweight={600} textalign="left" style={{ marginBottom: '25px', marginLeft: '12px' }}>
              How Does It Works?
            </FreedomTypographyText>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <img src={CircleBlackfilled} style={{ width: '12px', height: '12px' }} alt="img" />
              <FreedomTypographyText fontsize={'12px'} lineheight={'18px'} fontweight={500} textalign="left" margin={'0px 0px 0px 8px'}>
                {summarySteps[0].label}
              </FreedomTypographyText>
            </Box>
            <Box
              sx={{
                width: '100%',
                maxHeight: '48px',
                marginLeft: '5px',
                borderLeft: `1px dashed ${theme.palette.primary.main}`
              }}
            >
              <FreedomTypographyText color={theme.palette.primary.placeholder} fontsize={'12px'} lineheight={'18px'} fontweight={500} textalign="left" margin={'0px 0px 0px 12px'}>
                {summarySteps[0].description}
              </FreedomTypographyText>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <img src={CircleBlackfilled} style={{ width: '12px', height: '12px' }} alt="img" />
              <FreedomTypographyText fontsize={'12px'} lineheight={'18px'} fontweight={500} textalign="left" margin={'0px 0px 0px 8px'}>
                {summarySteps[1].label}
              </FreedomTypographyText>
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '48px',
                marginLeft: '5px',
                borderLeft: `1px dashed ${theme.palette.primary.main}`
              }}
            >
              <FreedomTypographyText color={theme.palette.primary.placeholder} fontsize={'12px'} lineheight={'18px'} fontweight={500} textalign="left" margin={'0px 0px 0px 12px'}>
                {summarySteps[1].description}
              </FreedomTypographyText>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <img src={BlackCheckIcon} style={{ width: '12px', height: '12px' }} alt="img" />
              <FreedomTypographyText fontsize={'12px'} lineheight={'18px'} fontweight={500} textalign="left" margin={'0px 0px 0px 8px'}>
                {summarySteps[2].label}
              </FreedomTypographyText>
            </Box>
            <Box sx={{ width: '100%', height: '48px', marginLeft: '5px' }}>
              <FreedomTypographyText color={theme.palette.primary.placeholder} fontsize={'12px'} lineheight={'18px'} fontweight={500} textalign="left" margin={'0px 0px 0px 12px'}>
                {summarySteps[2].description}
              </FreedomTypographyText>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.importantNote,
              border: `1px solid ${theme.palette.border.border}`,
              borderRadius: '5px',
              height: '36px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px',
              mt: 3,
              width: '100%'
            }}
          >
            <FreedomTypographyText fontsize="12px">Important Notes</FreedomTypographyText>
            <FreedomFollowUpIcon
              padding="4px"
              height="28px"
              width="28px"
              responsiveheight="28px"
              responsivewidth="28px"
              borderradius="5px"
              // bordercolor='none'
              backgroundcolor={theme.palette.primary.default}
              // backgroundhovercolor={theme.palette.icon.iconColor}
              // iconimg={mode === 'light' ? infoIconLight : infoIconLight}
              iconimg={infoIcon}
              onClick={() => setOpenDialog((prev) => !prev)}
            />
          </Box>
        </Wrapper>
      </Grid>
      <Grid item xs={12} sm={12} md={7} lg={5} xl={5}>
        <Wrapper height={'100%'} padding={'0px'}>
          <Wrapper height={'100%'} bordercolor={'none'}>
            <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                Deposited Currency{' '}
              </FreedomTypographyText>
              <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'50%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600} margin={'0px 4px'}>
                  {' '}
                  USD{' '}
                </FreedomTypographyText>
                <FreedomFollowUpIcon
                  tooltiptitle={'Copy'}
                  padding="2px"
                  height="16px"
                  width="16px"
                  responsiveheight="16px"
                  responsivewidth="16px"
                  borderradius="5px"
                  bordercolor="none"
                  iconimg={copyIcon}
                  onClick={async () => {
                    await navigator.clipboard.writeText('USD');
                  }}
                />
              </Wrapper>
            </Wrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.border} marginTop={'0px'} marginBottom={'0px'} />
            </Box>
            <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                Account Name{' '}
              </FreedomTypographyText>
              <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'50%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600} margin={'0px 4px'}>
                  {' '}
                  MyFreedom L.L.C-FZ{' '}
                </FreedomTypographyText>
                <FreedomFollowUpIcon
                  tooltiptitle={'Copy'}
                  padding="2px"
                  height="16px"
                  width="16px"
                  responsiveheight="16px"
                  responsivewidth="16px"
                  borderradius="5px"
                  bordercolor="none"
                  iconimg={copyIcon}
                  onClick={async () => {
                    await navigator.clipboard.writeText('MyFreedom L.L.C-FZ');
                  }}
                />
              </Wrapper>
            </Wrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.border} marginTop={'0px'} marginBottom={'0px'} />
            </Box>
            <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                Account Number
              </FreedomTypographyText>
              <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'50%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600} margin={'0px 4px'}>
                  {' '}
                  9946375812{' '}
                </FreedomTypographyText>
                <FreedomFollowUpIcon
                  tooltiptitle={'Copy'}
                  padding="2px"
                  height="16px"
                  width="16px"
                  responsiveheight="16px"
                  responsivewidth="16px"
                  borderradius="5px"
                  bordercolor="none"
                  iconimg={copyIcon}
                  onClick={async () => {
                    await navigator.clipboard.writeText('9946375812');
                  }}
                />
              </Wrapper>
            </Wrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.border} marginTop={'0px'} marginBottom={'0px'} />
            </Box>
            <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                Bank Name{' '}
              </FreedomTypographyText>
              <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'50%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600} margin={'0px 4px'}>
                  {' '}
                  Wio Bank PJSC{' '}
                </FreedomTypographyText>
                <FreedomFollowUpIcon
                  tooltiptitle={'Copy'}
                  padding="2px"
                  height="16px"
                  width="16px"
                  responsiveheight="16px"
                  responsivewidth="16px"
                  borderradius="5px"
                  bordercolor="none"
                  iconimg={copyIcon}
                  onClick={async () => {
                    await navigator.clipboard.writeText('Wio Bank PJSC');
                  }}
                />
              </Wrapper>
            </Wrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.border} marginTop={'0px'} marginBottom={'0px'} />
            </Box>
            <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                Bank Address{' '}
              </FreedomTypographyText>
              <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'75%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontsize={'12px'} fontweight={600}>
                  {' '}
                  Etihad Airways Centre 5th Floor, Abu Dhabi, UAE{' '}
                </FreedomTypographyText>
                <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} margin={'0px'} width={'20px'}>
                  <FreedomFollowUpIcon
                    tooltiptitle={'Copy'}
                    padding="2px"
                    height="16px"
                    width="16px"
                    responsiveheight="16px"
                    responsivewidth="16px"
                    borderradius="5px"
                    bordercolor="none"
                    iconimg={copyIcon}
                    onClick={async () => {
                      await navigator.clipboard.writeText('Etihad Airways Centre 5th Floor, Abu Dhabi, UAE');
                    }}
                  />
                </Wrapper>
              </Wrapper>
            </Wrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.border} marginTop={'0px'} marginBottom={'0px'} />
            </Box>
            <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                IBAN{' '}
              </FreedomTypographyText>
              <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'50%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600} margin={'0px 4px'}>
                  {' '}
                  AE210860000009946375812{' '}
                </FreedomTypographyText>
                <FreedomFollowUpIcon
                  tooltiptitle={'Copy'}
                  padding="2px"
                  height="16px"
                  width="16px"
                  responsiveheight="16px"
                  responsivewidth="16px"
                  borderradius="5px"
                  bordercolor="none"
                  iconimg={copyIcon}
                  onClick={async () => {
                    await navigator.clipboard.writeText('AE210860000009946375812');
                  }}
                />
              </Wrapper>
            </Wrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.border} marginTop={'0px'} marginBottom={'0px'} />
            </Box>
            <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                BIC/SWIFT{' '}
              </FreedomTypographyText>
              <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'50%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600} margin={'0px 4px'}>
                  {' '}
                  WIOBAEADXXX{' '}
                </FreedomTypographyText>
                <FreedomFollowUpIcon
                  tooltiptitle={'Copy'}
                  padding="2px"
                  height="16px"
                  width="16px"
                  responsiveheight="16px"
                  responsivewidth="16px"
                  borderradius="5px"
                  bordercolor="none"
                  iconimg={copyIcon}
                  onClick={async () => {
                    await navigator.clipboard.writeText('WIOBAEADXXX');
                  }}
                />
              </Wrapper>
            </Wrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.border} marginTop={'0px'} marginBottom={'0px'} />
            </Box>
            <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                Freedom Reference No.{' '}
              </FreedomTypographyText>
              <Wrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'50%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600} margin={'0px 4px'}>
                  {' '}
                  {systemID}{' '}
                </FreedomTypographyText>
                <FreedomFollowUpIcon
                  tooltiptitle={'Copy'}
                  padding="2px"
                  height="16px"
                  width="16px"
                  responsiveheight="16px"
                  responsivewidth="16px"
                  borderradius="5px"
                  bordercolor="none"
                  iconimg={copyIcon}
                  onClick={async () => {
                    await navigator.clipboard.writeText(`${systemID}`);
                  }}
                />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Grid>
    </Grid>
  );
};

export default BankDetail;
