import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import { GraphPeriod } from '../../FreedomConstants/freedomConstants';
import { devConsoleLog, pureFunctions } from '../../utils/utilityFunctions';
import { getFollowUpCustomerApiUrl } from '../../api/apiUrls/customerApiUrl';

const initialState = {
  graphTabValue: GraphPeriod.Week,
  pendingAffiliateCount: 0,
  manageAffiliateCount: 0,
  firstlineCount: 0,
  downlineCount: 0,
  downlineWeekArray: [],
  graphData: [],
  affiliateType: 1,
  graphPeriod: 1,
  firstlineGraphCount: 0,
  affiliateGraphCount: 0,
  pendingGraphCount: 0,
  firstLineSubCount: 0,
  affiliatesSubCount: 0,
  pendingAffiliatesSubCount: 0,
  downlineSubCount: 0,
  FollowUpDistributorData: [],
  PreviousInvitesData: [],
  PendingTabData: [],
  currentWeekNumber: pureFunctions.currentIsoWeekNo(),
  currentMonthNumber: pureFunctions.currentMonthNo(),
  selectedYear: pureFunctions.selectedYear(),
  startDateOfGraph: pureFunctions.last7daysStartDate(),
  endDateOfGraph: pureFunctions.last7daysEndDate(),
  // selectedYear: moment().format('YYYY'),
  // startDateOfGraph: moment().subtract(6,'d').format('YYYY-MM-DD'),
  // endDateOfGraph: moment().format('YYYY-MM-DD'),
  accumulateForGraph: true,
  growthForGraph: false,
  graphAffiliateType: 2,
  tilldateDataGraph: 0,
  graphDataForDropDown: [],
  graphGrowthAndAccumulateDropDownValue: 1,
  loading: false
};

export const affiliateSlice = createSlice({
  name: 'affiliate',
  initialState,
  reducers: {
    updateGraphTabValue: (state, action) => {
      state.graphTabValue = action.payload;
    },
    updatePendingAffiliateCount: (state, action) => {
      state.pendingAffiliateCount = action.payload;
    },
    updateManageAffiliateCount: (state, action) => {
      state.manageAffiliateCount = action.payload;
    },
    updateFirstlineCount: (state, action) => {
      state.firstlineCount = action.payload;
    },
    updateDownlineCount: (state, action) => {
      state.downlineCount = action.payload;
    },
    updateDownlineWeekArray: (state, action) => {
      state.downlineWeekArray = action.payload;
    },
    updateGraphData: (state, action) => {
      state.graphData = action.payload;
    },
    updateAffiliateType: (state, action) => {
      state.affiliateType = action.payload;
    },

    updateAffiliateDashboardData: (state, action) => {
      const { manageAffiliateCount, firstlineCount, downlineCount, pendingAffiliateCount, firstLineSubCount, downlineSubCount, pendingAffiliatesSubCount, affiliatesSubCount, tilldateDataGraph, graphDataForDropDown, graphAffiliateType, loading } =
        action.payload;
      state.manageAffiliateCount = manageAffiliateCount;
      state.firstlineCount = firstlineCount;
      state.downlineCount = downlineCount;
      state.pendingAffiliateCount = pendingAffiliateCount;
      state.firstLineSubCount = firstLineSubCount;
      state.downlineSubCount = downlineSubCount;
      state.graphAffiliateType = graphAffiliateType;
      state.affiliatesSubCount = affiliatesSubCount;
      state.pendingAffiliatesSubCount = pendingAffiliatesSubCount;
      state.tilldateDataGraph = tilldateDataGraph;
      state.graphDataForDropDown = graphDataForDropDown;
      state.loading = loading;
    },

    updateLatestData: (state, action) => {
      const { graphAffiliateType, graphPeriod, startDateOfGraph, endDateOfGraph, growthForGraph, accumulateForGraph } = action.payload;
      state.graphAffiliateType = graphAffiliateType;
      state.graphPeriod = graphPeriod;
      state.startDateOfGraph = startDateOfGraph;
      state.endDateOfGraph = endDateOfGraph;
      state.growthForGraph = growthForGraph;
      state.accumulateForGraph = accumulateForGraph;
    },

    updateAccumlateAndGrowthDropDown: (state, action) => {
      const { graphGrowthAndAccumulateDropDownValue, accumulateForGraph, growthForGraph } = action.payload;
      state.graphGrowthAndAccumulateDropDownValue = graphGrowthAndAccumulateDropDownValue;
      state.accumulateForGraph = accumulateForGraph;
      state.growthForGraph = growthForGraph;
    },
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },

    updateGraphGrowthAndAccumulateDropDownValue: (state, action) => {
      state.graphGrowthAndAccumulateDropDownValue = action.payload;
    },
    updateGraphPeriod: (state, action) => {
      state.graphPeriod = action.payload;
    },
    updateFirstlineGraphCount: (state, action) => {
      state.firstlineGraphCount = action.payload;
    },
    updateAffiliateGraphCount: (state, action) => {
      state.affiliateGraphCount = action.payload;
    },
    updatePendingGraphCount: (state, action) => {
      state.pendingGraphCount = action.payload;
    },
    updateFollowUpDistributorData: (state, action) => {
      state.FollowUpDistributorData = action.payload;
    },
    updatePreviousInvitesData: (state, action) => {
      state.PreviousInvitesData = action.payload;
    },

    updateInvitationData: (state, action) => {
      const { FollowUpDistributorData, PreviousInvitesData } = action.payload;

      if (FollowUpDistributorData !== undefined) {
        state.FollowUpDistributorData = FollowUpDistributorData;
      }
      if (PreviousInvitesData !== undefined) {
        state.PreviousInvitesData = PreviousInvitesData;
      }
    },
    updatePendingTabData: (state, action) => {
      state.PendingTabData = action.payload;
    },
    updateFirstLineSubCount: (state, action) => {
      state.firstLineSubCount = action.payload;
    },
    updateAffiliatesSubCount: (state, action) => {
      state.affiliatesSubCount = action.payload;
    },
    updatePendingAffiliatesSubCount: (state, action) => {
      state.pendingAffiliatesSubCount = action.payload;
    },
    updateDownlineSubCount: (state, action) => {
      state.downlineSubCount = action.payload;
    },
    updateSelectedYear: (state, action) => {
      state.selectedYear = action.payload;
    },
    updateStartDateOfGraph: (state, action) => {
      state.startDateOfGraph = action.payload;
    },
    updateEndDateOfGraph: (state, action) => {
      state.endDateOfGraph = action.payload;
    },
    updateStartAndEndDateOfAffiliateGraph: (state, action) => {
      const { startDateOfGraph, endDateOfGraph, graphPeriod } = action.payload;
      state.startDateOfGraph = startDateOfGraph;
      state.endDateOfGraph = endDateOfGraph;

      if (graphPeriod) {
        state.graphPeriod = graphPeriod;
      }
    },
    updateCurrentWeekNumber: (state, action) => {
      state.currentWeekNumber = action.payload;
    },
    updateCurrentMonthNumber: (state, action) => {
      state.currentMonthNumber = action.payload;
    },
    updateAccumulateGraph: (state, action) => {
      state.accumulateForGraph = action.payload;
    },
    updateAffiliateGraphType: (state, action) => {
      state.graphAffiliateType = action.payload;
    },
    updateGrowthForGraph: (state, action) => {
      state.growthForGraph = action.payload;
    },
    updateTillDateDataGraph: (state, action) => {
      state.tilldateDataGraph = action.payload;
    }
  }
  /**
	 * affiliatesSubCount: 0,
	pendingAffiliatesSubCount: 0,
	 */
});

export const getTabData = (accessToken) => async (dispatch) => {
  const today = new Date();

  const todayDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
  try {
    await axios
      .get(getFollowUpCustomerApiUrl(todayDate), {
        headers: { Authorization: 'Bearer ' + accessToken }
      })
      .then((res) => {
        devConsoleLog('response', res);
        dispatch(updatePendingTabData(res.data));
      });
  } catch (err) {
    throw new Error(err);
  }
};

// Action creators are generated for each case reducer function

export const {
  updateGraphTabValue,
  updatePendingAffiliateCount,
  updateManageAffiliateCount,
  updateFirstlineCount,
  updateDownlineCount,
  updateDownlineWeekArray,
  updateGraphData,
  updateAffiliateType,
  updateGraphPeriod,
  updateAffiliateDashboardData,
  updateLoading,
  updateLatestData,
  updateFirstlineGraphCount,
  updateAffiliateGraphCount,
  updatePendingGraphCount,
  updateFirstLineSubCount,
  updateAffiliatesSubCount,
  updateFollowUpDistributorData,
  updateInvitationData,
  updatePendingAffiliatesSubCount,
  updateGraphGrowthAndAccumulateDropDownValue,
  updateAccumlateAndGrowthDropDown,
  updateDownlineSubCount,
  updatePreviousInvitesData,
  updatePendingTabData,
  updateSelectedYear,
  updateStartDateOfGraph,
  updateEndDateOfGraph,
  updateCurrentWeekNumber,
  updateCurrentMonthNumber,
  updateAccumulateGraph,
  updateAffiliateGraphType,
  updateGrowthForGraph,
  updateTillDateDataGraph,
  updateStartAndEndDateOfAffiliateGraph
} = affiliateSlice.actions;

export const tabData = (state) => state.affiliate.PendingTabData;
export const distributorDashboardData = (state) => {
  devConsoleLog('satate of slice', state);
  return {
    manageAffiliateCount: state.affiliate.manageAffiliateCount,
    firstlineCount: state.affiliate.firstlineCount,
    downlineCount: state.affiliate.downlineCount,
    pendingAffiliateCount: state.affiliate.pendingAffiliateCount,
    firstLineSubCount: state.affiliate.firstLineSubCount,
    downlineSubCount: state.affiliate.downlineSubCount,
    pendingAffiliatesSubCount: state.affiliate.pendingAffiliatesSubCount,
    tilldateDataGraph: state.affiliate.tilldateDataGraph,
    graphDataForDropDown: state.affiliate.graphDataForDropDown,
    loading: state.affiliate.loading
  };
};

export default affiliateSlice.reducer;
