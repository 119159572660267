import { Box, Grid, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NewInvestmentButton from '../Button/NewInvestmentButton';
import { useSelector } from 'react-redux';
// import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { appInsights } from '../../../auth/appInsight';
import { devConsoleLog } from '../../../utils/utilityFunctions';

const CardWrapper = styled(Box)(({ cardColor, active, inactiveBgImg, activeBgImg, supreme, goldLive, width, theme }) => ({
  height: '100%' || (supreme && '100%'),
  border: active ? `1px solid ${cardColor}` : ' 1px solid #616161',
  borderRadius: ' 15px',
  // border: "2px solid #00922B",
  // borderRadius: '15px',
  padding: '13px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  // width: active ? "415px" : supreme || goldLive ? "100%" : "220px",
  transition: 'width .5s',
  backgroundImage: active ? `url(${activeBgImg})` : `url(${inactiveBgImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
}));

const CardHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px'
}));

const Image = styled('img')((props) => ({
  width: '32px',
  height: '32px',
  // borderRadius: "9px",
  marginRight: '10px'
}));

const Heading = styled(Typography)((props) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '24px',
  color: props.theme.palette.primary.onBoardTextColor
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '10px',
  lineHeight: '24px',
  color: theme.palette.primary.onBoardTextColor,
  textTransform: 'capitalize',
  paddingLeft: '4px',
  marginBottom: '20px',
  marginTop: '10px',
  whiteSpace: 'nowrap',
  width: '50%',
  transition: 'width .5s',
  [theme.breakpoints.down('sm')]: {
    fontWeight: '500',
    fontSize: '6px',
    lineHeight: '24px'
  }
}));

const CardInfo = styled(Typography)((props) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '9px',
  marginLeft: '5.5px',
  color: props.theme.palette.primary.onBoardTextColor,
  whiteSpace: 'nowrap'
}));

const InvestmentFooterCard = (props) => {
  const {
    card,
    cardColor,
    silverCard,
    activeIcon,
    inActiveIcon,
    active,
    index,
    setActive,
    supreme,
    goldLive,
    suscribed,
    productTypeId,
    // subHeadingBgColor,
    selectedButtonbg,
    hoverButtonbg,
    fullWidth,
    gridsNo
  } = props;

  const theme = useTheme();
  const subscribeButtonLabel = suscribed === true ? 'Subscribed' : `Upgrade to ${card.buttonLabel}`;
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);

  // const subscriptionHandler = () => {
  //   let payload = [
  //     {
  //       __metadata: { type: 'UserProducts' },
  //       Id: 0,
  //       UserId: "@UserId",
  //       ProductID: card?.apiData?.id,
  //       ProductCreatedOn: "2023-01-02",//
  //       ProductExpiresOn: "2023-01-02",
  //       TradeStatus: 1,
  //       TotalBalance: 2000,//minimumBalance
  //       RiskProfileLow: 10.5,//minLowRisk
  //       RiskProfileLowBalance: 1000,
  //       RiskProfileMedium: 1.2,
  //       RiskProfileMediumBalance: 500,
  //       RiskProfileHigh: null,
  //       RiskProfileHighBalance: null,
  //       Status: true,
  //       CreatedByUserID: "@UserId",
  //       ModifiedByUserID: "@UserId",
  //       ModifiedByAppId: 2,
  //       ModifiedByAppName: "Chrome",
  //       ModifiedByAppVersionNumber: "97.0.4692.99",
  //       ModifiedByBrowserName: "Chrome",
  //       ModifiedByBrowserVersion: "97.0.4692.99",
  //       ModifiedByPlatformId: 5,
  //       ModifiedByCity: "Virum",
  //       ModifiedByRegion: "CapitalRegion",
  //       ModifiedByCountryId: 61,
  //       ModifiedByLanguageId: 1
  //     },
  //   ];
  //   const myJSON = JSON.stringify(payload);
  //   const secondJSON = JSON.stringify(myJSON);

  //   axios({
  //     url: siteUrl,
  //     headers: {
  //       'content-type': 'application/json; charset=utf-8',
  //       Authorization: 'Bearer ' + accessToken,
  //     },
  //     method: 'post',
  //     data: secondJSON,
  //   })
  //     .then((res) => {
  //       devConsoleLog("res", res)
  //     })
  //     .catch((error) => {
  //       devConsoleLog(error);
  //     });
  // }

  const cardPropsSelector = (theme) => {
    switch (card.heading) {
      case 'Supreme': {
        return 'Gold';
      }
      case 'Elite': {
        return 'Platinum';
      }
      default: {
        devConsoleLog('Invalid choice');
        break;
      }
    }
  };

  try {
    return (
      <Grid
        item
        xs={12}
        sm={fullWidth ? 6 : gridsNo ? gridsNo : 4}
        md={fullWidth ? 4 : active ? 4 : gridsNo ? gridsNo : 4}
        lg={fullWidth ? 4 : active ? 3.8 : gridsNo ? gridsNo : 2.53}
        px={supreme && 4}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        <CardWrapper {...props} onClick={() => setActive(index)}>
          <CardHeaderWrapper>
            <Image src={active ? activeIcon : inActiveIcon} alt="img here" />
            <Heading>{card.heading}</Heading>
          </CardHeaderWrapper>
          <SubHeading display={active || silverCard || supreme || goldLive ? '' : 'none'}>
            Expected Annual ROI​ {card.apiData.annualExpectedRoi}%{/* {card.apiData.annualExpectedRoi}% expected annual ROI. */}
          </SubHeading>
          <Grid container mb={2}>
            {card.infoPoints.map((des, idx) => (
              <Grid
                item
                key={idx}
                // xs={silverCard ? 6 : active || goldLive ? 6 : supreme ? 4 : 12}
                xs={12}
                lg={silverCard ? 6 : active || goldLive ? 6 : supreme ? 4 : 12}
                display="flex"
                mb={silverCard ? '8.5px' : active ? '12px' : '7px'}
                mt={silverCard ? '3px' : active ? '7px' : ''}
              >
                <CheckCircleOutlineIcon fontsize="10px" style={{ color: theme.palette.primary.onBoardTextColor }} />
                <CardInfo>{des}</CardInfo>
              </Grid>
            ))}
          </Grid>

          {(silverCard || supreme || productTypeId === 2) && (
            <NewInvestmentButton
              fontsize="12px"
              // btnbackground={cardColor}
              btnbackground1={cardColor}
              // btnbackground1={'white'}
              width="100%"
              height="38px"
              borderColor={cardColor && cardColor}
              btncolor={theme.palette.primary.onBoardTextColor}
              onClick={() => navigate('/upgradeprofile')}
              disabled={subscribeButtonLabel === 'Subscribed' ? true : false}
              style={{
                background: (subscribeButtonLabel === 'Subscribed' && selectedButtonbg) || (hovered ? hoverButtonbg : null),
                '&.Mui-disabled': {
                  color: '#ffffff'
                },
                '&:hover': {
                  backgroundColor: selectedButtonbg
                }
              }}
            >
              Upgrade to {cardPropsSelector()}
            </NewInvestmentButton>
          )}
        </CardWrapper>
      </Grid>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default InvestmentFooterCard;
