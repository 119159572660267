import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  pendingState: [],
  activated: [],
  total: 0
};
const pendingPaymentSlice = createSlice({
  name: 'pendingPayment',
  initialState,
  reducers: {
    updatependings: (state, action) => {
      state.pendingState = action.payload;
    },
    setPendings: (state, action) => {
      state.pendingState = action.payload;
    },
    setActivated: (state, action) => {
      state.activated = action.payload;
    }
    // setTotal: (state, action) => {
    //   state.total = action.payload;
    // }
  }
});
export default pendingPaymentSlice.reducer;
export const { updatependings, setActivated, setPendings } = pendingPaymentSlice.actions;
