import React from 'react';
import FreedomPanel from '../../Components/SharedComponent/FreedomPanel/FreedomPanel';
import { Grid } from '@mui/material';
import FreedomVoucherCard from '../../Components/SharedComponent/FreedomVoucherCard/FreedomVoucherCard';

const VoucherFirstStep = ({ voucherArr, ChangeVoucher, selectedVoucher }) => {
  return (
    <Grid item xs={12} sm={12} lg={12} marginTop="0px">
      <FreedomPanel padding="10px" style={{ minHeight: '60vh' }}>
        <Grid container spacing={2} mt={-1}>
          {voucherArr?.map((item, index) => {
            if (item?.id === 11) return;
            return (
              <Grid key={item?.id} container item xs={12} sm={6} md={6} lg={4} xl={4} xll={3}>
                <FreedomVoucherCard id={item?.id} ischecked={item?.id == selectedVoucher} title={item?.voucherName} description={item?.description} price={item?.VoucherPrice} expirydate={item?.expiryDate} clickOnCard={() => ChangeVoucher(item)} />
              </Grid>
            );
          })}
        </Grid>
      </FreedomPanel>
    </Grid>
  );
};

export default VoucherFirstStep;
