export const notificationData = [
  {
    id: 1,
    message: 'The quick brown fox jumps over the lazy dog.The quick brown fox jumps over the lazy dog.',
    date: '02/06/2022'
  },
  {
    id: 2,
    message: 'The quick brown fox jumps over the lazy dog.The quick brown fox jumps over the lazy dog.',
    date: '02/06/2022'
  },
  {
    id: 3,
    message: 'The quick brown fox jumps over the lazy dog.The quick brown fox jumps over the lazy dog.',
    date: '02/06/2022'
  },
  {
    id: 4,
    message: 'The quick brown fox jumps over the lazy dog.The quick brown fox jumps over the lazy dog.',
    date: '02/06/2022'
  },
  {
    id: 5,
    message: 'The quick brown fox jumps over the lazy dog.The quick brown fox jumps over the lazy dog.',
    date: '02/06/2022'
  },
  {
    id: 6,
    message: 'The quick brown fox jumps over the lazy dog.The quick brown fox jumps over the lazy dog.',
    date: '02/06/2022'
  },
  {
    id: 7,
    message: 'The quick brown fox jumps over the lazy dog.The quick brown fox jumps over the lazy dog.',
    date: '02/06/2022'
  }
];
