import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedBottomBarItem: 'affiliate',
  bottomBarItemType: ''
};

export const bottomBarSlice = createSlice({
  name: 'bottomBar',
  initialState,
  reducers: {
    updateSelectedBottomBarItem: (state, action) => {
      state.selectedBottomBarItem = action.payload;
    },
    updateBottomBarItemType: (state, action) => {
      state.bottomBarItemType = action.payload;
    }
  }
});

export const { updateSelectedBottomBarItem, updateBottomBarItemType } = bottomBarSlice.actions;

export default bottomBarSlice.reducer;
