import React, { useState } from 'react';
import { Box, Card, Dialog, Grid, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FreedomButton1 from '../FreedomButton1/FreedomButton1';
import FreedomTypographyText from '../FreedomTypographyText/FreedomTypographyText';
import FreedomFollowUpIcon from '../FreedomFollowUpIcon/FreedomFollowUpIcon';
import { tableIcon } from '../../../url/SvgLinks';
const dangeralert = tableIcon.dangeralert;
const useDialogStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    //flexGrow: 1,
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '20px'
      // minWidth: '300px',
      // [theme.breakpoints.down('sm')]:{
      // 	minWidth: '300px'
      // }
      // maxWidth: '50%',
    },
    '& .MuiPaper-root-MuiDialog-paper': {
      // maxWidth:'50%'
    }
  },
  root1: {
    //flexGrow: 1,
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '10px',
      // overflowY: 'auto',
      // boxShadow: `0px 16px 40px ${theme.palette.border.border}`,
      border: `2px solid ${theme.palette.border.containerborder}`,
      // maxWidth: '481px',
      maxWidth: '70%',
      width: '100%'
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  dialogBox: {
    // border: `2px solid ${theme.palette.border.borderColor}`,
    // boxShadow: `0px 16px 40px rgba(230, 182, 74, 0.4)`,
    padding: '20px 0',
    // overflowY: 'auto',
    // background: 'linear-gradient(180deg, #FFF0C9 0%, #FFFBF1 100%)',
    background: theme.palette.primary.default,
    borderRadius: '10px',
    // maxWidth: '80%',
    width: '100%'
  }
}));

const ImportantNotes = ({ openDialog, setOpenDialog, heading, children }) => {
  const theme = useTheme();
  const dialogStyle = useDialogStyle();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // const handleClose = ()=> {
  //     setOpenDialog(false)
  // }
  const InsufficientBalance = (
    <Box>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={dialogStyle.root1}
        BackdropProps={{
          classes: {
            root1: dialogStyle.backDrop
          }
        }}
        sx={{
          backdropFilter: 'blur(5px)',
          overflow: 'none'
        }}
      >
        <Card className={dialogStyle.dialogBox}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid container item justifyContent="center" alignItems="center">
              <Grid item>
                <FreedomFollowUpIcon
                  backgroundcolor={'transparent'}
                  backgroundhovercolor={'none'}
                  bordercolor={'none'}
                  // padding='6px'
                  height="32px"
                  width="32px"
                  iconimg={dangeralert}
                />
              </Grid>
              <Grid item>
                <FreedomTypographyText
                  // whiteSpace={'wrap'}
                  // textalign={'center'}
                  fontsize={isSmallScreen ? '16px' : '24px'}
                  lineheight={isSmallScreen ? '24px' : '36px'}
                  fontweight={700}
                  color={theme.palette.primary.elite}
                >
                  {heading ? heading : 'Insufficient Balance'}{' '}
                </FreedomTypographyText>
              </Grid>
            </Grid>
            <Grid item>
              {children ? (
                children
              ) : (
                <FreedomTypographyText
                  style={{ margin: isSmallScreen ? '18px 0px 24px 0px' : '10px 43px 16px 0px' }}
                  // whiteSpace={'wrap'}
                  textalign={'left'}
                  fontsize={isSmallScreen ? '12px' : '12px'}
                  lineheight={isSmallScreen ? '18px' : '18px'}
                  fontweight={400}
                  textcolor={theme.palette.text.primary}
                >
                  {' '}
                  'Your balance is insufficient, please recharge your internal balance through wallet'{' '}
                </FreedomTypographyText>
              )}
            </Grid>
            <Grid item>
              <FreedomButton1
                height={'48px'}
                width={isSmallScreen ? '220px' : '272px'}
                margin="auto"
                btnbackground={theme.palette.button.buttonbgcolor}
                btncolor={theme.palette.primary.white}
                btnborder={theme.palette.border.border}
                borderradius="10px"
                onClick={() => {
                  //   navigate(SettingUrls.borkers)
                  setOpenDialog(false);
                }}
              >
                OK
              </FreedomButton1>
            </Grid>
          </Grid>
        </Card>
      </Dialog>
    </Box>
  );

  return <div>{InsufficientBalance}</div>;
};

export default ImportantNotes;
