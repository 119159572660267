import * as React from 'react';
import { styled } from '@mui/system';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FormControlLabel } from '@mui/material';
import { t } from 'i18next';
import { makeStyles } from '@mui/styles';
import { appInsights } from '../../../auth/appInsight';

const FreeddomSwitch = styled(Switch)(({ theme, width, height, circlewidth, circleheight }) => ({
  width: width || 44,
  height: height || 24,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: circleheight ? 1 : 2,
    '&.Mui-checked': {
      transform: 'translateX(21px)',
      color: theme.palette.primary.lightwhite,
      '& + .MuiSwitch-track': {
        opacity: 1,
        border: `1px solid ${theme.palette.icon.iconColor}`,
        backgroundColor: theme.palette.primary.main
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    transform: 'translateY(1px)',
    width: circlewidth || 18,
    height: circleheight || 18,
    borderRadius: 30,
    transition: theme.transitions.create(['width'], {
      duration: 1000
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 60 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : theme.palette.icon.switchTrack,
    boxSizing: 'border-box'
  }
}));

const useStyle = makeStyles((theme) => ({
  root: {
    margin: '0px!important'
    // '&.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label, &.css-1p0e78v': {
    // 	marginLeft: '16px',
    // 	fontFamily: 'Poppins',
    // 	fontStyle: 'normal',
    // 	fontWeight: 400,
    // 	fontSize: '16px',
    // 	lineHeight: '34px'
    // }
  }
}));

const FreedomSwitch = (props) => {
  const { switchbackgroundcolor = '', label, value = false } = props;

  const classes = useStyle();
  try {
    return (
      <FormControlLabel
        // className="mt-8 mb-16"
        className={classes.root}
        label={t(label)}
        checked={value}
        control={<FreeddomSwitch {...props} className="mr-3" inputProps={{ 'aria-label': 'ant design' }} switchbackgroundcolor={switchbackgroundcolor} />}
      />
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomSwitch;
