import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';

const useStyle = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    // eslint-disable-next-line
    ['& .css-cveggr-MuiListItemIcon-root']: {
      color: '#fff'
    }
  },

  // removeBorder: {
  //   paddingLeft: 0,
  //   marginLeft: 0,
  // },
  selectStyle: {
    borderRadius: '20px'
  },
  cardPadding: {
    padding: '4px'
  },
  menuBar: {
    boxShadow: 'none'
  },

  //Side Bar Colors
  bgCardWrap: {
    // backgroundColor: '#333333 !important',
    // color: '#fff',
    border: 'none !important'
  },
  bgWrapLight: {
    color: '#fff',
    // eslint-disable-next-line
    ['& .MuiPaper-root']: {
      backgroundColor: '#1C1C1C',
      color: '#fff'
    }
  },
  bgWrapDark: {
    color: '#fff',
    // eslint-disable-next-line
    ['& .MuiPaper-root']: {
      backgroundColor: '#1C1C1C',
      color: '#fff'
    }
  },
  bgLight: {
    backgroundColor: '#1C1C1C',
    color: '#fff',
    '&:hover': {
      background: 'linear-gradient(to bottom,  #f7e7ac 0%,#f2ad3c 2%,#f7e7ac 100%)'
    }
  },
  bgDark: {
    backgroundColor: '#1C1C1C',
    color: '#fff',
    '&:hover': {
      background: 'linear-gradient(to bottom,  #f7e7ac 0%,#f2ad3c 2%,#f7e7ac 100%)'
    }
  },
  bgonly: {
    background: 'transparent'
  },
  white: {
    color: '#fff!important'
  },
  //Top Bar
  AppBarLight: {
    backgroundColor: 'transparent'
    // backgroundColor: '#FAFAFA'
  },
  AppBarLightSolid: {
    // backgroundColor: 'transparent'
    backgroundColor: '#FAFAFA'
  },
  AppBarDark: {
    backgroundColor: 'transparent'
    // backgroundColor: '#333333'
  },

  //Border Boxes
  boxWrapLight: {
    borderRadius: '10px !important',
    padding: '20px',
    marginBottom: '30px',
    background: '#fff'
  },
  boxWrapDark: {
    border: '1px solid #E3A33D',
    borderRadius: '10px !important',
    padding: '20px',
    marginBottom: '30px',
    background: theme.palette.background.box
    // background: "#333333 !important",
  },
  devider: {
    paddingTop: '20px',
    marginBottom: '20px !important'
  },

  //App Js Css
  AppLight: {
    // background: 'transparent',
    // backgroundImage: 'radial-gradient(circle, rgba(234,158,88,1) 0%, rgba(234,158,88,0) 84%)',
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    // height: '100%',
    // background: '#ECECEC',
    // marginLeft: '300px',
    width: '100%',
    maxWidth: '80vw',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw'
    }
  },
  AppDark: {
    // background: '#333131',
    width: '100%',
    maxWidth: '80vw',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw'
    }
  },
  //Overall Wrapper Root
  WrapperLight: {
    background: 'transparent',
    // opacity: 0.7,
    // filter: 'blur(498.333px)',
    // transform: 'rotate(0.14deg)',
    // padding: '80px 15px 0px 15px',
    // marginTop: '80px',
    marginBottom: '60px',
    height: '100%',

    // marginBottom: '50px',
    // padding: '55px 0px 0px 0px',
    [theme.breakpoints.down('md')]: {
      // padding: '80px 0px 0px 0px',
      marginBottom: '60px'
      // marginTop: '60px'
    },
    [theme.breakpoints.down('sm')]: {
      // padding: '80px 0px 0px 0px',
      marginBottom: '20px'
    }
    // background: '#ECECEC'
  },
  WrapperDark: {
    // padding: '80px 15px 0px 15px',
    // marginTop: '80px',
    marginBottom: '60px',
    height: '100%',
    // marginTop: '80px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '60px'
      // marginTop: '60px'
      // padding: '80px 0px 0px 0px!important',
      // margin: 0
    },
    [theme.breakpoints.down('sm')]: {
      // padding: '80px 0px 0px 0px',
      marginBottom: '20px'
    }
    // background: '#333131'
  },
  // Freedom Activity Card
  FreedomActivity: {
    position: 'relative',
    background: 'transparent !important'
  },
  FreedomActivityHeader: {
    position: 'absolute',
    right: '10px',
    top: '0'
  },
  danger: {
    color: '#FF2E2E'
  },
  success: {
    color: '#25A140'
  },
  //Button Component
  ButtonLight: {
    padding: '16px 83px !important',
    backgroundColor: '#E3A33D',
    color: `${theme.palette.text.primary} !important`,
    fontWeight: '500'
  },
  ButtonDark: {
    padding: '10px 30px !important',
    backgroundColor: '#E3A33D',
    color: '#fff !important',
    fontWeight: '500'
  },
  Dollersign: {
    fontSize: '24px'
  },
  Pricefirst: {
    fontSize: '48px',
    fontWeight: '100'
  },
  PriceSecond: {
    fontSize: '24px'
  },
  right: {
    marginLeft: 'auto !important'
  },
  left: {
    float: 'right'
  },
  textRight: {
    textAlign: 'right'
  },
  progressFont: {
    fontSize: '14px !important'
  },
  headingFont: {
    fontSize: '20px !important',
    fontWeight: '500 !important'
  },
  labelhandle: {
    position: 'relative',

    // paddingTop: '10px',
    // eslint-disable-next-line
    ['& .MuiStepLabel-label']: {
      display: 'none !important'
    },

    // eslint-disable-next-line
    ['& .MuiStepLabel-label.Mui-active']: {
      display: 'block !important',
      position: 'absolute',
      width: '180px',
      top: '-10px',
      left: '-50px',
      fontSize: '14px',
      [theme.breakpoints.down('sm')]: {
        width: '75px',
        top: '-8px',
        left: '-15px',
        fontSize: '9px'
      },
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  stapingCardBalance: {
    paddingTop: '20px',
    border: 'none',
    // display: 'none',
    ['& .MuiStepConnector-root.Mui-active .MuiStepConnector-line']: {
      borderColor: 'red'
    },
    ['& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line']: {
      borderColor: 'green'
    },
    // eslint-disable-next-line
    ['& .css-z7uhs0-MuiStepConnector-line']: {
      borderColor: 'red !important',
      minHeight: 'auto'
    }
  },
  removeBorder: {
    border: 'none !important',
    paddingLeft: '0px!important',
    '&.css-14yr603-MuiStepContent-root': {
      padding: '0px!important',
      margin: '0px!important'
    }
  },
  sperater: {
    paddingTop: '15px !important',
    marginBottom: '15px !important'
  },
  imgFluid: {
    maxWidth: '100%',
    minHeight: 0
  },
  stepWrap: {
    position: 'relative',
    '&.css-1bw0nnu-MuiStep-root:last-child': {
      padding: '0px!important'
    },
    '&.css-1bw0nnu-MuiStep-root': {
      padding: '0px!important'
    }
  },
  stepNumber: {
    position: 'absolute',
    top: '100%',
    left: '100%',
    [theme.breakpoints.down('sm')]: {
      top: '13px',
      left: '18px'
    },
    zIndex: '2'
  },
  typoPadding: {
    margin: '20px 0'
  },
  thumbnailwrap: {
    textAlign: 'center',
    padding: '15px 10px'
  },
  thumbColor: {
    color: '#888888',
    paddingTop: '3px',
    display: 'block'
  },
  CardBgTransparent: {
    background: 'transparent !important'
  },
  checkmiddle: {
    paddingTop: '10px',
    justifyContent: 'right'
  },
  lablemiddle: {
    paddingTop: '20px'
  },
  primarytypo: {
    marginTop: '10px'
  },
  pagination: {
    paddingTop: '15px'
  },
  SettingTypo: {
    padding: '10px'
  },
  expandIcon: {
    color: '#fff'
  },
  primaryColor: {
    color: '#E3A33D',
    paddingTop: '3px',
    display: 'block'
  },
  accordion: {
    padding: '0 10px'
  },
  text: {
    paddingBottom: '20px',
    marginLeft: '40px'
  },
  textfilled: {
    marginRight: '5px'
  },
  typePadding: {
    paddingBottom: '20px',
    marginLeft: '70px'
  },
  codetext: {
    paddingBottom: '60px'
  },
  accordionitem: {
    padding: '0 !important',
    paddingLeft: '7px !important',
    marginLeft: '-8px',
    background: '#000',
    marginRight: '-11px'
  },
  accrodtionSummery: {
    paddingLeft: '7px !important',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '28px !important'
    }
  },
  accrodtionSummery2: {
    paddingLeft: '30px !important',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '28px !important'
    }
  },
  accordionitemtext: {
    paddingLeft: '0'
  },
  menutextonly: {
    paddingLeft: '7px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0'
    }
  },
  menutext: {
    paddingRight: '20px'
  },
  amountcolor: {
    color: '#2BC155'
  },
  paddingBalance: {
    paddingLeft: '0 !important',
    marginLeft: '0 !important',
    border: 'none !important'
  },
  '& .css-13hkxlg-MuiStepConnector-line': {
    minHeight: 0
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  accountAlert: {
    display: 'flex',
    background: '#333131',
    color: '#eee',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 0'
  },
  error: {
    color: theme.palette.primary.error,
    fontSize: '0.75rem',
    marginLeft: '10px',
    fontWeight: 400
  },

  ///customer styling
  headingTag: {
    letterSpacing: '1px',
    fontWeight: 600,
    fontSize: '2rem',
    color: theme.palette.primary.main
  },

  //connector
  connector: {
    '& .css-vnkopk-MuiStepLabel-iconContainer': {
      paddingRight: '0px!important'
    },
    '& .MuiStepConnector-line': {
      borderTopWidth: '2px'
    },
    '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
      borderColor: theme.palette.primary.primaryYellow,
      borderTopWidth: '2px'
    },
    '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
      borderColor: theme.palette.primary.success,
      borderTopWidth: '2px'
    }
  },
  textContent: {
    [theme.breakpoints.down('sm')]: {
      order: '2'
    }
  },

  arrow: {
    [theme.breakpoints.down('sm')]: {
      order: '1'
    }
  },
  cancelButton: {
    [theme.breakpoints.down('sm')]: {
      order: '2'
    }
  },
  saveButton: {
    [theme.breakpoints.down('sm')]: {
      order: '1'
    }
  },
  rounded: {
    '& .MuiPaginationItem-root': {
      borderWidth: '2px'
    }
  }
}));

export default useStyle;

export const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#DADADA',
  zIndex: 1,
  color: theme.palette.mode === 'dark' ? '#000' : '#fff',
  width: 18,
  height: 18,
  border: '2px solid #B0B0B0',
  '.css-mro3c9': {
    paddingLeft: '0px !important',
    paddingRight: '0px !important'
  },
  [theme.breakpoints.down('sm')]: {
    width: 18,
    height: 18
  },
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',

  // border: `2px solid ${theme.palette.primary.primaryYellow} !important`,
  // border: `2px solid #e2862e !important`,
  // eslint-disable-next-line
  ['.MuiStep-root']: {
    position: 'relative'
  },
  // eslint-disable-next-line
  ['.MuiSvgIcon-root']: {
    display: 'none',
    backgroundImage: 'linear-gradient(45deg,  #d36221 0%,#eda138 100%);',
    borderRadius: '50%',
    margin: '20px'
  },

  ...(ownerState.active && {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    // margin: '5px',
    // eslint-disable-next-line
    ['.MuiStepLabel-iconContainer']: {
      border: '2px solid #333131 !important'
    },

    // eslint-disable-next-line
    ['& .css-vnkopk-MuiStepLabel-iconContainer']: {
      // padding: '10px !important',
      borderRadius: '50% !important'
    },
    // eslint-disable-next-line
    ['.MuiSvgIcon-root']: {
      display: 'block',
      backgroundImage: 'linear-gradient(45deg,  #d36221 0%,#eda138 100%)',
      borderRadius: '50%'
      // margin: '20px'
    },
    border: `2px solid ${theme.palette.primary.primaryYellow}!important`,
    backgroundColor: 'transparent',
    width: '18px',
    height: '18px',
    // [theme.breakpoints.down('sm')]: {
    // 	width: '18px',
    // 	height: '18px'
    // },
    zIndex: '4',
    // eslint-disable-next-line
    ['.MuiSvgIcon-root']: {
      display: 'block',
      backgroundColor: theme.palette.primary.primaryYellow,
      color: theme.palette.primary.primaryYellow,
      // backgroundImage: 'linear-gradient(45deg,  #d36221 0%,#eda138 100%);',
      borderRadius: '50%',
      // margin: '20px',
      width: '8px',
      height: '8px'
      // padding: '12px',
      // [theme.breakpoints.down('sm')]: {
      // 	width: '18px',
      // 	height: '18px',
      // 	padding: '8px'
      // }
    }
  }),
  ...(ownerState.completed && {
    border: '2px solid #e2862e!important',
    backgroundColor: theme.palette.primary.success,

    width: '18px',
    height: '18px',
    zIndex: '4',
    // background: theme.palette.primary.primayYellow,
    // backgroundImage: 'linear-gradient(45deg,  #d36221 0%,#eda138 100%);',
    // eslint-disable-next-line
    ['.MuiSvgIcon-root']: {
      display: 'block',
      background: theme.palette.primary.success,
      // backgroundImage: 'linear-gradient(45deg,  #d36221 0%,#eda138 100%);',
      borderRadius: '50%',
      // margin: '20px',
      // width: '16px',
      // height: '16px',
      border: '2px solid #ffffff',
      filter: 'drop-shadow(0px 4px 8px #ABE4BC)'
      // filter: 'drop-shadow(0px 4px 8px rgba(0, 204, 57, 0.5))',
      // padding: '12px',
      // [theme.breakpoints.down('sm')]: {
      // 	width: '30px',
      // 	height: '30px',
      // 	padding: '8px'
      // }
    }
  })
}));

export const ColorlibStepIcon = (props) => {
  const { active, completed, className } = props;

  const icons = {
    1: <CheckIcon />,
    2: <CheckIcon />,
    3: <CheckIcon />,
    4: <CheckIcon />,
    5: <CheckIcon />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node
};

// export const FreedomWrapperRoot = styled('div')(({ theme, ownerState }) => ({
// 	background: 'linear-gradient(180deg, #F6F2E6 0%, rgba(246, 242, 230, 0) 100%)',
// 	borderRadius: '15px',
// 	padding: '15px 16px',
// 	border: ` 0.76321px solid ${theme.palette.border.borderColor}`,
// 	maxHeight: '592px'
// }));
