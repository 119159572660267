import React from 'react';
import FreedomWrapperRoot from '../../../Components/SharedComponent/FreedomWrapperRoot/FreedomWrapperRoot';
import SuccessTransaction from './components/SuccessTransaction';
import { paymentAccountType } from '../../../FreedomConstants/freedomConstants';
import { Box, Grid } from '@mui/material';
import CreditCardSummary from './components/CreditCardSummary';
import InternalAccountSummary from './components/InternalAccountSummary';
import FreedomPanel from '../../../Components/SharedComponent/FreedomPanel/FreedomPanel';

const UpgradeProfileFourthStep = ({ paymentPayMethod, form, cards, balance }) => {
  if (paymentPayMethod == paymentAccountType.creditCardAccount) {
    return (
      <Grid item sm={12} lg={12}>
        <FreedomPanel padding="10px" style={{ minHeight: '60vh' }}>
          <Box sx={{ maxWidth: '1440px', margin: 'auto' }}>
            <CreditCardSummary paymentPayMethod={paymentPayMethod} form={form} cards={cards} />
          </Box>
        </FreedomPanel>
      </Grid>
    );
  } else if (paymentPayMethod == paymentAccountType.internalAccount) {
    return (
      <Grid item sm={12} lg={12}>
        <FreedomPanel padding="10px" style={{ minHeight: '60vh' }}>
          <Box sx={{ maxWidth: '1440px', margin: 'auto' }}>
            <InternalAccountSummary paymentPayMethod={paymentPayMethod} form={form} balance={balance} />
          </Box>
        </FreedomPanel>
      </Grid>
    );
  } else if (paymentPayMethod == paymentAccountType.bankAccount) {
    return (
      <Grid item sm={12} lg={12}>
        <FreedomPanel padding="10px" style={{ minHeight: '60vh' }}>
          <Box sx={{ maxWidth: '1440px', margin: 'auto' }}>
            <SuccessTransaction paymentPayMethod={paymentPayMethod} />
          </Box>
        </FreedomPanel>
      </Grid>
    );
  }
};

export default UpgradeProfileFourthStep;
