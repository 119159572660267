import { getBaseUrl } from '../../utils/userConfig';

const baseUrl = getBaseUrl();

/**
 *
 * AccounUpGrade data API URL
 */

export const accountUpGradeUrl = () => {
  return `${baseUrl}AccountTypes?&select=Id,Name,UpgradePrice,CurrencyId,Color,Commission,Features,SharePointId,AccountTypeNumber,AccountUpgradeText,RoiMin,RoiMax,RoiValue,RiskMin,RiskMax,RiskValue`;
};
