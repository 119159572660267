import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { appInsights } from '../../../auth/appInsight';

const FreedomScrollBar = ({ vh, height = '100%', width = '100%', children, containerRef }) => {
  try {
    return (
      <PerfectScrollbar
        containerRef={containerRef}
        style={{
          height: vh ? vh : `${height}px`,
          width: `${width}`
          // paddingRight: '10px'
        }}
      >
        {children}
      </PerfectScrollbar>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomScrollBar;
