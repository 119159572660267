import { Box, Card, Dialog, Grid, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import FreedomButton1 from '../../../Components/SharedComponent/FreedomButton1/FreedomButton1';
import FreedomTypographyText from '../../../Components/SharedComponent/FreedomTypographyText/FreedomTypographyText';
import { Para } from '../../../Components/SharedComponent/WelcomeScreenElement/WelcomeScreenElement';

import useStyle from '../../../Components/Style';
import { PaperStone, WalletUrls } from '../../../url/Links';
import { appInsights } from '../../../auth/appInsight';
import { useSelector } from 'react-redux';
import CloseIcon from '../../../Components/SharedComponent/CloseIcon/CloseIcon';
import { svgIcon } from '../../../url/SvgLinks';
import { devConsoleLog } from '../../../utils/utilityFunctions';
const AlertIcon = svgIcon.AlertIcon;
const useDialogStyle = makeStyles((theme) => ({
  root: {
    //flexGrow: 1,
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '10px',
      // boxShadow: "0px 16px 40px rgba(230, 182, 74, 0.4)",
      // overflowY: 'auto',
      // height: '100%',
      // width: "100%",
      maxWidth: '1100px'
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  dialogBox: {
    border: `2px solid ${theme.palette.border.popupBorder}`,
    // boxShadow: "0px 16px 40px rgba(230, 182, 74, 0.4)",
    // overflowY: "auto"
    padding: '15px 60px 10px 60px',
    background: theme.palette.primary.onBoardTextColor,
    borderRadius: '10px!important',
    // minWidth: "479px",
    maxWidth: '846px',
    width: '100%',
    overflowY: 'auto!important',
    // maxHeight: '300px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px'
    }
  },
  term: {
    border: `1px solid ${theme.palette.border.borderColor}`,
    borderRadius: '5px',
    padding: '16px',
    marginBottom: '8px'
  }
}));
const LowInvestmentDialogBox = ({ setOpenDialog, openDialog, dialogamountcolor, productName, amount, brokerInvoice }) => {
  const theme = useTheme();
  //   const classes = useStyle();
  const { t } = useTranslation();
  const mobile360 = useMediaQuery(theme.breakpoints.down('sm'));
  const brokerId = useSelector((state) => state.broker.brokerId);
  const brokerDeatil = useSelector((state) => state.broker.brokerDeatil);
  const papperStone = brokerDeatil && brokerDeatil.broker.includes('pepperstone');
  const dialogStyle = useDialogStyle();

  devConsoleLog('broker details', brokerDeatil);
  const confirmClose = () => {
    setOpenDialog(false);
  };
  const navigate = useNavigate();
  try {
    return (
      <Dialog
        open={openDialog}
        // onClose={confirmClose}

        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={dialogStyle.root}
        BackdropProps={{
          classes: {
            root: dialogStyle.backDrop
          }
        }}
      >
        <Card
          // sx={{ width: "100%", maxWidth: "1100px" }}
          className={dialogStyle.dialogBox}
        >
          <Box
            style={{
              width: '100%',
              maxWidth: '1100px',
              padding: mobile360 ? '0px' : '10px'
            }}
          >
            <CloseIcon
              handleonclick={() => {
                confirmClose();
              }}
            />
            {/* <Grid mb={1} container justifyContent="center">
          <Grid item xs={8} sm={8} md={8} lg={8}> */}
            <FreedomTypographyText
              fontweight={600}
              fontsize={mobile360 ? '16px' : '24px'}
              lineheight={mobile360 ? '15px' : '33.6px'}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {/* {t(`Low Investment`)} */}
              <img
                src={AlertIcon}
                alt="alert-icon"
                style={{
                  width: '22px',
                  height: '22px',
                  marginRight: '15px'
                }}
              />
              Alert
            </FreedomTypographyText>
            {/* </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center">
          <Grid item md={12}> */}
            {brokerInvoice === true ? (
              <div style={{ margin: '10px 0 10px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>You need to pay your outstanding payment(s).</div>
            ) : (
              <Para textalign={'justify'} fontsize={'16px'} lineheight={'33.6px'} fontweight={'500'}>
                Your investment amount is lower than the minimum investment amount of
                <span
                  style={{
                    color: dialogamountcolor,
                    margin: '0 5px 0 8px'
                  }}
                >
                  {productName} ${amount}.
                </span>
                Kindly deposit minimum investment amount in the broker
                <a href={papperStone ? 'https://pepperstone.com/' : 'https://www.icmarkets.com/global/en/'} target="_blank" rel="noreferrer">
                  <span> {brokerDeatil === null ? '' : papperStone ? 'Pepperston' : 'IcMarkets'}</span>{' '}
                </a>
                to continue trading.
              </Para>
            )}
            <Grid container justifyContent={'center'}>
              {/* <Grid
                item
                mt={1}
                pr={{ md: 1 }}
                xs={8}
                md={6}
                lg={6}
                sx={{
                  textAlign: brokerInvoice ? "right" : "",
                  display: brokerInvoice ? "" : "contents",
                }}
              >
                <FreedomButton1
                  // btnbackground={theme.palette.icon.iconColor}
                  btncolor={theme.palette.primary.onBoardTextColor}
                  btnborder={theme.palette.icon.iconColor}
                  variant="contained"
                  borderradius="10px"
                  width="200px"
                  height={
                    // laptop ? '50px' :
                    "56px"
                  }
                  onClick={() => confirmClose()}
                  sx={{ marginBottom: "20px" }}
                >
                  {" "}
                  Cancel
                </FreedomButton1>
              </Grid> */}
              {
                brokerInvoice === true ? (
                  <Grid item mt={1} xs={8} md={6} lg={6} pl={{ md: 1 }}>
                    <FreedomButton1
                      btnbackground={theme.palette.icon.iconColor}
                      btncolor={theme.palette.background.default}
                      borderradius="10px"
                      variant="contained"
                      width="200px"
                      //   disabled={!(form.conditions && form.policies)}
                      onClick={() => navigate(WalletUrls.pendingPayments)}
                    >
                      Pending Payments
                    </FreedomButton1>
                  </Grid>
                ) : null
                //   <Grid item mt={1} xs={8} md={6} lg={6} pl={{md:1}}>
                //   <FreedomButton1
                //   btnbackground={theme.palette.icon.iconColor}
                //   btncolor={theme.palette.background.default}
                //   borderradius="10px"
                //   variant="contained"
                //   width="200px"

                //   //   disabled={!(form.conditions && form.policies)}
                //   onClick={() => navigate(WalletUrls.walletDashboard)}
                // >
                //  {t("Recharge")}
                // </FreedomButton1>
                // </Grid>
              }
            </Grid>

            {/* </Box> */}
            {/* </Grid> */}
          </Box>
        </Card>
      </Dialog>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default LowInvestmentDialogBox;
