import { Box, Card, Dialog, Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import FreedomTypographyText from '../FreedomTypographyText/FreedomTypographyText';
import CloseIcon from '../CloseIcon/CloseIcon';
import InvestmentProductCards from '../../../UpgradedInvestment/components/Card/InvestmentProductCards';
import { ColorModeContext } from '../../../store';
import { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import useFetch from '../../../CustomHooks/useFetch';
import { getInvestmentModule } from '../../../api/apiUrls/investmentApiUrls';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Loader } from '../FreedomLoader/FreedomLoader';
import { setProducts, setUserBalance, setUserMarkets, setUserMyPlanResponse, setUserProduct, setUserTradeStatus } from '../../../features/investment/productsSlice';
import { voucherSvg } from '../../../url/SvgLinks';
import { devConsoleLog } from '../../../utils/utilityFunctions';

const silverproductpopup = voucherSvg.silverproductpopup;
const goldproductpopup = voucherSvg.goldproductpopup;
const platinumproductpopup = voucherSvg.platinumproductpopup;

const useDialogStyle = makeStyles((theme) => ({
  root: {
    //flexGrow: 1,
    '& .MuiPaper-root': {
      // Remove background image
      backgroundImage: 'none',
      borderRadius: '10px'
    },
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '10px !important',
      border: `2px solid ${theme.palette.border.popupBorder}`

      // boxShadow: '0px 16px 40px rgba(230, 182, 74, 0.4)',
      // overflowY: 'auto',
      // height: '100%',
      // width: "100%",
      // maxWidth: "1100px"
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
    borderRadius: '15px !important'
  },
  dialogBox: {
    // border: `1px solid ${theme.palette.background.wrapperwallet}`,
    // border: `2px solid ${theme.palette.border.popupBorder}`,
    // boxShadow: "0px 16px 40px rgba(230, 182, 74, 0.4)",
    // overflowY: "auto"
    padding: '15px 15px 36px 15px',
    background: `${theme.palette.primary.default} !important`,
    borderRadius: '10px !important',
    // minWidth: "479px",
    // minWidth: '846px',
    // minHeight: '406px',
    maxWidth: '100%',
    overflowY: 'auto!important',
    // maxHeight: '300px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px'
    }
  },
  term: {
    border: `1px solid ${theme.palette.border.divider}`,
    borderRadius: '5px',
    padding: '16px',
    marginBottom: '8px'
  }
}));

const EmptyPopup = ({ item, openDialog, setOpenDialog }) => {
  const theme = useTheme();
  const { uniqueId } = useSelector((state) => state.user.user);
  const products = useSelector((state) => state.investmentProducts.products);
  const mobile360 = useMediaQuery(theme.breakpoints.down('sm'));
  const { loading: productLoading, data: productData, fetchData } = useFetch(getInvestmentModule(uniqueId));
  const dispatch = useDispatch();
  const dialogStyle = useDialogStyle();

  devConsoleLog(item);
  const {
    colorMode: { toggleMode, mode }
  } = useContext(ColorModeContext);

  useEffect(() => {
    if (!productLoading && productData?.data?.products.length) {
      dispatch(setProducts(productData?.data?.products));
      dispatch(setUserProduct(productData?.data?.userProduct));
      dispatch(setUserTradeStatus(productData?.data?.userStatus));
      dispatch(setUserBalance(productData?.data?.balance));
      dispatch(setUserMarkets(productData?.data?.markets));
      dispatch(setUserMyPlanResponse(productData));
    }
  }, [productLoading, products]);

  const productPopup = (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={dialogStyle.root}
      BackdropProps={{
        classes: {
          root: dialogStyle.backDrop
        }
      }}
      // fullWidth={widthSelector}
      // maxWidth={widthSelector ? "lg" : "xs"}
    >
      <Card className={dialogStyle.dialogBox}>
        <div
        // style={{
        //   backgroundImage: `url(${
        //     item?.id == 1
        //       ? silverproductpopup
        //       : item?.id == 2 && mode == "light"
        //       ? goldproductpopup
        //       : item?.id == 3
        //       ? platinumproductpopup
        //       : ""
        //   })`,
        // }}
        >
          <CloseIcon
            handleonclick={() => {
              setOpenDialog(false);
            }}
          />
          <FreedomTypographyText
            margin={'0 0 25px 0'}
            // fontweight={600}
            // fontsize={mobile360 ? '16px' : '20px'}
            fontsize={mobile360 ? '16px' : '24px'}
            lineheight={'normal'}
            // lineheight={mobile360 ? '15px' : '30px'}
          >
            {`${item?.VoucherName} Product Detail`}
            {/* {item?.id == 2 ? 'Silver Product Detail' : item?.id == 3 ? 'Gold Product Detail' : item?.id == 4 ? 'Platinum Product Detail' : ''} */}
          </FreedomTypographyText>
          <FreedomTypographyText margin={'0 0 25px 0'} fontweight={400} fontsize={mobile360 ? '12px' : '14px'} lineheight={mobile360 ? '20px' : '30px'} textalign={'justify'}>
            {item?.AccountUpgradeText}
          </FreedomTypographyText>
          {/* <InvestmentProductCards
                cardsData={cardsData}
                products={products}
                productTypeId={item?.id + 1}
                infoCard={true}
                /> */}
        </div>
      </Card>
    </Dialog>
  );

  return (
    <>
      {/* {(productLoading || !products) && (
        <Box
          sx={{
            position: "absolute",
            top: "45%",
            left: "47.5%",
          }}
        >
          <Loader />
        </Box>
      )} */}
      {productPopup}
    </>
  );
};

export default EmptyPopup;
