import { getBaseUrl } from '../../utils/userConfig';

const baseUrl = getBaseUrl();

// export const getUserInvitationData = () => {
//     return `${baseUrl}userinvitiations?$filter=(InvitedUserId eq ${parseInt(databaseId)} and Status eq 2) &$top=1&$orderby=CreatedOn desc &$select=Id,Status,InvitedByUserId &$expand=InvitedByUser($select = Id, Givenname, Surname, UserType, AlternateEmail, PhoneNumber)`
// }

export const getInvitationValidateCodeApiUrl = (invitationCode) => {
  // return `${baseUrl}userinvitations?$filter=(InvitationCode eq @UserId) &$top=1&$orderby=CreatedOn desc &$select=Id,Status,InvitedByUserId &$expand=InvitedByUser($select = Id, Givenname, Surname, UserType, AlternateEmail, PhoneNumber)`
  return `${baseUrl}userinvitations?$filter=InvitationCode eq '${invitationCode}' &$top=1&$orderby=CreatedOn desc &$select=Id,Status,InvitedByUserId,usertype,fullname,InvitedUserId, &$expand=InvitedByUser($select = Id, Givenname, Surname, UserType, AlternateEmail, PhoneNumber)`;
};

// export const getInvitationConfirmationDbUrl = (databaseId) => {
//     return `${baseUrl}userinvitiations?$filter=(InvitedUserId eq ${parseInt(databaseId)} and Status eq 2) &$top=1&$orderby=CreatedOn desc &$select=Id,Status,InvitedByUserId &$expand=InvitedByUser($select = Id, Givenname, Surname, UserType, AlternateEmail, PhoneNumber)`
// }

// export const getInvitationValidateCodeApiUrl = (invitationCode,databaseId) => {
// 	return `${baseUrl}userinvitiations?$filter=InvitationCode eq '${invitationCode}' and (Status eq 1 or Status eq 2) and (InvitedUserId eq null or InvitedUserId eq ${parseInt(databaseId)})&$top=1&$orderby=Id desc&$select=Id,Status`;
// };

export const getInvitationConfirmationDbUrl = (databaseId) => {
  return `${baseUrl}userinvitations?$filter=InvitedUserId eq @UserId&$top=1&$orderby=Id desc&$select=id,givenname,surname,fullname,status,usertype,invitationCode,invitedUserId`;
  // return `${baseUrl}userinvitations?$filter=InvitedUserId eq @UserId&$top=1&$orderby=Id desc&$select=Id,Status,Usertype`;
  // return `${baseUrl}userinvitations?$filter=(Status eq 2 and InvitedUserId eq ${databaseId})&$top=1&$orderby=Id desc&$select=Id,Status`;
  // return `${baseUrl}userinvitiations?$filter=(Status eq 2 and InvitedUserId eq ${parseInt(databaseId)})&$top=1&$orderby=Id desc&$select=Id,Status`;
};

export const getInvitationConfirmationEmailUrl = (principalUserName) => {
  return `${baseUrl}userinvitations?$filter=Email eq '${principalUserName}'&$top=1&$orderby=Id desc&$select=id,givenname,surname,fullname,status,usertype,invitationCode,invitedUserId`;
  // return `${baseUrl}userinvitations?$filter=InvitedUserId eq @UserId&$top=1&$orderby=Id desc&$select=Id,Status,Usertype`;
  // return `${baseUrl}userinvitations?$filter=(Status eq 2 and InvitedUserId eq ${databaseId})&$top=1&$orderby=Id desc&$select=Id,Status`;
  // return `${baseUrl}userinvitiations?$filter=(Status eq 2 and InvitedUserId eq ${parseInt(databaseId)})&$top=1&$orderby=Id desc&$select=Id,Status`;
};

export const getMemberIdDbUrl = (id) => {
  return `${baseUrl}users?$filter=Id eq @UserId&$select=MemberId`;
};

export const getCNICFromDataBaseUrl = (cnic, iso3Code) => {
  return `${baseUrl}personalidentificationnumbervalidate('${cnic}',${iso3Code})`;
};

// userinvitiations?$filter=(Status eq 1 or Status eq 2) and (InvitedUserId eq null or InvitedUserId eq @UserId)&$top=1&$orderby=Id desc&$select=Id,Status

// userinvitiations?$filter=InvitationCode eq '${invitationCode}' and (Status eq 1 or Status eq 2) and (InvitedUserId eq null or InvitedUserId eq @UserId)&$top=1&$orderby=Id desc&$select=Id,Status

// userinvitations?$filter=(Status eq 1 or Status eq 2) and (InvitedUserID eq null or InvitedUserID eq @UserId)&$top=1&$orderby Id desc&$select=InvitationCode

//Income Information List
export const getIncomeInformationUrl = () => {
  return `${baseUrl}getalldrowpdownforIndivisual`;
};
