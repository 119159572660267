import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { freedomConstants, freedomLanguage } from '../FreedomConstants/freedomConstants';
import { appInsights } from '../auth/appInsight';
import useOS from './useOS';
import { devConsoleLog } from '../utils/utilityFunctions';

const useDataJson = () => {
  const { osDetails } = useOS();
  const [jsonPrefix, setJsonPrefix] = useState();
  const { location } = useSelector((state) => state.geoLocation);

  const { browserName, fullVersion, osVersion } = osDetails;
  // const osVersion = useSelector((state) => state.location.osVersion);
  // const browserName = useSelector((state) => state.location.browserName);
  // const fullVersion = useSelector((state) => state.location.fullVersion);
  const platformId = useSelector((state) => state.user.user.platformId);

  const jsonPrefixObj = {
    ModifiedByAppId: freedomConstants.APP_ID,
    ModifiedByAppName: browserName?.length > 50 ? browserName?.slice(0, 49) : browserName,
    ModifiedByAppVersionNumber: fullVersion?.length > 50 ? fullVersion?.slice(0, 49) : fullVersion,
    ModifiedByBrowserName: browserName?.length > 50 ? browserName?.slice(0, 49) : browserName,
    ModifiedByBrowserVersion: fullVersion?.length > 50 ? fullVersion?.slice(0, 49) : fullVersion,
    ModifiedByPlatformVersion: osVersion?.length > 50 ? osVersion?.slice(0, 49) : osVersion,
    ModifiedByPlatformId: platformId,
    ModifiedByLatitude: location.CreatedByLatitude,
    ModifiedByLongitude: location.CreatedByLongitude,
    ModifiedByIp: location.CreatedByIp,
    ModifiedByCity: location.CreatedByCity,
    ModifiedByRegion: location.CreatedByRegion,
    ModifiedByCountryId: location.CreatedByCountryId,
    ModifiedByLanguageId: freedomLanguage.english
  };
  useEffect(() => {
    if (osDetails.browserName) {
      setJsonPrefix(jsonPrefixObj);
      devConsoleLog('jsonPrefixObj', jsonPrefixObj);
    }
  }, [osDetails.browserName]);

  try {
    return { jsonPrefix };
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default useDataJson;
