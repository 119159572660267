import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Title, Wrapper } from '../UpgradeProfile/UpgradeProfileSteps/components/upgradeprofile.styles';
import PaymentCard from '../UpgradeProfile/PaymentCard';
import { FreedomRadioButton1 } from '../../Components/SharedComponent/FreedomRadioButton1/FreedomRadioButton1';
import AccountCard from '../UpgradeProfile/AccountCard';
import FreedomColorDivider from '../../Components/SharedComponent/FreedomColorDivider/FreedomColorDivider';
import { paymentAccountType } from '../../FreedomConstants/freedomConstants';
import { FixedAfterTwoDigit1, currentDate, currentTime24hr } from '../../utils/utilityFunctions';
import FreedomTypographyText from '../../Components/SharedComponent/FreedomTypographyText/FreedomTypographyText';

const SummaryComp = ({ paymentPayMethod, handleChangeRadioButtonForPaymentMethod, balance, selectedVoucher, form, desc, duration = 'Life Time' }) => {
  const theme = useTheme();
  const { transactionFee, fullname: username, userName: email } = useSelector((state) => state.user.user);

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center">
      <Grid container spacing={1} item xs={12} sm={12} md={10} lg={10} xl={10}>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Wrapper height={'100%'}>
            <Wrapper height={'200px'} bordercolor={'none'} padding={'0px'}>
              <PaymentCard paymentPayMethod={paymentPayMethod} username={username} />
            </Wrapper>
            <Wrapper flexdirection={'row'} justifycontent={'space-between'} padding={'10px'} bordercolor={theme.palette.border.border} bgcolor={paymentPayMethod === paymentAccountType.internalAccount ? theme.palette.button.buttonbgcolor : ''}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <FreedomRadioButton1
                  margin={'0px 4px 0px 0px'}
                  checked={paymentPayMethod === paymentAccountType.internalAccount}
                  width={24}
                  height={24}
                  onClick={handleChangeRadioButtonForPaymentMethod}
                  value={paymentAccountType.internalAccount}
                  name="id"
                />
                <FreedomTypographyText fontFamily="Poppins" color={paymentPayMethod === paymentAccountType.internalAccount && theme.palette.primary.white} fontsize={'12px'} fontweight={400}>
                  {' '}
                  Internal Account{' '}
                </FreedomTypographyText>
              </Box>
              <Box>
                <FreedomTypographyText fontsize={'16px'} color={paymentPayMethod === paymentAccountType.internalAccount && theme.palette.primary.white} fontweight={700}>
                  {' '}
                  ${FixedAfterTwoDigit1(balance)}
                </FreedomTypographyText>
              </Box>
            </Wrapper>
            <Wrapper flexdirection={'row'} padding={'10px'} bordercolor={theme.palette.border.border} bgcolor={paymentPayMethod === paymentAccountType.creditCardAccount ? theme.palette.button.buttonbgcolor : ''}>
              <FreedomRadioButton1
                margin={'0px 4px 0px 0px'}
                checked={paymentPayMethod === paymentAccountType.creditCardAccount}
                width={24}
                height={24}
                onClick={handleChangeRadioButtonForPaymentMethod}
                value={paymentAccountType.creditCardAccount}
                name="id"
              />
              <FreedomTypographyText fontsize={'12px'} fontweight={400} color={paymentPayMethod === paymentAccountType.creditCardAccount && theme.palette.primary.white}>
                {' '}
                Card Payment{' '}
              </FreedomTypographyText>
            </Wrapper>
          </Wrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Wrapper height={'100%'}>
            <Wrapper height={'200px'} bordercolor={'none'} padding={'0px'}>
              <AccountCard accountType={selectedVoucher} price={form.voucherPrice} title={form.voucherName} />
            </Wrapper>
            <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                {desc} - {form.voucherName}{' '}
              </FreedomTypographyText>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                {' '}
                ${FixedAfterTwoDigit1(+form.voucherPrice)}{' '}
              </FreedomTypographyText>
            </Wrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
            </Box>
            <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
              <FreedomTypographyText fontsize={'12px'} fontweight={400}>
                {' '}
                Freedom Transaction Fee{' '}
              </FreedomTypographyText>
              <FreedomTypographyText fontsize={'12px'} fontweight={600}>
                {' '}
                ${FixedAfterTwoDigit1(+transactionFee)}
              </FreedomTypographyText>
            </Wrapper>

            <Wrapper flexdirection={'row'} justifycontent={'space-between'} padding={'10px'} bordercolor={theme.palette.border.border} alignItems={'center'}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '77%' }}>
                <FreedomTypographyText fontsize={'12px'} lineheight={'18px'} fontweight={400} textalign={'left'}>
                  {' '}
                  Total Amount Charged{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.placeholder} fontsize={'10px'} lineheight={'14px'} fontweight={400} textalign={'left'}>
                  {' '}
                  Total Amount Charged =({desc} + Fee)
                </FreedomTypographyText>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '23%' }}>
                <FreedomTypographyText fontsize={'14px'} lineheight={'18px'} fontweight={700} color={theme.palette.primary}>
                  {' '}
                  $ {FixedAfterTwoDigit1(+(form.voucherPrice + +transactionFee))}
                </FreedomTypographyText>
              </Box>
            </Wrapper>
          </Wrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Wrapper height={'100%'} padding={'0px'}>
            <Box
              sx={{
                backgroundColor: theme.palette.primary.paymentdetailbg,
                width: '100%',
                display: 'flex',
                padding: '8px',
                borderTopRightRadius: '8px',
                borderTopLeftRadius: '8px'
              }}
            >
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={600} color={theme.palette.primary.white}>
                {' '}
                Payment Details{' '}
              </FreedomTypographyText>
            </Box>
            <Wrapper height={'100%'} bordercolor={'none'}>
              <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Email{' '}
                </FreedomTypographyText>
                <Title color={theme.palette.primary.paymentsummarycolor} fontsize={'12px'} fontweight={600} textalign={'right'}>
                  {email}
                </Title>
              </Wrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontsize={'12px'} fontweight={400}>
                  {' '}
                  Transaction Time{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontsize={'12px'} fontweight={600}>
                  {' '}
                  {currentTime24hr()}{' '}
                </FreedomTypographyText>
              </Wrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontsize={'12px'} fontweight={400}>
                  {' '}
                  Transaction Date{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontsize={'12px'} fontweight={600}>
                  {' '}
                  {currentDate()}
                </FreedomTypographyText>
              </Wrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  {desc} Expiry Date{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontsize={'12px'} fontweight={600}>
                  {' '}
                  {duration}{' '}
                </FreedomTypographyText>
              </Wrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <Wrapper bordercolor={'none'} flexdirection={'row'} justifycontent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Payment Method{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  {paymentPayMethod === paymentAccountType.internalAccount ? 'Internal Account' : paymentPayMethod === paymentAccountType.creditCardAccount ? 'Card Payment' : paymentPayMethod === paymentAccountType.bankAccount ? 'Bank' : ''}{' '}
                </FreedomTypographyText>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryComp;
