import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  brokerId: 0,
  brokerValue: 0,
  brokerDeatil: [],
  newBorkerData: []
};

export const brokerSlice = createSlice({
  name: 'broker',
  initialState,
  reducers: {
    updateBrokerId: (state, action) => {
      state.brokerId = action.payload;
    },
    updateBrokerValue: (state, action) => {
      state.brokerValue = action.payload;
    },
    updateBroker: (state, action) => {
      state.brokerDeatil = action.payload;
    },
    updateNewBrokerData: (state, action) => {
      state.newBorkerData = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateBrokerId, updateBrokerValue, updateBroker, updateNewBrokerData } = brokerSlice.actions;

export default brokerSlice.reducer;
