import { Box, Grid, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
// import internalCard from "../../../Images/payment/internalCard.svg";
// import creditCard from "../../../Images/payment/creditCard.svg";
// import visa from '../../../Images/payment/visa.svg';
// import master from '../../../Images/payment/master.svg';
import FreedomTypographyText from '../FreedomTypographyText/FreedomTypographyText';
import { capitalizeFullName, creditCardTextColor } from '../../../utils/utilityFunctions';
import { typesOfCreditCard } from '../../../FreedomConstants/freedomConstants';
import { paymentsvg } from '../../../url/SvgLinks';

const visa = paymentsvg.visa;
const master = paymentsvg.master;

const FreedomCreditCard = (props) => {
  const theme = useTheme();
  const { username, number, expiry, issuer } = props;
  let textColor = creditCardTextColor(issuer);
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        borderRadius: '10px',
        backgroundSize: 'cover',
        display: 'flex',
        backgroundImage: issuer === 'visa' ? 'linear-gradient(103.49deg, #1A4CCE 1.51%, #08256D 94.41%)' : 'linear-gradient(25deg, #fbfbfb, #e8e9e5);',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Grid container justifyContent={'space-between'} direction={'column'} sx={{ margin: '16px' }}>
        <Grid item container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <FreedomTypographyText textalign="left" fontFamily="Poppins" fontsize={'16px'} fontweight={500} color={textColor.color}>
              {' '}
              Card Payment{' '}
            </FreedomTypographyText>
          </Grid>
          {issuer === typesOfCreditCard.master && (
            <Grid item>
              <img src={master} style={{ width: '40px', height: '30px' }} />
            </Grid>
          )}
          {issuer === typesOfCreditCard.visa && (
            <Grid item>
              <img src={visa} style={{ width: '40px', height: '30px' }} />
            </Grid>
          )}
        </Grid>
        <Grid item>
          <FreedomTypographyText textalign="left" fontFamily="Poppins" fontsize={'16px'} fontweight={500} color={textColor.color}>
            {' '}
            {number}
          </FreedomTypographyText>
        </Grid>
        <Grid item container alignItems="flex-end" direction="column">
          <Grid item>
            <FreedomTypographyText textalign="left" fontFamily="Poppins" fontsize={'16px'} fontweight={500} color={textColor.color}>
              {' '}
              VALID THRU
            </FreedomTypographyText>
          </Grid>
          <Grid item>
            <FreedomTypographyText textalign="left" fontFamily="Poppins" fontsize={'16px'} fontweight={500} color={textColor.color}>
              {expiry}
            </FreedomTypographyText>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FreedomCreditCard;
