//Function for api call
import axios from 'axios';
import { paymentOTPType } from '../../FreedomConstants/freedomConstants';
import { getEditBankInformationApiUrl, getEditCreditCardsApiUrl, getOTPApiURL, getPrimaryPaymentOptionUrl, getPrimaryCardUrl } from '../apiUrls/walletApiUrl';
import { devConsoleLog } from '../../utils/utilityFunctions';

/**
 * Get New Credit Card dataproviders
 * ends
 */
//=====================================================//

/**
 * Edit Credit Card dataproviders
 * start
 */
export const getEditCreditCardsData = async (accessToken, invitedUserId) => {
  return await axios
    .get(getEditCreditCardsApiUrl(invitedUserId), { headers: { Authorization: 'Bearer ' + accessToken } })
    .then((res) => {
      // devConsoleLog('data coming from usercreditcards', res.data);
      return res;
    })
    .catch(function (error) {
      // handle error
      devConsoleLog('Error in usercreditcards', error);
    });
};
/**
 * getPrimaryPaymentOptions
 * start
 */
export const getPrimaryPaymentOptionsData = async (accessToken, type) => {
  if (type === paymentOTPType.CreditCard) {
    return await axios
      .get(getPrimaryPaymentOptionUrl(), { headers: { Authorization: 'Bearer ' + accessToken } })
      .then((res) => {
        // devConsoleLog('data coming from res', res.data);

        return axios
          .get(getPrimaryCardUrl(res.data[0].PrimaryPaymentMethodId), {
            headers: { Authorization: 'Bearer ' + accessToken }
          })
          .then((response) => {
            // devConsoleLog('data coming from usercreditcards', response.data);
            return response;
          });

        // return res
      })
      .catch(function (error) {
        // handle error
        devConsoleLog('Error in usercreditcards', error);
      });
  }
};
/**
 * Edit Credit Card dataproviders
 * ends
 */
//=====================================================//

/**
 * Edit Bank Information dataproviders
 * start
 */

export const getEditBankInformationData = async (accessToken, invitedUserId) => {
  return await axios
    .get(getEditBankInformationApiUrl(invitedUserId), { headers: { Authorization: 'Bearer ' + accessToken } })
    .then((res) => {
      // devConsoleLog('data coming from UserBankInformations ', res.data);
      return res;
    })
    .catch(function (error) {
      // handle error
      devConsoleLog('Error in UserBankInformations', error);
    });
};
/**
 * Edit Bank Information dataproviders
 * end
 */
//=====================================================//
/**
 *
 * OTP Stored Procedures dataprovider
 * start
 */

export const getOTPData = async (OTP, OTPType, systemID, OTPStatus, accessToken) => {
  devConsoleLog('getOTPApiURL(OTP, OTPType, systemID, OTPStatus)', getOTPApiURL(OTP, OTPType, systemID, OTPStatus));
  return await axios
    .get(getOTPApiURL(OTP, OTPType, systemID, OTPStatus), { headers: { Authorization: 'Bearer ' + accessToken } })
    .then((res) => {
      // devConsoleLog('data coming from UserBankInformations ', res.data);
      return res;
    })
    .catch(function (error) {
      // handle error
      devConsoleLog('Error in OTP ', error?.response);
      return error;
    });
};

/**
 *
 *  OTP Stored Procedures dataprovider
 * end
 */
//=====================================================//
