import React from 'react';

import { styled } from '@mui/system';

import { Button } from '@mui/material';
import { appInsights } from '../../../auth/appInsight';

const FreedomButton = styled(Button)(({ backgroundcolor, buttoncolor, btnborderradius, buttonborder, padding, theme, smpadding, fontweight }) => ({
  background: backgroundcolor,
  color: buttoncolor,
  fontFamily: 'Poppins !important',
  fontWeight: fontweight || '600',
  fontSize: '15.8557px',
  lineHeight: '19px',
  padding: padding || '15px 30px',
  textTransform: 'capitalize',
  borderRadius: btnborderradius,
  border: buttonborder,
  '&:hover': {
    background: backgroundcolor
  },
  [theme.breakpoints.down('sm')]: {
    padding: smpadding || ''
  }
}));

const FreedomOutlineButton = (props) => {
  const { starticon, endicon, children } = props;

  try {
    return (
      <FreedomButton {...props} startIcon={starticon} endIcon={endicon}>
        {children}
      </FreedomButton>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomOutlineButton;
