import React from 'react';
import { Grid } from '@mui/material';
const FreedomGrid = (props) => {
  const { children } = props;

  return (
    <Grid container {...props}>
      {children}
    </Grid>
  );
};

export default FreedomGrid;
