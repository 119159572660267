export const countries = [
  {
    ID: 1,
    Name: 'Afghanistan',
    FullName: 'Islamic Republic of Afghanistan',
    ISO2: 'AF',
    ISO3: 'AFG',
    DialPrefix: '93   ',
    ContinentID: 2,
    CurrencyID: 3
  },
  {
    ID: 2,
    Name: 'Åland Islands',
    FullName: 'Åland Islands',
    ISO2: 'AX',
    ISO3: 'ALA',
    ContinentID: 3
  },
  {
    ID: 3,
    Name: 'Albania',
    FullName: 'Republic of Albania',
    ISO2: 'AL',
    ISO3: 'ALB',
    DialPrefix: '355  ',
    ContinentID: 3,
    CurrencyID: 1
  },
  {
    ID: 4,
    Name: 'Algeria',
    FullName: "People's Democratic Republic of Algeria",
    ISO2: 'DZ',
    ISO3: 'DZA',
    DialPrefix: '213  ',
    ContinentID: 1
  },
  {
    ID: 5,
    Name: 'American Samoa',
    FullName: 'American Samoa',
    ISO2: 'AS',
    ISO3: 'ASM',
    DialPrefix: '1684 ',
    ContinentID: 6
  },
  {
    ID: 6,
    Name: 'Andorra',
    FullName: 'Principality of Andorra',
    ISO2: 'AD',
    ISO3: 'AND',
    DialPrefix: '376  ',
    ContinentID: 3
  },
  {
    ID: 7,
    Name: 'Angola',
    FullName: 'Republic of Angola',
    ISO2: 'AO',
    ISO3: 'AGO',
    DialPrefix: '244  ',
    ContinentID: 1
  },
  {
    ID: 8,
    Name: 'Anguilla',
    FullName: 'Anguilla',
    ISO2: 'AI',
    ISO3: 'AIA',
    DialPrefix: '1264 ',
    ContinentID: 4
  },
  {
    ID: 9,
    Name: 'Antarctica',
    FullName: 'Antarctica (the territory South of 60 deg S)',
    ISO2: 'AQ',
    ISO3: 'ATA',
    ContinentID: 7
  },
  {
    ID: 10,
    Name: 'Antigua and Barbuda',
    FullName: 'Antigua and Barbuda',
    ISO2: 'AG',
    ISO3: 'ATG',
    DialPrefix: '1268 ',
    ContinentID: 4
  },
  {
    ID: 11,
    Name: 'Argentina',
    FullName: 'Argentine Republic',
    ISO2: 'AR',
    ISO3: 'ARG',
    DialPrefix: '54   ',
    ContinentID: 5,
    CurrencyID: 4
  },
  {
    ID: 12,
    Name: 'Armenia',
    FullName: 'Republic of Armenia',
    ISO2: 'AM',
    ISO3: 'ARM',
    DialPrefix: '374  ',
    ContinentID: 2
  },
  {
    ID: 13,
    Name: 'Aruba',
    FullName: 'Aruba',
    ISO2: 'AW',
    ISO3: 'ABW',
    DialPrefix: '297  ',
    ContinentID: 4,
    CurrencyID: 5
  },
  {
    ID: 14,
    Name: 'Australia',
    FullName: 'Commonwealth of Australia',
    ISO2: 'AU',
    ISO3: 'AUS',
    DialPrefix: '61   ',
    ContinentID: 6,
    CurrencyID: 6
  },
  {
    ID: 15,
    Name: 'Austria',
    FullName: 'Republic of Austria',
    ISO2: 'AT',
    ISO3: 'AUT',
    DialPrefix: '43   ',
    ContinentID: 3
  },
  {
    ID: 16,
    Name: 'Azerbaijan',
    FullName: 'Republic of Azerbaijan',
    ISO2: 'AZ',
    ISO3: 'AZE',
    DialPrefix: '994  ',
    ContinentID: 2,
    CurrencyID: 7
  },
  {
    ID: 17,
    Name: 'Bahamas',
    FullName: 'Commonwealth of the Bahamas',
    ISO2: 'BS',
    ISO3: 'BHS',
    DialPrefix: '1242 ',
    ContinentID: 4,
    CurrencyID: 8
  },
  {
    ID: 18,
    Name: 'Bahrain',
    FullName: 'Kingdom of Bahrain',
    ISO2: 'BH',
    ISO3: 'BHR',
    DialPrefix: '973  ',
    ContinentID: 2
  },
  {
    ID: 19,
    Name: 'Bangladesh',
    FullName: "People's Republic of Bangladesh",
    ISO2: 'BD',
    ISO3: 'BGD',
    DialPrefix: '880  ',
    ContinentID: 2
  },
  {
    ID: 20,
    Name: 'Barbados',
    FullName: 'Barbados',
    ISO2: 'BB',
    ISO3: 'BRB',
    DialPrefix: '1246 ',
    ContinentID: 4,
    CurrencyID: 9
  },
  {
    ID: 21,
    Name: 'Belarus',
    FullName: 'Republic of Belarus',
    ISO2: 'BY',
    ISO3: 'BLR',
    DialPrefix: '375  ',
    ContinentID: 3,
    CurrencyID: 10
  },
  {
    ID: 22,
    Name: 'Belgium',
    FullName: 'Kingdom of Belgium',
    ISO2: 'BE',
    ISO3: 'BEL',
    DialPrefix: '32   ',
    ContinentID: 3,
    CurrencyID: 11
  },
  {
    ID: 23,
    Name: 'Belize',
    FullName: 'Belize',
    ISO2: 'BZ',
    ISO3: 'BLZ',
    DialPrefix: '501  ',
    ContinentID: 4
  },
  {
    ID: 24,
    Name: 'Benin',
    FullName: 'Republic of Benin',
    ISO2: 'BJ',
    ISO3: 'BEN',
    DialPrefix: '229  ',
    ContinentID: 1
  },
  {
    ID: 25,
    Name: 'Bermuda',
    FullName: 'Bermuda',
    ISO2: 'BM',
    ISO3: 'BMU',
    DialPrefix: '1441 ',
    ContinentID: 4,
    CurrencyID: 13
  },
  {
    ID: 26,
    Name: 'Bhutan',
    FullName: 'Kingdom of Bhutan',
    ISO2: 'BT',
    ISO3: 'BTN',
    DialPrefix: '975  ',
    ContinentID: 2
  },
  {
    ID: 27,
    Name: 'Bolivia',
    FullName: 'Plurinational State of Bolivia',
    ISO2: 'BO',
    ISO3: 'BOL',
    DialPrefix: '591  ',
    ContinentID: 5,
    CurrencyID: 14
  },
  {
    ID: 28,
    Name: 'Bonaire, Sint Eustatius and Saba',
    FullName: 'Bonaire, Sint Eustatius and Saba',
    ISO2: 'BQ',
    ISO3: 'BES',
    ContinentID: 4
  },
  {
    ID: 29,
    Name: 'Bosnia and Herzegovina',
    FullName: 'Bosnia and Herzegovina',
    ISO2: 'BA',
    ISO3: 'BIH',
    DialPrefix: '387  ',
    ContinentID: 3,
    CurrencyID: 15
  },
  {
    ID: 30,
    Name: 'Botswana',
    FullName: 'Republic of Botswana',
    ISO2: 'BW',
    ISO3: 'BWA',
    DialPrefix: '267  ',
    ContinentID: 1,
    CurrencyID: 16
  },
  {
    ID: 31,
    Name: 'Bouvet Island (Bouvetoya)',
    FullName: 'Bouvet Island (Bouvetoya)',
    ISO2: 'BV',
    ISO3: 'BVT',
    ContinentID: 7
  },
  {
    ID: 32,
    Name: 'Brazil',
    FullName: 'Federative Republic of Brazil',
    ISO2: 'BR',
    ISO3: 'BRA',
    DialPrefix: '55   ',
    ContinentID: 5,
    CurrencyID: 18
  },
  {
    ID: 33,
    Name: 'British Indian Ocean Territory (Chagos Archipelago)',
    FullName: 'British Indian Ocean Territory (Chagos Archipelago)',
    ISO2: 'IO',
    ISO3: 'IOT',
    ContinentID: 2
  },
  {
    ID: 34,
    Name: 'British Virgin Islands',
    FullName: 'British Virgin Islands',
    ISO2: 'VG',
    ISO3: 'VGB',
    DialPrefix: '1284 ',
    ContinentID: 4
  },
  {
    ID: 35,
    Name: 'Brunei Darussalam',
    FullName: 'Brunei Darussalam',
    ISO2: 'BN',
    ISO3: 'BRN',
    DialPrefix: '673  ',
    ContinentID: 2,
    CurrencyID: 20
  },
  {
    ID: 36,
    Name: 'Bulgaria',
    FullName: 'Republic of Bulgaria',
    ISO2: 'BG',
    ISO3: 'BGR',
    DialPrefix: '359  ',
    ContinentID: 3,
    CurrencyID: 17
  },
  {
    ID: 37,
    Name: 'Burkina Faso',
    FullName: 'Burkina Faso',
    ISO2: 'BF',
    ISO3: 'BFA',
    DialPrefix: '226  ',
    ContinentID: 1
  },
  {
    ID: 38,
    Name: 'Burundi',
    FullName: 'Republic of Burundi',
    ISO2: 'BI',
    ISO3: 'BDI',
    DialPrefix: '257  ',
    ContinentID: 1
  },
  {
    ID: 39,
    Name: 'Cambodia',
    FullName: 'Kingdom of Cambodia',
    ISO2: 'KH',
    ISO3: 'KHM',
    DialPrefix: '855  ',
    ContinentID: 2,
    CurrencyID: 21
  },
  {
    ID: 40,
    Name: 'Cameroon',
    FullName: 'Republic of Cameroon',
    ISO2: 'CM',
    ISO3: 'CMR',
    DialPrefix: '237  ',
    ContinentID: 1
  },
  {
    ID: 41,
    Name: 'Canada',
    FullName: 'Canada',
    ISO2: 'CA',
    ISO3: 'CAN',
    DialPrefix: '1    ',
    ContinentID: 4,
    CurrencyID: 22
  },
  {
    ID: 42,
    Name: 'Cape Verde',
    FullName: 'Republic of Cape Verde',
    ISO2: 'CV',
    ISO3: 'CPV',
    DialPrefix: '238  ',
    ContinentID: 1
  },
  {
    ID: 43,
    Name: 'Cayman Islands',
    FullName: 'Cayman Islands',
    ISO2: 'KY',
    ISO3: 'CYM',
    DialPrefix: '1345 ',
    ContinentID: 4,
    CurrencyID: 23
  },
  {
    ID: 44,
    Name: 'Central African Republic',
    FullName: 'Central African Republic',
    ISO2: 'CF',
    ISO3: 'CAF',
    DialPrefix: '236  ',
    ContinentID: 1
  },
  {
    ID: 45,
    Name: 'Chad',
    FullName: 'Republic of Chad',
    ISO2: 'TD',
    ISO3: 'TCD',
    DialPrefix: '235  ',
    ContinentID: 1
  },
  {
    ID: 46,
    Name: 'Chile',
    FullName: 'Republic of Chile',
    ISO2: 'CL',
    ISO3: 'CHL',
    DialPrefix: '56   ',
    ContinentID: 5,
    CurrencyID: 24
  },
  {
    ID: 47,
    Name: 'China',
    FullName: "People's Republic of China",
    ISO2: 'CN',
    ISO3: 'CHN',
    DialPrefix: '86   ',
    ContinentID: 2,
    CurrencyID: 25
  },
  {
    ID: 48,
    Name: 'Christmas Island',
    FullName: 'Christmas Island',
    ISO2: 'CX',
    ISO3: 'CXR',
    ContinentID: 2
  },
  {
    ID: 49,
    Name: 'Cocos (Keeling) Islands',
    FullName: 'Cocos (Keeling) Islands',
    ISO2: 'CC',
    ISO3: 'CCK',
    ContinentID: 2
  },
  {
    ID: 50,
    Name: 'Colombia',
    FullName: 'Republic of Colombia',
    ISO2: 'CO',
    ISO3: 'COL',
    DialPrefix: '57   ',
    ContinentID: 5,
    CurrencyID: 26
  },
  {
    ID: 51,
    Name: 'Comoros',
    FullName: 'Union of the Comoros',
    ISO2: 'KM',
    ISO3: 'COM',
    DialPrefix: '269  ',
    ContinentID: 1
  },
  {
    ID: 52,
    Name: 'Congo',
    FullName: 'Democratic Republic of the Congo',
    ISO2: 'CD',
    ISO3: 'COD',
    DialPrefix: '242  ',
    ContinentID: 1
  },
  {
    ID: 53,
    Name: 'Cook Islands',
    FullName: 'Cook Islands',
    ISO2: 'CK',
    ISO3: 'COK',
    DialPrefix: '682  ',
    ContinentID: 6
  },
  {
    ID: 54,
    Name: 'Costa Rica',
    FullName: 'Republic of Costa Rica',
    ISO2: 'CR',
    ISO3: 'CRI',
    DialPrefix: '506  ',
    ContinentID: 4,
    CurrencyID: 27
  },
  {
    ID: 55,
    Name: "Cote d'Ivoire",
    FullName: "Republic of Cote d'Ivoire",
    ISO2: 'CI',
    ISO3: 'CIV',
    DialPrefix: '225  ',
    ContinentID: 1
  },
  {
    ID: 56,
    Name: 'Croatia',
    FullName: 'Republic of Croatia',
    ISO2: 'HR',
    ISO3: 'HRV',
    DialPrefix: '385  ',
    ContinentID: 3,
    CurrencyID: 28
  },
  {
    ID: 57,
    Name: 'Cuba',
    FullName: 'Republic of Cuba',
    ISO2: 'CU',
    ISO3: 'CUB',
    DialPrefix: '53   ',
    ContinentID: 4,
    CurrencyID: 29
  },
  {
    ID: 58,
    Name: 'Curaçao',
    FullName: 'Curaçao',
    ISO2: 'CW',
    ISO3: 'CUW',
    ContinentID: 4
  },
  {
    ID: 59,
    Name: 'Cyprus',
    FullName: 'Republic of Cyprus',
    ISO2: 'CY',
    ISO3: 'CYP',
    DialPrefix: '357  ',
    ContinentID: 2,
    CurrencyID: 30
  },
  {
    ID: 60,
    Name: 'Czech Republic',
    FullName: 'Czech Republic',
    ISO2: 'CZ',
    ISO3: 'CZE',
    DialPrefix: '420  ',
    ContinentID: 3,
    CurrencyID: 31
  },
  {
    ID: 61,
    Name: 'Denmark',
    FullName: 'Kingdom of Denmark',
    ISO2: 'DK',
    ISO3: 'DNK',
    DialPrefix: '45   ',
    ContinentID: 3,
    CurrencyID: 32
  },
  {
    ID: 62,
    Name: 'Djibouti',
    FullName: 'Republic of Djibouti',
    ISO2: 'DJ',
    ISO3: 'DJI',
    DialPrefix: '253  ',
    ContinentID: 1
  },
  {
    ID: 63,
    Name: 'Dominica',
    FullName: 'Commonwealth of Dominica',
    ISO2: 'DM',
    ISO3: 'DMA',
    DialPrefix: '1767 ',
    ContinentID: 4
  },
  {
    ID: 64,
    Name: 'Dominican Republic',
    FullName: 'Dominican Republic',
    ISO2: 'DO',
    ISO3: 'DOM',
    DialPrefix: '1809 ',
    ContinentID: 4,
    CurrencyID: 33
  },
  {
    ID: 65,
    Name: 'Ecuador',
    FullName: 'Republic of Ecuador',
    ISO2: 'EC',
    ISO3: 'ECU',
    DialPrefix: '593  ',
    ContinentID: 5
  },
  {
    ID: 66,
    Name: 'Egypt',
    FullName: 'Arab Republic of Egypt',
    ISO2: 'EG',
    ISO3: 'EGY',
    DialPrefix: '20   ',
    ContinentID: 1,
    CurrencyID: 35
  },
  {
    ID: 67,
    Name: 'El Salvador',
    FullName: 'Republic of El Salvador',
    ISO2: 'SV',
    ISO3: 'SLV',
    DialPrefix: '503  ',
    ContinentID: 4,
    CurrencyID: 36
  },
  {
    ID: 68,
    Name: 'Equatorial Guinea',
    FullName: 'Republic of Equatorial Guinea',
    ISO2: 'GQ',
    ISO3: 'GNQ',
    DialPrefix: '240  ',
    ContinentID: 1
  },
  {
    ID: 69,
    Name: 'Eritrea',
    FullName: 'State of Eritrea',
    ISO2: 'ER',
    ISO3: 'ERI',
    DialPrefix: '291  ',
    ContinentID: 1
  },
  {
    ID: 70,
    Name: 'Estonia',
    FullName: 'Republic of Estonia',
    ISO2: 'EE',
    ISO3: 'EST',
    DialPrefix: '372  ',
    ContinentID: 3
  },
  {
    ID: 71,
    Name: 'Ethiopia',
    FullName: 'Federal Democratic Republic of Ethiopia',
    ISO2: 'ET',
    ISO3: 'ETH',
    DialPrefix: '251  ',
    ContinentID: 1
  },
  {
    ID: 72,
    Name: 'Faroe Islands',
    FullName: 'Faroe Islands',
    ISO2: 'FO',
    ISO3: 'FRO',
    DialPrefix: '298  ',
    ContinentID: 3
  },
  {
    ID: 73,
    Name: 'Falkland Islands (Malvinas)',
    FullName: 'Falkland Islands (Malvinas)',
    ISO2: 'FK',
    ISO3: 'FLK',
    DialPrefix: '500  ',
    ContinentID: 5
  },
  {
    ID: 74,
    Name: 'Fiji',
    FullName: 'Republic of Fiji',
    ISO2: 'FJ',
    ISO3: 'FJI',
    DialPrefix: '679  ',
    ContinentID: 6,
    CurrencyID: 40
  },
  {
    ID: 75,
    Name: 'Finland',
    FullName: 'Republic of Finland',
    ISO2: 'FI',
    ISO3: 'FIN',
    DialPrefix: '358  ',
    ContinentID: 3
  },
  {
    ID: 76,
    Name: 'France',
    FullName: 'French Republic',
    ISO2: 'FR',
    ISO3: 'FRA',
    DialPrefix: '33   ',
    ContinentID: 3,
    CurrencyID: 41
  },
  {
    ID: 77,
    Name: 'French Guiana',
    FullName: 'French Guiana',
    ISO2: 'GF',
    ISO3: 'GUF',
    DialPrefix: '594  ',
    ContinentID: 5
  },
  {
    ID: 78,
    Name: 'French Polynesia',
    FullName: 'French Polynesia',
    ISO2: 'PF',
    ISO3: 'PYF',
    DialPrefix: '689  ',
    ContinentID: 6
  },
  {
    ID: 79,
    Name: 'French Southern Territories',
    FullName: 'French Southern Territories',
    ISO2: 'TF',
    ISO3: 'ATF',
    ContinentID: 7
  },
  {
    ID: 80,
    Name: 'Gabon',
    FullName: 'Gabonese Republic',
    ISO2: 'GA',
    ISO3: 'GAB',
    DialPrefix: '241  ',
    ContinentID: 1
  },
  {
    ID: 81,
    Name: 'Gambia',
    FullName: 'Republic of the Gambia',
    ISO2: 'GM',
    ISO3: 'GMB',
    DialPrefix: '220  ',
    ContinentID: 1
  },
  {
    ID: 82,
    Name: 'Georgia',
    FullName: 'Georgia',
    ISO2: 'GE',
    ISO3: 'GEO',
    DialPrefix: '995  ',
    ContinentID: 2
  },
  {
    ID: 83,
    Name: 'Germany',
    FullName: 'Federal Republic of Germany',
    ISO2: 'DE',
    ISO3: 'DEU',
    DialPrefix: '49   ',
    ContinentID: 3
  },
  {
    ID: 84,
    Name: 'Ghana',
    FullName: 'Republic of Ghana',
    ISO2: 'GH',
    ISO3: 'GHA',
    DialPrefix: '233  ',
    ContinentID: 1,
    CurrencyID: 42
  },
  {
    ID: 85,
    Name: 'Gibraltar',
    FullName: 'Gibraltar',
    ISO2: 'GI',
    ISO3: 'GIB',
    DialPrefix: '350  ',
    ContinentID: 3,
    CurrencyID: 43
  },
  {
    ID: 86,
    Name: 'Greece',
    FullName: 'Hellenic Republic Greece',
    ISO2: 'GR',
    ISO3: 'GRC',
    DialPrefix: '30   ',
    ContinentID: 3,
    CurrencyID: 44
  },
  {
    ID: 87,
    Name: 'Greenland',
    FullName: 'Greenland',
    ISO2: 'GL',
    ISO3: 'GRL',
    DialPrefix: '299  ',
    ContinentID: 4
  },
  {
    ID: 88,
    Name: 'Grenada',
    FullName: 'Grenada',
    ISO2: 'GD',
    ISO3: 'GRD',
    DialPrefix: '1473 ',
    ContinentID: 4
  },
  {
    ID: 89,
    Name: 'Guadeloupe',
    FullName: 'Guadeloupe',
    ISO2: 'GP',
    ISO3: 'GLP',
    DialPrefix: '590  ',
    ContinentID: 4
  },
  {
    ID: 90,
    Name: 'Guam',
    FullName: 'Guam',
    ISO2: 'GU',
    ISO3: 'GUM',
    DialPrefix: '1671 ',
    ContinentID: 6
  },
  {
    ID: 91,
    Name: 'Guatemala',
    FullName: 'Republic of Guatemala',
    ISO2: 'GT',
    ISO3: 'GTM',
    DialPrefix: '502  ',
    ContinentID: 4,
    CurrencyID: 45
  },
  {
    ID: 92,
    Name: 'Guernsey',
    FullName: 'Bailiwick of Guernsey',
    ISO2: 'GG',
    ISO3: 'GGY',
    ContinentID: 3,
    CurrencyID: 46
  },
  {
    ID: 93,
    Name: 'Guinea',
    FullName: 'Republic of Guinea',
    ISO2: 'GN',
    ISO3: 'GIN',
    DialPrefix: '224  ',
    ContinentID: 1
  },
  {
    ID: 94,
    Name: 'Guinea-Bissau',
    FullName: 'Republic of Guinea-Bissau',
    ISO2: 'GW',
    ISO3: 'GNB',
    DialPrefix: '245  ',
    ContinentID: 1
  },
  {
    ID: 95,
    Name: 'Guyana',
    FullName: 'Co-operative Republic of Guyana',
    ISO2: 'GY',
    ISO3: 'GUY',
    DialPrefix: '592  ',
    ContinentID: 5,
    CurrencyID: 47
  },
  {
    ID: 96,
    Name: 'Haiti',
    FullName: 'Republic of Haiti',
    ISO2: 'HT',
    ISO3: 'HTI',
    DialPrefix: '509  ',
    ContinentID: 4
  },
  {
    ID: 97,
    Name: 'Heard Island and McDonald Islands',
    FullName: 'Heard Island and McDonald Islands',
    ISO2: 'HM',
    ISO3: 'HMD',
    ContinentID: 7
  },
  {
    ID: 98,
    Name: 'Holy See (Vatican City State)',
    FullName: 'Holy See (Vatican City State)',
    ISO2: 'VA',
    ISO3: 'VAT',
    DialPrefix: '39   ',
    ContinentID: 3
  },
  {
    ID: 99,
    Name: 'Honduras',
    FullName: 'Republic of Honduras',
    ISO2: 'HN',
    ISO3: 'HND',
    DialPrefix: '504  ',
    ContinentID: 4,
    CurrencyID: 49
  },
  {
    ID: 100,
    Name: 'Hong Kong',
    FullName: 'Hong Kong Special Administrative Region of China',
    ISO2: 'HK',
    ISO3: 'HKG',
    DialPrefix: '852  ',
    ContinentID: 2,
    CurrencyID: 50
  },
  {
    ID: 101,
    Name: 'Hungary',
    FullName: 'Hungary',
    ISO2: 'HU',
    ISO3: 'HUN',
    DialPrefix: '36   ',
    ContinentID: 3,
    CurrencyID: 51
  },
  {
    ID: 102,
    Name: 'Iceland',
    FullName: 'Republic of Iceland',
    ISO2: 'IS',
    ISO3: 'ISL',
    DialPrefix: '354  ',
    ContinentID: 3,
    CurrencyID: 52
  },
  {
    ID: 103,
    Name: 'India',
    FullName: 'Republic of India',
    ISO2: 'IN',
    ISO3: 'IND',
    DialPrefix: '91   ',
    ContinentID: 2,
    CurrencyID: 53
  },
  {
    ID: 104,
    Name: 'Indonesia',
    FullName: 'Republic of Indonesia',
    ISO2: 'ID',
    ISO3: 'IDN',
    DialPrefix: '62   ',
    ContinentID: 2,
    CurrencyID: 54
  },
  {
    ID: 105,
    Name: 'Iran',
    FullName: 'Islamic Republic of Iran',
    ISO2: 'IR',
    ISO3: 'IRN',
    DialPrefix: '98   ',
    ContinentID: 2,
    CurrencyID: 55
  },
  {
    ID: 106,
    Name: 'Iraq',
    FullName: 'Republic of Iraq',
    ISO2: 'IQ',
    ISO3: 'IRQ',
    DialPrefix: '964  ',
    ContinentID: 2
  },
  {
    ID: 107,
    Name: 'Ireland',
    FullName: 'Ireland',
    ISO2: 'IE',
    ISO3: 'IRL',
    DialPrefix: '353  ',
    ContinentID: 3,
    CurrencyID: 56
  },
  {
    ID: 108,
    Name: 'Isle of Man',
    FullName: 'Isle of Man',
    ISO2: 'IM',
    ISO3: 'IMN',
    ContinentID: 3,
    CurrencyID: 57
  },
  {
    ID: 109,
    Name: 'Israel',
    FullName: 'State of Israel',
    ISO2: 'IL',
    ISO3: 'ISR',
    DialPrefix: '972  ',
    ContinentID: 2,
    CurrencyID: 58
  },
  {
    ID: 110,
    Name: 'Italy',
    FullName: 'Italian Republic',
    ISO2: 'IT',
    ISO3: 'ITA',
    DialPrefix: '39   ',
    ContinentID: 3,
    CurrencyID: 59
  },
  {
    ID: 111,
    Name: 'Jamaica',
    FullName: 'Jamaica',
    ISO2: 'JM',
    ISO3: 'JAM',
    DialPrefix: '1876 ',
    ContinentID: 4,
    CurrencyID: 60
  },
  {
    ID: 112,
    Name: 'Japan',
    FullName: 'Japan',
    ISO2: 'JP',
    ISO3: 'JPN',
    DialPrefix: '81   ',
    ContinentID: 2,
    CurrencyID: 61
  },
  {
    ID: 113,
    Name: 'Jersey',
    FullName: 'Bailiwick of Jersey',
    ISO2: 'JE',
    ISO3: 'JEY',
    ContinentID: 3,
    CurrencyID: 62
  },
  {
    ID: 114,
    Name: 'Jordan',
    FullName: 'Hashemite Kingdom of Jordan',
    ISO2: 'JO',
    ISO3: 'JOR',
    DialPrefix: '962  ',
    ContinentID: 2
  },
  {
    ID: 115,
    Name: 'Kazakhstan',
    FullName: 'Republic of Kazakhstan',
    ISO2: 'KZ',
    ISO3: 'KAZ',
    DialPrefix: '7    ',
    ContinentID: 2,
    CurrencyID: 63
  },
  {
    ID: 116,
    Name: 'Kenya',
    FullName: 'Republic of Kenya',
    ISO2: 'KE',
    ISO3: 'KEN',
    DialPrefix: '254  ',
    ContinentID: 1
  },
  {
    ID: 117,
    Name: 'Kiribati',
    FullName: 'Republic of Kiribati',
    ISO2: 'KI',
    ISO3: 'KIR',
    DialPrefix: '686  ',
    ContinentID: 6
  },
  {
    ID: 118,
    Name: 'North Korea',
    FullName: "Democratic People's Republic of North Korea",
    ISO2: 'KP',
    ISO3: 'PRK',
    DialPrefix: '850  ',
    ContinentID: 2
  },
  {
    ID: 119,
    Name: 'Kuwait',
    FullName: 'State of Kuwait',
    ISO2: 'KW',
    ISO3: 'KWT',
    DialPrefix: '965  ',
    ContinentID: 2
  },
  {
    ID: 120,
    Name: 'Kyrgyz Republic',
    FullName: 'Kyrgyz Republic',
    ISO2: 'KG',
    ISO3: 'KGZ',
    DialPrefix: '996  ',
    ContinentID: 2
  },
  {
    ID: 121,
    Name: "Lao People's Democratic Republic",
    FullName: "Lao People's Democratic Republic",
    ISO2: 'LA',
    ISO3: 'LAO',
    DialPrefix: '856  ',
    ContinentID: 2
  },
  {
    ID: 122,
    Name: 'Latvia',
    FullName: 'Republic of Latvia',
    ISO2: 'LV',
    ISO3: 'LVA',
    DialPrefix: '371  ',
    ContinentID: 3,
    CurrencyID: 68
  },
  {
    ID: 123,
    Name: 'Lebanon',
    FullName: 'Lebanese Republic',
    ISO2: 'LB',
    ISO3: 'LBN',
    DialPrefix: '961  ',
    ContinentID: 2,
    CurrencyID: 69
  },
  {
    ID: 124,
    Name: 'Lesotho',
    FullName: 'Kingdom of Lesotho',
    ISO2: 'LS',
    ISO3: 'LSO',
    DialPrefix: '266  ',
    ContinentID: 1
  },
  {
    ID: 125,
    Name: 'Liberia',
    FullName: 'Republic of Liberia',
    ISO2: 'LR',
    ISO3: 'LBR',
    DialPrefix: '231  ',
    ContinentID: 1,
    CurrencyID: 70
  },
  {
    ID: 126,
    Name: 'Libya',
    FullName: 'Libya',
    ISO2: 'LY',
    ISO3: 'LBY',
    DialPrefix: '218  ',
    ContinentID: 1
  },
  {
    ID: 127,
    Name: 'Liechtenstein',
    FullName: 'Principality of Liechtenstein',
    ISO2: 'LI',
    ISO3: 'LIE',
    DialPrefix: '423  ',
    ContinentID: 3,
    CurrencyID: 71
  },
  {
    ID: 128,
    Name: 'Lithuania',
    FullName: 'Republic of Lithuania',
    ISO2: 'LT',
    ISO3: 'LTU',
    DialPrefix: '370  ',
    ContinentID: 3,
    CurrencyID: 72
  },
  {
    ID: 129,
    Name: 'Luxembourg',
    FullName: 'Grand Duchy of Luxembourg',
    ISO2: 'LU',
    ISO3: 'LUX',
    DialPrefix: '352  ',
    ContinentID: 3,
    CurrencyID: 73
  },
  {
    ID: 130,
    Name: 'Macao',
    FullName: 'Macao Special Administrative Region of China',
    ISO2: 'MO',
    ISO3: 'MAC',
    DialPrefix: '853  ',
    ContinentID: 2
  },
  {
    ID: 131,
    Name: 'Macedonia',
    FullName: 'Republic of Macedonia',
    ISO2: 'MK',
    ISO3: 'MKD',
    DialPrefix: '389  ',
    ContinentID: 3,
    CurrencyID: 74
  },
  {
    ID: 132,
    Name: 'Madagascar',
    FullName: 'Republic of Madagascar',
    ISO2: 'MG',
    ISO3: 'MDG',
    DialPrefix: '261  ',
    ContinentID: 1
  },
  {
    ID: 133,
    Name: 'Malawi',
    FullName: 'Republic of Malawi',
    ISO2: 'MW',
    ISO3: 'MWI',
    DialPrefix: '265  ',
    ContinentID: 1
  },
  {
    ID: 134,
    Name: 'Malaysia',
    FullName: 'Malaysia',
    ISO2: 'MY',
    ISO3: 'MYS',
    DialPrefix: '60   ',
    ContinentID: 2,
    CurrencyID: 75
  },
  {
    ID: 135,
    Name: 'Maldives',
    FullName: 'Republic of Maldives',
    ISO2: 'MV',
    ISO3: 'MDV',
    DialPrefix: '960  ',
    ContinentID: 2
  },
  {
    ID: 136,
    Name: 'Mali',
    FullName: 'Republic of Mali',
    ISO2: 'ML',
    ISO3: 'MLI',
    DialPrefix: '223  ',
    ContinentID: 1
  },
  {
    ID: 137,
    Name: 'Malta',
    FullName: 'Republic of Malta',
    ISO2: 'MT',
    ISO3: 'MLT',
    DialPrefix: '356  ',
    ContinentID: 3,
    CurrencyID: 76
  },
  {
    ID: 138,
    Name: 'Marshall Islands',
    FullName: 'Republic of the Marshall Islands',
    ISO2: 'MH',
    ISO3: 'MHL',
    DialPrefix: '692  ',
    ContinentID: 6
  },
  {
    ID: 139,
    Name: 'Martinique',
    FullName: 'Martinique',
    ISO2: 'MQ',
    ISO3: 'MTQ',
    DialPrefix: '596  ',
    ContinentID: 4
  },
  {
    ID: 140,
    Name: 'Mauritania',
    FullName: 'Islamic Republic of Mauritania',
    ISO2: 'MR',
    ISO3: 'MRT',
    DialPrefix: '222  ',
    ContinentID: 1
  },
  {
    ID: 141,
    Name: 'Mauritius',
    FullName: 'Republic of Mauritius',
    ISO2: 'MU',
    ISO3: 'MUS',
    DialPrefix: '230  ',
    ContinentID: 1,
    CurrencyID: 77
  },
  {
    ID: 142,
    Name: 'Mayotte',
    FullName: 'Mayotte',
    ISO2: 'YT',
    ISO3: 'MYT',
    ContinentID: 1
  },
  {
    ID: 143,
    Name: 'Mexico',
    FullName: 'United Mexican States',
    ISO2: 'MX',
    ISO3: 'MEX',
    DialPrefix: '52   ',
    ContinentID: 4,
    CurrencyID: 78
  },
  {
    ID: 144,
    Name: 'Micronesia',
    FullName: 'Federated States of Micronesia',
    ISO2: 'FM',
    ISO3: 'FSM',
    DialPrefix: '691  ',
    ContinentID: 6
  },
  {
    ID: 145,
    Name: 'Moldova',
    FullName: 'Republic of Moldova',
    ISO2: 'MD',
    ISO3: 'MDA',
    DialPrefix: '373  ',
    ContinentID: 3
  },
  {
    ID: 146,
    Name: 'Monaco',
    FullName: 'Principality of Monaco',
    ISO2: 'MC',
    ISO3: 'MCO',
    DialPrefix: '377  ',
    ContinentID: 3
  },
  {
    ID: 147,
    Name: 'Mongolia',
    FullName: 'Mongolia',
    ISO2: 'MN',
    ISO3: 'MNG',
    DialPrefix: '976  ',
    ContinentID: 2,
    CurrencyID: 79
  },
  {
    ID: 148,
    Name: 'Montenegro',
    FullName: 'Montenegro',
    ISO2: 'ME',
    ISO3: 'MNE',
    ContinentID: 3
  },
  {
    ID: 149,
    Name: 'Montserrat',
    FullName: 'Montserrat',
    ISO2: 'MS',
    ISO3: 'MSR',
    DialPrefix: '1664 ',
    ContinentID: 4
  },
  {
    ID: 150,
    Name: 'Morocco',
    FullName: 'Kingdom of Morocco',
    ISO2: 'MA',
    ISO3: 'MAR',
    DialPrefix: '212  ',
    ContinentID: 1
  },
  {
    ID: 151,
    Name: 'Mozambique',
    FullName: 'Republic of Mozambique',
    ISO2: 'MZ',
    ISO3: 'MOZ',
    DialPrefix: '258  ',
    ContinentID: 1,
    CurrencyID: 80
  },
  {
    ID: 152,
    Name: 'Myanmar',
    FullName: 'Republic of the Union of Myanmar',
    ISO2: 'MM',
    ISO3: 'MMR',
    DialPrefix: '95   ',
    ContinentID: 2
  },
  {
    ID: 153,
    Name: 'Namibia',
    FullName: 'Republic of Namibia',
    ISO2: 'NA',
    ISO3: 'NAM',
    DialPrefix: '264  ',
    ContinentID: 1,
    CurrencyID: 81
  },
  {
    ID: 154,
    Name: 'Nauru',
    FullName: 'Republic of Nauru',
    ISO2: 'NR',
    ISO3: 'NRU',
    DialPrefix: '674  ',
    ContinentID: 6
  },
  {
    ID: 155,
    Name: 'Nepal',
    FullName: 'Federal Democratic Republic of Nepal',
    ISO2: 'NP',
    ISO3: 'NPL',
    DialPrefix: '977  ',
    ContinentID: 2,
    CurrencyID: 82
  },
  {
    ID: 156,
    Name: 'Netherlands',
    FullName: 'Kingdom of the Netherlands',
    ISO2: 'NL',
    ISO3: 'NLD',
    DialPrefix: '31   ',
    ContinentID: 3,
    CurrencyID: 84
  },
  {
    ID: 157,
    Name: 'New Caledonia',
    FullName: 'New Caledonia',
    ISO2: 'NC',
    ISO3: 'NCL',
    DialPrefix: '687  ',
    ContinentID: 6
  },
  {
    ID: 158,
    Name: 'New Zealand',
    FullName: 'New Zealand',
    ISO2: 'NZ',
    ISO3: 'NZL',
    DialPrefix: '64   ',
    ContinentID: 6,
    CurrencyID: 85
  },
  {
    ID: 159,
    Name: 'Nicaragua',
    FullName: 'Republic of Nicaragua',
    ISO2: 'NI',
    ISO3: 'NIC',
    DialPrefix: '505  ',
    ContinentID: 4,
    CurrencyID: 86
  },
  {
    ID: 160,
    Name: 'Niger',
    FullName: 'Republic of Niger',
    ISO2: 'NE',
    ISO3: 'NER',
    DialPrefix: '227  ',
    ContinentID: 1
  },
  {
    ID: 161,
    Name: 'Nigeria',
    FullName: 'Federal Republic of Nigeria',
    ISO2: 'NG',
    ISO3: 'NGA',
    DialPrefix: '234  ',
    ContinentID: 1,
    CurrencyID: 87
  },
  {
    ID: 162,
    Name: 'Niue',
    FullName: 'Niue',
    ISO2: 'NU',
    ISO3: 'NIU',
    DialPrefix: '683  ',
    ContinentID: 6
  },
  {
    ID: 163,
    Name: 'Norfolk Island',
    FullName: 'Norfolk Island',
    ISO2: 'NF',
    ISO3: 'NFK',
    DialPrefix: '672  ',
    ContinentID: 6
  },
  {
    ID: 164,
    Name: 'Northern Mariana Islands',
    FullName: 'Commonwealth of the Northern Mariana Islands',
    ISO2: 'MP',
    ISO3: 'MNP',
    DialPrefix: '1670 ',
    ContinentID: 6
  },
  {
    ID: 165,
    Name: 'Norway',
    FullName: 'Kingdom of Norway',
    ISO2: 'NO',
    ISO3: 'NOR',
    DialPrefix: '47   ',
    ContinentID: 3,
    CurrencyID: 89
  },
  {
    ID: 166,
    Name: 'Oman',
    FullName: 'Sultanate of Oman',
    ISO2: 'OM',
    ISO3: 'OMN',
    DialPrefix: '968  ',
    ContinentID: 2,
    CurrencyID: 90
  },
  {
    ID: 167,
    Name: 'Pakistan',
    FullName: 'Islamic Republic of Pakistan',
    ISO2: 'PK',
    ISO3: 'PAK',
    DialPrefix: '92   ',
    ContinentID: 2,
    CurrencyID: 91
  },
  {
    ID: 168,
    Name: 'Palau',
    FullName: 'Republic of Palau',
    ISO2: 'PW',
    ISO3: 'PLW',
    DialPrefix: '680  ',
    ContinentID: 6
  },
  {
    ID: 169,
    Name: 'Palestine',
    FullName: 'State of Palestine',
    ISO2: 'PS',
    ISO3: 'PSE',
    ContinentID: 2
  },
  {
    ID: 170,
    Name: 'Panama',
    FullName: 'Republic of Panama',
    ISO2: 'PA',
    ISO3: 'PAN',
    DialPrefix: '507  ',
    ContinentID: 4,
    CurrencyID: 92
  },
  {
    ID: 171,
    Name: 'Papua New Guinea',
    FullName: 'Independent State of Papua New Guinea',
    ISO2: 'PG',
    ISO3: 'PNG',
    DialPrefix: '675  ',
    ContinentID: 6
  },
  {
    ID: 172,
    Name: 'Paraguay',
    FullName: 'Republic of Paraguay',
    ISO2: 'PY',
    ISO3: 'PRY',
    DialPrefix: '595  ',
    ContinentID: 5,
    CurrencyID: 93
  },
  {
    ID: 173,
    Name: 'Peru',
    FullName: 'Republic of Peru',
    ISO2: 'PE',
    ISO3: 'PER',
    DialPrefix: '51   ',
    ContinentID: 5,
    CurrencyID: 94
  },
  {
    ID: 174,
    Name: 'Philippines',
    FullName: 'Republic of the Philippines',
    ISO2: 'PH',
    ISO3: 'PHL',
    DialPrefix: '63   ',
    ContinentID: 2,
    CurrencyID: 95
  },
  {
    ID: 175,
    Name: 'Pitcairn Islands',
    FullName: 'Pitcairn Islands',
    ISO2: 'PN',
    ISO3: 'PCN',
    DialPrefix: '0    ',
    ContinentID: 6
  },
  {
    ID: 176,
    Name: 'Poland',
    FullName: 'Republic of Poland',
    ISO2: 'PL',
    ISO3: 'POL',
    DialPrefix: '48   ',
    ContinentID: 3,
    CurrencyID: 96
  },
  {
    ID: 177,
    Name: 'Portugal',
    FullName: 'Portuguese Republic',
    ISO2: 'PT',
    ISO3: 'PRT',
    DialPrefix: '351  ',
    ContinentID: 3
  },
  {
    ID: 178,
    Name: 'Puerto Rico',
    FullName: 'Commonwealth of Puerto Rico',
    ISO2: 'PR',
    ISO3: 'PRI',
    DialPrefix: '1787 ',
    ContinentID: 4
  },
  {
    ID: 179,
    Name: 'Qatar',
    FullName: 'State of Qatar',
    ISO2: 'QA',
    ISO3: 'QAT',
    DialPrefix: '974  ',
    ContinentID: 2,
    CurrencyID: 97
  },
  {
    ID: 180,
    Name: 'Réunion',
    FullName: 'Réunion',
    ISO2: 'RE',
    ISO3: 'REU',
    DialPrefix: '262  ',
    ContinentID: 1
  },
  {
    ID: 181,
    Name: 'Romania',
    FullName: 'Romania',
    ISO2: 'RO',
    ISO3: 'ROU',
    ContinentID: 3,
    CurrencyID: 98
  },
  {
    ID: 182,
    Name: 'Russian Federation',
    FullName: 'Russian Federation',
    ISO2: 'RU',
    ISO3: 'RUS',
    DialPrefix: '70   ',
    ContinentID: 3
  },
  {
    ID: 183,
    Name: 'Rwanda',
    FullName: 'Republic of Rwanda',
    ISO2: 'RW',
    ISO3: 'RWA',
    DialPrefix: '250  ',
    ContinentID: 1
  },
  {
    ID: 184,
    Name: 'Saint Barthélemy',
    FullName: 'Saint Barthélemy',
    ISO2: 'BL',
    ISO3: 'BLM',
    ContinentID: 4
  },
  {
    ID: 185,
    Name: 'Saint Helena, Ascension and Tristan da Cunha',
    FullName: 'Saint Helena, Ascension and Tristan da Cunha',
    ISO2: 'SH',
    ISO3: 'SHN',
    DialPrefix: '290  ',
    ContinentID: 1
  },
  {
    ID: 186,
    Name: 'Saint Kitts and Nevis',
    FullName: 'Federation of Saint Kitts and Nevis',
    ISO2: 'KN',
    ISO3: 'KNA',
    DialPrefix: '1869 ',
    ContinentID: 4
  },
  {
    ID: 187,
    Name: 'Saint Lucia',
    FullName: 'Saint Lucia',
    ISO2: 'LC',
    ISO3: 'LCA',
    DialPrefix: '1758 ',
    ContinentID: 4
  },
  {
    ID: 188,
    Name: 'Saint Martin',
    FullName: 'Saint Martin (French part)',
    ISO2: 'MF',
    ISO3: 'MAF',
    ContinentID: 4
  },
  {
    ID: 189,
    Name: 'Saint Pierre and Miquelon',
    FullName: 'Saint Pierre and Miquelon',
    ISO2: 'PM',
    ISO3: 'SPM',
    DialPrefix: '508  ',
    ContinentID: 4
  },
  {
    ID: 190,
    Name: 'Saint Vincent and the Grenadines',
    FullName: 'Saint Vincent and the Grenadines',
    ISO2: 'VC',
    ISO3: 'VCT',
    DialPrefix: '1784 ',
    ContinentID: 4
  },
  {
    ID: 191,
    Name: 'Samoa',
    FullName: 'Independent State of Samoa',
    ISO2: 'WS',
    ISO3: 'WSM',
    DialPrefix: '684  ',
    ContinentID: 6
  },
  {
    ID: 192,
    Name: 'San Marino',
    FullName: 'Republic of San Marino',
    ISO2: 'SM',
    ISO3: 'SMR',
    DialPrefix: '378  ',
    ContinentID: 3
  },
  {
    ID: 193,
    Name: 'Sao Tome and Principe',
    FullName: 'Democratic Republic of Sao Tome and Principe',
    ISO2: 'ST',
    ISO3: 'STP',
    DialPrefix: '239  ',
    ContinentID: 1
  },
  {
    ID: 194,
    Name: 'Saudi Arabia',
    FullName: 'Kingdom of Saudi Arabia',
    ISO2: 'SA',
    ISO3: 'SAU',
    DialPrefix: '966  ',
    ContinentID: 2,
    CurrencyID: 101
  },
  {
    ID: 195,
    Name: 'Senegal',
    FullName: 'Republic of Senegal',
    ISO2: 'SN',
    ISO3: 'SEN',
    DialPrefix: '221  ',
    ContinentID: 1
  },
  {
    ID: 196,
    Name: 'Serbia',
    FullName: 'Republic of Serbia',
    ISO2: 'RS',
    ISO3: 'SRB',
    ContinentID: 3,
    CurrencyID: 102
  },
  {
    ID: 197,
    Name: 'Seychelles',
    FullName: 'Republic of Seychelles',
    ISO2: 'SC',
    ISO3: 'SYC',
    DialPrefix: '248  ',
    ContinentID: 1,
    CurrencyID: 103
  },
  {
    ID: 198,
    Name: 'Sierra Leone',
    FullName: 'Republic of Sierra Leone',
    ISO2: 'SL',
    ISO3: 'SLE',
    DialPrefix: '232  ',
    ContinentID: 1
  },
  {
    ID: 199,
    Name: 'Singapore',
    FullName: 'Republic of Singapore',
    ISO2: 'SG',
    ISO3: 'SGP',
    DialPrefix: '65   ',
    ContinentID: 2,
    CurrencyID: 104
  },
  {
    ID: 200,
    Name: 'Sint Maarten (Dutch part)',
    FullName: 'Sint Maarten (Dutch part)',
    ISO2: 'SX',
    ISO3: 'SXM',
    ContinentID: 4
  },
  {
    ID: 201,
    Name: 'Slovakia (Slovak Republic)',
    FullName: 'Slovakia (Slovak Republic)',
    ISO2: 'SK',
    ISO3: 'SVK',
    DialPrefix: '421  ',
    ContinentID: 3
  },
  {
    ID: 202,
    Name: 'Slovenia',
    FullName: 'Republic of Slovenia',
    ISO2: 'SI',
    ISO3: 'SVN',
    DialPrefix: '386  ',
    ContinentID: 3,
    CurrencyID: 105
  },
  {
    ID: 203,
    Name: 'Solomon Islands',
    FullName: 'Solomon Islands',
    ISO2: 'SB',
    ISO3: 'SLB',
    DialPrefix: '677  ',
    ContinentID: 6,
    CurrencyID: 106
  },
  {
    ID: 204,
    Name: 'Somalia',
    FullName: 'Federal Republic of Somalia',
    ISO2: 'SO',
    ISO3: 'SOM',
    DialPrefix: '252  ',
    ContinentID: 1,
    CurrencyID: 107
  },
  {
    ID: 205,
    Name: 'South Africa',
    FullName: 'Republic of South Africa',
    ISO2: 'ZA',
    ISO3: 'ZAF',
    DialPrefix: '27   ',
    ContinentID: 1,
    CurrencyID: 108
  },
  {
    ID: 206,
    Name: 'South Georgia and the South Sandwich Islands',
    FullName: 'South Georgia and the South Sandwich Islands',
    ISO2: 'GS',
    ISO3: 'SGS',
    ContinentID: 7
  },
  {
    ID: 207,
    Name: 'South Sudan',
    FullName: 'Republic of South Sudan',
    ISO2: 'SS',
    ISO3: 'SSD',
    ContinentID: 1
  },
  {
    ID: 208,
    Name: 'Spain',
    FullName: 'Kingdom of Spain',
    ISO2: 'ES',
    ISO3: 'ESP',
    DialPrefix: '34   ',
    ContinentID: 3,
    CurrencyID: 110
  },
  {
    ID: 209,
    Name: 'Sri Lanka',
    FullName: 'Democratic Socialist Republic of Sri Lanka',
    ISO2: 'LK',
    ISO3: 'LKA',
    DialPrefix: '94   ',
    ContinentID: 2,
    CurrencyID: 111
  },
  {
    ID: 210,
    Name: 'Sudan',
    FullName: 'Republic of Sudan',
    ISO2: 'SD',
    ISO3: 'SDN',
    DialPrefix: '249  ',
    ContinentID: 1
  },
  {
    ID: 211,
    Name: 'Suriname',
    FullName: 'Republic of Suriname',
    ISO2: 'SR',
    ISO3: 'SUR',
    DialPrefix: '597  ',
    ContinentID: 5,
    CurrencyID: 114
  },
  {
    ID: 212,
    Name: 'Svalbard & Jan Mayen Islands',
    FullName: 'Svalbard & Jan Mayen Islands',
    ISO2: 'SJ',
    ISO3: 'SJM',
    DialPrefix: '47   ',
    ContinentID: 3
  },
  {
    ID: 213,
    Name: 'Swaziland',
    FullName: 'Kingdom of Swaziland',
    ISO2: 'SZ',
    ISO3: 'SWZ',
    DialPrefix: '268  ',
    ContinentID: 1
  },
  {
    ID: 214,
    Name: 'Sweden',
    FullName: 'Kingdom of Sweden',
    ISO2: 'SE',
    ISO3: 'SWE',
    DialPrefix: '46   ',
    ContinentID: 3,
    CurrencyID: 112
  },
  {
    ID: 215,
    Name: 'Switzerland',
    FullName: 'Swiss Confederation',
    ISO2: 'CH',
    ISO3: 'CHE',
    DialPrefix: '41   ',
    ContinentID: 3,
    CurrencyID: 113
  },
  {
    ID: 216,
    Name: 'Syrian Arab Republic',
    FullName: 'Syrian Arab Republic',
    ISO2: 'SY',
    ISO3: 'SYR',
    DialPrefix: '963  ',
    ContinentID: 2
  },
  {
    ID: 217,
    Name: 'Taiwan',
    FullName: 'Taiwan, Province of China',
    ISO2: 'TW',
    ISO3: 'TWN',
    DialPrefix: '886  ',
    ContinentID: 2,
    CurrencyID: 116
  },
  {
    ID: 218,
    Name: 'Tajikistan',
    FullName: 'Republic of Tajikistan',
    ISO2: 'TJ',
    ISO3: 'TJK',
    DialPrefix: '992  ',
    ContinentID: 2
  },
  {
    ID: 219,
    Name: 'Tanzania',
    FullName: 'United Republic of Tanzania',
    ISO2: 'TZ',
    ISO3: 'TZA',
    DialPrefix: '255  ',
    ContinentID: 1
  },
  {
    ID: 220,
    Name: 'Thailand',
    FullName: 'Kingdom of Thailand',
    ISO2: 'TH',
    ISO3: 'THA',
    DialPrefix: '66   ',
    ContinentID: 2,
    CurrencyID: 117
  },
  {
    ID: 221,
    Name: 'Timor-Leste',
    FullName: 'Democratic Republic of Timor-Leste',
    ISO2: 'TL',
    ISO3: 'TLS',
    ContinentID: 2
  },
  {
    ID: 222,
    Name: 'Togo',
    FullName: 'Togolese Republic',
    ISO2: 'TG',
    ISO3: 'TGO',
    DialPrefix: '228  ',
    ContinentID: 1
  },
  {
    ID: 223,
    Name: 'Tokelau',
    FullName: 'Tokelau',
    ISO2: 'TK',
    ISO3: 'TKL',
    DialPrefix: '690  ',
    ContinentID: 6
  },
  {
    ID: 224,
    Name: 'Tonga',
    FullName: 'Kingdom of Tonga',
    ISO2: 'TO',
    ISO3: 'TON',
    DialPrefix: '676  ',
    ContinentID: 6
  },
  {
    ID: 225,
    Name: 'Trinidad and Tobago',
    FullName: 'Republic of Trinidad and Tobago',
    ISO2: 'TT',
    ISO3: 'TTO',
    DialPrefix: '1868 ',
    ContinentID: 4,
    CurrencyID: 118
  },
  {
    ID: 226,
    Name: 'Tunisia',
    FullName: 'Tunisian Republic',
    ISO2: 'TN',
    ISO3: 'TUN',
    DialPrefix: '216  ',
    ContinentID: 1
  },
  {
    ID: 227,
    Name: 'Turkey',
    FullName: 'Republic of Turkey',
    ISO2: 'TR',
    ISO3: 'TUR',
    DialPrefix: '90   ',
    ContinentID: 2,
    CurrencyID: 119
  },
  {
    ID: 228,
    Name: 'Turkmenistan',
    FullName: 'Turkmenistan',
    ISO2: 'TM',
    ISO3: 'TKM',
    DialPrefix: '7370 ',
    ContinentID: 2
  },
  {
    ID: 229,
    Name: 'Turks and Caicos Islands',
    FullName: 'Turks and Caicos Islands',
    ISO2: 'TC',
    ISO3: 'TCA',
    DialPrefix: '1649 ',
    ContinentID: 4
  },
  {
    ID: 230,
    Name: 'Tuvalu',
    FullName: 'Tuvalu',
    ISO2: 'TV',
    ISO3: 'TUV',
    DialPrefix: '688  ',
    ContinentID: 6,
    CurrencyID: 121
  },
  {
    ID: 231,
    Name: 'Uganda',
    FullName: 'Republic of Uganda',
    ISO2: 'UG',
    ISO3: 'UGA',
    DialPrefix: '256  ',
    ContinentID: 1
  },
  {
    ID: 232,
    Name: 'Ukraine',
    FullName: 'Ukraine',
    ISO2: 'UA',
    ISO3: 'UKR',
    DialPrefix: '380  ',
    ContinentID: 3,
    CurrencyID: 122
  },
  {
    ID: 233,
    Name: 'United Arab Emirates',
    FullName: 'United Arab Emirates',
    ISO2: 'AE',
    ISO3: 'ARE',
    DialPrefix: '971  ',
    ContinentID: 2
  },
  {
    ID: 234,
    Name: 'United Kingdom of Great Britain & Northern Ireland',
    FullName: 'United Kingdom of Great Britain & Northern Ireland',
    ISO2: 'GB',
    ISO3: 'GBR',
    DialPrefix: '44   ',
    ContinentID: 3,
    CurrencyID: 37
  },
  {
    ID: 235,
    Name: 'United States of America',
    FullName: 'United States of America',
    ISO2: 'US',
    ISO3: 'USA',
    DialPrefix: '1    ',
    ContinentID: 4,
    CurrencyID: 2
  },
  {
    ID: 236,
    Name: 'United States Minor Outlying Islands',
    FullName: 'United States Minor Outlying Islands',
    ISO2: 'UM',
    ISO3: 'UMI',
    ContinentID: 6
  },
  {
    ID: 237,
    Name: 'United States Virgin Islands',
    FullName: 'United States Virgin Islands',
    ISO2: 'VI',
    ISO3: 'VIR',
    DialPrefix: '1340 ',
    ContinentID: 4
  },
  {
    ID: 238,
    Name: 'Uruguay',
    FullName: 'Eastern Republic of Uruguay',
    ISO2: 'UY',
    ISO3: 'URY',
    DialPrefix: '598  ',
    ContinentID: 5,
    CurrencyID: 125
  },
  {
    ID: 239,
    Name: 'Uzbekistan',
    FullName: 'Republic of Uzbekistan',
    ISO2: 'UZ',
    ISO3: 'UZB',
    DialPrefix: '998  ',
    ContinentID: 2,
    CurrencyID: 126
  },
  {
    ID: 240,
    Name: 'Vanuatu',
    FullName: 'Republic of Vanuatu',
    ISO2: 'VU',
    ISO3: 'VUT',
    DialPrefix: '678  ',
    ContinentID: 6
  },
  {
    ID: 241,
    Name: 'Venezuela',
    FullName: 'Bolivarian Republic of Venezuela',
    ISO2: 'VE',
    ISO3: 'VEN',
    DialPrefix: '58   ',
    ContinentID: 5,
    CurrencyID: 128
  },
  {
    ID: 242,
    Name: 'Vietnam',
    FullName: 'Socialist Republic of Vietnam',
    ISO2: 'VN',
    ISO3: 'VNM',
    DialPrefix: '84   ',
    ContinentID: 2,
    CurrencyID: 129
  },
  {
    ID: 243,
    Name: 'Wallis and Futuna',
    FullName: 'Wallis and Futuna',
    ISO2: 'WF',
    ISO3: 'WLF',
    DialPrefix: '681  ',
    ContinentID: 6
  },
  {
    ID: 244,
    Name: 'Western Sahara',
    FullName: 'Western Sahara',
    ISO2: 'EH',
    ISO3: 'ESH',
    DialPrefix: '212  ',
    ContinentID: 1
  },
  {
    ID: 245,
    Name: 'Yemen',
    FullName: 'Yemen',
    ISO2: 'YE',
    ISO3: 'YEM',
    DialPrefix: '967  ',
    ContinentID: 2,
    CurrencyID: 130
  },
  {
    ID: 246,
    Name: 'Zambia',
    FullName: 'Republic of Zambia',
    ISO2: 'ZM',
    ISO3: 'ZMB',
    DialPrefix: '260  ',
    ContinentID: 1
  },
  {
    ID: 247,
    Name: 'Zimbabwe',
    FullName: 'Republic of Zimbabwe',
    ISO2: 'ZW',
    ISO3: 'ZWE',
    DialPrefix: '263  ',
    ContinentID: 1,
    CurrencyID: 131
  },
  {
    ID: 248,
    Name: 'South Korea',
    FullName: "Democratic People's Republic of South Korea",
    ISO2: 'KP',
    ISO3: 'PRK',
    DialPrefix: '850  ',
    ContinentID: 2
  }
];
export const banedCountries = [
  {
    ID: 14,
    Name: 'Australia',
    FullName: 'Commonwealth of Australia',
    ISO2: 'AU',
    ISO3: 'AUS',
    DialPrefix: '61   ',
    ContinentID: 6,
    CurrencyID: 6
  },
  // {
  //     "ID": 103,
  //     "Name": "India",
  //     "FullName": "Republic of India",
  //     "ISO2": "IN",
  //     "ISO3": "IND",
  //     "DialPrefix": "91   ",
  //     "ContinentID": 2,
  //     "CurrencyID": 53
  // },
  {
    ID: 105,
    Name: 'Iran',
    FullName: 'Islamic Republic of Iran',
    ISO2: 'IR',
    ISO3: 'IRN',
    DialPrefix: '98   ',
    ContinentID: 2,
    CurrencyID: 55
  },
  {
    ID: 109,
    Name: 'Israel',
    FullName: 'State of Israel',
    ISO2: 'IL',
    ISO3: 'ISR',
    DialPrefix: '972  ',
    ContinentID: 2,
    CurrencyID: 58
  },
  {
    ID: 118,
    Name: 'North Korea',
    FullName: "Democratic People's Republic of North Korea",
    ISO2: 'KP',
    ISO3: 'PRK',
    DialPrefix: '850  ',
    ContinentID: 2
  },
  {
    ID: 152,
    Name: 'Myanmar',
    FullName: 'Republic of the Union of Myanmar',
    ISO2: 'MM',
    ISO3: 'MMR',
    DialPrefix: '95   ',
    ContinentID: 2
  },
  {
    ID: 158,
    Name: 'New Zealand',
    FullName: 'New Zealand',
    ISO2: 'NZ',
    ISO3: 'NZL',
    DialPrefix: '64   ',
    ContinentID: 6,
    CurrencyID: 85
  },
  // {
  //     "ID": 167,
  //     "Name": "Pakistan",
  //     "FullName": "Islamic Republic of Pakistan",
  //     "ISO2": "PK",
  //     "ISO3": "PAK",
  //     "DialPrefix": "92   ",
  //     "ContinentID": 2,
  //     "CurrencyID": 91
  // },
  {
    ID: 235,
    Name: 'United States of America',
    FullName: 'United States of America',
    ISO2: 'US',
    ISO3: 'USA',
    DialPrefix: '1    ',
    ContinentID: 4,
    CurrencyID: 2
  }
];
export const identityNoOfCountries = [
  {
    ID: 15,
    Name: 'Austria',
    FullName: 'Republic of Austria',
    ISO2: 'AT',
    ISO3: 'AUT',
    DialPrefix: '43   ',
    ContinentID: 3,
    label: 'Austrian Identity Number',
    textFieldType: 'alphaNumeric',
    minLength: 4,
    maxLength: 15
  },

  {
    ID: 22,
    Name: 'Belgium',
    FullName: 'Kingdom of Belgium',
    ISO2: 'BE',
    ISO3: 'BEL',
    DialPrefix: '32   ',
    ContinentID: 3,
    CurrencyID: 11,
    label: 'National Register Number',
    textFieldType: 'Numeric',
    minLength: 12,
    maxLength: 12
  },
  {
    ID: 36,
    Name: 'Bulgaria',
    FullName: 'Republic of Bulgaria',
    ISO2: 'BG',
    ISO3: 'BGR',
    DialPrefix: '359  ',
    ContinentID: 3,
    CurrencyID: 17,
    label: 'Unified Civil Number',
    textFieldType: 'Numeric',
    minLength: 10,
    maxLength: 10
  },
  {
    ID: 56,
    Name: 'Croatia',
    FullName: 'Republic of Croatia',
    ISO2: 'HR',
    ISO3: 'HRV',
    DialPrefix: '385  ',
    ContinentID: 3,
    CurrencyID: 28,
    label: 'Osobni Identifikacijski Broj (OIB)',
    textFieldType: 'Numeric',
    minLength: 11,
    maxLength: 11
  },
  {
    ID: 59,
    Name: 'Cyprus',
    FullName: 'Republic of Cyprus',
    ISO2: 'CY',
    ISO3: 'CYP',
    DialPrefix: '357  ',
    ContinentID: 2,
    CurrencyID: 30,
    label: 'Identification Number as on Cypriot identity card',
    textFieldType: 'Numeric',
    minLength: 10,
    maxLength: 10
  },
  {
    ID: 60,
    Name: 'Czech Republic',
    FullName: 'Czech Republic',
    ISO2: 'CZ',
    ISO3: 'CZE',
    DialPrefix: '420  ',
    ContinentID: 3,
    CurrencyID: 31,
    label: 'Czech National identification number (Rodné číslo)',
    textFieldType: 'alphaNumeric',
    minLength: 9,
    maxLength: 10
  },
  {
    ID: 61,
    Name: 'Denmark',
    FullName: 'Kingdom of Denmark',
    ISO2: 'DK',
    ISO3: 'DNK',
    DialPrefix: '45   ',
    ContinentID: 3,
    CurrencyID: 32,
    label: 'Central Person Registration (CPR) Number',
    textFieldType: 'Numeric',
    minLength: 10,
    maxLength: 10
  },
  {
    ID: 70,
    Name: 'Estonia',
    FullName: 'Republic of Estonia',
    ISO2: 'EE',
    ISO3: 'EST',
    DialPrefix: '372  ',
    ContinentID: 3,
    label: 'Estonian Personal Identification Code (Isikukood)',
    textFieldType: 'Numeric',
    minLength: 11,
    maxLength: 11
  },
  {
    ID: 75,
    Name: 'Finland',
    FullName: 'Republic of Finland',
    ISO2: 'FI',
    ISO3: 'FIN',
    DialPrefix: '358  ',
    ContinentID: 3,
    label: 'Personal identity code (Henkilötunnus Suomen henkilökorttiin)',
    textFieldType: 'alphaNumeric',
    minLength: 11,
    maxLength: 11
  },
  {
    ID: 76,
    Name: 'France',
    FullName: 'French Republic',
    ISO2: 'FR',
    ISO3: 'FRA',
    DialPrefix: '33   ',
    ContinentID: 3,
    CurrencyID: 41,
    label: 'National Identification Numbers (INSEE) Code',
    textFieldType: 'alphaNumeric',
    minLength: 15,
    maxLength: 15
  },
  {
    ID: 83,
    Name: 'Germany',
    FullName: 'Federal Republic of Germany',
    ISO2: 'DE',
    ISO3: 'DEU',
    DialPrefix: '49   ',
    ContinentID: 3,
    label: 'Deutsche Personalausweisnummer',
    textFieldType: 'alphaNumeric',
    minLength: 4,
    maxLength: 15
  },
  {
    ID: 86,
    Name: 'Greece',
    FullName: 'Hellenic Republic Greece',
    ISO2: 'GR',
    ISO3: 'GRC',
    DialPrefix: '30   ',
    ContinentID: 3,
    CurrencyID: 44,
    label: 'Identity Number (αριθμός ταυτότητας)',
    textFieldType: 'alphaNumeric',
    minLength: 4,
    maxLength: 24
  },
  {
    ID: 101,
    Name: 'Hungary',
    FullName: 'Hungary',
    ISO2: 'HU',
    ISO3: 'HUN',
    DialPrefix: '36   ',
    ContinentID: 3,
    CurrencyID: 51,
    label: 'personal identification number (személyi azonosító szám)',
    textFieldType: 'alphaNumeric',
    minLength: 4,
    maxLength: 11
  },
  {
    ID: 107,
    Name: 'Ireland',
    FullName: 'Ireland',
    ISO2: 'IE',
    ISO3: 'IRL',
    DialPrefix: '353  ',
    ContinentID: 3,
    CurrencyID: 56,
    label: 'ID Number ( pas Éireannach)',
    textFieldType: 'alphaNumeric',
    minLength: 4,
    maxLength: 15
  },
  {
    ID: 110,
    Name: 'Italy',
    FullName: 'Italian Republic',
    ISO2: 'IT',
    ISO3: 'ITA',
    DialPrefix: '39   ',
    ContinentID: 3,
    CurrencyID: 59,
    label: 'Fiscal code (Codice fiscale)',
    textFieldType: 'alphaNumeric',
    minLength: 16,
    maxLength: 16
  },
  {
    ID: 122,
    Name: 'Latvia',
    FullName: 'Republic of Latvia',
    ISO2: 'LV',
    ISO3: 'LVA',
    DialPrefix: '371  ',
    ContinentID: 3,
    CurrencyID: 68,
    label: 'Personal code (Personas kods)',
    textFieldType: 'Numeric',
    minLength: 11,
    maxLength: 11
  },
  {
    ID: 128,
    Name: 'Lithuania',
    FullName: 'Republic of Lithuania',
    ISO2: 'LT',
    ISO3: 'LTU',
    DialPrefix: '370  ',
    ContinentID: 3,
    CurrencyID: 72,
    label: 'Personal code (Asmens kodas)',
    textFieldType: 'Numeric',
    minLength: 11,
    maxLength: 11
  },
  {
    ID: 129,
    Name: 'Luxembourg',
    FullName: 'Grand Duchy of Luxembourg',
    ISO2: 'LU',
    ISO3: 'LUX',
    DialPrefix: '352  ',
    ContinentID: 3,
    CurrencyID: 73,
    label: 'Luxembourg National identification number',
    textFieldType: 'alphaNumeric',
    minLength: 4,
    maxLength: 15
  },
  {
    ID: 137,
    Name: 'Malta',
    FullName: 'Republic of Malta',
    ISO2: 'MT',
    ISO3: 'MLT',
    DialPrefix: '356  ',
    ContinentID: 3,
    CurrencyID: 76,
    label: "National ID Number (numru ta 'identifikazzjoni personali)",
    textFieldType: 'alphaNumeric',
    minLength: 8,
    maxLength: 8
  },
  {
    ID: 156,
    Name: 'Netherlands',
    FullName: 'Kingdom of the Netherlands',
    ISO2: 'NL',
    ISO3: 'NLD',
    DialPrefix: '31   ',
    ContinentID: 3,
    CurrencyID: 84,
    label: 'Citizen Service Number (burgerservicenummer, BSN)',
    textFieldType: 'alphaNumeric',
    minLength: 9,
    maxLength: 9
  },
  {
    ID: 176,
    Name: 'Poland',
    FullName: 'Republic of Poland',
    ISO2: 'PL',
    ISO3: 'POL',
    DialPrefix: '48   ',
    ContinentID: 3,
    CurrencyID: 96,
    label: 'Personal Number ( PESEL Numer)',
    textFieldType: 'Numeric',
    minLength: 11,
    maxLength: 11
  },
  {
    ID: 177,
    Name: 'Portugal',
    FullName: 'Portuguese Republic',
    ISO2: 'PT',
    ISO3: 'PRT',
    DialPrefix: '351  ',
    ContinentID: 3,
    label: 'NIF (Número de Identificação Fiscal)',
    textFieldType: 'Numeric',
    minLength: 9,
    maxLength: 9
  },
  {
    ID: 181,
    Name: 'Romania',
    FullName: 'Romania',
    ISO2: 'RO',
    ISO3: 'ROU',
    ContinentID: 3,
    CurrencyID: 98,
    label: 'CNP Number (Cod Numeric Personal)',
    textFieldType: 'Numeric',
    minLength: 13,
    maxLength: 13
  },
  {
    ID: 201,
    Name: 'Slovakia (Slovak Republic)',
    FullName: 'Slovakia (Slovak Republic)',
    ISO2: 'SK',
    ISO3: 'SVK',
    DialPrefix: '421  ',
    ContinentID: 3,
    label: 'Personal number (Rodné číslo)',
    textFieldType: 'Numeric',
    minLength: 10,
    maxLength: 10
  },
  {
    ID: 202,
    Name: 'Slovenia',
    FullName: 'Republic of Slovenia',
    ISO2: 'SI',
    ISO3: 'SVN',
    DialPrefix: '386  ',
    ContinentID: 3,
    CurrencyID: 105,
    label: 'Personal Registration Number (Enotna Matična Številka Občana)',
    textFieldType: 'alphaNumeric',
    minLength: 13,
    maxLength: 13
  },
  {
    ID: 208,
    Name: 'Spain',
    FullName: 'Kingdom of Spain',
    ISO2: 'ES',
    ISO3: 'ESP',
    DialPrefix: '34   ',
    ContinentID: 3,
    CurrencyID: 110,
    label: 'DNI Code',
    textFieldType: 'alphaNumeric',
    minLength: 9,
    maxLength: 9
  },
  {
    ID: 214,
    Name: 'Sweden',
    FullName: 'Kingdom of Sweden',
    ISO2: 'SE',
    ISO3: 'SWE',
    DialPrefix: '46   ',
    ContinentID: 3,
    CurrencyID: 112,
    label: 'Personal ID Number (personnummer)',
    textFieldType: 'alphaNumeric',
    minLength: 10,
    maxLength: 10
  },
  {
    ID: 233,
    Name: 'United Arab Emirates',
    FullName: 'United Arab Emirates',
    ISO2: 'AE',
    ISO3: 'ARE',
    DialPrefix: '971  ',
    ContinentID: 2,
    label: 'Emirates ID Number (رقم الهوية)',
    textFieldType: 'Numeric',
    minLength: 15,
    maxLength: 15
  }
];
