import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  placeObj: {
    street_number: '',
    postal_code: '',
    street: '',
    province: '',
    city: '',
    country: '',
    address: '',
    state: ''
  },
  completeAddress: ''
};

export const googleAddressSearchSlice = createSlice({
  name: 'googleAddressSearchSlice',
  initialState,
  reducers: {
    updatePlaces: (state, action) => {
      state.placeObj = action.payload;
    },
    updateCompleteAddress: (state, action) => {
      state.completeAddress = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { updatePlaces, updateCompleteAddress } = googleAddressSearchSlice.actions;

export default googleAddressSearchSlice.reducer;
