import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  startRange: 5000,
  endRange: 10000
};

const tradingSlice = createSlice({
  name: 'trading',
  initialState,
  reducers: {
    updateStartRange: (state, action) => {
      state.startRange = action.payload;
    },
    updateEndRange: (state, action) => {
      state.endRange = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateStartRange, updateEndRange } = tradingSlice.actions;

export default tradingSlice.reducer;
