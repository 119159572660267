// AppInsights.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { getReactInsightKey } from '../utils/userConfig';

const reactPlugin = new ReactPlugin();

const ai = new ApplicationInsights({
  config: {
    instrumentationKey: getReactInsightKey(),
    enableAutoRouteTracking: true,
    extensions: [reactPlugin]
  }
});

ai.loadAppInsights();

const insight = (Component) => withAITracking(reactPlugin, Component);

export default insight;

export const appInsights = ai.appInsights;
