import { freedomUserType, inviteStatus } from '../../FreedomConstants/freedomConstants';
import { getMLMBaseUrl } from '../../utils/mlmConfig';

const baseUrl = getMLMBaseUrl();

/**
 *
 * graph data API URL
 */

export const graphInvesterDataAPIUrl = (affiliateType, graphPeriod, startDate, endDate, userType) => {
  return `${baseUrl}GetInvestorsDashboardData(${affiliateType},${graphPeriod},${startDate},${endDate},${userType})`;
};

export const getFollowUpCustomerApiUrl = (userType = freedomUserType.Customer, todayDate, searchText) => {
  if (searchText) {
    const encodedSearchValue = encodeURIComponent(searchText);
    return `${baseUrl}/userinvitations?$filter=InvitedByUserId eq @UserId and (FollowUpDate eq ${todayDate} or FollowUpDate gt ${todayDate}) and Status ne ${inviteStatus.Completed} and Usertype eq ${userType} and (contains(Givenname, '${encodedSearchValue}') or contains(Surname, '${encodedSearchValue}') or contains(Email, '${encodedSearchValue}'))&$select=Id,Givenname,Surname,PlatformTypeId,MobileNumber,Email,FollowUpDate&$orderby=FollowUpDate desc&$top=5`;
  } else {
    return `${baseUrl}/userinvitations?$filter=InvitedByUserId eq @UserId and FollowUpDate eq ${todayDate} and Status ne ${inviteStatus.Completed} and Usertype eq ${userType}&$select=Id,Givenname,Surname,PlatformTypeId,MobileNumber,Email,FollowUpDate &$orderby=FollowUpDate desc&$top=5`;
  }
};

export const getNextFollowUpInvestor = (userType = freedomUserType.Customer, todayDate) => {
  return `${baseUrl}userinvitations?$filter=InvitedByUserId eq @UserId and FollowUpDate gt ${todayDate} and Status ne ${inviteStatus.Completed} and Usertype eq ${userType}&$select=Id,Givenname,Surname,PlatformTypeId,MobileNumber,Email,FollowUpDate &$orderby=FollowUpDate desc&$top=5`;
};

/**
 *
 * api url for Managed customers
 */

export const getManageCustomerApiUrl = (page, countSkip, property, order, userType) => {
  return `${baseUrl}getcustomers(${page},${countSkip},'${property}','${order}',${userType})`;
};

/**
 *
 * api url for searched data in Manage customer
 */
export const getManageCustomerSearchedApiUrl = (page, countSkip, property, order, searchedText, userType) => {
  return `${baseUrl}searchcustomers(${page},${countSkip},'${property}','${order}','${searchedText?.toLowerCase()}',${userType})`;
};

/**api for customer first line
 * ends
 */

export const getPendingCustomerApiUrl = (page, countSkip, property, order, userType, searchedText) => {
  if (searchedText) {
    return `${baseUrl}searchpendingcustomers(${page},${countSkip},'${property}','${order}',${searchedText?.toLowerCase()},${userType})`;
  } else {
    return `${baseUrl}getpendingcustomers(${page},${countSkip},'${property}','${order}',${userType})`;
  }
};
export const getPendingCustomerSearchApiUrl = (page, skipCount, property, order, searchedText, userType) => {
  return `${baseUrl}searchpendingcustomers(${page},${skipCount},'${property}','${order}',${searchedText?.toLowerCase()},${userType})`;
};

export const getUsersMobileOrEmailExistsUrl = (contactMobile = '', contactEmail = '') => {
  return `${baseUrl}usernameexists('${contactEmail?.toLocaleLowerCase() || contactMobile?.toLocaleLowerCase()}')`;
};

////////////////////Investor email already exixt///////////////////////
export const getInvestorEmailExitsUrl = (contactEmail = '') => {
  return `${baseUrl}userInvitations?$filter=InvitedByUserId eq @UserId and Email eq '${contactEmail}' and Usertype eq ${freedomUserType.Customer}&$select=Id&$top=1&$orderby=CreatedOn desc`;
};

////////////////////Investor already send rquest to an affiliate///////////////////////
export const getAlreadyInvestorToAffiliateUrl = () => {
  return `${baseUrl}convertinvestortoaffiliaterequestexists`;
};
