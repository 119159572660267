import { Box, Grid, useTheme } from '@mui/material';
import React, { useContext } from 'react';

// import internalCard from "../../Images/payment/internalCard.svg";
// import creditCard from "../../Images/payment/creditCard.svg";
// import bankCard from "../../Images/payment/bankCard.svg";
// import visa from '../../Images/payment/visa.svg';
// import master from '../../Images/payment/master.svg';

import { paymentAccountType } from '../../FreedomConstants/freedomConstants';
import FreedomTypographyText from '../../Components/SharedComponent/FreedomTypographyText/FreedomTypographyText';
import { capitalizeFullName } from '../../utils/utilityFunctions';
import { paymentsvg, topNavbar } from '../../url/SvgLinks';
import { ColorModeContext } from '../../store';

const internalCard = paymentsvg.internalCard;
const internalCardLight = paymentsvg.internalCardLight;
const creditCard = paymentsvg.creditCard;
const creditCardLight = paymentsvg.creditCardLight;
const bankCard = paymentsvg.bankCard;
const bankCardLight = paymentsvg.bankCard;
const visa = paymentsvg.visa;
const master = paymentsvg.master;
const FreedomIcon = topNavbar.freedomIcon1;

const PaymentCard = (props) => {
  const theme = useTheme();
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  const { paymentPayMethod, username } = props;
  const cardImage =
    paymentPayMethod === paymentAccountType.internalAccount
      ? mode === 'light'
        ? internalCardLight
        : internalCard
      : paymentPayMethod === paymentAccountType.creditCardAccount
      ? mode === 'light'
        ? creditCardLight
        : creditCard
      : paymentPayMethod === paymentAccountType.bankAccount
      ? mode === 'light'
        ? bankCardLight
        : bankCard
      : '';
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        borderRadius: '10px',
        backgroundSize: 'cover',
        display: 'flex',
        backgroundImage: `url(${cardImage})`,
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Grid container justifyContent={'space-between'} direction={'column'} sx={{ margin: '16px' }}>
        <Grid item>
          <FreedomTypographyText textalign="left" fontFamily="Poppins" fontsize={'16px'} fontweight={500} color={theme.palette.text.primary}>
            {paymentPayMethod === paymentAccountType.internalAccount ? 'Freedom Internal Account' : paymentPayMethod === paymentAccountType.creditCardAccount ? 'Card Payment' : paymentPayMethod === paymentAccountType.bankAccount ? 'Bank' : ''}
          </FreedomTypographyText>
        </Grid>
        <Grid item container justifyContent="space-between" alignItems="center" direction="row">
          <Grid item>
            <FreedomTypographyText textalign="left" fontFamily="Poppins" fontsize={'16px'} fontweight={500} color={theme.palette.text.primary}>
              {paymentPayMethod === paymentAccountType.internalAccount
                ? capitalizeFullName(username)
                : paymentPayMethod === paymentAccountType.creditCardAccount
                ? ''
                : paymentPayMethod === paymentAccountType.bankAccount
                ? capitalizeFullName(username)
                : ''}
            </FreedomTypographyText>
          </Grid>
          <Grid item>
            {paymentPayMethod === paymentAccountType.creditCardAccount ? (
              <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                <Grid item>
                  <img src={master} style={{ width: '40px', height: '30px' }} />
                </Grid>
                <Grid item>
                  <img src={visa} style={{ width: '40px', height: '30px' }} />
                </Grid>
              </Grid>
            ) : paymentPayMethod === paymentAccountType.internalAccount ? (
              <img src={FreedomIcon} style={{ width: '70px', height: '70px' }} />
            ) : paymentPayMethod === paymentAccountType.bankAccount ? (
              ''
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentCard;
