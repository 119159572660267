import React from 'react';
import { useTheme } from '@mui/material';

import './FreedomLinearLoader.css';
import { appInsights } from '../../auth/appInsight';

const FreedomLinearLoader = ({ marginleft, margintop }) => {
  const theme = useTheme();
  try {
    return (
      <span
        className="loader"
        style={{
          color: theme.palette.primary.onBoardTextColor,
          marginLeft: marginleft || '',
          marginTop: margintop || ''
        }}
      ></span>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomLinearLoader;
