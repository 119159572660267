import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Typography } from '@mui/material';
import { appInsights } from '../../../auth/appInsight';
import { devConsoleLog } from '../../../utils/utilityFunctions';

const FormControl = styled(FormControlLabel)(({ theme }) => ({}));

const Text = styled('span')(({ theme, checkboxcolortext, fontsize, smfontsize, lineheight, fontweight, textalign }) => ({
  fontFamily: 'Poppins!important',
  fontStyle: 'normal',
  fontWeight: fontweight || '400',
  fontSize: fontsize || '16px',
  lineHeight: lineheight || '34px',
  textAlign: textalign,
  color: checkboxcolortext || theme.palette.text.secondary,
  [theme.breakpoints.down('sm')]: {
    fontSize: smfontsize ? `${smfontsize}px !important` : '9px !important'
  }
}));

const BpIcon = styled('span')(({ theme, checkboxcolor = '' }) => ({
  borderRadius: 5,
  width: 17,
  height: 17,
  padding: '2px',
  border: `2px solid ${checkboxcolor || theme.palette.border.border}`,
  backgroundColor: 'transparent',

  // "input:hover ~ &": {
  // backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5"
  // },

  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)'
  }
}));

const BpCheckedIcon = styled(BpIcon)(({ theme, checkboxcolor = '' }) => ({
  backgroundColor: 'transparent',
  '&:before': {
    display: 'block',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    borderRadius: 3,
    backgroundColor: checkboxcolor || theme.palette.border.border,
    content: '""'
  }
}));

function BpCheckbox(props) {
  const { label, checkboxcolor, handleChange, checked, margintop, ...rest } = props;
  return (
    <FormControl
      onChange={handleChange}
      checked={checked}
      sx={{ alignItems: 'flex-start' }}
      control={
        <Checkbox
          sx={{
            marginTop: margintop || '-1px',
            '&:hover': { bgcolor: 'transparent' }
          }}
          {...props}
          disableRipple
          color="default"
          checkedIcon={<BpCheckedIcon checkboxcolor={checkboxcolor} />}
          icon={<BpIcon checkboxcolor={checkboxcolor} />}
          inputProps={{ 'aria-label': 'Checkbox demo' }}
        />
      }
      label={
        <Text {...props} variant="caption">
          {label}
        </Text>
      }
    />
  );
}

export default function FreedomCheckbox1(props) {
  try {
    const { label, checkboxcolor, handlechange, checked, margintop, fontsize, ...rest } = props;

    devConsoleLog('rest operator', { ...rest });
    return (
      <FormControlLabel
        onChange={handlechange}
        checked={checked}
        sx={{ alignItems: 'flex-start' }}
        control={
          <Checkbox
            sx={{
              marginTop: margintop || '-1px',
              '&:hover': { bgcolor: 'transparent' },
              padding: '6px'
            }}
            {...rest}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon checkboxcolor={checkboxcolor} />}
            icon={<BpIcon checkboxcolor={checkboxcolor} />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
          />
        }
        label={
          <Text fontsize={fontsize} {...rest} variant="caption">
            {label}
          </Text>
        }
      />
      // <BpCheckbox {...props} />
      // <BpCheckbox defaultChecked />
      // <BpCheckbox disabled />
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
}

// import * as React from "react";
// import {
//   Checkbox,
//   checkboxClasses,
//   FormControlLabel,
//   Typography,
// } from "@mui/material";
// import { styled } from "@mui/system";
// import { appInsights } from "../../../auth/appInsight";

// const FormControl = styled(FormControlLabel)(({ theme }) => ({}));

// const Text = styled(Typography)(
//   ({
//     theme,
//     checkboxcolortext,
//     fontsize,
//     smfontsize,
//     lineheight,
//     fontweight,
//     textalign,
//   }) => ({
//     fontFamily: "Poppins!important",
//     fontStyle: "normal",
//     fontWeight: fontweight || "400",
//     fontSize: fontsize || "16px",
//     lineHeight: lineheight || "34px",
//     textAlign: textalign,
//     color: checkboxcolortext || theme.palette.text.secondary,
//     [theme.breakpoints.down("sm")]: {
//       fontSize: smfontsize ? `${smfontsize}px !important` : "9px !important",
//     },
//   })
// );

// const FreedomCheckbox1 = (props) => {
//   const { label, checkboxcolor, handleChange, checked, margintop } = props;
//   try {
//     return (
//       <FormControl
//         onChange={handleChange}
//         checked={checked}
//         control={
//           <Checkbox
//             {...props}
//             sx={{
//               marginTop: margintop || "0px",
//               [`&, &.${checkboxClasses.checked}`]: {
//                 color: checkboxcolor,
//               },
//             }}
//           />
//         }
//         label={
//           <Text {...props} variant="caption">
//             {label}
//           </Text>
//         }
//       />
//     );
//   } catch (error) {
//     appInsights.trackException({ error: new Error(error) });
//   }
// };

// export default FreedomCheckbox1;
