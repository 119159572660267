import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';
import { devConsoleLog } from '../../utils/utilityFunctions';
import { countries } from '../../Distributors/freedomDB/db';
import { getUserLocationApiUrl } from '../../utils/userConfig';

export const getUserLocation = createAsyncThunk('geoLocation/getUserLocation', async () => {
  const { data } = await axios.get(getUserLocationApiUrl());

  devConsoleLog('data of location', data);
  let country = '';
  let locationInfo = {
    CreatedByIp: '',
    CreatedByLatitude: '',
    CreatedByLongitude: '',
    CreatedByRegion: 'Asia',
    CreatedByCity: 'Lahore',
    CreatedByCountry: 'pakistan',
    CreatedByCountryCode: 'pk',
    CreatedByCountryId: 167
  };

  if (data) {
    if (Object.keys(data).length > 0) {
      country = countries.filter((country) => country.ISO2 === _.upperCase(data.countryCode));
      // devConsoleLog("country", country)
      if (country.length > 0) {
        country = country[0].ID;
      } else {
        country = null;
      }
    } else {
      country = null;
    }

    locationInfo = {
      CreatedByIp: data.query,
      CreatedByLatitude: data.lat,
      CreatedByLongitude: data.lon,
      CreatedByRegion: data.region,
      CreatedByCity: data.city,
      CreatedByCountry: data.country,
      CreatedByCountryCode: data.countryCode,
      CreatedByCountryId: country
    };
  }

  devConsoleLog('locationInfo', locationInfo);
  // dispatch(updateLocation(locationInfo));
  return locationInfo;

  // return data;
});
