import { createSlice } from '@reduxjs/toolkit';
import { devConsoleLog } from '../../utils/utilityFunctions';

const initialState = {
  location: {},
  os: '',
  osVersion: '',
  browserName: '',
  fullBrowserVersion: '',
  fullVersion: '',
  osDetails: {}
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    updateLocation: (state, action) => {
      state.location = action.payload;
    },
    updateOs: (state, action) => {
      state.os = action.payload;
    },
    updateOsVersion: (state, action) => {
      state.osVersion = action.payload;
    },
    updateBrowserName: (state, action) => {
      state.browserName = action.payload;
    },
    updateBrowserFullVersion: (state, action) => {
      state.fullBrowserVersion = action.payload;
    },
    updateBrowserInfo: (state, action) => {
      state.os = action.payload.os;
      state.fullVersion = action.payload.fullVersion;
      state.browserName = action.payload.browserName;
      state.osVersion = action.payload.osVersion;
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateLocation, updateOs, updateOsVersion, updateBrowserName, updateBrowserFullVersion, updateBrowserInfo } = locationSlice.actions;

export default locationSlice.reducer;
