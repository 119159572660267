import React from 'react';

import { styled } from '@mui/system';
import { appInsights } from '../../../auth/appInsight';
const Divider = styled('div')((props) => ({
  marginTop: props.marginTop || '20px ',
  marginBottom: props.marginBottom || '20px',
  height: '0.5px',
  backgroundColor: props.dividerbackgroundcolor
}));
const FreedomColorDivider = (props) => {
  const { dividerbackgroundcolor } = props;
  // const classes = useStyle();
  try {
    return <Divider {...props} dividerbackgroundcolor={dividerbackgroundcolor} />;
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomColorDivider;
