import styled from '@emotion/styled';

export const Wrapper = styled('div')(({ theme, bgcolor, padding, height, margin, flexdirection, borderRadius, bordercolor, justifycontent, alignItems, width }) => ({
  border: bordercolor ? `2px solid ${bordercolor}` : `2px solid ${theme.palette.border.containerborder}`,
  margin: margin || '4px',
  padding: padding || '5px',
  borderRadius: borderRadius || '10px',
  background: bgcolor || '',
  height: height || '',
  width: width || '100%',
  display: 'flex',
  flexDirection: flexdirection || 'column',
  justifyContent: justifycontent,
  alignItems: alignItems || 'center'
}));

export const Title = styled('div')(({ theme, fontweight, fontsize, lineheight, textalign, color }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: fontweight || '400',
  fontSize: fontsize || '13px',
  lineHeight: lineheight || '18px',
  color: color || theme.palette.primary,
  whiteSpace: 'nowrap',
  width: '70%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: textalign || 'center'
}));
