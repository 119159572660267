import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';

import { devConsoleLog } from '../utils/utilityFunctions';
import { useEffect } from 'react';
import { loginRequest } from '../auth/authConfig';
import { useMsal } from '@azure/msal-react';
import { appInsights } from '../auth/appInsight';
import { getPublisableKeyStripeUrl } from '../api/apiUrls/walletApiUrl';
import { getPlatformUrl, getUsersUrl } from '../api/apiUrls/apiUrl';
import { updateStripePublishableKey, updateUser } from '../features/user/userSlice';
import { getOS } from '../helper/helperFunctions';
import { freedomConstants, inviteStatus } from '../FreedomConstants/freedomConstants';
import { getInvitationConfirmationDbUrl, getInvitationConfirmationEmailUrl, getInvitationValidateCodeApiUrl } from '../api/apiUrls/OnBoardApiUrls';
import useLocation from '../freedomHooks/useLocation';
import { getBaseUrl, getDefaultImage, getProfileImage, getUserPrincipleName } from '../utils/userConfig';
import { EventType } from '@azure/msal-browser';

const baseUrl = getBaseUrl();

export const getPlatformId = async (accessToken, os) => {
  if (accessToken !== '') {
    return await axios
      .get(getPlatformUrl(os), { headers: { Authorization: 'Bearer ' + accessToken } })
      .then((res) => {
        // handle success
        return res.data[0].Id;
      })
      .catch((error) => {
        // handle error
        devConsoleLog(error?.response);
      });
  }
};

export const getUserObject = (response, user, platformId) => {
  let userInformation = user;

  devConsoleLog('user information', userInformation);
  if (user) {
    const todayDate = new Date();
    const autoDeleteOn = new Date(user.AutoDeleteOn);
    const diffTime = Math.abs(autoDeleteOn - todayDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // const theme = 'light'
    const theme = user.ThemeOther === 'L' ? 'light' : 'dark';

    const Fullname = `${userInformation.Givenname} ${userInformation.Surname}`;

    let active = true;
    if (userInformation.OnboardingCompleted === false && autoDeleteOn < todayDate) {
      active = false;
    } else {
      active = userInformation.Active;
    }

    userInformation = {
      accessToken: response.accessToken,
      uniqueId: response.uniqueId,
      givenname: userInformation.Givenname ?? '',
      surname: userInformation.Surname ?? '',
      fullname: Fullname,
      // Active: false,
      activeUser: active,
      // Active: userInformation.Active,
      rowNumber: userInformation.RowNumber,
      userType: userInformation.UserType,
      invitedByUserId: userInformation.InvitedByUserId,
      parentUserId: userInformation.ParentUserId,
      parentUserLeg: userInformation.ParentUserLeg,
      userName: userInformation.Username,
      alternateEmail: userInformation.AlternateEmail,
      // OnboardingCompleted: false,
      onboardingCompleted: userInformation.OnboardingCompleted,
      themeOther: theme,
      accountTypeId: userInformation.AccountTypeId,
      profileType: userInformation.ProfileType,
      userLevel: userInformation.Level,
      autoDeleteOn: userInformation.AutoDeleteOn,
      primaryPaymentMethodId: userInformation.PrimaryPaymentMethodId,
      primaryPaymentMethodType: userInformation.PrimaryPaymentMethodType,
      aboutMe: userInformation.AboutMe,
      hasProfilePicture: userInformation?.HasProfilePicture,
      platformId: platformId,
      termsAndConditionsAgreed: userInformation?.TermsAndConditionsAgreed,
      privacyPolicyAgreed: userInformation?.PrivacyPolicyAgreed,
      riskAndWarningAgreed: userInformation?.RiskAndWarningAgreed,
      affiliateAgreementAgreed: userInformation?.AffiliateAgreementAgreed,
      affiliateLevelId: userInformation?.AffiliateLevelId,
      memberNumber: userInformation?.MemberNumber,
      profilePicture: userInformation?.HasProfilePicture ? getProfileImage(response?.uniqueId) : getDefaultImage()
    };

    devConsoleLog('user information data', userInformation);
  }
  return userInformation;
};

const getValidInvitationCode = async (accessToken, uniqueId, platformId, osDetails, location) => {
  let payload = [];
  let userInvitationJSON = {};
  const { browserName, fullVersion, osVersion } = osDetails;

  const getUserData = (userType) => {
    // if(!location) return
    return {
      __metadata: { type: 'Users' },
      ModifiedByAppId: freedomConstants.APP_ID,
      ModifiedByAppName: browserName?.slice(0, 49),
      ModifiedByAppVersionNumber: fullVersion?.slice(0, 49),
      ModifiedByBrowserName: browserName?.slice(0, 49),
      ModifiedByBrowserVersion: fullVersion?.slice(0, 49),
      ModifiedByPlatformVersion: osVersion?.slice(0, 49),
      ModifiedByPlatformId: platformId,
      ModifiedByLatitude: location.CreatedByLatitude?.slice(0, 49),
      ModifiedByLongitude: location.CreatedByLongitude?.slice(0, 49),
      ModifiedByIp: location.CreatedByIp?.slice(0, 49),
      ModifiedByCity: location.CreatedByCity?.slice(0, 49),
      ModifiedByRegion: location.CreatedByRegion?.slice(0, 49),
      ModifiedByCountryId: location.CreatedByCountryId,
      Id: '@UserId'
      // userType: userType
    };
  };

  //Creating User
  //Try fetching UserInvitaiton From LocalStorage
  let AnyOtherUserInvitationData = null;
  let AnyOtherUserInvitationsData = null;
  let UserInvitationData = null;
  let UserInvitationsData = null;
  let UserInvitationCode = null;
  let fetchedByInvitedUserId = false;

  UserInvitationCode = localStorage.getItem('inv');
  if (accessToken !== '') {
    if (UserInvitationCode === null) {
      //Fetch UserInvitationData by InvitedUserId From Api
      await axios
        .get(getInvitationConfirmationDbUrl(), { headers: { Authorization: 'Bearer ' + accessToken } })
        .then(async (res) => {
          if (res.data?.length > 0) {
            AnyOtherUserInvitationsData = res?.data;
            AnyOtherUserInvitationData = res?.data[0];
            fetchedByInvitedUserId = true;
            UserInvitationData = res?.data[0];
            UserInvitationsData = res?.data;
          } else {
            await axios
              .get(getUserPrincipleName(), { headers: { Authorization: 'Bearer ' + accessToken } })
              .then(async (res) => {
                let username = '';
                if (res.data !== undefined) {
                  username = res?.data.toLowerCase();
                }
                // console.log('response from', res.data, username);
                await axios
                  .get(getInvitationConfirmationEmailUrl(username), {
                    headers: { Authorization: 'Bearer ' + accessToken }
                  })
                  .then((res) => {
                    if (res.data.length > 0) {
                      AnyOtherUserInvitationsData = res?.data;
                      AnyOtherUserInvitationData = res?.data[0];
                      fetchedByInvitedUserId = true;
                      UserInvitationData = res?.data[0];
                      UserInvitationsData = res?.data;
                      // console.log('response from user email', res);
                    }
                  });
              })
              .catch((error) => {
                console.log(error?.repsonse);
                return;
              });
          }
        })
        .catch((error) => {
          console.log(error?.response);
        });
    } else {
      if (accessToken !== '') {
        await axios
          .get(getInvitationValidateCodeApiUrl(UserInvitationCode), {
            headers: { Authorization: 'Bearer ' + accessToken }
          })
          .then((res) => {
            UserInvitationData = res?.data[0];
            UserInvitationsData = res?.data;
            // console.log('response from userInvitations', UserInvitationData, UserInvitationsData);
          })
          .catch((error) => {
            console.log(error?.response);
            return;
          });
      }
    }
    if (accessToken !== '') {
      if (UserInvitationsData?.length > 0) {
        //The user has not any parent yet
        if (fetchedByInvitedUserId || UserInvitationData?.InvitedUserId === uniqueId) {
          // console.log('control comes here');
          if (UserInvitationData?.Status === 1) {
            // Status = 2 //Send Status 2 to UserInvitation to InvitedUserId:@UserId json
            userInvitationJSON = {
              __metadata: { type: 'UserInvitations' },
              InvitedUserID: '@UserId',
              id: UserInvitationData?.Id,
              status: inviteStatus.Onboarding
            };
            payload = [userInvitationJSON, getUserData(UserInvitationData?.Usertype)];
          }
        } else if (UserInvitationData?.InvitedUserId === null) {
          // console.log('control comes here in null');
          //Fetch Anyother userinvitations where InvitedUserId = currentUserId and status != 3
          //And Id != UserInvitationId
          if (AnyOtherUserInvitationData === null) {
            // console.log('control comes here in AnyOtherUserInvitationData');
            //Fetch UserInvitationData by InvitedUserId From Api
            await axios
              .get(getInvitationConfirmationDbUrl(), { headers: { Authorization: 'Bearer ' + accessToken } })
              .then((res) => {
                AnyOtherUserInvitationsData = res?.data;
                AnyOtherUserInvitationData = res?.data[0];
                fetchedByInvitedUserId = true;
              })
              .catch((error) => {
                console.log(error?.response);
                return;
              });
          }
          if (AnyOtherUserInvitationsData?.length > 0) {
            if (AnyOtherUserInvitationData?.Status === 1) {
              // Status = 2 //Send Status 2 to UserInvitation to InvitedUserId:@UserId json
              userInvitationJSON = {
                __metadata: { type: 'UserInvitations' },
                InvitedUserID: '@UserId',
                id: AnyOtherUserInvitationData?.Id,
                status: inviteStatus.Onboarding
              };
              payload = [userInvitationJSON, getUserData(AnyOtherUserInvitationData?.Usertype)];

              // console.log('control comes here in AnyOtherUserInvitationData', payload);
            }
          } else {
            if (UserInvitationData?.Status !== 3) {
              if (UserInvitationData?.Status === 1) {
                userInvitationJSON = {
                  __metadata: { type: 'UserInvitations' },
                  InvitedUserID: '@UserId',
                  id: UserInvitationData?.Id,
                  status: inviteStatus.Onboarding
                };
                payload = [userInvitationJSON, getUserData(UserInvitationData?.Usertype)];
                // console.log('control comes here in AnyOtherUserInvitationData', payload);
              }
            }
          }
        }

        if (payload.length > 0) {
          const myJSON = JSON.stringify(payload);
          const secondJSON = JSON.stringify(myJSON);
          // console.log('2nd Stringify data', secondJSON);
          await axios({
            url: baseUrl,
            headers: {
              'content-type': 'application/json; charset=utf-8',
              Authorization: 'Bearer ' + accessToken
            },
            method: 'post',
            data: secondJSON
          })
            .then((res) => {
              // console.log('appi Response after set status 2 ', res);
            })
            .catch(function (error) {
              console.log('Error while posting data', error);
              return;
            });
        }

        return { isCodeValid: true, validationCode: UserInvitationData?.Id };
      } else {
        localStorage.removeItem('inv');
        return { isCodeValid: false };
      }
    }
  }
};

/**
 *
 * Get user information if user exists
 */
const getUserInformation = async (accessToken) => {
  const { data } = await axios.get(getUsersUrl(), { headers: { Authorization: 'Bearer ' + accessToken } });
  return data;
};

/**
 *
 * create new user if not already exists
 */

const createNewUser = async (accessToken, osDetails, platformId, location) => {
  if (accessToken && osDetails && platformId && location) {
    const { browserName, fullVersion: fullBrowserVersion, osVersion } = osDetails;

    devConsoleLog('token, osDetails, platformId, location', accessToken, osDetails, platformId, location);

    let payload = [
      {
        __metadata: { type: 'Users' },
        ModifiedByAppId: freedomConstants.APP_ID,
        ModifiedByAppName: browserName?.slice(0, 49),
        ModifiedByAppVersionNumber: fullBrowserVersion?.slice(0, 49),
        ModifiedByBrowserName: browserName?.slice(0, 49),
        ModifiedByBrowserVersion: fullBrowserVersion?.slice(0, 49),
        ModifiedByPlatformVersion: osVersion?.slice(0, 49),
        ModifiedByPlatformId: platformId,
        ModifiedByLatitude: location.CreatedByLatitude?.slice(0, 49),
        ModifiedByLongitude: location.CreatedByLongitude?.slice(0, 49),
        ModifiedByIp: location.CreatedByIp?.slice(0, 49),
        ModifiedByCity: location.CreatedByCity?.slice(0, 49),
        ModifiedByRegion: location.CreatedByRegion?.slice(0, 49),
        ModifiedByCountryId: location.CreatedByCountryId,
        Id: '@UserId',
        // "CreatedByUserId": "@UserId",
        // "AadObjectId": '@AadObjectId',
        Username: '@UserName'
      }
    ];

    const firstStringify = JSON.stringify(payload);
    const secondStringify = JSON.stringify(firstStringify);
    devConsoleLog('2nd Stringify data of Get Create Request ', secondStringify);

    try {
      const data = await axios({
        url: baseUrl,
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + accessToken
        },
        method: 'post',
        data: secondStringify
      });

      devConsoleLog('data reponse from post call', data);
      return data;
    } catch (error) {
      throw Error(error);
    }
  } else {
    devConsoleLog('something went wrong');
  }
};

const useUserAuthentication = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const osDetails = useSelector((state) => state.location);
  const { location, error: locationError, loading: locationLoading } = useSelector((state) => state?.geoLocation);

  const redirectToLogin = async () => {
    await instance
      .handleRedirectPromise()
      .then((authResult) => {
        // Check if user signed in

        const account = instance.getActiveAccount();
        if (!account) {
          // devConsoleLog('control comes here in no account');
          // redirect anonymous user to login page
          instance.loginRedirect();
        }
      })
      .catch((err) => {
        // TODO: Handle errors
        console.log(err);
      });
  };

  const requestAccessToken = async (instance) => {
    const request = {
      ...loginRequest,
      account: instance.getActiveAccount()
    };

    devConsoleLog('called');
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    return await instance
      .acquireTokenSilent(request)
      .then((response) => {
        devConsoleLog('control comes in silent token', response);
        if (response) {
          return response;
        }
      })
      .catch(async (e) => {
        devConsoleLog('control comes in popup token', instance.getAllAccounts());
        return await instance.acquireTokenPopup(request).then((response) => {
          //  setAccessToken(response.accessToken);
          if (response) {
            return response;
          }
        });
      });
  };

  const getAccessToken = async () => {
    let userData = {};
    try {
      let response = await requestAccessToken(instance);

      // devConsoleLog("response of token access", response)
      if (!response) {
        response = await requestAccessToken(instance);
        return;
      }
      if (response.accessToken) {
        /**
         * stripe API loader
         */
        await axios
          .get(getPublisableKeyStripeUrl(), { headers: { Authorization: 'Bearer ' + response.accessToken } })
          .then((res) => {
            let stripePromise = loadStripe(res?.data);
            dispatch(updateStripePublishableKey(stripePromise));
          })
          .catch((error) => {
            devConsoleLog(error);
            return;
          });

        /**
         * User Platform type API
         */

        let platformId = await getPlatformId(response.accessToken, getOS());

        /**
         * User information
         */
        const data = await getUserInformation(response.accessToken);

        if (data?.length > 0) {
          userData = await getUserObject(response, data[0], platformId);
        } else {
          try {
            const user = await createNewUser(response.accessToken, osDetails, platformId, location);

            if (user?.status === 200) {
              const latestUserData = await getUserInformation(response.accessToken);
              userData = await getUserObject(response, latestUserData[0], platformId);
            } else {
              devConsoleLog('user ');
            }
          } catch (error) {
            setError(error?.message);
            // devConsoleLog("error occured during user creation", error?.message)
          }
        }

        // devConsoleLog("error outside", error)
        if (error) return;

        const { isCodeValid, validationCode } = await getValidInvitationCode(response.accessToken, response.uniqueId, platformId, osDetails, location);

        userData = { ...userData, isCodeValid, validationCode };

        devConsoleLog('user data after invitation code', userData);
        dispatch(updateUser(userData));
        // setAccessToken(response.accessToken);
      }
    } catch (error) {
      console.error('Error acquiring token:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    // Set active acccount on page load
    const accountsInstance = instance.getAllAccounts();

    if (accountsInstance.length > 0) {
      instance.setActiveAccount(accountsInstance[0]);
    }

    instance.addEventCallback(
      (event) => {
        // set active account after redirect
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account;
          instance.setActiveAccount(account);

          // console.log('get active account', instance.getActiveAccount(), accounts);
        }
      },
      (error) => {
        console.log('error', error);
      }
    );

    // handle auth redired/do all initial setup for msal
    instance
      .handleRedirectPromise()
      .then((authResult) => {
        // Check if user signed in
        const account = instance.getActiveAccount();
        if (!account) {
          // redirect anonymous user to login page
          instance.loginRedirect();
        }
      })
      .catch((err) => {
        // TODO: Handle errors
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (locationLoading || !osDetails.os || accounts.length === 0) return;
    getAccessToken();
  }, [accounts, locationLoading, osDetails]);

  return { redirectToLogin, isLoading, error };
};

export default useUserAuthentication;
