import React, { useContext, useEffect } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
// import { styled } from "@mui/material/styles";
import FreedomWrapperRoot from '../../../Components/SharedComponent/FreedomWrapperRoot/FreedomWrapperRoot';
import InvestmentProductCard from './InvestmentProductCard';
import { ColorModeContext } from '../../../store';
import { getInvestmentModule, grtSubsctibedProducts } from '../../../api/apiUrls/investmentApiUrls';
import useFetch from '../../../CustomHooks/useFetch';
import FreedomCardsWrapper from '../../Layouts/CardsWrapper';
import InvestmentGoldProducts from '../../Pages/InvestmentGoldProducts';
import InvestmentPlatinumProducts from '../../Pages/InvestmentPlatinumProducts';
import { useState } from 'react';
// import { GetUserProducts } from '../../../api/DataProvider/investmentDataProvider';
import { setUserProduct } from '../../../features/investment/productsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { appInsights } from '../../../auth/appInsight';
import { svgIcon, svgLinksDark, svgLinksLight } from '../../../url/SvgLinks';
import { Fragment } from 'react';
import { devConsoleLog } from '../../../utils/utilityFunctions';

//images from Azure Cloud
const standardBasicDarkFinal = svgLinksDark.standardBasicDark;
const standardAdvanceFinalDark = svgLinksDark.standardAdvanceDark;
const standardProFinalDark = svgLinksDark.standardProDark;
const standardBasicLightFinal = svgLinksLight.standardBasicLight;
const standardAdvanceFinalLight = svgLinksLight.standardAdvanceLight;
const standardProFinalLight = svgLinksLight.standardProLight;
const silverBasicFinalLight = svgLinksLight.silverBasicLight;
const silverAdvanceFinalLight = svgLinksLight.silverAdvanceLight;
const silverProFinalLight = svgLinksLight.silverProLight;
const GoldLightInactiveBgImg = svgLinksLight.goldLight;
const GoldLightHoverBgImg = svgLinksLight.goldLightHover;
const silverBasicFinalDark = svgLinksDark.silverBasicDark;
const silverAdvanceFinalDark = svgLinksDark.silverAdvanceDark;
const silverProFinalDark = svgLinksDark.silverProDark;
const EliteFooterBgImg = svgLinksDark.eliteDark;
const GoldDarkInactiveBgImg = svgLinksDark.goldDark;
const silverbasicicon = svgIcon.silverbasicicon;
const silveradvanceicon = svgIcon.silveradvanceicon;
const silverproicon = svgIcon.silverproicon;
const basicicon = svgIcon.standardbasicicon;
const advanceicon = svgIcon.standardadvanceicon;
const proIcon1 = svgIcon.standardproicon;
const SupremeIcon = svgIcon.supremeIcon;
const EliteIcon = svgIcon.eliteIcon;
const PlatinumCardbg = svgLinksLight.PlatinumCardbg;
const cardPropsSelector = (type, mode) => {
  switch (type) {
    case 'StandardBasic': {
      return {
        color: '#2A9EED',
        // color: "#00922B",
        // icon: BasicActiveIcon,
        icon: basicicon,
        inactiveBgImg: mode === 'light' ? standardBasicLightFinal : standardBasicDarkFinal,
        hoveredBgImg: mode === 'light' ? standardBasicLightFinal : standardBasicDarkFinal,
        activeBgImg: mode === 'light' ? standardBasicLightFinal : standardBasicDarkFinal,
        subHeadingBgColor: 'rgba(42, 158, 237, 0.5)',
        hoverButtonbg: 'rgba(42, 158, 237, 0.29)',
        selectedButtonbg: '#2A9EED',
        // subHeadingBgColor: " rgba(0, 146, 43, 0.25)",
        // hoverButtonbg: "rgba(0, 146, 43, 0.29)",
        // selectedButtonbg: "#00922B",
        textColor: mode === 'light' ? '#000000' : '#ffffff'
        // icon:
      };
    }
    case 'StandardAdvance': {
      return {
        color: '#2A9EED',
        // color: "#00A894",
        // icon: AdvanceActiveIcon,
        icon: advanceicon,
        inactiveBgImg: mode === 'light' ? standardAdvanceFinalLight : standardAdvanceFinalDark,
        hoveredBgImg: mode === 'light' ? standardAdvanceFinalLight : standardAdvanceFinalDark,
        activeBgImg: mode === 'light' ? standardAdvanceFinalLight : standardAdvanceFinalDark,
        subHeadingBgColor: 'rgba(42, 158, 237, 0.5)',
        hoverButtonbg: 'rgba(42, 158, 237, 0.29)',
        selectedButtonbg: '#2A9EED',
        // subHeadingBgColor: "rgba(0, 168, 148, 0.25)",
        // hoverButtonbg: "rgba(0, 168, 148, 0.29)",
        // selectedButtonbg: "#00A894",
        textColor: mode === 'light' ? '#000000' : '#ffffff'
      };
    }
    case 'StandardPro': {
      return {
        color: '#2A9EED',
        // icon: ProActiveIcon,
        icon: proIcon1,
        inactiveBgImg: mode === 'light' ? standardProFinalLight : standardProFinalDark,
        hoveredBgImg: mode === 'light' ? standardProFinalLight : standardProFinalDark,
        activeBgImg: mode === 'light' ? standardProFinalLight : standardProFinalDark,
        subHeadingBgColor: 'rgba(42, 158, 237, 0.5)',
        hoverButtonbg: 'rgba(42, 158, 237, 0.29)',
        selectedButtonbg: '#2A9EED',
        textColor: mode === 'light' ? '#000000' : '#ffffff'
      };
    }
    case 'Basic+': {
      return {
        color: '#B1B1B1',
        // color: "#00922B",
        icon: mode === 'light' ? silverbasicicon : silverbasicicon,
        inactiveBgImg: mode === 'light' ? silverBasicFinalLight : silverBasicFinalDark,
        hoveredBgImg: mode === 'light' ? silverBasicFinalLight : silverBasicFinalDark,
        activeBgImg: mode === 'light' ? silverBasicFinalLight : silverBasicFinalDark,
        // color: mode === "light" ? "black" : "gray",
        // bgImg: SilverBasicInactiveIcon,
        // inactiveBgImg: mode === "light" ? silverLightInactive : silverinactive,
        // mode === "light" ? BasicSiLightInactive : BasicSiDarkInactive,
        // hoveredBgImg: ProStandardHoveredSelectedBgImg,
        // subHeadingBgColor: " rgba(0, 146, 43, 0.25)",
        subHeadingBgColor: mode === 'light' ? 'rgba(149, 149, 149, 0.30)' : ' rgba(255, 255, 255, 0.10)',
        hoverButtonbg: 'rgba(105, 105, 105, 0.90)',
        selectedButtonbg: 'rgba(105, 105, 105, 0.90)',
        // hoverButtonbg: "rgba(0, 146, 43, 0.29)",
        // selectedButtonbg: "#00922B",
        textColor: mode === 'light' ? '#000000' : '#ffffff'
      };
    }
    case 'Advance+': {
      return {
        color: '#B1B1B1',
        // color: "#00A894",
        // color: mode === "light" ? "black" : "gray",
        icon: mode === 'light' ? silveradvanceicon : silveradvanceicon,
        // bgImg: SilverAdvanceInactiveIcon
        inactiveBgImg: mode === 'light' ? silverAdvanceFinalLight : silverAdvanceFinalDark,
        // mode === "light" ? BasicSiLightInactive : BasicSiDarkInactive,
        hoveredBgImg: mode === 'light' ? silverAdvanceFinalLight : silverAdvanceFinalDark,
        activeBgImg: mode === 'light' ? silverAdvanceFinalLight : silverAdvanceFinalDark,
        // subHeadingBgColor: "rgba(0, 168, 148, 0.25)",
        subHeadingBgColor: mode === 'light' ? 'rgba(149, 149, 149, 0.30)' : ' rgba(255, 255, 255, 0.10)',
        hoverButtonbg: 'rgba(105, 105, 105, 0.90)',
        // hoverButtonbg: "rgba(0, 168, 148, 0.29)",
        selectedButtonbg: 'rgba(105, 105, 105, 0.90)',
        // selectedButtonbg: "#00A894",
        textColor: mode === 'light' ? '#000000' : '#ffffff'
      };
    }
    case 'Pro+': {
      return {
        color: '#B1B1B1',
        // color: "#2A9EED",
        // color: mode === "light" ? "black" : "gray",
        icon: mode === 'light' ? silverproicon : silverproicon,
        // bgImg: SilverProInactiveIcon
        inactiveBgImg: mode === 'light' ? silverProFinalLight : silverProFinalDark,
        // mode === "light" ? BasicSiLightInactive : BasicSiDarkInactive,
        hoveredBgImg: mode === 'light' ? silverProFinalLight : silverProFinalDark,
        activeBgImg: mode === 'light' ? silverProFinalLight : silverProFinalDark,
        // subHeadingBgColor: "rgba(42, 158, 237, 0.5)",
        subHeadingBgColor: mode === 'light' ? 'rgba(149, 149, 149, 0.30)' : ' rgba(255, 255, 255, 0.10)',

        hoverButtonbg: 'rgba(105, 105, 105, 0.90)',
        // hoverButtonbg: "rgba(42, 158, 237, 0.29)",
        selectedButtonbg: 'rgba(105, 105, 105, 0.90)',
        // selectedButtonbg: "#2A9EED",
        textColor: mode === 'light' ? '#000000' : '#ffffff'
      };
    }
    case 'Supreme': {
      return {
        color: '#ECC44F',
        icon: mode === 'light' ? SupremeIcon : SupremeIcon,
        // ? SilverBasicIcon
        // : SilverBasicIcon,
        // bgImg: SilverBasicInactiveIcon,
        inactiveBgImg: mode === 'light' ? GoldLightInactiveBgImg : GoldDarkInactiveBgImg,
        hoveredBgImg: mode === 'light' ? GoldLightInactiveBgImg : GoldDarkInactiveBgImg,
        subHeadingBgColor: 'rgba(194, 165, 82, 0.3)',
        selectedButtonbg: 'rgba(241, 209, 118, 0.9',
        hoverButtonbg: 'rgba(42, 158, 237, 0.29)',

        textColor: mode === 'light' ? '#000000' : '#ffffff'
        // hoveredBgImg: BasicSiHover
      };
    }
    case 'Elite': {
      return {
        // color: theme.palette.investment.elite,
        color: '#C111FF',
        icon: mode === 'light' ? EliteIcon : EliteIcon,
        activeBgImg: EliteFooterBgImg,
        inactiveBgImg: mode === 'light' ? PlatinumCardbg : EliteFooterBgImg,
        inActiveIcon: EliteIcon,
        activeIcon: EliteIcon,
        subHeadingBgColor: 'rgba(255, 255, 255, 0.1)',
        hoveredBgImg: mode === 'light' ? PlatinumCardbg : EliteFooterBgImg,
        hoverButtonbg: 'rgba(241, 209, 118, 0.29',
        // selectedButtonbg: "rgba(241, 209, 118, 0.29",
        textColor: mode === 'light' ? '#000000' : '#ffffff'
      };
    }
    default: {
      return {
        color: '#00922B',
        // icon: BasicActiveIcon,
        icon: basicicon,
        inactiveBgImg: mode === 'light' ? standardBasicLightFinal : standardBasicDarkFinal,
        hoveredBgImg: mode === 'light' ? standardBasicLightFinal : standardBasicDarkFinal,
        activeBgImg: mode === 'light' ? standardBasicLightFinal : standardBasicDarkFinal,
        subHeadingBgColor: ' rgba(0, 146, 43, 0.25)',
        hoverButtonbg: 'rgba(0, 146, 43, 0.29)',
        selectedButtonbg: '#00922B',
        textColor: mode === 'light' ? '#000000' : '#ffffff'
        // icon:
      };
    }
  }
};

const cardIterationNumber = (productTypeId) => {
  switch (productTypeId) {
    case 1: {
      return { startIndex: 1, endIndex: 3 };
    }
    case 2: {
      return { startIndex: 4, endIndex: 6 };
    }
    case 3: {
      return { startIndex: 7, endIndex: 7 };
    }
    case 4: {
      return { startIndex: 8, endIndex: 8 };
    }
    default: {
      devConsoleLog('Invalid choice');
      break;
    }
  }
};

const InvestmentProductCards = ({ cardsData, products, productTypeId, infoCard }) => {
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);

  const dispatch = useDispatch();
  const theme = useTheme();
  const userProduct = useSelector((state) => state.investmentProducts.userProduct);
  // const userProduct = useSelector(
  //   (state) => state.investmentProducts.myPlanResponse?.data?.data?.userProduct
  // );

  // const uniqueId = localStorage.getItem("uniqueId")
  devConsoleLog('userProduct', userProduct, products);
  const mobile360 = useMediaQuery(theme.breakpoints.down('sm'));
  // const {loading: productLoading,data: productData,fetchData} = useFetch(getInvestmentModule(uniqueId));

  // useEffect(() => {
  //   dispatch(setUserProduct(productData?.data?.userProduct));
  // }, [productData?.data?.userProduct, dispatch]);

  // devConsoleLog("userProduct=>",userProduct);
  const id = userProduct ? userProduct?.id : 0;
  const infoCarditeration = infoCard ? 8 : 6;
  try {
    return (
      <FreedomCardsWrapper mode={mode} padding={'0px 15px'}>
        <Grid container spacing={mobile360 ? 1 : 3}>
          {products?.length
            ? cardsData?.map((card, idx) => {
                let cardProps = cardPropsSelector(card.cardName, mode);
                let iteration = cardIterationNumber(productTypeId);
                return (
                  <Fragment key={card.cardName}>
                    {card?.apiData?.id >= iteration.startIndex && card?.apiData?.id <= iteration.endIndex && iteration.startIndex <= infoCarditeration ? (
                      <Grid item xs={12} sm={iteration.startIndex > 6 && infoCard ? 12 : 6} md={iteration.startIndex > 6 && infoCard ? 12 : 6} lg={iteration.startIndex > 6 && infoCard ? 12 : 4} key={idx}>
                        {iteration.startIndex <= infoCarditeration && <InvestmentProductCard cardProps={cardProps} card={card} cardStatus subscribed={id === card?.apiData?.id ? true : false} id={id} infoCard={infoCard} />}
                      </Grid>
                    ) : null}
                  </Fragment>
                );
              })
            : null}
          {productTypeId === 3 && !infoCard ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <InvestmentGoldProducts />
            </Grid>
          ) : null}
          {productTypeId === 4 && !infoCard ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <InvestmentPlatinumProducts />
            </Grid>
          ) : null}
        </Grid>
      </FreedomCardsWrapper>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default InvestmentProductCards;
