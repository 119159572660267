export const gender = [
  {
    id: 1,
    title: 'Male',
    Name: 'Male'
  },
  {
    id: 2,
    title: 'Female',
    Name: 'Female'
  }
];

export const preferredLanguage = [
  {
    id: 1,
    label: 'English',
    value: 'english'
  }
  // {
  // 	id: 2,
  // 	label: 'Danish',
  // 	value: 'danish'
  // }
];

export const preferredWalletPaymentOption = [
  {
    id: 1,
    title: 'Credit Card'
  },
  {
    id: 2,
    title: 'Bank Account'
  },

  {
    id: 3,
    title: 'Crypto'
  }

  // { title: "Wallet" },

  // { title: "Wallet" },
];
export const paymentMethodToBeRemoved = [
  {
    id: 1,
    label: 'Bank',
    value: 'Bank'
  },

  {
    id: 2,
    label: 'Card',
    value: 'Card'
  }
];

export const Borkers = [
  { id: 1, label: 'Pepperstone', value: 'Pepperstone' },
  { id: 2, label: 'Pepperstone', value: 'Pepperstone' },
  { id: 3, label: 'Pepperstone', value: 'Pepperstone' }
];
export const preferredPlatform = [
  { id: 1, label: 'Email', value: 'Email' }
  // { id: 2, label: "WhatsApp", value: "WhatsApp" },
];

export const tradeHistory = [
  {
    id: 1,
    label: 'All History',
    value: 'All History'
  },
  {
    id: 2,
    label: 'Today',
    value: 'Today'
  },
  {
    id: 3,
    label: 'Yesterday',
    value: 'Yesterday'
  },
  {
    id: 4,
    label: 'Current Week',
    value: 'Current Week'
  },
  {
    id: 5,
    label: 'Current Month',
    value: 'Current Month'
  },
  {
    id: 6,
    label: 'Custom',
    value: 'Custom'
  }
];

export const tradeDirections = [
  {
    id: 1,
    label: 'All Direction',
    value: ''
  },
  {
    id: 2,

    label: 'Sell',
    value: 'Sell'
  },
  {
    id: 3,
    label: 'Buy',
    value: 'Buy'
  }
];

export const fullleg = [
  // { title: "Auto", value: "Auto" },
  { title: 'Left Leg', value: 'Left' },
  { title: 'Right Leg', value: 'Right' }
];

export const fullleg1 = [
  // { title: "Auto", value: "Auto" },
  { title: 'Left Leg', value: 'Left' },
  { title: 'Right Leg', value: 'Right' },
  { title: 'Select Later', value: 'Later' }
];

// export const selectPlatform = [
//   { title: 'Whatspp', value: 'Whatspp' },
//   { title: 'Email', value: 'Email' },
//   { title: 'Discord', value: 'Discord' }
// ];

export const countryTemplates = [
  {
    id: 1,
    textPlaceholder: 'Computerised National Identity Card',
    label: 'Pakistan'
  },
  {
    id: 2,
    textPlaceholder: 'Personal Identification Number',
    label: 'Denmark'
  },
  { id: 3, textPlaceholder: 'Social Security Code', label: 'United State' }
];

export const monthOfYear = [
  { id: '01', Name: 'January' },
  { id: '02', Name: 'February' },
  { id: '03', Name: 'March' },
  { id: '04', Name: 'April' },
  { id: '05', Name: 'May' },
  { id: '06', Name: 'June' },
  { id: '07', Name: 'July' },
  { id: '08', Name: 'August' },
  { id: '09', Name: 'September' },
  { id: '10', Name: 'October' },
  { id: '11', Name: 'November' },
  { id: '12', Name: 'December' }
];

export const BinaryPointStatus = [
  {
    id: 0,
    label: 'All',
    value: 'All'
  },
  {
    id: 1,
    label: 'Active',
    value: 'Active'
  },
  {
    id: 2,
    label: 'Historic',
    value: 'Historic'
  },
  {
    id: 3,
    label: 'Flushed',
    value: 'Flushed'
  }
];
export const directCommissionStatus = [
  {
    id: 0,
    label: 'All',
    value: 'All'
  },
  // {
  //   id: 1,
  //   label: "Active",
  //   value: "Active",
  // },
  {
    id: 1,
    label: 'Pending',
    value: 'Pending'
  },
  {
    id: 2,
    label: 'Cancelled',
    value: 'Cancelled'
  },
  {
    id: 3,
    label: 'Settled',
    value: 'Settled'
  }
];

export const BinaryCommissionPaycircleStatus = [
  {
    id: 0,
    label: 'All',
    value: 'All'
  },
  {
    id: 1,
    label: 'Active',
    value: 'Active'
  },
  {
    id: 2,
    label: 'Settled',
    value: 'Settled'
  }
  // {
  //   id: 3,
  //   label: "Cancelled",
  //   value: "Cancelled",
  // },
  // {
  //   id: 4,
  //   label: "Pending",
  //   value: "Pending",
  // },
  // {
  //   id: 5,
  //   label: "Postponed",
  //   value: "Postponed",
  // },
];
export const BinaryCommissionTrasactionLevelTwo = [
  {
    id: 0,
    label: 'All',
    value: 'All'
  },
  {
    id: 1,
    label: 'Pending',
    value: 'Pending'
  },
  {
    id: 2,
    label: 'Postponed',
    value: 'Postponed'
  },
  {
    id: 3,
    label: 'Cancelled',
    value: 'Cancelled'
  },
  {
    id: 4,
    label: 'Settled',
    value: 'Settled'
  }
];
export const BinaryCommissionPayoutNumberStatus = [
  {
    id: 0,
    label: 'All',
    value: 'All'
  },

  {
    id: 2,
    label: 'Settled',
    value: 'Settled'
  },
  {
    id: 3,
    label: 'Cancelled',
    value: 'Cancelled'
  },
  {
    id: 4,
    label: 'Pending',
    value: 'Pending'
  },
  {
    id: 5,
    label: 'Postponed',
    value: 'Postponed'
  }
];
export const EarningSummaryStatus = [
  {
    id: 0,
    label: 'Search By All',
    value: 'Search By All'
  },
  {
    id: 1,
    label: 'Search By Type',
    value: 'Search By Type'
  },
  {
    id: 2,
    label: 'Search By Paycircle',
    value: 'Search By Paycircle'
  },
  {
    id: 3,
    label: 'Search By PayoutDate',
    value: 'Search By PayoutDate'
  }
];
export const BinaryCommissionTransactionStatus = [
  {
    id: 0,
    label: 'All',
    value: 'All'
  },

  {
    id: 1,
    label: 'Settled',
    value: 'Settled'
  },

  {
    id: 3,
    label: 'Pending',
    value: 'Pending'
  }
];
export const SuspendedBinaryPointStatus = [
  {
    id: 0,
    label: 'All',
    value: 'All'
  },

  {
    id: 1,
    label: 'Left Leg Side Points',
    value: 'Left Leg Side Points'
  },

  {
    id: 3,
    label: 'Right Leg Side Points',
    value: 'Right Leg Side Points'
  },
  {
    id: 3,
    label: 'Investor Points',
    value: 'Investor Points'
  },
  {
    id: 4,
    label: 'Affiliates points',
    value: 'Affiliates points'
  }
];
export const matchingBonusesStatus = [
  {
    id: 0,
    label: 'All',
    value: 'All'
  },

  // {
  //   id: 1,
  //   label: "Active",
  //   value: "Active",
  // },
  {
    id: 1,
    label: 'Pending',
    value: 'Pending'
  },
  {
    id: 2,
    label: 'Settled',
    value: 'Settled'
  },
  {
    id: 3,
    label: 'Cancelled',
    value: 'Cancelled'
  }
];
