import React from 'react';
import { makeStyles } from '@mui/styles';
import { Radio, useTheme } from '@mui/material';
import { appInsights } from '../../../auth/appInsight';

const useStyles = makeStyles((theme) => ({
  // root: {
  //     padding: "10px",
  // }
}));

export const FreedomRadioButton1 = (props) => {
  const { checked, height, width, radioiconsize, margin } = props;
  const classes = useStyles();
  const theme = useTheme();
  // debugger
  try {
    return (
      <Radio
        {...props}
        // size="small"
        sx={{
          background: 'transparent',
          margin: margin || '0px',
          height: `${height}px`,
          width: `${width}px`,
          borderRadius: '7px',
          border: `2px solid ${theme.palette.border.border}`,
          color: theme.palette.border.border,
          '& .MuiSvgIcon-root': {
            fontSize: radioiconsize || 18
          },
          cursor: 'pointer',
          // "&:hover": {
          //   background: theme.palette.primary.white,
          // },
          // "&.Mui-checked:hover": {
          //   background: theme.palette.primary.white,
          // },
          '&.Mui-checked': {
            color: theme.palette.border.border,
            background: theme.palette.background.default
          }
        }}
        // sx={{
        //   "&, &.Mui-checked": {
        //     color: radiocolor,
        //   },
        // }}
      />
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};
