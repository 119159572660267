/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import { store } from './store/store';
import './Components/Translation/i18n';
import { ColorContextProvider } from './store';
import { msalConfig } from './auth/authConfig';
import reportWebVitals from './reportWebVitals';
import { msalConfigSignIn } from './auth/signInAuthConfig';
import { PublicClientApplication } from '@azure/msal-browser';

const msalInstance = new PublicClientApplication(msalConfig);
const msalInstanceSignIn = new PublicClientApplication(msalConfigSignIn);

const query = new URLSearchParams(window.location.search);

const inviteCode = query.get('inv');

const msal = inviteCode !== null ? msalInstance : msalInstanceSignIn;

ReactDOM.render(
  <ColorContextProvider>
    <BrowserRouter>
      <Provider store={store}>
        <MsalProvider instance={inviteCode !== null ? msalInstance : msalInstanceSignIn}>
          <App />
        </MsalProvider>
      </Provider>
    </BrowserRouter>
  </ColorContextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(devConsoleLog))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
