import { createSlice } from '@reduxjs/toolkit';
import { devConsoleLog } from '../../utils/utilityFunctions';
import moment from 'moment';

const todayDate = moment().format('YYYY-MM-DD');
const lastYearDate = moment(todayDate).subtract(1, 'years').format('YYYY-MM-DD');

const initialState = {
  fullName: '',
  userPrincipleName: '',
  uniqueId: '',
  isActiveUser: true,
  Id: 0,
  profileImage: '',
  memberId: '',
  daysDiff: 29,
  distributorSelectId: 0,
  binaryNodeSelectedId: 0,
  accessToken: '',
  inviteCode: 0,
  UserType: 1,
  userStatus: 1,
  ParentUserLeg: null,
  InvitationPlatformType: null,
  ParentUserId: 0,
  isOnboardingCompleted: false,
  isLogout: false,
  platformId: '',
  dataLoading: true,
  theme: 'dark',
  mobReqUserId: 0,
  mobReqToken: '',
  rowNumber: 0,
  primaryCard: {
    PrimaryPaymentMethodId: null,
    PrimaryPaymentMethodType: null
  },
  accountTypeId: null,
  profileType: null,
  userLevel: null,
  stripePromise: null,
  termsAndConditionsAgreed: false,
  privacyPolicyAgreed: false,
  riskAndWarningAgreed: false,
  affiliateAgreementAgreed: false,
  transactionFee: 2,
  loginUser: [],
  user: {},
  startDate: lastYearDate,
  endDate: todayDate,
  alternateEmail: ''
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserName: (state, action) => {
      state.fullName = action.payload;
    },
    updateUserPrincipleName: (state, action) => {
      state.userPrincipleName = action.payload;
    },
    updateUniqueId: (state, action) => {
      state.uniqueId = action.payload;
    },
    updateProfileImage: (state, action) => {
      state.profileImage = action.payload;
    },
    updateIsActive: (state, action) => {
      state.isActiveUser = action.payload;
    },
    updateDataBaseId: (state, action) => {
      state.Id = action.payload;
    },
    updateRowNumber: (state, action) => {
      state.rowNumber = action.payload;
    },

    updateDayDiff: (state, action) => {
      state.daysDiff = action.payload;
    },
    updateDistributorSelectId: (state, action) => {
      state.distributorSelectId = action.payload;
    },
    updateBinaryNodeSelectedId: (state, action) => {
      state.binaryNodeSelectedId = action.payload;
    },
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    updateInviteCode: (state, action) => {
      state.inviteCode = action.payload;
    },
    updateUserType: (state, action) => {
      state.UserType = action.payload;
    },
    updateUserStatus: (state, action) => {
      state.userStatus = action.payload;
    },
    updateParentUserLeg: (state, action) => {
      state.ParentUserLeg = action.payload;
    },
    updateInvitationPlatformType: (state, action) => {
      state.InvitationPlatformType = action.payload;
    },
    updateUserId: (state, action) => {
      state.ParentUserId = action.payload;
    },
    updateOnBoardingComplete: (state, action) => {
      state.isOnboardingCompleted = action.payload;
    },
    updateOnLogout: (state, action) => {
      state.isLogout = action.payload;
    },
    updatePlatFormId: (state, action) => {
      state.platformId = action.payload;
    },
    updateIsEmptyInviteCode: (state, action) => {
      state.isEmptyInviteCode = action.payload;
    },
    updateMemberId: (state, action) => {
      state.memberId = action.payload;
    },
    updateDataLoading: (state, action) => {
      state.dataLoading = action.payload;
    },
    updateTheme: (state, action) => {
      state.theme = action.payload;
    },
    //mob request
    updateMobUserId: (state, action) => {
      state.mobReqUserId = action.payload;
    },
    updateMobToken: (state, action) => {
      state.mobReqToken = action.payload;
    },
    updatePrimaryCard: (state, action) => {
      state.primaryCard = action.payload;
    },
    updateAccountTypeId: (state, action) => {
      state.accountTypeId = action.payload;
    },
    updateProfileType: (state, action) => {
      state.profileType = action.payload;
    },
    updateUserLevel: (state, action) => {
      state.userLevel = action.payload;
    },
    updateStripePublishableKey: (state, action) => {
      state.stripePromise = action.payload;
    },
    updateTermsAndConditionsAgreed: (state, action) => {
      state.termsAndConditionsAgreed = action.payload;
    },
    updatePrivacyPolicyAgreed: (state, action) => {
      state.privacyPolicyAgreed = action.payload;
    },
    updateRiskAndWarningAgreed: (state, action) => {
      state.riskAndWarningAgreed = action.payload;
    },
    updateAffiliateAgreementAgreed: (state, action) => {
      state.affiliateAgreementAgreed = action.payload;
    },
    updateTransactionFee: (state, action) => {
      state.user = { ...state.user, transactionFee: action.payload };
    },
    updateUserThemeOther: (state, action) => {
      state.user = {
        ...state.user,
        themeOther: action.payload
      };
    },

    updateLoginUser: (state, action) => {
      state.loginUser = action.payload;
    },
    updateUser: (state, action) => {
      devConsoleLog('update user', action.payload);
      state.user = action.payload;
    },
    updateUserInformation: (state, action) => {
      devConsoleLog('updated information', action.payload);
      state.user = {
        ...state.user,
        userType: action.payload.userType,
        invitationPlatformType: action.payload.invitationPlatformType,
        parentUserLeg: action.payload.parentUserLeg
      };
    },
    updateStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    updateEndEnd: (state, action) => {
      state.endDate = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  updateUserName,
  updateUserPrincipleName,
  updateOnBoardProcess,
  updateUniqueId,
  updateIsActive,
  updateDataBaseId,
  updateRowNumber,
  updateDayDiff,
  updateDistributorSelectId,
  updateBinaryNodeSelectedId,
  updateAccessToken,
  updateInviteCode,
  updateUserType,
  updateParentUserLeg,
  updateInvitationPlatformType,
  updateUserId,
  updateProfileImage,
  updateTheme,
  updateOnBoardingComplete,
  updateOnLogout,
  updatePlatFormId,
  updateMemberId,
  updateDataLoading,
  updateMobUserId,
  updateMobToken,
  updatePrimaryCard,
  updateAccountTypeId,
  updateProfileType,
  updateUserLevel,
  updateStripePublishableKey,
  updateTermsAndConditionsAgreed,
  updatePrivacyPolicyAgreed,
  updateRiskAndWarningAgreed,
  updateAffiliateAgreementAgreed,
  updateTransactionFee,
  updateLoginUser,
  updateUser,
  updateUserInformation,
  updateStartDate,
  updateEndEnd,
  updateUserThemeOther
} = userSlice.actions;

export default userSlice.reducer;
