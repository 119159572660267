import { de } from 'date-fns/locale';
import Payment from 'payment';

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);

  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, cardNumber, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 3;
  if (cardNumber) {
    const issuer = Payment.fns.cardType(cardNumber);
    switch (issuer) {
      case 'hipercard':
        maxLength = 4;
        break;
      case 'amex':
        maxLength = 4;
        break;
      default:
        maxLength = 3;
        break;
    }
  }
  const val = clearValue.slice(0, maxLength);
  // debugger
  return val;
}

// export function formatCVC(value, prevValue, allValues = {}) {
//   const clearValue = clearNumber(value);
//   let maxLength = 4;
//   debugger
//   if (allValues.number) {
//     const issuer = Payment.fns.cardType(allValues.number);
//     maxLength = issuer === "amex" ? 4 : 3;
//   }
//    const val = clearValue.slice(0, maxLength);
//     debugger
//    return val;
// }

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);
  const date = new Date();
  let utcYear = date.getUTCFullYear();
  let utcMonth = date.getUTCMonth();
  if (clearValue.length >= 3) {
    if (clearValue.slice(0, 2) > 12) {
      return `${12}/${clearValue.slice(2, 4)}`;
    }
    if (clearValue.length >= 4) {
      if (clearValue.slice(2, 4) < `${utcYear}`.slice(2, 4)) {
        if (clearValue.slice(0, 2) < `${utcMonth + 1}`) {
          return `${12}/${`${utcYear}`.slice(2, 4)}`;
        }
        return `${clearValue.slice(0, 2)}/${`${utcYear}`.slice(2, 4)}`;
      }
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}
