import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import FreedomButton1 from '../FreedomButton1/FreedomButton1';
import { CheckCircleOutline } from '@mui/icons-material';
import FreedomFollowUpIcon from '../FreedomFollowUpIcon/FreedomFollowUpIcon';
import { ColorModeContext } from '../../../store';
import { useContext } from 'react';
import { appInsights } from '../../../auth/appInsight';
import { FixedAfterTwoDigit1 } from '../../../utils/utilityFunctions';
import { images, upgradeAccount, voucherSvg } from '../../../url/SvgLinks';

const gold = images.gold;
const platinum = images.platinum;

const darkgold = voucherSvg.darkgold;
const lightsilver = voucherSvg.lightsilver;
const darksilver = voucherSvg.darksilver;
const lightgold = voucherSvg.lightgold;
const lightplatinum = voucherSvg.lightplatinum;
const darkplatinum = voucherSvg.darkplatinum;
const lightsilvertogold = voucherSvg.lightsilvertogold;
const darksilvertogold = voucherSvg.darksilvertogold;
const lightsilvertoplatinum = voucherSvg.lightsilvertoplatinum;
const darksilvertoplatinum = voucherSvg.darksilvertoplatinum;
const lightgoldtoplatinum = voucherSvg.lightgoldtoplatinum;
const darkgoldtoplatinum = voucherSvg.darkgoldtoplatinum;
const exclusivevoucherdark = voucherSvg.exclusivevoucherdark;
const exclusivevoucherlight = voucherSvg.exclusivevoucherlight;

const vipvoucherdark = voucherSvg.vipvoucherdark;
const vipvoucherlight = voucherSvg.vipvoucherlight;
const exclusivetovipvoucherdark = voucherSvg.exclusivetovipvoucherdark;
const exclusivetovipvoucherlight = voucherSvg.exclusivetovipvoucherlight;

const silver = images.silver;
const exclusive = upgradeAccount.ExclusiveAccountTypeIcon;
const vip = upgradeAccount.VipAccountTypeIcon;
const voucherCards = (title, mode) => {
  return title === 'Silver'
    ? mode === 'light'
      ? `url(${lightsilver})`
      : `url(${darksilver})`
    : title === 'Gold'
    ? mode === 'light'
      ? `url(${lightgold})`
      : `url(${darkgold})`
    : title === 'Platinum'
    ? mode === 'light'
      ? `url(${lightplatinum})`
      : `url(${darkplatinum})`
    : title === 'Silver To Gold'
    ? mode === 'light'
      ? `url(${lightsilvertogold})`
      : `url(${darksilvertogold})`
    : title === 'Silver To Platinum'
    ? mode === 'light'
      ? `url(${lightsilvertoplatinum})`
      : `url(${darksilvertoplatinum})`
    : title === 'Gold To Platinum'
    ? mode === 'light'
      ? `url(${lightgoldtoplatinum})`
      : `url(${darkgoldtoplatinum})`
    : title === 'Exclusive'
    ? mode === 'light'
      ? `url(${exclusivevoucherlight})`
      : `url(${exclusivevoucherdark})`
    : title === 'VIP'
    ? mode === 'light'
      ? `url(${vipvoucherlight})`
      : `url(${vipvoucherdark})`
    : title === 'Exclusive To VIP'
    ? mode === 'light'
      ? `url(${exclusivetovipvoucherlight})`
      : `url(${exclusivetovipvoucherdark})`
    : '';
};

const VoucherWrapper = styled(Box)(({ theme, mode, title, ischecked, background, bordercolor }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  padding: '12px 14px 17px 19px',
  backgroundImage: voucherCards(title, mode),
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',

  borderRadius: '10px'
  // [theme.breakpoints.up('xll')]: {
  //   minHeight: '200px',
  // }
}));

const borderStyle = (title, mode, theme) => {
  return title === 'Silver'
    ? mode === 'light'
      ? '2px solid #B1B1B1'
      : '2px solid #505050'
    : title === 'Gold'
    ? mode === 'light'
      ? '2px solid #CFA632'
      : '2px solid #D0A935'
    : title === 'Platinum'
    ? mode === 'light'
      ? '2px solid #BC10F9'
      : '2px solid #AF0DEB'
    : title === 'Silver To Gold'
    ? mode === 'light'
      ? '2px solid #D5BF82'
      : '2px solid #C1AC70'
    : title === 'Silver To Platinum'
    ? mode === 'light'
      ? '2px solid #B260C5'
      : '2px solid #CC7BE1'
    : title === 'Gold To Platinum'
    ? mode === 'light'
      ? '2px solid #B5517F'
      : '2px solid #B6507F'
    : title === 'Exclusive'
    ? mode === 'light'
      ? `2px solid ${theme.palette.primary.exclusivebgbutton}`
      : `2px solid ${theme.palette.primary.exclusivebgbutton}`
    : title === 'VIP'
    ? mode === 'light'
      ? `2px solid ${theme.palette.primary.vipbgbutton}`
      : `2px solid ${theme.palette.primary.vipbgbutton}`
    : title === 'Exclusive To VIP'
    ? mode === 'light'
      ? `2px solid ${theme.palette.primary.exclusivebgbutton}`
      : `2px solid ${theme.palette.primary.exclusivebgbutton}`
    : '';
};

const UpCircle = styled('div')(({ theme, ischecked, title, mode }) => ({
  position: 'absolute',
  left: '60%',
  top: '-15px',
  width: '28px',
  height: '28px',
  borderRadius: ' 50%',
  border: borderStyle(title, mode, theme),
  borderTopColor: 'transparent',
  borderLeftColor: 'transparent',
  transform: 'rotate(45deg)',
  // background: theme.palette.mode === 'light' ? `linear-gradient(180deg, ${theme.palette.background.wrappereBottom} 0%, ${theme.palette.background.wrapperbgTop} 100%)` : theme.palette.background.default,
  background: theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.background.default
}));

const DownCircle = styled('div')(({ theme, ischecked, title, mode }) => ({
  position: 'absolute',
  left: '60%',
  bottom: '-14.9px',
  width: '28px',
  height: '28px',
  borderRadius: ' 50%',
  // border: `1px solid ${
  //   ischecked ? theme.palette.primary.textcolor : theme.palette.primary.main
  // }`,
  border: `${
    title === 'Silver'
      ? mode === 'light'
        ? '2px solid #B1B1B1'
        : '2px solid #505050'
      : title === 'Gold'
      ? mode === 'light'
        ? '2px solid #CEA631'
        : '2px solid #CEA631'
      : title === 'Platinum'
      ? mode === 'light'
        ? '2px solid #C111FF'
        : '2px solid #C111FF'
      : title === 'Silver To Gold'
      ? mode === 'light'
        ? '2px solid #D5B762'
        : '2px solid #CAAD57'
      : title === 'Silver To Platinum'
      ? mode === 'light'
        ? '2px solid #AC33CB'
        : '2px solid #B93FD8'
      : title === 'Gold To Platinum'
      ? mode === 'light'
        ? '2px solid #AC2BA5'
        : '2px solid #AC2AA6'
      : title === 'Exclusive'
      ? mode === 'light'
        ? `2px solid ${theme.palette.primary.exclusivebgbutton}`
        : `2px solid ${theme.palette.primary.exclusivebgbutton}`
      : title === 'VIP'
      ? mode === 'light'
        ? `2px solid ${theme.palette.primary.vipbgbutton}`
        : `2px solid ${theme.palette.primary.vipbgbutton}`
      : title === 'Exclusive To VIP'
      ? mode === 'light'
        ? `2px solid ${theme.palette.primary.exclusivebgbutton}`
        : `2px solid ${theme.palette.primary.exclusivebgbutton}`
      : ''
  }`,
  borderTopColor: 'transparent',
  borderLeftColor: 'transparent',
  transform: 'rotate(225deg)',
  // background: theme.palette.mode === 'light' ? `linear-gradient(180deg, ${theme.palette.background.wrappereBottom} 0%, ${theme.palette.background.wrapperbgTop} 100%)` : theme.palette.background.default,
  background: theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.background.default
}));
const DottedLines = styled('div')(({ theme, title, mode }) => ({
  position: 'absolute',
  left: '63.5%',
  top: '21px',
  // width: "2px",
  // height: "85%",
  // border: "1px dashed #000000",
  // padding:"30px"
  // rotate: '90deg'
  width: '1px',
  height: '76%',
  backgroundImage:
    title === 'Silver'
      ? mode === 'light'
        ? 'linear-gradient(to bottom, #626262 60%, transparent 40%)'
        : 'linear-gradient(to bottom, #FFFFFF 60%, transparent 40%)'
      : title === 'Platinum'
      ? mode === 'light'
        ? 'linear-gradient(to bottom, #C111FF 60%, transparent 40%)'
        : 'linear-gradient(to bottom, #C111FF 60%, transparent 40%)'
      : title === 'Exclusive'
      ? `linear-gradient(to bottom, ${theme.palette.primary.exclusivebgbutton} 60%, transparent 40%)`
      : title === 'Exclusive To VIP'
      ? `linear-gradient(to bottom, ${theme.palette.primary.exclusivebgbutton} 60%, transparent 40%)`
      : title === 'VIP'
      ? `linear-gradient(to bottom, ${theme.palette.primary.vipbgbutton} 60%, transparent 40%)`
      : 'linear-gradient(to bottom, #ECC44F 60%, transparent 40%)',
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat-y',
  backgroundSize: '6px 20px'
}));

const VoucherTitle = styled('div')(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '16px',
  // lineHeight: '30px',
  color: theme.palette.primary.onBoardTextColor,
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px'
    // lineHeight: '30px',
  }
  // [theme.breakpoints.up("xlll")]: {
  //   fontSize: "32px",
  //   // lineHeight: '30px',
  // },
}));
const VoucherPrice = styled('div')(({ theme, fontsize = '16px' }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: fontsize || '32px',
  // lineHeight: "48px",
  lineHeight: '30px',
  textAlign: 'right',
  color: theme.palette.primary.onBoardTextColor,
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
    // lineHeight: "36px",
    lineHeight: '15px'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
    lineHeight: '32px'
    // lineHeight: "24px",
  },
  [theme.breakpoints.only('xll')]: {
    // fontSize: "24px",
    // lineHeight: "36px",
    lineHeight: '31px'
  },
  [theme.breakpoints.up('xlll')]: {
    // fontSize: "32px",
    lineHeight: '48px'
  }
}));

const VoucherDescription = styled('div')(({ theme, ischecked }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '10px',
  lineHeight: '15px',
  color: theme.palette.primary.onBoardTextColor,
  // mariginTop: '-6px',
  // color: ischecked
  //   ? theme.palette.primary.textcolor
  //   : theme.palette.primary.voucherColor,
  [theme.breakpoints.down('sm')]: {
    // mariginTop: '-6px',
    fontSize: '10px',
    lineHeight: '15px'
  },
  [theme.breakpoints.up('xll')]: {
    fontSize: '12px',
    lineHeight: 1.5
  }
  // [theme.breakpoints.up("xlll")]: {
  //   fontSize: "14px",
  //   lineHeight: 1.5,
  // },
}));

const ExpiryDateText = styled('div')(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '18px',
  textAlign: 'right',
  color: theme.palette.primary.onBoardTextColor,
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    lineHeight: '15px'
  }
}));

const CardBorder = styled('div')(({ theme, title, mode }) => ({
  border:
    title === 'Silver'
      ? mode === 'light'
        ? '2px solid #B1B1B1'
        : '2px solid #505050'
      : title === 'Gold'
      ? mode === 'light'
        ? '2px solid #CEA631'
        : '2px solid #CEA631'
      : title === 'Platinum'
      ? mode === 'light'
        ? '2px solid #C111FF'
        : '2px solid #C111FF'
      : title === 'Exclusive'
      ? mode === 'light'
        ? `2px solid ${theme.palette.primary.exclusivebgbutton}`
        : `2px solid ${theme.palette.primary.exclusivebgbutton}`
      : title === 'VIP'
      ? mode === 'light'
        ? `2px solid ${theme.palette.primary.vipbgbutton}`
        : `2px solid ${theme.palette.primary.vipbgbutton}`
      : '' /* set the border width and make it transparent  theme.palette.primary.exclusivebgbutton*/,
  background:
    title === 'Silver To Gold'
      ? mode === 'light'
        ? 'linear-gradient(-40deg, #CEA631, #DCDCDC)'
        : 'linear-gradient(-40deg, #CEA631, #B1B1B1)'
      : title === 'Silver To Platinum'
      ? mode === 'light'
        ? 'linear-gradient(-40deg, #9D00C5, #C8C8C8)'
        : 'linear-gradient(-40deg, #9D00C5, #FFFFFF)'
      : title === 'Gold To Platinum'
      ? mode === 'light'
        ? 'linear-gradient(-40deg, #9D00C5, #CEA631)'
        : 'linear-gradient(-40deg, #9D00C5, #CEA631)'
      : title === 'Exclusive To VIP'
      ? mode === 'light'
        ? `linear-gradient(-40deg, ${theme.palette.primary.vipbgbutton}, ${theme.palette.primary.exclusivebgbutton})`
        : `linear-gradient(-40deg, ${theme.palette.primary.vipbgbutton}, ${theme.palette.primary.exclusivebgbutton})`
      : '' /* set the border gradient */,
  // background: 'linear-gradient(-40deg, #CEA631, #DCDCDC)',
  borderImageSlice: 1 /* set the border image slice to 1 */,
  padding: title === 'Silver' || title === 'Gold' || title === 'Platinum' ? '0px' : '1px' /* add some padding to the div */,
  borderRadius: '10px'
}));

const ExpiryDate = styled('div')(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  textAlign: 'right',
  // marginRight: "8px",
  color: theme.palette.primary.onBoardTextColor,
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '18px'
  },
  [theme.breakpoints.only('xs')]: {
    marginBottom: '10px'
  }
}));

const FreedomVoucherCard = (props) => {
  const { bordercolor, ischecked, description, title, price, expirydate, clickOnCard, id } = props;
  //  debugger
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const mobile360 = useMediaQuery(theme.breakpoints.down('sm'));
  const xllScreen = useMediaQuery(theme.breakpoints.only('xlll'));
  const smonly = useMediaQuery(theme.breakpoints.only('sm'));
  try {
    return (
      <CardBorder title={title} mode={mode}>
        <VoucherWrapper
          mode={mode}
          title={title}
          // ischecked={ischecked}
          // bordercolor={bordercolor}
        >
          <UpCircle {...props} title={title} mode={mode} />
          <DownCircle {...props} title={title} mode={mode} />
          <DottedLines title={title} mode={mode} />
          <Grid container justifyContent={'space-between'}>
            <Grid item xs={7} sm={7} md={7} lg={7}>
              <Grid item container spacing={mobile360 ? 1 : 2}>
                {/* {title === "Silver" ||
                  title === "Gold" ||
                  title === "Platinum" ? (
                  <> */}
                <Grid item>
                  <Box
                    display={'flex'}
                    gap={'5px'}
                    alignItems={'center'}
                    // mt={mobile360 ? 1 : xllScreen ? 0 : 1}
                  >
                    {/* <Box> */}
                    <FreedomFollowUpIcon
                      disabled
                      padding="2px"
                      borderradius="6.10748px"
                      backgroundcolor={theme.palette.primary.default}
                      bordercolor={theme.palette.border.containerborder}
                      backgroundhovercolor={theme.palette.primary.white}
                      imageheight={'18px'}
                      imagewidth={'18px'}
                      iconimg={
                        title === 'Silver'
                          ? silver
                          : title === 'Gold'
                          ? gold
                          : title === 'Platinum'
                          ? platinum
                          : title === 'Silver To Gold'
                          ? gold
                          : title === 'Silver To Platinum'
                          ? platinum
                          : title === 'Gold To Platinum'
                          ? platinum
                          : title === 'Exclusive'
                          ? exclusive
                          : title === 'VIP'
                          ? vip
                          : title === 'Exclusive To VIP'
                          ? vip
                          : ''
                      }
                      width={'23.21px'}
                      height={'23.82px'}
                      responsiveheight={'20.18px'}
                      responsivewidth={'20.71x'}
                      responsiveborderrad={'5.31085px'}
                    />
                    {/* </Box>
                  <Box> */}
                    <VoucherTitle>{title}</VoucherTitle>
                    {/* </Box> */}
                  </Box>
                </Grid>
                {/* </>
                )  */}
                {/* : (
                  <Box>
                    <VoucherTitle>{title}</VoucherTitle>
                  </Box>
                )} */}
                <Grid item>
                  <VoucherDescription {...props}>{description}</VoucherDescription>
                </Grid>
              </Grid>

              {/* <Grid item xs={12}>
              <Grid item xs={12} ml={1} container spacing={1}>
                <Grid item>

                  <ul style={{padding: '0px'}}>
                    <VoucherDescHeading {...props}>
                    Lorem ipsum dolor sit amet .
                    </VoucherDescHeading>
                    </ul>
                  {/* <FreedomDot
                    width={"5px"}
                    height={"5px"}
                    dotbackgroundcolor={
                      ischecked
                        ? theme.palette.primary.textcolor
                        : theme.palette.primary.voucherColor
                    }
                  />
                </Grid>

                <Grid item>
                  <VoucherDescHeading {...props}>
                    Lorem ipsum dolor sit amet .
                  </VoucherDescHeading> */}
              {/* </Grid>
              </Grid>
              <Grid item xs={12} ml={1} container spacing={1}>
                <Grid item>
                <ul style={{padding: '0px'}}>
                    <VoucherDescHeading {...props}>
                    Lorem ipsum dolor sit amet .
                    </VoucherDescHeading>
                    </ul> */}
              {/* <FreedomDot
                    width={"5px"}
                    height={"5px"}
                    dotbackgroundcolor={
                      ischecked
                        ? theme.palette.primary.textcolor
                        : theme.palette.primary.voucherColor
                    }
                  />
                </Grid>

                <Grid item>
                  <VoucherDescHeading {...props}>
                    Lorem ipsum dolor sit amet .
                  </VoucherDescHeading> */}
              {/* </Grid>
              </Grid>
              <Grid item xs={12} ml={1} container spacing={1}>
                <Grid item mt={1}>
                <ul style={{padding: '0px'}}>
                    <VoucherDescHeading {...props}>
                    Lorem ipsum dolor sit amet .
                    </VoucherDescHeading>
                    </ul> */}
              {/* <FreedomDot
                    width={"5px"}
                    height={"5px"}
                    dotbackgroundcolor={
                      ischecked
                        ? theme.palette.primary.textcolor
                        : theme.palette.primary.voucherColor
                    }
                  />
                </Grid>

                <Grid item>
                  <VoucherDescHeading {...props}>
                    Lorem ipsum dolor sit amet .
                  </VoucherDescHeading> */}
              {/* </Grid>
              </Grid>
            </Grid> */}
            </Grid>
            <Grid item container xs={4} sm={4} md={4} lg={4} justifyContent={'right'} spacing={mobile360 ? 1 : 1.75}>
              <Grid item xs={12}>
                <VoucherPrice fontsize="20px">${FixedAfterTwoDigit1(+price)}</VoucherPrice>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <ExpiryDateText>
                    {/* Expiry Date */}
                    Validity:
                  </ExpiryDateText>
                  <ExpiryDate>{expirydate}</ExpiryDate>
                </Box>
              </Grid>
              {ischecked ? (
                <Grid item xs={12} sm={12} md={10}>
                  <FreedomButton1
                    // sx={{  mr: -12 }}
                    // btnhovercolor
                    // texthovercolor
                    btnbackground={
                      title === 'Silver'
                        ? theme.palette.button.vouchersilverbtncolor
                        : title === 'Gold'
                        ? theme.palette.button.vouchergoldbtncolor
                        : title === 'Platinum'
                        ? theme.palette.button.voucherplatinumbtncolor
                        : title === 'Silver To Gold'
                        ? theme.palette.button.vouchergoldbtncolor
                        : title === 'Silver To Platinum'
                        ? theme.palette.button.voucherplatinumbtncolor
                        : title === 'Gold To Platinum'
                        ? theme.palette.button.voucherplatinumbtncolor
                        : title === 'Exclusive'
                        ? theme.palette.primary.exclusivebgbutton
                        : title === 'VIP'
                        ? theme.palette.primary.vipbgbutton
                        : title === 'Exclusive To VIP'
                        ? theme.palette.primary.vipbgbutton
                        : ''
                    }
                    btncolor={theme.palette.primary.white}
                    btnhovercolor={
                      title === 'Silver'
                        ? theme.palette.button.vouchersilverbtncolor
                        : title === 'Gold'
                        ? theme.palette.button.vouchergoldbtncolor
                        : title === 'Platinum'
                        ? theme.palette.button.voucherplatinumbtncolor
                        : title === 'Silver To Gold'
                        ? theme.palette.button.vouchergoldbtncolor
                        : title === 'Silver To Platinum'
                        ? theme.palette.button.voucherplatinumbtncolor
                        : title === 'Gold To Platinum'
                        ? theme.palette.button.voucherplatinumbtncolor
                        : title === 'Exclusive'
                        ? theme.palette.primary.exclusivebgbutton
                        : title === 'VIP'
                        ? theme.palette.primary.vipbgbutton
                        : title === 'Exclusive To VIP'
                        ? theme.palette.primary.vipbgbutton
                        : ''
                    }
                    texthovercolor={'none'}
                    variant="contained"
                    height={'34px'}
                    // xlheight={"34px"}
                    // width={'88px'}
                    lineheight={'24px'}
                    fontsize={smonly ? '10px' : '12px'}
                    // xlfontsize={"12px"}
                    startIcon={<CheckCircleOutline />}
                    //  padding={"12px 16px"}
                    borderradius={'5px'}
                  >
                    {t('Selected')}
                  </FreedomButton1>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={10}
                  // mt={1}
                  // ml={mobile360 ? 2 : smonly ? 6 : 4}
                >
                  <FreedomButton1
                    // sx={{  mr: -12 }}
                    btnbackground={theme.palette.primary.default}
                    btncolor={theme.palette.primary.onBoardTextColor}
                    btnhovercolor={'none'}
                    texthovercolor={theme.palette.primary.onBoardTextColor}
                    variant="contained"
                    height={'34px'}
                    // height={mobile360 ? '24px' : smonly ? '34px' :''}
                    // width={'112px'}
                    // width={mobile360 ? '88px' : smonly ? '112px' : ''}
                    lineheight={'24px'}
                    // padding={mobile360 ? '15px 14px' : '5px 27px'}
                    borderradius={'5px'}
                    fontsize={'12px'}
                    onClick={() => {
                      clickOnCard(id, title, price, expirydate);
                    }}
                  >
                    {t('Select')}
                  </FreedomButton1>
                </Grid>
              )}
            </Grid>
          </Grid>
        </VoucherWrapper>
      </CardBorder>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomVoucherCard;
