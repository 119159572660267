import { Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import FreedomWrapperRoot from '../../Components/SharedComponent/FreedomWrapperRoot/FreedomWrapperRoot';
import TradingMarketsContainer from '../components/TradingMarketsContainer/TradingMarketsContainer';
import ProductDesctiptionContainer from '../components/ProductDesctiptionContainer/ProductDesctiptionContainer';
// import { goldLiveCardData } from "../data";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsByTypeUrl, grtSubsctibedProducts } from '../../api/apiUrls/investmentApiUrls';
import useFetch from '../../CustomHooks/useFetch';
import InvestmentProductCard from '../components/Card/InvestmentProductCard';
import { useContext } from 'react';
import { ColorModeContext } from '../../store';
import { appInsights } from '../../auth/appInsight';
import { svgIcon, svgLinksDark, svgLinksLight } from '../../url/SvgLinks';
import { devConsoleLog } from '../../utils/utilityFunctions';

const EliteFooterBgImg = svgLinksDark.eliteDark;
const PlatinumBGDark = svgLinksDark.PlatinumBGDark;
const PlatinumBGLight = svgLinksLight.PlatinumBGLight;
const PlatinumCardbg = svgLinksLight.PlatinumCardbg;
const PlatinumIcon = svgIcon.PlatinumIcon;
const EliteIcon = svgIcon.eliteIcon;
const godldCardIcons = [
  {
    icon: PlatinumIcon,
    altText: 'PlatinumIcon'
  }
];

const InvestmentPlatinumProducts = ({ fullwidth, isUpgradePlatinum, _padding }) => {
  const theme = useTheme();
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  const [load, isLoading] = useState(true);
  // let subscribedProduct = useFetch(grtSubsctibedProducts());
  const mobile360 = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  const products = useSelector((state) => state.investmentProducts.products);
  const userProduct = useSelector((state) => state.investmentProducts.userProduct);
  const eliteCard = products[7];

  const eliteLiveCardData = [
    {
      heading: eliteCard?.name,
      subheading: `ROI ${eliteCard?.annualExpectedRoi}%​`,
      infoPoints: [
        // `Expected Annual ROI ${eliteCard?.annualExpectedRoi}%​`,
        `VPS Class ${eliteCard?.vpsclass}`,
        'Period 12 Month​',
        `Risk Profile ${eliteCard?.riskProfile}%​`,
        'Money Management​',
        'Risk Management​',
        // `Investment from $${eliteCard?.minimumBalance}​`,
        'Upgradable​'
      ],
      status: ['inactive', 'hover', 'selected', 'disable'],
      type: eliteCard?.name,
      apiData: eliteCard,
      cardName: eliteCard?.name
    }
  ];

  const id = userProduct?.id;
  devConsoleLog('_padding', _padding);
  try {
    return (
      <Grid container>
        {/* <Grid item xs={12} textalign="center">
        <PageHeading mb="24px">Products</PageHeading>
      </Grid> */}
        <Box width="100%">
          {/* <FreedomWrapperRoot table="true" marginTop="0"> */}
          <Box>
            <Box
              py={_padding ? _padding : 3}
              px={{ xs: 1.5, md: 3 }}
              sx={{
                backgroundImage: mode === 'light' ? `url(${PlatinumBGLight})` : `url(${PlatinumBGDark})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '15px'
              }}
              width={mobile360 || fullwidth ? '100%' : '70%'}
              margin="auto"
            >
              <Grid container>
                <Grid item xs={12} lg={fullwidth ? 5.5 : 6.1} pt={2} marginY="auto">
                  <ProductDesctiptionContainer
                    standardCardIcons={godldCardIcons}
                    goldLive={true}
                    header="PLATINUM PRODUCT"
                    description="Upgrade to Elite product with an adjustable risk ratio. Platinum has a risk profile of up to 50%, and the expected annual ROI is +50 %. Elite offers money management and risk management with extended sub-products.  "
                  />
                </Grid>
                <Grid item xs={12} lg={fullwidth ? 6.5 : 5.9}>
                  {eliteLiveCardData.map((card, idx) => {
                    const eliteCardData = {
                      activeBgImg: mode === 'light' ? PlatinumCardbg : EliteFooterBgImg,
                      inactiveBgImg: mode === 'light' ? PlatinumCardbg : EliteFooterBgImg,
                      inActiveIcon: EliteIcon,
                      // activeIcon: EliteIcon,
                      // color: "#C111FF",
                      selectedButtonbg: '#C111FF',
                      hoverButtonbg: '#C111FF',

                      color: '#C111FF',
                      hoveredBgImg: mode === 'light' ? PlatinumCardbg : EliteFooterBgImg,
                      textColor: mode === 'light' ? 'black' : 'white',
                      icon: EliteIcon,
                      subHeadingBgColor: mode === 'light' ? 'rgba(193, 17, 255, 0.30)' : 'rgba(255, 255, 255, 0.1)'
                    };
                    return (
                      <Box key={idx}>
                        <InvestmentProductCard cardProps={eliteCardData} card={card} cardStatus subscribed={userProduct?.id === card?.apiData?.id ? true : false} id={id} isUpgradePlatinum={isUpgradePlatinum} marginTop={0} />
                        {/* <InvestmentFooterCard
                          inactiveBgImg={eliteCardData.inactiveBgImg}
                          activeBgImg={eliteCardData.activeBgImg}
                          cardColor={eliteCardData.color}
                          card={card}
                          activeIcon={eliteCardData.activeIcon}
                          inActiveIcon={eliteCardData.inActiveIcon}
                          goldLive={true}
                          width='100%'
                          index={idx}
                        /> */}
                      </Box>
                    );
                  })}
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* </FreedomWrapperRoot> */}
        </Box>
        <Grid item xs={12} sm={6} md={4} lg={12}></Grid>
      </Grid>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default InvestmentPlatinumProducts;
