import { configureStore } from '@reduxjs/toolkit';
import userReducer from './../features/user/userSlice';
import invitationCodeReducer from './../features/user/invitationCodeSlice';
import locationReducer from './../features/location/locationSlice';
import geolocationReducer from './../features/location/geoLocationSlice';
import googleAddressSearchReducer from './../features/googleAddressSearch/googleAdressSearchSlice';
import sidebarToggleReducer from './../features/Sidebar/sideBarSlice';
import affiliateToggleReducer from './../features/affiliate/affiliateSlice';
import customerReducer from './../features/affiliate/customerSlice';
import binaryTreeReducer from './../features/binaryTree/binaryTreeSlice';
import bottomBarReducer from './../features/bottomBar/bottomBarSlice';
import onBoardReucer from '../features/onboard/onBoardSlice';
import investmentProducts from '../features/investment/productsSlice';
import pendingPaymentReducer from '../features/pendingPayment/PendingPaymentSlice';
import brokerReducer from '../features/broker/brokerSlice';
// import cashReducer from '../features/cash/cashSlice';
import tradingReducer from '../features/trading/tradingSlice';
import voucherReducer from '../features/voucher/voucherSlice';
import snackbarReducer from '../features/snackbar/snackbarSlice';
import productSelectionSlice from '../features/productSelection/productSelectionSlice';
import tradeHistorySlice from '../features/trading/tradeHistorySlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    location: locationReducer,
    invitationCode: invitationCodeReducer,
    googleAddressSearch: googleAddressSearchReducer,
    sidebar: sidebarToggleReducer,
    affiliate: affiliateToggleReducer,
    customer: customerReducer,
    binaryTree: binaryTreeReducer,
    bottomBar: bottomBarReducer,
    onBoardSlice: onBoardReucer,
    investmentProducts: investmentProducts,
    pendingPayments: pendingPaymentReducer,
    // cash: cashReducer,
    broker: brokerReducer,
    trading: tradingReducer,
    voucher: voucherReducer,
    geoLocation: geolocationReducer,
    snackbar: snackbarReducer,
    productSelection: productSelectionSlice,
    tradeHistory: tradeHistorySlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
