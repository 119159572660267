import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { appInsights } from '../../../auth/appInsight';

const useStyles = makeStyles((theme, props) => ({
  root: {
    fontFamily: 'Poppins',
    fontSize: '24px !important',
    fontWeight: 500,
    lineHeight: '36px',
    color: theme.palette.text.primary,
    ...props
    // fontFamily: "Montserrat"
    // backgroundColor: theme.palette.primary.main,
  }
}));
export const FreedomTypography = (props) => {
  const classes = useStyles();
  const { children } = props;
  try {
    return (
      <Typography className={classes.root} {...props}>
        {' '}
        {children}
      </Typography>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};
