import { Box } from '@mui/material';
import React, { useContext } from 'react';

import useStyle from '../../Style';
import { ColorModeContext } from '../../../store';
import { appInsights } from '../../../auth/appInsight';

const FreedomWrapper = (props) => {
  const { children } = props;
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  const classes = useStyle();
  try {
    return (
      <Box className={mode === 'light' ? classes.WrapperLight : classes.WrapperDark} {...props}>
        {children}
      </Box>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomWrapper;
