import { Box, Grid, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import NewInvestmentButton from '../Button/NewInvestmentButton';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { ColorModeContext } from '../../../store';
import { appInsights } from '../../../auth/appInsight';
import { devConsoleLog } from '../../../utils/utilityFunctions';

const Icon = styled('img')(({ theme }) => ({
  width: '32px',
  height: '32px',
  marginRight: '12px'
}));

const HeaderText = styled(Typography)((props) => ({
  width: props.goldLive ? '232px' : '222px',
  height: props.gold && '43px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: props.goldLive ? '30px' : '32px',
  lineHeight: '40px',
  letterSpacing: '0.418792px',
  textTransform: 'uppercase',
  color: props.theme.palette.primary.onBoardTextColor,
  margin: props.gold ? '0' : '16px 0 10px 0',
  paddingTop: props.silverLive && '16px'
}));

const Description = styled(Box)(({ gold, theme, mode }) => ({
  // width: "243px",
  // height: "108px",
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  // textTransform: "capitalize",
  color: theme.palette.primary.onBoardTextColor,
  marginBottom: gold && '12px',
  marginLeft: gold && '19px',
  width: '100%',
  // marginTop:'12px'
  [theme.breakpoints.down('md')]: {
    marginTop: '10px'
  }
}));

const ProductDesctiptionContainer = ({ silverCard, silverCardIcons, standardCardIcons, gold, header, description, goldLive, setProductTypeId, productTypeId, silverLive }) => {
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  const navigate = useNavigate();
  const theme = useTheme();
  try {
    return (
      <Grid item sm={12} md={silverCard ? 12 : gold ? 12 : 12} lg={silverCard ? 3.5 : gold || silverLive ? 12 : productTypeId === 2 ? 3.9 : 3.1} px={gold ? 4 : 0.1} mt={gold && 2}>
        <Box display={{ lg: gold ? 'flex' : goldLive ? 'inline-grid' : '' }} alignItems={gold || goldLive ? 'flex-start' : ''} justifyContent={gold ? 'center' : ''}>
          {/* <Box
          display={gold || (goldLive && "flex")}
          alignItems={goldLive && "center"}
        > */}
          <Box display="flex">
            {(silverCard || productTypeId === 2 ? silverCardIcons : standardCardIcons)?.map((obj) => (
              <Icon key={obj.altText} src={obj.icon} alt={obj.altText} />
            ))}
          </Box>
          <HeaderText gold={gold} goldLive={goldLive} silverLive={silverLive}>
            {header} {silverLive && <>products</>}
          </HeaderText>
          {/* </Box> */}
          <Description mode={mode} gold={gold}>
            {description}
          </Description>
        </Box>
        {!silverCard && !gold && !goldLive && (productTypeId < 2 || silverLive) && (
          <NewInvestmentButton
            fontsize="12px"
            // btnbackground=" rgba(115, 115, 115, 0.29)"
            // btnbackground1="#737373"
            width="100%"
            height="38px"
            btncolor={theme.palette.primary.onBoardTextColor}
            margin="20px 0 0 0"
            onClick={() => navigate('/upgradeprofile')}
            hoveredBackground={'rgba(115, 115, 115, 0.29)'}
          >
            Upgrade to Silver
          </NewInvestmentButton>
        )}

        {/* {silverLive && (
        <NewInvestmentButton
          fontsize='12px'
          btnbackground=' rgba(115, 115, 115, 0.29)'
          btnbackground1='#737373'
          width='100%'
          height='38px'
          btncolor='#D9D9D9'
          margin='20px 0 0 0'
          onClick={() => navigate('/upgradeprofile')}
        >
          Upgrade to Silver
        </NewInvestmentButton>
      )} */}
      </Grid>
    );
  } catch (error) {
    devConsoleLog(error);
    appInsights.trackException({ error: new Error(error) });
  }
};

export default ProductDesctiptionContainer;
