import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  user: {},
  userProfileData: {},
  selectedUserId: '',
  selectedUserArray: [],
  selectedNodesArray: [],
  selectedSearchAffiliateId: '',
  toggleTableAndInfo: false,
  searchedBinayNodeId: '',
  rootNode: [],
  treeToTableView: false,
  selectedTabOfDwonline: 0,
  tableOfTreeData: [],
  totalRanks: []
};
const binaryTreeSlice = createSlice({
  name: 'binaryTree',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    updateUserProfileData: (state, action) => {
      state.userProfileData = action.payload;
    },
    updateSelectedUserId: (state, action) => {
      state.selectedUserId = action.payload;
    },
    setSelectedUserArray: (state, action) => {
      state.selectedUserArray = [...state.selectedUserArray, action.payload];
    },
    updateSelectedNodesArray: (state, action) => {
      state.selectedNodesArray = action.payload;
    },
    updateSelectedSearchAffiliateId: (state, action) => {
      state.selectedSearchAffiliateId = action.payload;
    },

    updateNodeArrayAndSearchAffiliateId: (state, action) => {
      const { selectedNodesArray, selectedSearchAffiliateId } = action.payload;
      state.selectedNodesArray = selectedNodesArray;
      state.selectedSearchAffiliateId = selectedSearchAffiliateId;
    },
    updateToggleTableAndInfo: (state, action) => {
      state.toggleTableAndInfo = action.payload;
    },
    updateRootNode: (state, action) => {
      state.rootNode = action.payload;
    },
    updateSearchedBinayNodeId: (state, action) => {
      state.searchedBinayNodeId = action.payload;
    },
    updateTreeToTableView: (state, action) => {
      state.treeToTableView = action.payload;
    },
    updateSelectedTabOfDwonline: (state, action) => {
      state.selectedTabOfDwonline = action.payload;
    },
    updateTableOfTreeData: (state, action) => {
      state.tableOfTreeData = action.payload;
    },
    updateTotalRanks: (state, action) => {
      state.totalRanks = action.payload;
    }
  }
});

// destructure actions and reducer from the slice (or you can access as counterSlice.actions)
const { actions, reducer } = binaryTreeSlice;

// export individual action creator functions
export const {
  setUser,
  updateUserProfileData,
  updateSelectedUserId,
  setSelectedUserArray,
  updateSelectedNodesArray,
  updateSelectedSearchAffiliateId,
  updateToggleTableAndInfo,
  updateSearchedBinayNodeId,
  updateRootNode,
  updateTreeToTableView,
  updateSelectedTabOfDwonline,
  updateTableOfTreeData,
  updateNodeArrayAndSearchAffiliateId,
  updateTotalRanks
} = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;
