import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Menu, MenuItem, useTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import NotificationSideBar from '../Settings/Notifications/PushNotifications/NotificationSideBar';
import { ColorModeContext } from '../store';
import { useTranslation } from 'react-i18next';
import useLogout from '../CustomHooks/useLogout';
import { appInsights } from '../auth/appInsight';
import { images, topNavbar } from '../url/SvgLinks';

const avatarimage = images.avatarimage;
const freedomIcon = topNavbar.freedomIcon;
const Sun = topNavbar.Sun;
const darkMode = topNavbar.darkMode;

const useStyle = makeStyles((theme) => ({
  topNavBar: {
    zIndex: 5,
    width: '100%',
    maxWidth: '80vw',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    borderRadius: '0px 0px 20px 20px',
    borderTop: 'none',
    border: `2px solid ${theme.palette.border.border}`,
    background: `${theme.palette.topbar.barBgColor}`,
    // background: `${theme.palette.background.default}`,
    padding: '0 3%',
    top: '25px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw'
    }
  },
  freedomTextIcon: {
    Width: '124.94px',
    Height: '29.74px'
  },
  menuIcondiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    border: `2px solid ${theme.palette.icon.borderColor}`,
    // backgroundColor: `${theme.palette.icon.iconBgColor}`,
    '&:hover': {
      // backgroundColor: `${theme.palette.primary.tabHover}`,
    },
    borderRadius: '10px',
    margin: '10px',
    cursor: 'pointer'
  },

  menuProfilediv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    borderRadius: '10px',
    margin: '10px',
    cursor: 'pointer'
  },
  menuIcon: {
    Width: '17.88px',
    Height: '19px',
    background: `${theme.palette.icon.iconColor}`,
    '&:hover': {
      background: `${theme.palette.icon.iconHoverColor}`
    }
  }
}));

const TopNavBar1 = () => {
  const classes = useStyle();
  const theme = useTheme();
  const {
    colorMode: { toggleMode, mode }
  } = useContext(ColorModeContext);
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

  const isNotifiactionMenuBarOpen = Boolean(notificationAnchorEl);
  const isMenuOpen = Boolean(anchorEl);

  //Selector

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };
  const handleNotificationbarClose = () => {
    setNotificationAnchorEl(null);
  };
  const { handleLogOutRedirect } = useLogout();

  const handleProfileMenuOpen = (event) => {
    // devConsoleLog("image ", event)
    setAnchorEl(event.currentTarget);
  };

  const notificationMenuId = 'primary-search-account-men';
  const renderNotificationMenu = (
    <Menu
      anchorEl={notificationAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      id={notificationMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isNotifiactionMenuBarOpen}
      onClose={handleNotificationbarClose}
    >
      <NotificationSideBar handleNotificationbarClose={handleNotificationbarClose} />
    </Menu>
  );

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className=".mui-fixed"
    >
      <MenuItem
        //  sx={{borderBottom:"1px solid #A9A9A9"}}
        onClick={handleLogOutRedirect}
      >
        {t('Logout')}
      </MenuItem>
    </Menu>
  );
  try {
    return (
      <React.Fragment>
        <CssBaseline />
        <Box className={classes.topNavBar}>
          <Box className={classes.freedomTextIcon}>
            <img src={freedomIcon} style={{ height: 30, width: 125 }} alt="Freedom" />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box
              className={classes.menuIcondiv}
              // onClick={toggleMode}
              onClick={toggleMode}
            >
              {mode === 'dark' ? (
                // <FreedomFollowUpIcon iconimg={darkMode} borderradius="5px" />
                <img
                  src={Sun}
                  style={{
                    height: 32,
                    width: 32,
                    border: `2px solid ${theme.palette.border.border}`,
                    padding: '5px',
                    borderRadius: '10px'
                  }}
                  alt="Mode"
                />
              ) : (
                <img
                  src={darkMode}
                  style={{
                    height: 32,
                    width: 32,
                    border: `2px solid ${theme.palette.border.border}`,
                    padding: '5px',
                    borderRadius: '10px'
                  }}
                  alt="Mode"
                />
              )}
            </Box>

            <Box className={classes.menuProfilediv} onClick={handleProfileMenuOpen}>
              <img
                src={avatarimage}
                style={{
                  borderRadius: '8px',
                  width: '100%',
                  height: '100%',
                  border: `2px solid ${theme.palette.border.border}`
                }}
                alt="profile"
              />
            </Box>
          </Box>
        </Box>
        {renderMenu}
        {renderNotificationMenu}
      </React.Fragment>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default TopNavBar1;
